import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const adminurl = "https://tramango-core.com/api/";

export const getAllExperience = (payload) => {
  const URL = `${adminurl}experienceList?status=all`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const getExperienceWithID = (payload) => {
  const URL = `${adminurl}experienceView?id=${payload}`;
  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const verifyExperience = (payload) => {
  const URL = `${adminurl}experienceVerify?id=${payload}&verify=verified`;
  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const createInformational = (payload) => {
  const URL = `${adminurl}createExpInfo`;

  return axios(URL, {
    method: "POST",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const getInformationalwithID = (payload) => {
  const URL = `${adminurl}infoExpView?id=${payload}`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const getAllInformational = (payload) => {
  const URL = `${adminurl}infoAllExpView`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const updateInformational = (payload) => {
  const { data, id } = payload;
  const URL = `${adminurl}updateExpInfo/${id}`;

  return axios(URL, {
    method: "PUT",

    headers: setAuthHeaders(defaultHeader),
    data: data,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const deleteInformational = (payload) => {
  const URL = `${adminurl}deleteExpInfo/${payload}`;

  return axios(URL, {
    method: "DELETE",

    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
