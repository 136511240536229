import * as api from "../../services/auth-service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setToken, removeToken } from "../../utils/auth-helpers";

export const LOGIN = createAsyncThunk("LOG IN", async (payload) => {
  const response = await api.Login(payload);
  await setToken(true);
  return response;
});

export const getUserData = createAsyncThunk(
  "FETCH_USER_DATA",
  async (payload) => {
    const response = await api.getUserData();
    return response;
  }
);

export const LogOut = createAsyncThunk("LOG OUT", async (payload) => {
  const response = await api.LogOut(payload);
  await removeToken();
  return response;
});

export const get2FA = createAsyncThunk("GET 2FA", async (payload) => {
  const response = await api.get2FA(payload);
  return response;
});

export const verify2FA = createAsyncThunk("VERIFY 2FA", async (payload) => {
  const response = await api.verify2FA(payload);
  return response;
});
export const ChangePassword = createAsyncThunk(
  "Change Password",
  async (payload) => {
    const response = await api.changePassword(payload);
    return response;
  }
);
export const addKey = createAsyncThunk("ADD KEY", async (payload) => {
  const response = await api.addKey(payload);
  return response;
});

export const getAllKeys = createAsyncThunk("GET ALL KEYS", async (payload) => {
  const response = await api.getAllKeys(payload);
  return response;
});

export const getKey = createAsyncThunk("GET KEY", async (payload) => {
  const response = await api.getKey(payload);
  return response;
});

export const updateKey = createAsyncThunk("UPDATE KEY", async (payload) => {
  const response = await api.updateKey(payload);
  return response;
});
