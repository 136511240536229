import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const API_URL = process.env.REACT_APP_BASEURL;
const adminurl = "hrllo";

export const issueTicket = (payload) => {
  const URL = `${API_URL}/flight/flight-orders/${payload}/issuance`;
  return axios(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.error;
    });
};

export const cancelOrder = (payload) => {
  const URL = `${API_URL}/flight/flight-orders/${payload}`;
  return axios(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

const numberOfFlights = (payload) => {
  const URL = `${adminurl}numberOfFlight`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const flightBookings = (payload) => {
  const URL = `${adminurl}numberOfFlight?successful=true`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.bookings)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const numberOfSuccessfulFlights = (payload) => {
  const URL = `${adminurl}numberOfFlight?successful=true`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const numberOfFailedFlights = (payload) => {
  const URL = `${adminurl}numberOfFlight?failed=true`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const numberOfFailedFlightsforAYear = (payload) => {
  const URL = `${adminurl}numberOfFlight?year=2022&&failed=true`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const numberOfSuccessfulFlightsforAYear = (payload) => {
  const URL = `${adminurl}numberOfFlight?year=2022&successful=true`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const numberOfFlightsforAYear = (payload) => {
  const URL = `${adminurl}numberOfFlight?year=2022`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const numberOfFailedFlightsForMonth = (payload) => {
  const URL = `${adminurl}numberOfFlight?month=2022-08&failed=true`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const numberOfSuccessfulFlightsForMonth = (payload) => {
  const URL = `${adminurl}numberOfFlight?month=2022-08&successful=true`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const numberOfFlightsForMonth = (payload) => {
  const URL = `${adminurl}numberOfFlight?month=2022-08`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data.count)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
