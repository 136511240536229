import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/auth";
import financeReducer from "../features/finance/finance";
import flightReducer from "../features/flight/flight";
import insuranceReducer from "../features/insurance/insurance";
import staffReducer from "../features/staff/staff";
import vendorReducer from "../features/vendor/vendor";
import staysReducer from "../features/stays/stay";
import experienceReducer from "../features/experience/experience";
import userReducer from "../features/user/user";
import advertReducer from "../features/advert/advert";
import { combineReducers } from "redux";

const reducers = combineReducers({
  auth: authReducer,
  finance: financeReducer,
  flight: flightReducer,
  insurance: insuranceReducer,
  staff: staffReducer,
  vendor: vendorReducer,
  stays: staysReducer,
  experience: experienceReducer,
  user: userReducer,
  advert: advertReducer,
});

export default configureStore({
  reducer: reducers,
});
