import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  getAllExperience,
  createInformational,
  getAllInformational,
  getInformationalwithID,
  updateInformational,
  getExperienceWithID,
  verifyExperience,
  deleteInformational,
} from "./experienceThunk";
const initialState = {
  experience: [],
  loading: false,
  informational: [],
  selected_experience: null,
  selected_informational: null,
  message: null,
};
export const experienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {},
  extraReducers: {
    [deleteInformational.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteInformational.fulfilled]: (state, action) => {
      state.message = action.payload;
      state.loading = false;
      toast.success("Experience Delete", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [deleteInformational.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Experience cant be delete", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [verifyExperience.pending]: (state, action) => {
      state.loading = true;
    },
    [verifyExperience.fulfilled]: (state, action) => {
      state.message = action.payload;
      state.loading = false;
      toast.success("Experience Verified", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [verifyExperience.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Experience cant be verified", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getExperienceWithID.pending]: (state, action) => {
      state.loading = true;
    },
    [getExperienceWithID.fulfilled]: (state, action) => {
      state.selected_experience = action.payload;
      state.loading = false;
      toast.success("Experience Fetched", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getExperienceWithID.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Experience cant be fetched", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [updateInformational.pending]: (state, action) => {
      state.loading = true;
    },
    [updateInformational.fulfilled]: (state, action) => {
      state.message = action.payload;
      state.loading = false;
    },
    [updateInformational.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllExperience.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllExperience.fulfilled]: (state, action) => {
      state.experience = action.payload;
      state.loading = false;
    },
    [getAllExperience.rejected]: (state, action) => {
      state.loading = false;
    },
    [createInformational.pending]: (state, action) => {
      state.loading = true;
    },
    [createInformational.fulfilled]: (state, action) => {
      state.message = action.payload;
      state.loading = false;
      toast.success("Informational Experience Created", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [createInformational.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Informational Experience cant be created", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getAllInformational.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllInformational.fulfilled]: (state, action) => {
      state.informational = action.payload;
      state.loading = false;
    },
    [getAllInformational.rejected]: (state, action) => {
      state.loading = false;
    },
    [getInformationalwithID.pending]: (state, action) => {
      state.loading = true;
      toast.error("Experience cant be fetched", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getInformationalwithID.fulfilled]: (state, action) => {
      state.selected_informational = action.payload;
      state.loading = false;
      toast.success("Experience Fetched", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getInformationalwithID.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default experienceSlice.reducer;
