import { createSlice } from "@reduxjs/toolkit";
import { flightBookings, issueTicket, cancelOrder } from "./flightThunk";

const initialState = {
  bookings: [],
  thenumberofFlights: null,
  thenumberofSuccessfulFlights: null,
  thenumberofFailedFlights: null,
  numberofFlightsInMonth: null,
  numberofSuccessfulFlightsInMonth: null,
  numberofFailedFlightsInMonth: null,
  numberofFailedFlightsInYear: null,
  numberofFlightsInYear: null,
  numberofSuccessfulFlightsInYear: null,
  flightLoader: false,
};

export const flightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {},
  extraReducers: {
    [flightBookings.pending]: (state, action) => {
      state.flightLoader = true;
    },
    [flightBookings.fulfilled]: (state, action) => {
      state.bookings = action.payload;
      state.flightLoader = false;
    },
    [flightBookings.rejected]: (state, action) => {
      state.flightLoader = false;
    },

    [issueTicket.pending]: (state, action) => {
      state.flightLoader = true;
    },
    [issueTicket.fulfilled]: (state, action) => {
      state.thenumberofFailedFlights = action.payload;
      state.flightLoader = false;
    },
    [issueTicket.rejected]: (state, action) => {
      state.flightLoader = false;
    },
    [cancelOrder.pending]: (state, action) => {
      state.flightLoader = true;
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.thenumberofSuccessfulFlights = action.payload;
      state.flightLoader = false;
    },
    [cancelOrder.rejected]: (state, action) => {
      state.flightLoader = false;
    },
  },
});
export default flightSlice.reducer;
