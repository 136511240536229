import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Space,
  Modal,
  Input,
  Tag,
  Select,
  Skeleton,
} from "antd";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import StatisticWidget from "../../components/shared-components/StatisticWidget";

import "./Staff.scss";
import {
  addAdmin,
  allStaff,
  deleteAccount,
  disableAdmin,
  enableAdmin,
} from "../../features/staff/staffThunk";
import { allUsers } from "../../features/user/userThunk";

const Staff = () => {
  const dispatch = useDispatch();
  const { users, usersLoader } = useSelector((state) => state.user);
  const staff = users?.admins;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [adminType, setAdminType] = useState("");
  const { Option } = Select;
  useEffect(() => {
    dispatch(allUsers());
  }, [dispatch]);
  useEffect(() => {
    setIData(staff);
  }, [users]);
  const [idata, setIData] = useState(staff);

  const searchTerm = (term) => {
    // const result = data.filter((item) => item.order_id === orderId);
    const result = staff.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(term.toLowerCase());
    });
    if (result) {
      setIData(result);
    } else {
      setIData(staff);
    }

    console.log(result);
  };
  const dat = {
    email,
    firstName: first_name,
    lastName: last_name,
    adminType,
  };

  const enabledata = {
    email,
    disable: false,
  };
  const disabledata = {
    email,
    disable: true,
  };
  console.log(dat);
  const handleEnable = (e, payload) => {
    e.preventDefault();
    dispatch(enableAdmin(payload));
  };
  const handleDisable = (e, payload) => {
    e.preventDefault();
    dispatch(disableAdmin(payload));
  };
  const handleDelete = (e, id) => {
    e.preventDefault();
    dispatch(deleteAccount(id));
  };
  const onChange = (value) => {
    setAdminType(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(addAdmin(dat));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email Adress",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "admin_section_id",
      key: "admin_section_id",
      render: (_, { admin_section_id }) => (
        <>
          {admin_section_id === 1 && <Tag color="red">SUPER ADMIN</Tag>}
          {admin_section_id === 2 && <Tag color="blue">UTILITY ADMIN</Tag>}
          {admin_section_id === 3 && <Tag color="green">FINANCE ADMIN</Tag>}
          {admin_section_id === 4 && <Tag color="blue">ENGINEER</Tag>}
          {admin_section_id === 5 && <Tag color="orange">FLIGHT ADMIN</Tag>}
          {admin_section_id === 6 && <Tag color="green">MARKETING ADMIN</Tag>}
          {admin_section_id === 7 && <Tag color="purple">INSURANCE ADMIN</Tag>}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "disabled",
      key: "disabled",
      render: (disabled) => (
        <>
          {disabled ? (
            <Tag color="red">DISABLED</Tag>
          ) : (
            <Tag color="green">ACTIVE</Tag>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Suspend {record.first_name}</a> */}
          {record.disabled ? (
            <Button
              onClick={(e) =>
                handleEnable(e, {
                  email: record.email,
                  disable: false,
                })
              }
            >
              ENABLE {record.firstName}
            </Button>
          ) : (
            <Button
              onClick={(e) =>
                handleDisable(e, {
                  email: record.email,
                  disable: true,
                })
              }
            >
              DISABLE {record.firstName}
            </Button>
          )}
          <Button onClick={(e) => handleDelete(e, record._id)}>Delete</Button>
        </Space>
      ),
    },
  ];
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <Modal
          title="Add a new Staff"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Input
            placeholder="Tramango Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="First Name"
            onChange={(e) => setFirst_name(e.target.value)}
          />
          <Input
            placeholder="Last Name"
            onChange={(e) => setLast_name(e.target.value)}
          />

          <Select
            showSearch
            placeholder="Select Admin type"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value={"SUPER"}>Super Admin</Option>
            <Option value={"UTILITY"}>Utility Admin</Option>
            <Option value={"FINANCE"}>Finance Admin</Option>
            <Option value={"FLIGHT"}>Flight Admin</Option>
            <Option value={"ENGINEER-1"}>Level 1 Engineer</Option>
            <Option value={"ENGINEER-2"}>Level 2 Engineer</Option>
            <Option value={"ENGINEER-3"}>Level 3 Engineer</Option>
            <Option value={"MARKETING"}>Marketing Admin</Option>
          </Select>
        </Modal>
        <div className="widgets"></div>
        <div style={{ width: 250 }} className=" p-2">
          <StatisticWidget
            title="Total Number of Staff"
            value={staff?.length}
            status={8.8}
            subtitle="Past 30 days"
            isLoading={usersLoader}
          />
        </div>
        <div className="charts"></div>
        <div className="listContainer">
          <Button onClick={showModal} type="primary">
            Add staff
          </Button>
          <div className="listTitle">Staffs</div>

          <input
            style={{ width: "70%", padding: "9px", marginBottom: "10px" }}
            type="text"
            placeholder="Search Staffs"
            onChange={(e) => searchTerm(e.target.value)}
          />
          {usersLoader ? (
            <Skeleton active />
          ) : (
            <Table columns={columns} dataSource={idata} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Staff;
