export const AAL = ["Aalborg", "Aalborg Airport", "Denmark", "AAL"];
export const AAR = ["Aarhus", "Aarhus Airport", "Denmark", "AAR"];
export const ABD = ["Abadan", " Abadan International Airport", "Iran", "ABD"];
export const PKX = [
  "Beijing",
  "Beijing Daxing International Airport",
  "China",
  "PKX",
];
export const YXX = [
  "Abbotsford",
  "Abbotsford International",
  "Canada (BC)",
  "YXX",
];
export const AEH = ["Abeche", "Abeche Airport", "Chad", "AEH"];
export const ABZ = [
  "Aberdeen",
  "Aberdeen International Airport",
  "Scotland, UK",
  "ABZ",
];
export const ABR = [
  "Aberdeen (SD)",
  " Aberdeen Regional Airport",
  "USA",
  "ABR",
];
export const ABJ = [
  "Abidjan",
  "Félix-Houphouët-Boigny International   (fr)",
  "Cote d'Ivoire",
  "ABJ",
];
export const ABI = [
  "Abilene (TX)",
  " Abilene Regional Airport  (GA)",
  "USA",
  "ABI",
];
export const AUH = [
  "Abu Dhabi",
  "Abu Dhabi International",
  "United Arab Emirates",
  "AUH",
];
export const ABV = [
  "Abuja",
  "Nnamdi Azikiwe International Airport",
  "Nigeria",
  "ABV",
];
export const AUE = ["Abu Rudeis", "Abu Rudeis Airport", "Egypt", "AUE"];
export const ABS = ["Abu Simbel", "Abu Simbel Airport", "Egypt", "ABS"];
export const ACA = [
  "Acapulco",
  "Acapulco International Airport",
  "Mexico",
  "ACA",
];
export const ACC = ["Accra", "Kotoka International Airport", "Ghana", "ACC"];
export const LCG = ["A Coruna (Corunna)", "A Coruña Airport", "Spain", "LCG"];
export const ADA = ["Adana", " Adana Sakirpasa Airport", "Turkey", "ADA"];
export const ADD = [
  "Addis Ababa",
  "Addis Ababa Bole International Airport",
  "Ethiopia",
  "ADD",
];
export const ADL = ["Adelaide", "Adelaide Airport", "Australia", "ADL"];
export const ADE = ["Aden", "Aden International Airport", "Yemen", "ADE"];
export const ADF = ["Adiyaman", " Adıyaman Airport", "Turkey", "ADF"];
export const AER = [
  "Adler/Sochi",
  "Adler-Sochi International Airport",
  "Russia",
  "AER",
];
export const AJY = [
  "Agadez",
  " Mano Dayak International Airport",
  "Niger",
  "AJY",
];
export const AGA = [
  "Agadir (Amazigh)",
  " Agadir Massira Airport",
  "Morocco",
  "AGA",
];
export const AGZ = ["Aggeneys", " Aggeneys Airport", "South Africa", "AGZ"];
export const AJI = ["Agri (AğrU+0131)", "Agri Airport", "Turkey", "AJI"];
export const BQN = [
  "Aguadilla",
  "Rafael Hernández Airport",
  "Puerto Rico",
  "BQN",
];
export const AGU = [
  "Aguascalientes",
  "Aguascalientes International Airport",
  "Mexico",
  "AGU",
];
export const AMD = [
  "Ahmedabad",
  "Ahmedabad International Airport",
  "Gujarat, India",
  "AMD",
];
export const AYU = ["Aiyura", " Aiyura Airport", "Papua New Guinea", "AYU"];
export const AJA = [
  "Ajaccio",
  "Ajaccio Napoleon Bonaparte Airport",
  "Corsica, France",
  "AJA",
];
export const AXT = ["Akita", "Akita Airport", "Japan", "AXT"];
export const CAK = [
  "Akron (OH)",
  " Akron-Canton Regional Airport",
  "USA",
  "CAK",
];
export const AKT = [
  "Akrotiri (British Overseas Territory)",
  "Royal Air Force Akrotiri",
  "Cyprus",
  "AKT",
];
export const AAN = [
  "Al Ain",
  "Al Ain International Airport",
  "United Arab Emirates",
  "AAN",
];
export const AAC = ["Al Arish", " Arish International Airport", "Egypt", "AAC"];
export const AHU = [
  "Al Hoceima",
  " Sharif Al ldrissi Airport",
  "Morocco",
  "AHU",
];
export const ALH = ["Albany", "Albany Airport", "Australia", "ALH"];
export const ABY = [
  "Albany (GA)",
  " Southwest Georgia Regional Airport",
  "USA",
  "ABY",
];
export const ALB = [
  "Albany (NY)",
  "Albany International Airport",
  "USA",
  "ALB",
];
export const LBI = ["Albi", "Le Sequestre Airport  (GA)", "France", "LBI"];
export const ABQ = [
  "Albuquerque (NM)",
  "Albuquerque Sunport Airport",
  "USA",
  "ABQ",
];
export const ABX = ["Albury", "Albury Airport", "Australia", "ABX"];
export const ACI = [
  "Alderney",
  " Alderney Airport",
  "Channel Islands (UK)",
  "ACI",
];
export const ALP = ["Aleppo", " Aleppo International Airport", "Syria", "ALP"];
export const AES = ["Alesund", " Ålesund Airport, Vigra", "Norway", "AES"];
export const ALJ = [
  "Alexander Bay",
  "Kortdoorn Airport",
  "South Africa",
  "ALJ",
];
export const HBE = ["Alexandria", "Borg El Arab Airport", "Egypt", "HBE"];
export const ALY = ["Alexandria", "El Nhouza Airport", "Egypt", "ALY"];
export const AEX = [
  "Alexandria (LA)",
  "Alexandria International Airport",
  "USA",
  "AEX",
];
export const ESF = ["Alexandria (LA)", "Esler Field", "USA", "ESF"];
export const AHO = [
  "Alghero Sassari",
  "Fertilia Airport (Alghero Airport)",
  "Sardinia, Italy",
  "AHO",
];
export const ALG = ["Algiers", "Houari Boumediene Airport", "Algeria", "ALG"];
export const ALC = ["Alicante", "Alicante-Elche Airport", "Spain", "ALC"];
export const ASP = [
  "Alice Springs",
  " Alice Springs Airport",
  "Australia",
  "ASP",
];
export const ADY = [
  "Alldays",
  " Alldays Airport",
  "Limpopo, South Africa",
  "ADY",
];
export const ABE = [
  "Allentown (PA)",
  "Lehigh Valley International Airport",
  "USA",
  "ABE",
];
export const ALA = [
  "Almaty",
  "Almaty International Airport",
  "Kazakhstan",
  "ALA",
];
export const LEI = [
  "Almeria",
  " Almería International Airport",
  "Spain",
  "LEI",
];
export const ALF = ["Alta", " Alta Airport", "Norway", "ALF"];
export const AAT = ["Altay", "Altay Airport", "Xinjiang, China", "AAT"];
export const ACH = [
  "Altenrhein, St. Gallen",
  " People's Business Airport",
  "Switzerland",
  "ACH",
];
export const AOO = [
  "Altoona (PA)",
  "Altoona-Blair County Airport",
  "USA",
  "AOO",
];
export const AXS = [
  " Altus (OK)",
  "Altus/Quartz Mountain Regional Airport",
  "USA",
  "AXS",
];
export const ASJ = ["Amami", "Amami Airport Amami Island", "Japan", "ASJ"];
export const AMA = [
  "Amarillo (TX)",
  "Rick Husband Amarillo Airport",
  "USA",
  "AMA",
];
export const AZB = [
  "Amazon Bay/Deba",
  " Amazon Bay Airport",
  "Deba, Papua New Guinea",
  "AZB",
];
export const AMM = [
  "Amman",
  "Queen Alia International Airport",
  "Jordan",
  "AMM",
];
export const ADJ = ["Amman", "Amman Civil Airport", "Jordan", "ADJ"];
export const ATQ = [
  "Amritsar",
  "Sri Guru Ram Dass Jee International Airport",
  "Punjab, India",
  "ATQ",
];
export const AMS = [
  "Amsterdam",
  "Amsterdam Airport Schiphol  (Luchthaven Schiphol)",
  "Netherlands",
  "AMS",
];
export const ANC = [
  "Anchorage (AK)",
  "Ted Stevens Anchorage International",
  "USA",
  "ANC",
];
export const AOI = ["Ancona", "Ancona Falconara Airport", "Italy", "AOI"];
export const ALV = ["Andorra La Vella", "Heliport", "Andorra", "ALV"];
export const AXA = [
  "Anguilla/The Valley",
  "Wallblake Airport",
  "Anguilla",
  "AXA",
];
export const AJN = ["Anjouan", "Anjouan Airport", "Comoros (Comores)", "AJN"];
export const ANK = ["Ankara", "Metropolitan Area", "Turkey", "ANK"];
export const ESB = ["Ankara", "Ankara Esenboğa Airport", "Turkey", "ESB"];
export const AAE = [
  "Annaba",
  "Rabah Bitat Airport (Les Salines Airport)",
  "Algeria",
  "AAE",
];
export const ARB = [
  "Ann Arbor",
  "Ann Arbor Municipal Airport",
  "MI, USA",
  "ARB",
];
export const NCY = ["Annecy", "Meythet Airport", "France", "NCY"];
export const ANB = [
  "Anniston (AL)",
  "Anniston Metropolitan Airport",
  "USA",
  "ANB",
];
export const AYT = ["Antalya", "Antalya Airport", "Turkey", "AYT"];
export const TNR = [
  "Antananarivo",
  "Ivato International Airport",
  "Madagascar",
  "TNR",
];
export const ANF = [
  "Antofagasta",
  "Cerro Moreno International Airport  (es)",
  "Chile",
  "ANF",
];
export const ANR = [
  "Antwerp",
  "Antwerp International Airport",
  "Belgium",
  "ANR",
];
export const AOJ = ["Aomori", "Aomori Airport", "Japan", "AOJ"];
export const APW = ["Apia", "Faleolo International Airport", "Samoa", "APW"];
export const ATW = [
  "Appelton/Neenah/Menasha",
  "Outagamie County Regional Airport",
  "WI, USA",
  "ATW",
];
export const AQJ = [
  "Aqaba",
  " King Hussein International Airport",
  "Jordan",
  "AQJ",
];
export const AJU = ["Aracaju", "Santa Maria Airport", "Brazil", "AJU"];
export const AQP = [
  "Arequipa",
  "Rodriguez Ballon International Airport",
  "Peru",
  "AQP",
];
export const ARH = ["Arkhangelsk", "Talagi Airport", "Russia", "ARH"];
export const ARK = ["Arusha", "Arusha Airport", "Tanzania", "ARK"];
export const GPA = ["Araxos/Patras", " Araxos Airport", "Greece", "GPA"];
export const RLT = ["Arlit", " Arlit Airport", "Niger", "RLT"];
export const ACE = ["Arrecife/Lanzarote", "Lanzarote Airport", "Spain", "ACE"];
export const AVL = [
  "Asheville",
  "Asheville Regional Airport",
  "NC, USA",
  "AVL",
];
export const ASB = [
  "Ashgabat",
  "Ashgabat International Airport",
  "Turkmenistan",
  "ASB",
];
export const ASM = ["Asmara", "Asmara International Airport", "Eritrea", "ASM"];
export const ASE = ["Aspen (CO)", "Aspen-Pitkin County Airport", "USA", "ASE"];
export const ATZ = ["Assiut", " Assiut Airport", "Egypt", "ATZ"];
export const TSE = [
  "Astana",
  "Astana International Airport",
  "Kazakhstan",
  "TSE",
];
export const ASU = [
  "Asuncion",
  "Asunción International Airport",
  "Paraguay",
  "ASU",
];
export const ASW = ["Aswan", "Daraw Airport", "Egypt", "ASW"];
export const ATH = ["Athens", "Athens International Airport", "Greece", "ATH"];
export const HEW = [
  "Athens",
  "Hellinikon International Airport (closed)",
  "Greece",
  "HEW",
];
export const AHN = ["Athens (GA)", "Athens-Ben Epps Airport", "USA", "AHN"];
export const ATO = [
  "Athens (OH)",
  "Ohio University Airport (Snyder Field)",
  "USA",
  "ATO",
];
export const ATL = [
  "Atlanta (GA)",
  "Hartsfield-Jackson Atlanta International",
  "USA",
  "ATL",
];
export const ACY = [
  "Atlantic City (NJ)",
  "Atlantic City International Airport",
  "USA",
  "ACY",
];
export const YAT = [
  "Attawapiskat",
  "Attawapiskat Airport",
  "NT, Canada",
  "YAT",
];
export const AKL = [
  "Auckland",
  "Auckland International Airport",
  "New Zealand",
  "AKL",
];
export const AGB = ["Augsburg", "Augsburg Airport", "Germany", "AGB"];
export const AGS = [
  "Augusta (GA)",
  "Augusta Regional Airport at Bush Field",
  "USA",
  "AGS",
];
export const AUG = ["Augusta (ME)", "Augusta State Airport", "USA", "AUG"];
export const AUR = ["Aurillac", "Aurillac Airport", "France", "AUR"];
export const AUS = [
  "Austin",
  "Austin-Bergstrom International Airport",
  " (TX) USA",
  "AUS",
];
export const AYW = ["Ayawasi", "Ayawasi Airport", "Indonesia", "AYW"];
export const AYQ = [
  "Ayers Rock",
  "Ayers Rock Airport (Connellan)",
  "Australia",
  "AYQ",
];
export const AYR = ["Ayr", " Ayr Airport", "Australia", "AYR"];
export const BCD = [
  "Bacolod",
  "Bacolod–Silay International Airport",
  "Philippines",
  "BCD",
];
export const BCM = ["Bacău", "Bacău International Airport", "Romania", "BCM"];
export const BJZ = ["Badajoz", "Badajoz Airport", "Spain", "BJZ"];
export const BGW = ["Bagdad", "Baghdad International Airport", "Iraq", "BGW"];
export const IXB = ["Bagdogra", "Bagdogra Airport", "India", "IXB"];
export const BHV = ["Bahawalpur", " Bahawalpur Airport", "Pakistan", "BHV"];
export const BFL = ["Bakersfield (CA)", " Meadows Field", "USA", "BFL"];
export const BAK = ["Baku", "Baku International Airport", "Azerbaijan", "BAK"];
export const BPN = [
  "Balikpapan",
  "Sepinggan Airport",
  "Kalimantan, Indonesia",
  "BPN",
];
export const BNK = ["Ballina", "Ballina Airport", "Australia", "BNK"];
export const ABM = ["Bamaga", "Bamaga Injinoo Airport", "Australia", "ABM"];
export const BKO = [
  "Bamako",
  "Bamako-Sénou International Airport",
  "Mali",
  "BKO",
];
export const BBY = [
  "Bambari",
  "Bambari Airport",
  "Central African Republic",
  "BBY",
];
export const BTJ = [
  "Banda Aceh",
  "Sultan Iskandar Muda International Airport",
  "Indonesia",
  "BTJ",
];
export const BND = [
  "Bandar Abbas",
  "Bandar Abbas International Airport  (fa)",
  "Iran",
  "BND",
];
export const BWN = [
  "Bandar Seri Begawan",
  "Brunei International Airport",
  "Brunei",
  "BWN",
];
export const BDO = [
  "Bandung",
  "Husein Sastranegara International Airport",
  "Indonesia",
  "BDO",
];
export const BLR = [
  "Bangalore",
  "Bengaluru International Airport",
  "India",
  "BLR",
];
export const BGU = [
  "Bangassou",
  "Bangassou Airport",
  "Central African Republic",
  "BGU",
];
export const BKK = [
  "Bangkok",
  "Suvarnabhumi International Airport",
  "Thailand",
  "BKK",
];
export const DMK = [
  "Bangkok",
  "Don Mueang International Airport",
  "Thailand",
  "DMK",
];
export const BGR = [
  "Bangor (ME)",
  "Bangor International Airport",
  "USA",
  "BGR",
];
export const BGF = [
  "Bangui",
  "Bangui M'Poko International Airport",
  "Central African Republic",
  "BGF",
];
export const BNX = [
  "Banja Luka",
  " Banja Luka Airport",
  "Bosnia and Herzegovina",
  "BNX",
];
export const BDJ = [
  "Banjarmasin",
  "Syamsudin Noor Airport",
  "Kalimantan, Indonesia",
  "BDJ",
];
export const BJL = [
  "Banjul",
  "Banjul Yundum International Airport",
  "The Gambia",
  "BJL",
];
export const BNP = ["Bannu", "Bannu Airport", "Pakistan", "BNP"];
export const BAV = [
  "Baotou",
  "Baotou Erliban Airport",
  "Inner Mongolia, China",
  "BAV",
];
export const BCN = ["Barcelona", "Barcelona El Prat Airport", "Spain", "BCN"];
export const BLA = [
  "Barcelona",
  "José Antonio Anzoátegui International",
  "Venezuela",
  "BLA",
];
export const BDU = ["Bardufoss", "Bardufoss Airport", "Norway", "BDU"];
export const BRI = ["Bari", "Puglia Airport", "Italy", "BRI"];
export const BZ = ["Barisal", "Barisal Airport", "Bangladesh", " BZL"];
export const BDQ = ["Baroda (Vadodara)", "Vadodara Airport", "India", "BDQ"];
export const BRR = [
  "Barra",
  " Barra Airport (Northbay Airport)",
  "Scotland, United Kingdom",
  "BRR",
];
export const BAQ = [
  "Barranquilla",
  "Ernesto Cortissoz International Airport",
  "Colombia",
  "BAQ",
];
export const BS = ["Basel", "Metropolitan Area", "Switzerland", " BSL"];
export const EAP = [
  "Basel",
  "EuroAirport Basel-Mulhouse-Freiburg",
  "Switzerland",
  "EAP",
];
export const BSR = [
  "Basra - (Basrah)",
  " Basrah International Airport",
  "Iraq",
  "BSR",
];
export const SKB = [
  "Basseterre",
  " Robert L. Bradshaw International Airport",
  "Saint Kitts and Nevis",
  "SKB",
];
export const BIA = ["Bastia", " Bastia - Poretta Airport", "France", "BIA"];
export const BTH = [
  "Batam",
  "Hang Nadim International Airport",
  "Riau Islands, Indonesia",
  "BTH",
];
export const BTR = [
  "Baton Rouge (LA)",
  "Baton Rouge Metropolitan Airport",
  "USA",
  "BTR",
];
export const BBM = ["Battambang", "Battambang Airport", "Cambodia", "BBM"];
export const BCU = ["Bauchi", "Bauchi State Airport", "Nigeria", "BCU"];
export const BYU = [
  "Bayreuth",
  "Bindlacher Berg Airport (GA)",
  "Germany",
  "BYU",
];
export const BPT = [
  "Beaumont/Pt. Arthur (TX)",
  "Jack Brooks Regional Airport",
  "USA",
  "BPT",
];
export const BVA = ["Beauvais", "Beauvais–Tillé Airport", "France", "BVA"];
export const BKW = [
  "Beckley (WV)",
  "Raleigh County Memorial Airport  (GA)",
  "USA",
  "BKW",
];
export const EIS = [
  "Beef Island",
  "Terrance B. Lettsome Airport",
  "Virgin Islands (British)",
  "EIS",
];
export const BHY = [
  "Beihai",
  "Beihai Fucheng Airport",
  "Guangxi, China",
  "BHY",
];
export const PEK = ["Beijing", "Beijing", "PR China", "PEK"];
export const BJS = [
  "Beijing",
  "Beijing Capital International Airport",
  "China",
  "BJS",
];
//  export const --- =[
//     "Beijing",
//     "Beijing Daxing International Airport (future airport under construction)",
//     "China",
//     "---"
//   ];
export const NAY = ["Beijing", "Beijing Nanyuan Airport", "China", "NAY"];
export const BEW = ["Beira", "Beira Airport", "Mozambique", "BEW"];
export const BEY = [
  "Beirut",
  "Beirut Rafic Hariri International Airport",
  "Lebanon",
  "BEY",
];
export const BEL = [
  "Belém",
  " Val de Cans International Airport",
  "Brazil",
  "BEL",
];
export const BFS = [
  "Belfast",
  "Belfast International Airport",
  "Northern Ireland, UK",
  "BFS",
];
export const BHD = [
  "Belfast",
  "George Best Belfast City Airport",
  "United Kingdom",
  "BHD",
];
export const IXG = ["Belgaum", "Belgaum Airport", "India", "IXG"];
export const BEG = [
  "Belgrad (Beograd; capital city)",
  "Belgrade Nikola Tesla International Airport",
  "Serbia",
  "BEG",
];
export const BZE = [
  "Belize City",
  "Philip S.W.Goldson International",
  "Belize",
  "BZE",
];
export const BLI = [
  "Bellingham (WA)",
  "Bellingham International Airport",
  "USA",
  "BLI",
];
export const BCV = ["Belmopan", "Hector Silva Airstrip", "Belize", "BCV"];
export const CNF = [
  "Belo Horizonte",
  "Tancredo Neves International Airport",
  "Brazil",
  "CNF",
];
export const PLU = [
  "Belo Horizonte",
  "Belo Horizonte Pampulha Airport",
  "Brazil",
  "PLU",
];
export const BJI = ["Bemidji (MN)", "Bemidji Regional Airport", "USA", "BJI"];
export const BEB = ["Benbecula", "Benbecula Airport", "United Kingdom", "BEB"];
export const BEN = [
  "Benghazi (Bengasi)",
  "Benina International Airport",
  "Libya",
  "BEN",
];
export const BUG = ["Benguela", " Benguela Airport", "Angola", "BUG"];
export const BNI = ["Benin City", "Benin City Airport", "Nigeria", "BNI"];
export const BEH = [
  "Benton Harbour (MI)",
  "Southwest Michigan Regional Airport  (GA)",
  "USA",
  "BEH",
];
export const BBT = [
  "Berberati",
  "Berberati Airport",
  "Central African Republic",
  "BBT",
];
export const BGO = ["Bergen", " Bergen Flesland Airport", "Norway", "BGO"];
export const EGC = [
  "Bergerac",
  "Bergerac Dordogne Périgord Airport",
  "France",
  "EGC",
];
export const BER = [
  "Berlin",
  "Berlin Brandenburg Airport  (coming soon, or not, you never know)",
  "Germany",
  "BER",
];
export const SXF = ["Berlin", "Berlin Schönefeld Airport", "Germany", "SXF"];
export const TXL = ["Berlin", "Berlin Tegel Airport", "Germany", "TXL"];
export const THF = ["Berlin", "Berlin Tempelhof (closed)", "Germany", "THF"];
export const BDA = [
  "Bermuda",
  "L.F. Wade International Airport",
  "Bermuda",
  "BDA",
];
export const BRN = ["Berne", "Bern Airport", "Switzerland", "BRN"];
export const BET = ["Bethel (AK)", "Bethel Airport", "USA", "BET"];
export const BMO = ["Bhamo", "Bhamo Airport", "Myanmar", "BMO"];
export const BHR = ["Bharatpur", "Bharatpur Airport", "Nepal", "BHR"];
export const BHO = ["Bhopal", "Bhopal Airport", "India", "BHO"];
export const BBI = [
  "Bhubaneswar",
  "Biju Patnaik International Airport",
  "India",
  "BBI",
];
export const BIQ = [
  "Biarritz",
  "Biarritz - Anglet - Bayonne Airport",
  "France",
  "BIQ",
];
export const BIO = ["Bilbao", "Bilbao Airport", "Spain", "BIO"];
export const BIL = [
  "Billings (MT)",
  "Billings Logan International Airport",
  "USA",
  "BIL",
];
export const BLL = ["Billund", "Billund Airport", "Denmark", "BLL"];
export const BTU = ["Bintulu", "Bintulu Airport", "Malaysia", "BTU"];
export const IRO = [
  "Biraro",
  " Birao Airport",
  "Central African Republic",
  "IRO",
];
export const BHX = [
  "Birmingham",
  "Birmingham Airport",
  "United Kingdom",
  "BHX",
];
export const BHM = [
  "Birmingham (AL)",
  "Birmingham–Shuttlesworth International",
  "USA",
  "BHM",
];
export const FRU = [
  "Bishkek",
  "Manas International Airport  (ru)",
  "Kyrgyzstan",
  "FRU",
];
export const BIS = [
  "Bismarck (ND)",
  "Bismarck Municipal Airport",
  "USA",
  "BIS",
];
export const BXO = [
  "Bissau",
  "Osvaldo Vieira International Airport",
  "Guinea-Bissau",
  "BXO",
];
export const BCB = [
  "Blacksburg (VA)",
  " Virginia Tech Montgomery Airport  (GA)",
  "USA",
  "BCB",
];
export const BLK = [
  "Blackpool",
  "Blackpool Airport  (GA)",
  "United Kingdom",
  "BLK",
];
export const BLT = [
  "Blackwater",
  " Blackwater Airport (GA)",
  "Australia",
  "BLT",
];
export const BLZ = [
  "Blantyre",
  "Chileka International Airport",
  "Malawi",
  "BLZ",
];
export const BHE = ["Blenheim", "Marlborough Airport", "New Zealand", "BHE"];
export const BFN = [
  "Bloemfontein (judicial capital)",
  "Bloemfontein Airport",
  "South Africa",
  "BFN",
];
export const BMI = [
  "Bloomington (IL)",
  "Central Illinois Regional Airport",
  "USA",
  "BMI",
];
export const BMG = [
  "Bloomington (IN)",
  "Greater Rochester International Airport (formerly Monroe County Airport)",
  "USA",
  "BMG",
];
export const BLF = ["Bluefield (WV)", "Mercer County Airport", "USA", "BLF"];
export const BYH = [
  "Blytheville (AR)",
  "Arkansas International Airport",
  "USA",
  "BYH",
];
export const BVB = [
  "Boa Vista",
  " Boa Vista International Airport",
  "Brazil",
  "BVB",
];
export const BVC = [
  "Boa Vista",
  "Boavista Airport",
  "Boa Vista, Cape Verde",
  "BVC",
];
export const BOY = [
  "Bobo Dioulasso",
  "Bobo Dioulasso Airport",
  "Burkina Faso",
  "BOY",
];
export const BOO = ["Bodø", "Bodø Airport", "Norway", "BOO"];
export const BJV = ["Bodrum", "Milas–Bodrum Airport", "Turkey", "BJV"];
export const BOG = [
  "Bogota",
  "El Dorado International Airport",
  "Colombia",
  "BOG",
];
export const BOI = ["Boise (ID)", "Boise Airport", "USA", "BOI"];
export const BKJ = [" Boké", "Boké Baralande Airport", "Guinea", "BKJ"];
export const BLQ = ["Bologna", "Bologna Airport", "Italy", "BLQ"];
export const BZO = ["Bolzano", "Bolzano Airport", "Italy", "BZO"];
export const BON = [
  "Bonaire",
  " Flamingo International Airport",
  "Netherlands Antilles",
  "BON",
];
export const YVB = ["Bonaventure", "Bonaventure Airport", "Canada", "YVB"];
export const BTE = [
  "Bonthe",
  "Sherbro Airport (abandoned)",
  "Sierra Leone",
  "BTE",
];
export const BOB = [
  "Bora Bora",
  "Bora Bora Airport  (Motu Mute Airport)",
  "French Polynesia",
  "BOB",
];
export const BOD = ["Bordeaux", "Bordeaux Airport", "France", "BOD"];
export const BXS = [
  "Borrego Springs (CA)",
  " Borrego Valley Airport",
  "USA",
  "BXS",
];
export const BSA = [
  "Bosaso",
  "Bender Qassim International Airport",
  "Somalia",
  "BSA",
];
export const BOS = ["Boston (MA)", "Logan International Airport", "USA", "BOS"];
export const BYK = ["Bouake", "Bouake Airport", "Cote d'Ivoire", "BYK"];
export const BLD = [
  "Boulder City (NV)",
  "Boulder City Municipal Airport  (GA)",
  "USA",
  "BLD",
];
export const BOJ = ["Bourgas/Burgas", "Burgas Airport", "Bulgaria", "BOJ"];
export const BOH = [
  "Bournemouth",
  "Bournemouth Airport",
  "United Kingdom",
  "BOH",
];
export const ZBO = ["Bowen", "Bowen Airport", "Australia", "ZBO"];
export const BZN = ["Bozeman (MT)", " Gallatin Field Airport", "USA", "BZN"];
export const BFD = [
  "Bradford/Warren (NY)",
  " Bradford Regional Airport  (GA)",
  "USA",
  "BFD",
];
export const BGZ = ["Braga", "Braga Airport", "Portugal", "BGZ"];
export const BRD = [
  "Brainerd (MN)",
  "Brainerd Lakes Regional Airport",
  "USA",
  "BRD",
];
export const BMP = [
  "Brampton Island",
  " Brampton Island Airport",
  "Australia",
  "BMP",
];
export const BSB = ["Brasilia", "Brasilia Airport", "Brazil", "BSB"];
export const BTS = [
  "Bratislava",
  "Bratislava Airport M. R. Štefánik",
  "Slovakia",
  "BTS",
];
export const BZV = ["Brazzaville", "Maya-Maya Airport", "Congo (ROC)", "BZV"];
export const BRE = ["Bremen", "City Airport Bremen", "Germany", "BRE"];
export const VBS = ["Brescia", "Brescia Airport", "Italy", "VBS"];
export const BES = ["Brest", "Brest Bretagne Airport", "France", "BES"];
export const BIV = ["Bria", "Bria Airport", "Central African Republic", "BIV"];
export const BDR = [
  "Bridgeport (CT)",
  "Sikorsky Memorial Airport (GA)",
  "USA",
  "BDR",
];
export const BGI = [
  "Bridgetown",
  "Grantley Adams International Airport",
  "Barbados",
  "BGI",
];
export const BDS = ["Brindisi", " Brindisi – Salento Airport", "Italy", "BDS"];
export const BNE = ["Brisbane", "Brisbane Airport", "Australia", "BNE"];
export const BRS = ["Bristol", "Bristol Airport", "United Kingdom", "BRS"];
export const BRQ = ["Brno", "Brno–Tuřany Airport", "Czech Republic", "BRQ"];
export const BNN = [
  "Broennoeysund",
  "Brønnøysund Airport, Brønnøy",
  "Norway",
  "BNN",
];
export const BHQ = ["Broken Hill", "Broken Hill Airport", "Australia", "BHQ"];
export const BKX = [
  "Brookings (SD)",
  " Brookings Regional Airport",
  "USA",
  "BKX",
];
export const BME = [
  "Broome",
  " Broome International Airport",
  "Australia",
  "BME",
];
export const BQK = [
  "Brunswick (GA)",
  "Brunswick Golden Isles Airport",
  "USA",
  "BQK",
];
export const BRU = ["Brussels", "Brussels Airport", "Belgium", "BRU"];
export const BGA = ["Bucaramanga", "Palonegro Airport", "Colombia", "BGA"];
export const BUH = ["Bucharest", "Metropolitan Area", "Romania", "BUH"];
export const OTP = [
  "Bucharest",
  "Henri Coandă International Airport",
  "Romania",
  "OTP",
];
export const BUD = [
  "Budapest",
  "Budapest Ferihegy International Airport",
  "Hungary",
  "BUD",
];
export const BUE = ["Buenos Aires", "Metropolitan Area", "Argentina", "BUE"];
export const AEP = [
  "Buenos Aires",
  "Aeroparque Jorge Newbery",
  "Argentina",
  "AEP",
];
export const BFO = [
  "Buffalo Range",
  "Buffalo Range Airport",
  "Zimbabwe",
  "BFO",
];
export const BUF = [
  "Buffalo/Niagara Falls (NY)",
  "Buffalo Niagara International Airport",
  "USA",
  "BUF",
];
export const BJM = [
  "Bujumbura",
  "Bujumbura International Airport",
  "Burundi",
  "BJM",
];
export const BUQ = [
  "Bulawayo",
  "Joshua Mqabuko Nkomo International",
  "Zimbabwe",
  "BUQ",
];
export const BDB = ["Bundaberg", "Bundaberg Airport", "Australia", "BDB"];
export const BUR = ["Burbank (CA)", "Burbank Bob Hope Airport", "USA", "BUR"];
export const BRL = [
  "Burlington (IA)",
  "Southeast Iowa Regional Airport  (GA)",
  "USA",
  "BRL",
];
export const BTV = [
  "Burlington (VT)",
  "Burlington International Airport",
  "USA",
  "BTV",
];
export const BWT = ["Burnie (Wynyard)", "Burnie Airport", "Australia", "BWT"];
export const BUZ = ["Bushehr", "Bushehr Airport", "Iran", "BUZ"];
export const BTM = ["Butte (MT)", "Bert Mooney Airport", "USA", "BTM"];
export const CAB = ["Cabinda", "Cabinda Airport", "Angola", "CAB"];
export const Bra = [
  "Cabo Frio",
  "Cabo Frio International Airport",
  "Rio de Janeiro",
  "Brazil",
];
export const CAG = ["Cagliari", " Cagliari Airport", "Sardinia, Italy", "CAG"];
export const CNS = ["Cairns", "Cairns Airport", "Australia", "CNS"];
export const CAI = ["Cairo", "Cairo International Airport", "Egypt", "CAI"];
export const CJC = ["Calama", "El Loa Airport", "Chile", "CJC"];
export const YYC = [
  "Calgary",
  "Calgary International Airport",
  "Canada",
  "YYC",
];
export const CLO = [
  "Cali",
  "Alfonso Bonilla Aragón International Airport",
  "Colombia",
  "CLO",
];
export const CLY = [
  "Calvi",
  "Calvi - Sainte-Catherine Airport",
  "Corsica, France",
  "CLY",
];
export const YCB = [
  "Cambridge Bay",
  "Cambridge Bay Airport",
  "Nunavut, Canada",
  "YCB",
];
export const CBG = ["Cambrigde", "Cambridge Airport", "United Kingdom", "CBG"];
export const CAL = [
  "Campbeltown",
  "Campbeltown Airport",
  "United Kingdom",
  "CAL",
];
export const CGR = [
  "Campo Grande",
  "Campo Grande International Airport",
  "Brazil",
  "CGR",
];
export const CBR = [
  "Canberra",
  "Canberra International Airport",
  "Australia",
  "CBR",
];
export const CUN = ["Cancun", "Cancún International Airport", "Mexico", "CUN"];
export const CIW = [
  "Canouan (island)",
  "Canouan Island Airport",
  "Saint Vincent & the Grenadines",
  "CIW",
];
export const CPT = [
  "Cape Town",
  "Cape Town International Airport",
  "South Africa",
  "CPT",
];
export const CCS = [
  "Caracas",
  "Simón Bolívar International Airport",
  "Venezuela",
  "CCS",
];
export const CWL = ["Cardiff", "Cardiff Airport", "United Kingdom", "CWL"];
export const CLD = ["Carlsbad (CA)", "McClellan–Palomar Airport", "USA", "CLD"];
export const CVQ = ["Carnarvon", "Carnarvon Airport", "Australia", "CVQ"];
export const CRF = [
  "Carnot",
  "Carnot Airport",
  "Central African Republic",
  "CRF",
];
export const CSN = [
  "Carson City (NV)",
  "Carson Airport  (GA)",
  "United States",
  "CSN",
];
export const CTG = [
  "Cartagena",
  "Rafael Núñez International Airport",
  "Colombia",
  "CTG",
];
export const LRM = [
  "La Romana",
  "La Romana International Airport",
  "Dominican Republic",
  "LRM",
];
export const CAS = ["Casablanca", "Anfa Airport", "Morocco", "CAS"];
export const CMN = [
  "Casablanca",
  "Mohammed V International Airport",
  "Morocco",
  "CMN",
];
export const CSI = ["Casino", "Casino Airport", "Australia", "CSI"];
export const CPR = [
  "Casper (WY)",
  "Natrona County International Airport",
  "USA",
  "CPR",
];
export const SLU = [
  "Castries",
  "George F. L. Charles Airport  (formerly Vigie Airport)",
  "Saint Lucia",
  "SLU",
];
export const CTA = ["Catania", "Catania–Fontanarossa Airport", "Italy", "CTA"];
export const CAY = [
  "Cayenne",
  "Cayenne-Rochambeau Airport",
  "French Guiana",
  "CAY",
];
export const CDC = [
  "Cedar City (UT)",
  " Cedar City Regional Airport",
  "USA",
  "CDC",
];
export const CID = [
  "Cedar Rapids (IA)",
  "The Eastern Iowa Airport",
  "USA",
  "CID",
];
export const CED = ["Ceduna", "Ceduna Airport", "Australia", "CED"];
export const CES = ["Cessnock", "Cessnock Airport", "Australia", "CES"];
export const CMF = ["Chambery", "Chambéry-Savoie Airport", "France", "CMF"];
export const CMI = [
  "Champaign (IL)",
  "University of Illinois Willard Airport",
  "USA",
  "CMI",
];
export const IXC = [
  "Chandigarh",
  "Chandigarh International Airport",
  "India",
  "IXC",
];
export const CGQ = [
  "Changchun",
  "Changchun Longjia International Airport",
  "Jilin, China",
  "CGQ",
];
export const CHQ = ["Chania", "Chania International Airport", "Greece", "CHQ"];
export const CHG = ["Chaoyang", "Chaoyang Airport", "Liaoning, China", "CHG"];
export const CHS = [
  "Charleston (SC)",
  "Charleston International Airport",
  "USA",
  "CHS",
];
export const CRW = ["Charleston (WV)", "Yeager Airport", "USA", "CRW"];
export const CLT = [
  "Charlotte (NC)",
  "Charlotte Douglas International Airport",
  "USA",
  "CLT",
];
export const CHO = [
  "Charlottesville (VA)",
  "Charlottesville-Albemarle Airport",
  "USA",
  "CHO",
];
export const CXT = [
  "Charters Towers",
  " Charters Towers Airport",
  "Australia",
  "CXT",
];
export const CHA = [
  "Chattanooga (TN)",
  "Chattanooga Metropolitan Airport",
  "USA",
  "CHA",
];
export const CTU = [
  "Chengdu",
  "Chengdu Shuangliu International Airport",
  "Sichuan, PR China",
  "CTU",
];
export const CYS = [
  "Cheyenne (WY)",
  " Cheyenne Regional Airport",
  "USA",
  "CYS",
];
export const CNX = [
  "Chiang Mai",
  "Chiang Mai International Airport",
  "Thailand",
  "CNX",
];
export const CHI = ["Chicago (IL)", "Metropolitan Area", "USA", "CHI"];
export const ORD = [
  "Chicago (IL)",
  "Chicago O'Hare International Airport",
  "USA",
  "ORD",
];
export const MDW = ["Chicago (IL)", "Chicago Midway Airport", "USA", "MDW"];
export const CZA = [
  "Chichen Itza",
  "Chichen Itza International Airport",
  "Mexico",
  "CZA",
];
export const CIC = ["Chico (CA)", "Chico Municipal Airport", "USA", "CIC"];
export const CUU = [
  "Chihuahua",
  "Chihuahua International Airport",
  "Mexico",
  "CUU",
];
export const JKH = [
  "Chios Island",
  "Chios Island National Airport",
  "Greece",
  "JKH",
];
export const CIP = ["Chipata", "Chipata Airport", "Zambia", "CIP"];
export const KIV = [
  "Chisinau",
  "Chişinău International Airport",
  "Moldova",
  "KIV",
];
export const HTA = ["Chita (Tschita)", " Kadala Airport", "Russia", "HTA"];
export const CTS = ["Sapporo", "New Chitose Airport", "Japan", "CTS"];
export const CJL = ["Chitral", "Chitral Airport", "Pakistan", "CJL"];
export const CGP = [
  "Chittagong",
  "Shah Amanat International Airport",
  "Bangladesh",
  "CGP",
];
export const CKG = [
  "Chongqing",
  "Chongqing Jiangbei International Airport",
  "Sichuan, PR China",
  "CKG",
];
export const CHC = [
  "Christchurch",
  "Christchurch International Airport",
  "New Zealand",
  "CHC",
];
export const CCZ = [
  "Chub Cay",
  " Chub Cay International Airport",
  "Bahamas",
  "CCZ",
];
export const YYQ = [
  "Churchill",
  "Churchill Airport",
  "Manitoba, Canada",
  "YYQ",
];
export const CFG = ["Cienfuegos", "Jaime González Airport", "Cuba", "CFG"];
export const CVG = [
  "Cincinnati (OH)",
  "Northern Kentucky International Airport",
  "USA",
  "CVG",
];
export const CME = [
  "Ciudad Del Carmen",
  "Ciudad del Carmen International Airport",
  "Mexico",
  "CME",
];
export const CGU = [
  "Ciudad Guayana",
  "General Manuel Carlos Piar Airport",
  "Venezuela",
  "CGU",
];
export const CJS = [
  "Ciudad Juárez",
  "Abraham González International Airport",
  "Mexico",
  "CJS",
];
export const CEN = [
  "Ciudad Obregon",
  "Ciudad Obregón International Airport",
  "Mexico",
  "CEN",
];
export const CVM = [
  "Ciudad Victoria",
  "General Pedro J. Méndez National Airport",
  "Mexico",
  "CVM",
];
export const CKB = [
  "Clarksburg (WV)",
  "North Central West Virginia Airport",
  "USA",
  "CKB",
];
export const CMQ = ["Clermont", "Clermont Airport", "Australia", "CMQ"];
export const CFE = [
  "Clermont Ferrand",
  "Clermont-Ferrand Auvergne Airport",
  "France",
  "CFE",
];
export const CLE = [
  "Cleveland (OH)",
  "Cleveland Hopkins International Airport",
  "USA",
  "CLE",
];
export const BKL = [
  "Cleveland (OH)",
  "Burke Lakefront Airport  (GA)",
  "USA",
  "BKL",
];
export const CBB = [
  "Cochabamba",
  " Jorge Wilstermann International Airport",
  "Bolivia",
  "CBB",
];
export const COK = ["Cochin", "Cochin International", "Kerala, India", "COK"];
export const COD = ["Cody (WY)", "Yellowstone Regional Airport", "USA", "COD"];
export const KCC = [
  "Coffmann Cove (AK)",
  "Coffman Cove Seaplane Base",
  "USA",
  "KCC",
];
export const CFS = [
  "Coffs Harbour",
  " Coffs Harbour Airport",
  "Australia",
  "CFS",
];
export const CJB = [
  "Coimbatore",
  "Coimbatore International Airport",
  "India",
  "CJB",
];
export const CLQ = ["Colima", "Colima National Airport", "Mexico", "CLQ"];
export const CLL = [
  "College Station/Bryan (TX)",
  "Easterwood Airport",
  "USA",
  "CLL",
];
export const KCE = ["Collinsville", "Collinsville Airport", "Australia", "KCE"];
export const CMB = [
  "Colombo",
  "Bandaranaike International Airport",
  "Sri Lanka",
  "CMB",
];
export const COS = [
  "Colorado Springs (CO)",
  "Colorado Springs Airport",
  "USA",
  "COS",
];
export const CAE = [
  "Columbia (SC)",
  "Columbia Metropolitan Airport",
  "USA",
  "CAE",
];
export const CSG = ["Columbus (GA)", "Columbus Airport", "USA", "CSG"];
export const CMH = [
  "Columbus (OH)",
  "Port Columbus International Airport",
  "USA",
  "CMH",
];
export const CKY = [
  "Conakry",
  " Conakry International (Gbessia Airport)",
  "Guinea",
  "CKY",
];
export const CEP = ["Concepción", "Concepción Airport", "Bolivia", "CEP"];
export const CCP = [
  "Concepción",
  "Carriel Sur International Airport",
  "Chile",
  "CCP",
];
export const CCR = ["Concord (CA)", "Buchanan Field", "USA", "CCR"];
export const CON = [
  "Concord (NH)",
  "Concord Municipal Airport  (GA)",
  "USA",
  "CON",
];
export const CZL = [
  "Constantine",
  "Mohamed Boudiaf International Airport",
  "Algeria",
  "CZL",
];
export const CND = [
  "Constanta (Constanța)",
  "Mihail Kogălniceanu International Airport",
  "Romania",
  "CND",
];
export const CPD = ["Coober Pedy", "Coober Pedy Airport", "Australia", "CPD"];
export const CTN = ["Cooktown", "Cooktown Airport", "Australia", "CTN"];
export const OOM = [
  "Cooma",
  "Cooma–Snowy Mountains Airport",
  "Australia",
  "OOM",
];
export const CPH = ["Copenhagen", "Copenhagen Airport", "Denmark", "CPH"];
export const COR = [
  "Cordoba",
  " Ingeniero Ambrosio L.V. Taravella International Airport",
  "Argentina",
  "COR",
];
export const ODB = ["Cordoba", "Córdoba Airport  (GA)", "Spain", "ODB"];
export const CDV = [
  "Cordova (AK)",
  " Merle K. (Mudhole) Smith Airport",
  "USA",
  "CDV",
];
export const CFU = [
  "Corfu (island)",
  "Ioannis Kapodistrias International Airport",
  "Greece",
  "CFU",
];
export const ORK = ["Cork", "Cork Airport", "Ireland", "ORK"];
export const CRP = [
  "Corpus Christi (TX)",
  "Corpus Christi International Airport",
  "USA",
  "CRP",
];
export const CBU = [
  "Cottbus",
  "Cottbus-Drewitz Airport  (GA)",
  "Germany",
  "CBU",
];
export const CVT = [
  "Coventry - Baginton",
  "Coventry Airport",
  "United Kingdom",
  "CVT",
];
export const CZM = [
  "Cozumel (island)",
  "Cozumel International Airport",
  "Mexico",
  "CZM",
];
export const CGA = ["Craig (AK)", "Craig Seaplane Base", "USA", "CGA"];
export const CEC = ["Crescent City (CA)", "Jack McNamara Field", "USA", "CEC"];
export const CGB = [
  "Cuiaba",
  "Marechal Rondon International Airport",
  "Brazil",
  "CGB",
];
export const CUL = [
  "Culiacan",
  "Culiacan International Airport",
  "Mexico",
  "CUL",
];
export const CWB = [
  "Curitiba",
  "Afonso Pena International Airport",
  "Brazil",
  "CWB",
];
export const CYU = ["Cuyo", "Cuyo Airport", "Palawan, Philippines", "CYU"];
export const DKR = [
  "Dakar",
  "Léopold Sédar Senghor International Airport",
  "Senegal",
  "DKR",
];
export const DSS = [
  "Dakar",
  "Blaise Diagne International Airport",
  "Senegal",
  "DSS",
];
export const DAK = ["Dakhla Oasis", "Dakhla Oasis Airport", "Egypt", "DAK"];
export const DLM = ["Dalaman", " Dalaman Airport", "Turkey", "DLM"];
export const DLI = ["Da Lat", "Lien Khuong Airport", "Vietnam", "DLI"];
export const DBY = ["Dalby", "Dalby Airport", "Australia", "DBY"];
export const DLU = ["Dali", "Dali Airport", "Yunnan, China", "DLU"];
export const DLC = [
  "Dalian",
  "Dalian Zhoushuizi International Airport  (cn)",
  "Liaoning,  China",
  "DLC",
];
export const DAL = ["Dallas (TX)", "Dallas Love Field", "USA", "DAL"];
export const DJO = ["Daloa", "Daloa Airport", "Cote d'Ivoire", "DJO"];
export const DAM = [
  "Damascus",
  "Damascus International Airport",
  "Syria",
  "DAM",
];
export const DMM = [
  "Dammam",
  "King Fahd International Airport",
  "Saudi Arabia",
  "DMM",
];
export const DAD = [
  "Da Nang",
  "Da Nang International Airport",
  "Vietnam",
  "DAD",
];
export const DAN = [
  "Danville (VA)",
  " Danville Regional Airport",
  "USA",
  "DAN",
];
export const DCY = [
  "Daocheng",
  "Daocheng Yading Airport",
  "Sichuan, China",
  "DCY",
];
export const DAR = [
  "Dar es Salaam (Daressalam)",
  "Julius Nyerere International Airport",
  "Tanzania",
  "DAR",
];
export const DRW = [
  "Darwin",
  "Darwin International Airport",
  "Australia",
  "DRW",
];
export const DVO = [
  "Davao",
  "Francisco Bangoy International Airport",
  "Philippines",
  "DVO",
];
export const DVN = [
  "Davenport (IA)",
  "Davenport Municipal Airport (GA)",
  "USA",
  "DVN",
];
export const DAV = [
  "David",
  "Enrique Malek International Airport",
  "Panama",
  "DAV",
];
export const DAY = [
  "Dayton (OH)",
  "Dayton International Airport",
  "USA",
  "DAY",
];
export const DAB = [
  "Daytona Beach (FL)",
  " Daytona Beach International Airport",
  "USA",
  "DAB",
];
export const DEC = ["Decatur (IL)", "Decatur Airport (GA)", "USA", "DEC"];
export const YDF = [
  "Deer Lake/Corner Brook",
  "Deer Lake Regional Airport",
  "Canada",
  "YDF",
];
export const DED = [
  "Dehradun",
  "Dehradun Airport",
  "Uttarakhand, India",
  "DED",
];
export const DRT = [
  "Del Rio (TX)",
  "Del Rio International Airport (GA)",
  "USA",
  "DRT",
];
export const DEL = [
  "Delhi",
  " Indira Gandhi International Airport",
  "India",
  "DEL",
];
export const DNZ = ["Denizli", "Çardak Airport", "Turkey", "DNZ"];
export const DPS = [
  "Denpasar",
  "Ngurah Rai International Airport",
  "Bali, Indonesia",
  "DPS",
];
export const DEN = [
  "Denver (CO)",
  " Denver International Airport",
  "USA",
  "DEN",
];
export const DSK = [
  "Dera Ismail Khan",
  "Dera Ismail Khan Airport",
  "Pakistan",
  "DSK",
];
export const DRB = ["Derby", "Derby Airport", "Australia", "DRB"];
export const DSM = [
  "Des Moines (IA)",
  "Des Moines International Airport",
  "USA",
  "DSM",
];
export const DTT = ["Detroit (MI)", "Metropolitan Area", "USA", "DTT"];
export const DTW = [
  "Detroit (MI)",
  "Detroit Metropolitan Airport",
  "USA",
  "DTW",
];
export const DET = [
  "Detroit (MI)",
  "Coleman A. Young International Airport (GA)",
  "USA",
  "DET",
];
export const DVL = [
  "Devils Lake (ND)",
  "Devils Lake Municipal Airport",
  "USA",
  "DVL",
];
export const DPO = [
  "Devonport",
  "Devonport Airport",
  "Tasmania, Australia",
  "DPO",
];
export const DHA = [
  "Dhahran",
  "King Abdulaziz Air Base",
  "Saudi Arabia",
  "DHA",
];
export const DAC = [
  "Dhaka",
  "Hazrat Shahjalal International Airport (Zia)",
  "Bangladesh",
  "DAC",
];
export const DIN = ["Dien Bien Phu", "Dien Bien Phu Airport", "Vietnam", "DIN"];
export const DIL = [
  "Dili",
  "Nicolau Lobato International Airport",
  "Timor Leste (East Timor)",
  "DIL",
];
export const DLG = ["Dillingham (AK)", "Dillingham Airport", "USA", "DLG"];
export const DNR = [
  "Dinard",
  "Dinard–Pleurtuit–Saint-Malo Airport",
  "France",
  "DNR",
];
export const DIR = [
  "Dire Dawa",
  "Dire Dawa International Airport",
  "Ethiopia",
  "DIR",
];
export const DIU = ["Diu", "Diu Airport", "Daman and Diu, India", "DIU"];
export const DIY = ["Diyarbakir", " Diyarbakır Airport", "Turkey", "DIY"];
export const DJE = ["Djerba (island)", " Zarzis Airport", "Tunisia", "DJE"];
export const JIB = [
  "Djibouti City",
  "Djibouti-Ambouli International Airport",
  "Djibouti",
  "JIB",
];
export const DOD = [
  "Dodoma",
  "Dodoma International Airport",
  "Tanzania",
  "DOD",
];
export const DOH = ["Doha", "Hamad International Airport", "Qatar", "DOH"];
export const CFN = [
  "Donegal (Carrickfin)",
  "Donegal Airport",
  "Ireland",
  "CFN",
];
export const DOK = [
  "Donetsk",
  "Donetsk International Airport (destroyed in 2014)",
  "Ukraine",
  "DOK",
];
export const DGM = [
  "Dandugama (Colombo)",
  " Dandugama Water Aerodrome",
  "Sri Lanka",
  "DGM",
];
export const DTM = ["Dortmund", " Dortmund Airport", "Germany", "DTM"];
export const DHN = ["Dothan (AL)", "Dothan Regional Airport", "USA", "DHN"];
export const DLA = ["Douala", "Douala Airport", "Cameroon", "DLA"];
export const DRS = [
  "Dresden",
  " Dresden International Airport",
  "Germany",
  "DRS",
];
export const DXB = [
  "Dubai",
  "Dubai International Airport",
  "United Arab Emirates",
  "DXB",
];
export const DBO = ["Dubbo", "Dubbo Regional Airport", "Australia", "DBO"];
export const DUB = ["Dublin", "Dublin Airport", "Ireland", "DUB"];
export const DUJ = ["Dubois (PA)", "DuBois Regional Airport", "USA", "DUJ"];
export const DBV = [
  "Dubrovnik",
  "Dubrovnik Airport",
  "Croatia (Hrvatska)",
  "DBV",
];
export const DBQ = ["Dubuque (IA)", "Dubuque Regional Airport", "USA", "DBQ"];
export const DUS = [
  "Duesseldorf",
  "Düsseldorf International Airport",
  "Germany",
  "DUS",
];
export const DLH = [
  "Duluth (WI)",
  "Duluth International Airport",
  "USA",
  "DLH",
];
export const DUM = [" Dumai City", "Pinang Kampai Airport", "Indonesia", "DUM"];
export const DND = ["Dundee", "Dundee Airport", "United Kingdom", "DND"];
export const DUD = ["Dunedin", "Dunedin Airport", "New Zealand", "DUD"];
export const DKI = ["Dunk Island", "Dunk Island Airport", "Australia", "DKI"];
export const DGO = [
  "Durango",
  "Guadalupe Victoria International Airport",
  "Mexico",
  "DGO",
];
export const DRO = [
  "Durango (CO)",
  "Durango-La Plata County Airport",
  "USA",
  "DRO",
];
export const DUR = [
  "Durban",
  "King Shaka International Airport",
  "South Africa",
  "DUR",
];
export const DYU = ["Dushanbe", "Dushanbe Airport", "Tajikistan", "DYU"];
export const DUT = ["Dutch Harbor (AK)", "Unalaska Airport", "USA", "DUT"];
export const DYA = ["Dysart", "Dysart Airport", "Australia", "DYA"];
export const DZA = [
  "Dzaoudzi",
  "Dzaoudzi Pamandzi International Airport",
  "Mayotte",
  "DZA",
];
export const ELS = [
  "East London",
  "East London Airport",
  "South Africa",
  "ELS",
];
export const IPC = [
  "Easter Island (Rapa Nui)",
  "Mataveri International Airport",
  "Chile",
  "IPC",
];
export const EAU = [
  "Eau Clarie (WI)",
  " Chippewa Valley Regional Airport",
  "USA",
  "EAU",
];
export const EDI = ["Edinburgh", "Edinburgh Airport", "Scotland, UK", "EDI"];
export const YEA = ["Edmonton", "Metropolitan Area", "Canada", "YEA"];
export const YEG = [
  "Edmonton",
  " Edmonton International Airport",
  "Canada",
  "YEG",
];
export const YXD = [
  "Edmonton",
  "Edmonton City Centre Airport (closed 2013)",
  "Canada",
  "YXD",
];
export const EGS = [
  "Egilsstadir (Egilsstaðir)",
  "Egilsstaðir Airport",
  "Iceland",
  "EGS",
];
export const EIN = ["Eindhoven", " Eindhoven Airport", "Netherlands", "EIN"];
export const ETH = ["Eilat", "Eilat Airport  (J. Hozman)", "Israel", "ETH"];
export const VDA = ["Eilat", "Ovda International Airport", "Israel", "VDA"];
export const EUN = [
  " El Aaiún (Laayoune)",
  "Hassan I Airport",
  "Morocco",
  "EUN",
];
export const EBA = [
  "Elba (island)",
  "Elba Airport Marina di Campo",
  "Italy",
  "EBA",
];
export const ELD = [
  "El Dorado (AR)",
  "South Arkansas Regional Airport",
  "USA",
  "ELD",
];
export const EDL = [
  " Eldoret",
  "Eldoret International Airport",
  "Kenya",
  "EDL",
];
export const EKI = [
  "Elkhart (IN)",
  " Elkhart Municipal Airport (GA)",
  "USA",
  "EKI",
];
export const EKO = ["Elko (NV)", "Elko Regional Airport", "USA", "EKO"];
export const ELL = ["Ellisras", "Ellisras Airport", "South Africa", "ELL"];
export const EMY = ["El Minya", " El Minya (no airport)", "Egypt", "EMY"];
export const ELM = [
  "Elmira (NY)",
  " Elmira Corning Regional Airport",
  "USA",
  "ELM",
];
export const ELP = [
  "El Paso (TX)",
  "El Paso International Airport",
  "USA",
  "ELP",
];
export const ELY = ["Ely (NV)", "Ely Airport", "USA", "ELY"];
export const EDR = [
  "Edward River, Pormpuraaw",
  "Edward River Airport",
  "Australia",
  "EDR",
];
export const EMD = ["Emerald", " Emerald Airport", "Australia", "EMD"];
export const ENF = ["Enontekiö", " Enontekiö Airport", "Finland", "ENF"];
export const EBL = ["Erbil", "Erbil International Airport", "Iraq", "EBL"];
export const ERF = [
  "Erfurt",
  "Erfurt Weimar Airport  (Flughafen Erfurt Weimar)",
  "Germany",
  "ERF",
];
export const ERI = ["Erie (PA)", "Erie International Airport", "USA", "ERI"];
export const ERC = ["Erzincan", " Erzincan Airport", "Turkey", "ERC"];
export const ERZ = ["Erzurum", "Erzurum Airport", "Turkey", "ERZ"];
export const EBJ = ["Esbjerg", "Esbjerg Airport", "Denmark", "EBJ"];
export const ESC = ["Escanaba (MI)", "Delta County Airport", "USA", "ESC"];
export const EPR = ["Esperance", "Esperance Airport", "Australia", "EPR"];
export const EUG = [
  "Eugene (OR)",
  " Eugene Airport (Mahlon Sweet Field)",
  "USA",
  "EUG",
];
export const ACV = ["Eureka (CA)", "Arcata-Eureka Airport", "USA", "ACV"];
export const EVV = [
  "Evansville (IN)",
  "Evansville Regional Airport",
  "USA",
  "EVV",
];
export const EVE = [
  "Evenes",
  "Harstad/Narvik Airport, Evenes",
  "Norway",
  "EVE",
];
export const EXT = [
  "Exeter",
  "Exeter International Airport",
  "United Kingdom",
  "EXT",
];
export const EZE = [
  "Ezeiza (Buenos Aires)",
  "Ezeiza International Airport",
  "Argentina",
  "EZE",
];
export const FAI = [
  "Fairbanks (AK)",
  " Fairbanks International Airport",
  "USA",
  "FAI",
];
export const FIE = [
  "Fair Isle (island)",
  " Fair Isle Airport",
  "Shetland, United Kingdom",
  "FIE",
];
export const LYP = [
  "Faisalabad",
  " Faisalabad International Airport",
  "Pakistan",
  "LYP",
];
export const FAR = ["Fargo (ND)", "Hector International Airport", "USA", "FAR"];
export const FMN = [
  "Farmington (NM)",
  " Four Corners Regional Airport",
  "USA",
  "FMN",
];
export const FAO = ["Faro", "Faro Airport", "Portugal", "FAO"];
export const FAE = ["Faroer (island)", "Vágar Airport", "Denmark", "FAE"];
export const FYV = ["Fayetteville (AR)", "Drake Field", "USA", "FYV"];
export const FAY = [
  "Fayetteville/Ft. Bragg (NC)",
  " Fayetteville Regional Airport",
  "USA",
  "FAY",
];
export const FEZ = ["Fes", "Fes-Saïss Airport", "Morocco", "FEZ"];
export const FSC = ["Figari", "Sud Corse Airport", "Corsica, France", "FSC"];
export const FLG = [
  "Flagstaff (AZ)",
  "Flagstaff Pulliam Airport",
  "USA",
  "FLG",
];
export const YFO = ["Flin Flon", "Flin Flon Airport", "Canada", "YFO"];
export const FNT = ["Flint (MI)", "Bishop International Airport", "USA", "FNT"];
export const FLR = [
  "Florence (Firenze)",
  "Florence Airport  (Amerigo Vespucci Airport)",
  "Italy",
  "FLR",
];
export const FLO = ["Florence (SC)", "Florence Regional Airport", "USA", "FLO"];
export const FLN = [
  "Florianopolis",
  "Hercílio Luz International Airport",
  "Brazil",
  "FLN",
];
export const FRO = ["Floroe (Florø)", "Florø Airport", "Norway", "FRO"];
export const YFA = ["Fort Albany", "Fort Albany Airport", "Canada", "YFA"];
export const FDF = [
  "Fort de France (territorial capital)",
  "Martinique Aimé Césaire International Airport",
  "Martinique",
  "FDF",
];
export const FOD = [
  "Fort Dodge (IA)",
  "Fort Dodge Regional Airport",
  "USA",
  "FOD",
];
export const FLL = [
  "Fort Lauderdale (FL)",
  "Fort Lauderdale–Hollywood International",
  "USA",
  "FLL",
];
export const YMM = ["Fort McMurray", "Fort McMurray Airport", "Canada", "YMM"];
export const FMY = ["Fort Myers (FL)", "Page Field  (GA)", "USA", "FMY"];
export const RSW = [
  "Fort Myers (FL)",
  "Southwest Florida Regional Airport",
  "USA",
  "RSW",
];
export const FRI = [
  "Fort Riley/Junction City (KS)",
  "Marshall Army Airfield (Fort Riley)",
  "USA",
  "FRI",
];
export const YSM = ["Fort Smith", "Fort Smith Airport", "Canada", "YSM"];
export const FSM = [
  "Fort Smith (AR)",
  " Fort Smith Regional Airport",
  "USA",
  "FSM",
];
export const YXJ = ["Fort St. John", " Fort St. John Airport", "Canada", "YXJ"];
export const VPS = [
  "Fort Walton Beach (FL)",
  "Northwest Florida Regional Airport",
  "USA",
  "VPS",
];
export const FWA = [
  "Fort Wayne (IN)",
  " Fort Wayne International Airport",
  "USA",
  "FWA",
];
export const DFW = [
  "Fort Worth (TX)",
  "Dallas/Fort Worth International Airport",
  "USA",
  "DFW",
];
export const FOR = [
  "Fortaleza",
  "Pinto Martins International Airport",
  "Brazil",
  "FOR",
];
export const IGU = [
  "Foz do Iguacu",
  "Foz do Iguaçu International Airport",
  "Paraná, Brazil",
  "IGU",
];
export const FRW = ["Francistown", "Francistown Airport", "Botswana", "FRW"];
export const FRA = [
  "Frankfurt/Main",
  "Frankfurt Airport  (Rhein-Main-Flughafen)",
  "Germany",
  "FRA",
];
export const HHN = [
  "Frankfurt/Hahn",
  "Frankfurt-Hahn Airport",
  "Germany",
  "HHN",
];
export const FKL = [
  "Franklin/Oil City (PA)",
  "Venango Regional Airport",
  "USA",
  "FKL",
];
export const YFC = [
  "Fredericton",
  " Fredericton International Airport",
  "Canada",
  "YFC",
];
export const FNA = [
  "Freetown",
  "Lungi International Airport",
  "Sierra Leone",
  "FNA",
];
export const FRJ = ["Frejus", " Frejus Saint-Raphael Airport", "France", "FRJ"];
export const FAT = [
  "Fresno (CA)",
  "Fresno Yosemite International Airport",
  "USA",
  "FAT",
];
export const FDH = [
  "Friedrichshafen",
  "Bodensee-Airport Friedrichshafen",
  "Germany",
  "FDH",
];
export const FUE = [
  "Fuerteventura",
  "El Fuerteventura Matorral Airport",
  "Spain",
  "FUE",
];
export const FJR = [
  "Fujairah",
  "Fujairah International Airport",
  "United Arab Emirates",
  "FJR",
];
export const FUK = ["Fukuoka", "Fukuoka Airport", "Japan", "FUK"];
export const FKS = ["Fukushima", "Fukushima Airport", "Japan", "FKS"];
export const FNC = ["Funchal", "Madeira Airport", "Madeira, Portugal", "FNC"];
export const FUT = [
  "Futuna",
  "Pointe Vele Airport",
  "Wallis and Futuna Islands",
  "FUT",
];
export const GBE = [
  "Gaborone",
  "Sir Seretse Khama International Airport",
  "Botswana",
  "GBE",
];
export const GAD = [
  "Gadsden (AL)",
  "Northeast Alabama Regional Airport",
  "USA",
  "GAD",
];
export const GNV = [
  "Gainesville (FL)",
  "Gainesville Regional Airport",
  "USA",
  "GNV",
];
export const GWY = ["Galway", "Galway Airport", "Ireland", "GWY"];
export const YQX = ["Gander", " Gander International Airport", "Canada", "YQX"];
export const GOU = [
  "Garoua",
  "Garoua International Airport",
  "Cameroon",
  "GOU",
];
export const ELQ = [
  "Gassim (Al-Qassim)",
  "Nayef bin Abdulaziz Regional Airport",
  "Saudi Arabia",
  "ELQ",
];
export const GZA = [
  "Gaza City",
  "Yasser Arafat International Airport",
  "Palestinian Territory",
  "GZA",
];
export const GZT = ["Gaziantep", "Oğuzeli Airport", "Turkey", "GZT"];
export const GDN = ["Gdansk", "Gdańsk Lech Wałęsa Airport", "Poland", "GDN"];
export const GEX = ["Geelong", "Geelong Airport (closed)", "Australia", "GEX"];
export const GVA = [
  "Geneva",
  "Geneva-Cointrin International Airport",
  "Switzerland",
  "GVA",
];
export const GOA = ["Genoa", "Cristoforo Colombo Airport", "Italy", "GOA"];
export const GRJ = ["George", "George Airport", "South Africa", "GRJ"];
export const GEO = [
  "Georgetown",
  "Cheddi Jagan International Airport",
  "Guyana",
  "GEO",
];
export const GET = ["Geraldton", "Geraldton Airport", "Australia", "GET"];
export const GRO = [
  "Gerona (Girona)",
  " Girona-Costa Brava Airport",
  "Spain",
  "GRO",
];
export const GNE = [
  "Ghent (Gent)",
  "St. Denijs-Westrem Airport (closed)",
  "Belgium",
  "GNE",
];
export const GIB = ["Gibraltar", " Gibraltar Airport", "Gibraltar", "GIB"];
export const GCC = [
  "Gilette (WY)",
  "Gillette-Campbell County Airport",
  "USA",
  "GCC",
];
export const GIL = ["Gilgit", "Gilgit Airport", "Pakistan", "GIL"];
export const YGX = ["Gillam", " Gillam Airport", "Canada", "YGX"];
export const GLT = ["Gladstone", "Gladstone Airport", "Australia", "GLT"];
export const GLA = [
  "Glasgow",
  " Glasgow International Airport",
  "Scotland, UK",
  "GLA",
];
export const GGW = [
  "Glasgow (MT)",
  "Glasgow Airport (Wokal Field)",
  "USA",
  "GGW",
];
export const GDV = ["Glendive (MT)", "Dawson Community Airport", "USA", "GDV"];
export const GOI = ["Goa", "Goa International Airport", "India", "GOI"];
export const GYN = ["Goiania", "Santa Genoveva Airport", "Brazil", "GYN"];
export const OOL = [
  "Gold Coast, Coolangatta",
  "Gold Coast Airport",
  "Australia",
  "OOL",
];
export const GOO = ["Goondiwindi", " Goondiwindi Airport", "Australia", "GOO"];
export const YYR = ["Goose Bay", "Goose Bay Airport", "Canada", "YYR"];
export const GOZ = ["Gorna", "Gorna Oryahovitsa Airport", "Bulgaria", "GOZ"];
export const GOT = [
  "Gothenburg (Göteborg)",
  " Göteborg Landvetter Airport",
  "Sweden",
  "GOT",
];
export const GOV = ["Gove/Nhulunbuy", "Gove Airport", "Australia", "GOV"];
export const GHB = [
  "Govenors Harbour",
  "Governor's Harbour Airport",
  "Eleuthera, Bahamas",
  "GHB",
];
export const GRX = ["Granada", "F.G.L. Granada-Jaén Airport", "Spain", "GRX"];
export const FPO = [
  "Grand Bahama at Freeport",
  " Grand Bahama International Airport",
  "Bahamas",
  "FPO",
];
export const GCN = [
  "Grand Canyon, Tusayan (AZ)",
  "Grand Canyon National Park Airport",
  "USA",
  "GCN",
];
export const GCM = [
  "Grand Cayman",
  " Owen Roberts International",
  "Cayman Islands",
  "GCM",
];
export const GFK = ["Grand Forks (ND)", "Grand Forks Airport", "USA", "GFK"];
export const GJT = [
  "Grand Junction (CO)",
  "Grand Junction Regional Airport",
  "USA",
  "GJT",
];
export const GRR = [
  "Grand Rapids (MI)",
  "Gerald R. Ford International Airport",
  "USA",
  "GRR",
];
export const GPZ = [
  "Grand Rapids (MN)",
  "Grand Rapids/Itasca County Airport (GA)",
  "USA",
  "GPZ",
];
export const GRZ = ["Graz", " Graz Airport", "Austria", "GRZ"];
export const GTF = ["Great Falls (MT)", " Great Falls Airport", "USA", "GTF"];
export const GKL = [
  "Great Keppel Island",
  "Great Keppel Island Airport",
  "Australia",
  "GKL",
];
export const GRB = ["Green Bay (WI)", "Austin Straubel Airport", "USA", "GRB"];
export const GSO = ["Greensboro (NC)", " Piedmont Triad Airport", "USA", "GSO"];
export const GLH = [
  "Greenville (MS)",
  "Mid-Delta Regional Airport (GA)",
  "USA",
  "GLH",
];
export const PGV = ["Greenville (NC)", "Pitt-Greenville Airport", "USA", "PGV"];
export const GSP = [
  "Greenville/Spartanburg (SC)",
  "GSP International Airport",
  "USA",
  "GSP",
];
export const GND = [
  "St. George's",
  " Maurice Bishop International  (Point Salines)",
  "Grenada",
  "GND",
];
export const GNB = ["Grenoble", "Grenoble-Isère Airport", "France", "GNB"];
export const GFF = ["Griffith", "Griffith Airport", "Australia", "GFF"];
export const GRQ = [
  "Groningen",
  " Groningen Airport Eelde",
  "Netherlands",
  "GRQ",
];
export const GTE = [
  "Groote Eylandt (island)",
  "Groote Eylandt Airport",
  "Australia",
  "GTE",
];
export const GON = [
  "Groton/New London (CT)",
  "Groton-New London Airport",
  "USA",
  "GON",
];
export const GDL = [
  "Guadalajara",
  "Don Miguel Hidalgo y Costilla International",
  "Mexico",
  "GDL",
];
export const CAN = [
  "Guangzhou",
  "Baiyun International Airport  (cn)",
  "Guangdong, PR China",
  "CAN",
];
export const GUA = [
  " Guatemala City",
  "La Aurora International Airport",
  "Guatemala",
  "GUA",
];
export const GYE = [
  "Guayaquil",
  " Guayaquil Airport José Joaquín de Olmedo",
  "Ecuador",
  "GYE",
];
export const GCI = ["Guernsey", " Guernsey Airport", "Channel Islands", "GCI"];
export const GTI = ["Guettin", "Rügen Airport (GA)", "Germany", "GTI"];
export const KWL = [
  "Guilin",
  "Guilin Liangjiang International Airport  (cn)",
  "Guangxi, China",
  "KWL",
];
export const GPT = [
  "Gulfport/Biloxi (MS)",
  " Gulfport-Biloxi International Airport",
  "USA",
  "GPT",
];
export const ULU = ["Gulu", "Gulu Airport", "Uganda", "ULU"];
export const GUC = [
  "Gunnison (CO)",
  "Gunnison-Crested Butte Regional Airport",
  "USA",
  "GUC",
];
export const GAU = [
  "Guwahati",
  "Lokpriya Gopinath Bordoloi International",
  "Assam, India",
  "GAU",
];
export const GWD = ["Gwadar", "Gwadar Airport", "Pakistan", "GWD"];
export const GWE = ["Gweru", "Gweru-Thornhill Air Base", "Zimbabwe", "GWE"];
export const GYP = ["Gympie", "Gympie Airport", "Australia", "GYP"];
export const HAC = [
  "Hachijo Jima",
  "Hachijojima Airport (Hachijo Jima Airport)",
  "Japan",
  "HAC",
];
export const GUM = [
  "Hagåtña (capital)",
  "Guam International Airport  (Hagatna A. B. Won Pat Int'l Airport)",
  "Guam",
  "GUM",
];
export const HFA = [
  "Haifa",
  "Haifa Airport  (Uri Michaeli Airport)",
  "Israel",
  "HFA",
];
export const HNS = ["Haines (AK)", "Haines Airport", "USA", "HNS"];
export const HKD = ["Hakodate", " Hakodate Airport", "Hokkaido, Japan", "HKD"];
export const YHZ = [
  "Halifax",
  "Halifax Stanfield International Airport",
  "Nova Scotia, Canada",
  "YHZ",
];
export const YUX = [
  "Hall Beach",
  "Hall Beach Airport",
  "Nunavut, Canada",
  "YUX",
];
export const HRI = [
  "Hambantota",
  "Mattala Rajapaksa International Airport",
  "Sri Lanka",
  "HRI",
];
export const HAM = ["Hamburg", "Hamburg Airport", "Germany", "HAM"];
export const HLT = [
  "Hamilton",
  "Hamilton Airport",
  "Victoria, Australia",
  "HLT",
];
export const YHM = [
  "Hamilton",
  "Hamilton International Airport",
  "Canada",
  "YHM",
];
export const HLZ = [
  "Hamilton",
  "Hamilton International Airport",
  "New Zealand",
  "HLZ",
];
export const HTI = [
  "Hamilton Island",
  "Great Barrier Reef Airport",
  "Australia",
  "HTI",
];
export const HFT = ["Hammerfest", "Hammerfest Airport", "Norway", "HFT"];
export const CMX = [
  "Hancock (MI)",
  "Houghton County Memorial Airport",
  "USA",
  "CMX",
];
export const HGH = [
  "Hangchow (Hangzhou)",
  "Hangzhou International Airport  (cn)",
  "Zhejiang, China",
  "HGH",
];
export const HAJ = [
  "Hannover",
  "Hannover-Langenhagen Airport",
  "Germany",
  "HAJ",
];
export const HAN = [
  "Hanoi",
  "Noi Bai International Airport",
  "Viet Nam",
  "HAN",
];
export const HRE = [
  "Harare",
  "Harare International Airport",
  "Zimbabwe",
  "HRE",
];
export const HRB = [
  "Harbin (Haerbin)",
  "Harbin Taiping International Airport",
  "Heilongjiang,  China",
  "HRB",
];
export const HRL = [
  "Harlingen/South Padre Island (TX)",
  " Valley International Airport",
  "USA",
  "HRL",
];
export const MDT = [
  "Harrisburg (PA)",
  "Harrisburg International Airport",
  "USA",
  "MDT",
];
export const HAR = [
  "Harrisburg (PA)",
  "Harrisburg Capital City Airport (GA)",
  "USA",
  "HAR",
];
export const BDL = [
  "Hartford (CT)",
  "Bradley International Airport",
  "USA",
  "BDL",
];
export const PIB = [
  " Hattiesburg/Laurel (MS)",
  "Hattiesburg-Laurel Regional Airport",
  "USA",
  "PIB",
];
export const HDY = [
  "Hatyai (Hat Yai)",
  "Hat Yai International Airport",
  "Thailand",
  "HDY",
];
export const HAU = ["Haugesund", " Haugesund Airport, Karmøy", "Norway", "HAU"];
export const HAV = [
  "Havana",
  "José Martí International Airport",
  "Cuba",
  "HAV",
];
export const HVR = ["Havre (MT)", "Havre City-County Airport", "USA", "HVR"];
export const HIS = [
  "Hayman Island",
  "Hayman Island Resort Seaplace Base",
  "Australia",
  "HIS",
];
export const HLN = ["Helena (MT)", "Helena Regional Airport", "USA", "HLN"];
export const JHE = ["Helsingborg", "Hamnen Heliport", "Sweden", "JHE"];
export const HEL = ["Helsinki", "Helsinki-Vantaa Airport", "Finland", "HEL"];
export const HEM = [
  "Helsinki",
  "Helsinki-Malmi Airport (GA)",
  "Finland",
  "HEM",
];
export const HER = [
  "Heraklion",
  "Heraklion International Nikos Kazantzakis Crete",
  "Greece",
  "HER",
];
export const HMO = [
  "Hermosillo",
  "Gen. Pesqueira Garcia Airport",
  "Mexico",
  "HMO",
];
export const HVB = ["Hervey Bay", " Hervey Bay Airport", "Australia", "HVB"];
export const HIB = ["Hibbing (MN)", "Chisholm-Hibbing Airport", "USA", "HIB"];
export const HKY = [
  "Hickory (NC)",
  "Hickory Regional Airport (GA)",
  "USA",
  "HKY",
];
export const ITO = ["Hilo (HI)", " Hilo International Airport", "USA", "ITO"];
export const HHH = [
  "Hilton Head Island (SC)",
  "Hilton Head Airport (GA)",
  "USA",
  "HHH",
];
export const HNK = [
  "Hinchinbrook Island",
  " Hinchinbrook Island Airport",
  "Australia",
  "HNK",
];
export const HIJ = ["Hiroshima", "Hiroshima Airport", "Honshu, Japan", "HIJ"];
export const HBA = ["Hobart", "Hobart Airport", "Tasmania, Australia", "HBA"];
export const HOQ = [
  "Hof",
  "Hof–Plauen Regional Airport  (GA)",
  "Germany",
  "HOQ",
];
export const HOG = ["Holguin", " Frank País Airport", "Cuba", "HOG"];
export const HOM = ["Homer (AK)", " Homer Airport", "USA", "HOM"];
export const HKG = [
  "Hong Kong",
  "Hong Kong International Airport  (HKIA) know as Chek Lap Kok Airport",
  "Hong Kong",
  "HKG",
];
export const HIR = [
  "Honiara",
  "Henderson International Airport",
  "Guadalcanal, Solomon Islands",
  "HIR",
];
export const HNL = [
  "Honolulu (HI)",
  "Honolulu International Airport",
  "USA, Hawaii",
  "HNL",
];
export const HNH = ["Hoonah (AK)", "Hoonah Airport", "USA", "HNH"];
export const HOR = ["Horta, Azores", "Horta Airport", "Portugal", "HOR"];
export const IAH = [
  "Houston (TX)",
  "George Bush Intercontinental Airport",
  "USA",
  "IAH",
];
export const HOU = ["Houston (TX)", "William P. Hobby Airport", "USA", "HOU"];
export const HUH = [
  "Huahine, Society Islands",
  "Fare Airport",
  "French Polynesia",
  "HUH",
];
export const HUX = [
  "Huatulco",
  "Bahías de Huatulco International Airport",
  "Mexico",
  "HUX",
];
export const HUI = ["Hue", "Phu Bai International Airport", "Vietnam", "HUI"];
export const HUY = [
  "Humberside",
  "Humberside Airport",
  "United Kingdom",
  "HUY",
];
export const HTS = ["Huntington (WV)", "Tri-State Airport", "USA", "HTS"];
export const HSV = [
  "Huntsville (AL)",
  " Huntsville International Airport",
  "USA",
  "HSV",
];
export const HRG = [
  "Hurghada",
  "Hurghada International Airport",
  "Egypt",
  "HRG",
];
export const HON = ["Huron (SD)", "Huron Regional Airport", "USA", "HON"];
export const HWN = [
  "Hwange National Park",
  "Hwange National Park Airport",
  "Zimbabwe",
  "HWN",
];
export const HYA = [
  "Hyannis (MA)",
  "Barnstable Municipal Airport",
  "USA",
  "HYA",
];
export const HYG = ["Hydaburg (AK)", "Hydaburg Seaplane Base", "USA", "HYG"];
export const HYD = [
  "Hyderabad",
  "Rajiv Gandhi International Airport",
  "Telangana, India",
  "HYD",
];
export const HDD = ["Hyderabad", "Hyderabad Airport", "Sindh, Pakistan", "HDD"];
export const IAS = [
  "Iasi (Iași)",
  "Iași International Airport",
  "Romania",
  "IAS",
];
export const IBA = [" Ibadan", "Ibadan Airport", "Nigeria", "IBA"];
export const IBE = ["Ibagué", " Perales Airport", "Colombia", "IBE"];
export const IBZ = ["Ibiza (island)", "Ibiza Airport", "Ibiza, Spain", "IBZ"];
export const IDA = [
  "Idaho Falls (ID)",
  " Idaho Falls Regional Airport",
  "USA",
  "IDA",
];
export const IEJ = [
  "Iejima (island)",
  "Iejima Airport",
  "Okinawa, Japan",
  "IEJ",
];
export const IGR = [
  "Iguazu",
  "Cataratas del Iguazú International Airport",
  "Argentina",
  "IGR",
];
export const IJU = ["Ijuí", " Ijuí Airport", "Brazil", "IJU"];
export const IOS = [
  "Ilhéus",
  " Ilhéus Jorge Amado Airport",
  "Bahia, Brazil",
  "IOS",
];
export const ILP = [
  "Ile des Pins (island)",
  "Île des Pins Airport",
  "New Caledonia",
  "ILP",
];
export const IOU = [
  "Ile Ouen (island)",
  "Edmond Cane Airport",
  "New Caledonia",
  "IOU",
];
export const ILI = ["Iliamna", "Iliamna Airport", "AK, USA", "ILI"];
export const ILQ = ["Ilo", " Ilo Airport", "Peru", "ILQ"];
export const ILR = ["Ilorin", " Ilorin Airport", "Nigeria", "ILR"];
export const IMP = [
  "Imperatriz",
  " Imperatriz Airport",
  "Maranhão, Brazil",
  "IMP",
];
export const IPL = ["Imperial", " Imperial County Airport", "CA, USA", "IPL"];
export const IMF = [" Imphal", "Tulihal Airport", "Manipur, India", "IMF"];
export const IDP = [
  "Independence",
  "Independence Municipal Airport",
  "Kansas, USA",
  "IDP",
];
export const IND = [
  "Indianapolis",
  "Indianapolis International Airport",
  "IN, USA",
  "IND",
];
export const IGH = ["Ingham", "Ingham Airport", "Australia", "IGH"];
export const IGS = [
  " Ingolstadt",
  " Ingolstadt Manching Airport  (GA)",
  "Germany",
  "IGS",
];
export const INH = ["Inhambane", "Inhambane Airport", "Mozambique", "INH"];
export const IFL = ["Innisfail", " Innisfail Airport", "Australia", "IFL"];
export const IDR = [
  "Indore",
  "Devi Ahilyabai Holkar International Airport",
  "Madhya Pradesh, India",
  "IDR",
];
export const INN = [
  "Innsbruck - Kranebitten",
  " Innsbruck Airport  (Kranebitten Airport)",
  "Austria",
  "INN",
];
export const INL = [
  "International Falls (MN)",
  "Falls International Airport",
  "USA",
  "INL",
];
export const YEV = [
  "Inuvik",
  "Inuvik (Mike Zubko) Airport",
  "NWT, Canada",
  "YEV",
];
export const IVC = [
  "Invercargill",
  "Invercargill Airport",
  "New Zealand",
  "IVC",
];
export const INV = ["Inverness", "Inverness Airport", "United Kingdom", "INV"];
export const IYK = ["Inyokern", "Inyokern Airport", "CA, USA", "IYK"];
export const IOA = ["Ioannina", " Ioannina National Airport", "Greece", "IOA"];
export const IOW = [
  " Iowa City",
  " Iowa City Municipal Airport",
  "Iowa, USA",
  "IOW",
];
export const IPN = [
  "Ipatinga",
  "Usiminas Airport",
  "Minas Gerais, Brazil",
  "IPN",
];
export const IPI = ["Ipiales", " San Luis Airport", " Colombia", "IPI"];
export const IPH = [
  "Ipoh",
  "Sultan Azlan Shah Airport",
  "Perak, Malaysia",
  "IPH",
];
export const YFB = [
  "Iqaluit (Frobisher Bay)",
  "Iqaluit Airport",
  "Nunavut, Canada",
  "YFB",
];
export const IQQ = [
  "Iquique",
  "Diego Aracena International Airport",
  "Chile",
  "IQQ",
];
export const IQT = [
  "Iquitos",
  "Coronel FAP Francisco Secada Vignetta International Airport  (es)",
  "Peru",
  "IQT",
];
export const IRI = ["Iringa", " Iringa Airport", "Tanzania", "IRI"];
export const IKT = [
  "Irkutsk",
  "Irkutsk International Airport",
  "Russia",
  "IKT",
];
export const ISH = [
  "Ischia (island)",
  "nearest airport is Naples",
  "Italy",
  "ISH",
];
export const IFN = [
  "Isfahan (Esfahan)",
  "Shahid Beheshti International Airport  (fa)",
  "Iran",
  "IFN",
];
export const ISG = ["Ishigaki", "Painushima Ishigaki Airport", "Japan", "ISG"];
export const IRP = [
  "Isiro",
  "Matari Airport",
  "Democratic Rep. of the Congo",
  "IRP",
];
export const ILY = [
  "Islay",
  "Port Ellen Airport",
  "Scotland, United Kingdom",
  "ILY",
];
export const IOM = [
  "Isle of Man",
  "Isle of Man Airport",
  "United Kingdom",
  "IOM",
];
export const ISE = ["Isparta", "Suleyman Demirel Airport", "Turkey", "ISE"];
export const IST = [
  "Istanbul",
  "Atatürk International Airport",
  "Turkey",
  "IST",
];
export const SAW = [
  "Istanbul",
  "Sabiha Gökçen International Airport",
  "Turkey",
  "SAW",
];
export const ITB = ["Itaituba", "Itaituba Airport", "Pará, Brazil", "ITB"];
export const ITH = [
  "Ithaca",
  " Ithaca Tompkins Regional Airport",
  "NY, USA",
  "ITH",
];
export const ITR = ["Itumbiara", "Itumbiara Airport", "Goiás, Brazil", "ITR"];
export const IVL = ["Ivalo", "Ivalo Airport", "Finland", "IVL"];
export const IWO = [
  " Iwo Jima (Iwo To)",
  " Iwo Jima Air Base",
  "Bonin Islands, Japan",
  "IWO",
];
export const IJK = ["Izhevsk", "Izhevsk Airport", "Udmurt Rep., Russia", "IJK"];
export const ADB = [
  "Izmir",
  "Adnan Menderes International Airport",
  "Turkey",
  "ADB",
];
export const IZO = ["Izumo", "Izumo Airport", "Shimane, Japan", "IZO"];
export const JLR = [
  "Jabalpur",
  "Jabalpur Airport",
  "Madhya Pradesh, India",
  "JLR",
];
export const JAB = ["Jabiru", "Jabiru Airport", "NT, Australia", "JAB"];
export const KPT = ["Jackpot (NV)", "Jackpot Airport", "USA", "KPT"];
export const JXN = ["Jackson (MI)", "Jackson County Airport", "USA", "JXN"];
export const JAN = [
  "Jackson (MS)",
  "Jackson-Evers International Airport",
  "USA",
  "JAN",
];
export const HKS = ["Jackson (MS)", "Hawkins Field (GA)", " USA", "HKS"];
export const MKL = [
  "Jackson (TN)",
  " McKellar-Sipes Regional Airport",
  "USA",
  "MKL",
];
export const JAC = ["Jackson (WY)", " Jackson Hole Airport", "USA", "JAC"];
export const LRF = ["Jacksonville (AR)", "Little Rock AFB", "USA", "LRF"];
export const JAX = [
  "Jacksonville (FL)",
  "Jacksonville International Airport",
  "USA",
  "JAX",
];
export const NZC = [
  "Jacksonville (FL)",
  "Cecil Field NAS (closed)",
  "USA",
  "NZC",
];
export const NIP = [
  "Jacksonville (FL)",
  "Naval Air Station Jacksonville",
  "USA",
  "NIP",
];
export const CRG = [
  "Jacksonville (FL)",
  "Craig Municipal Airport",
  "USA",
  "CRG",
];
export const IJX = ["Jacksonville (IL)", "Municipal Airport", "USA", "IJX"];
export const OAJ = [
  "Jacksonville (NC)",
  "Albert J. Ellis Airport",
  "USA",
  "OAJ",
];
export const JKV = [
  "Jacksonville (TX)",
  " Cherokee County Airport",
  "USA",
  "JKV",
];
export const JAK = ["Jacmel", " Jacmel Airport", "Haiti", "JAK"];
export const JAG = ["Jacobabad", "Jacobabad Air Base", "Pakistan", "JAG"];
export const JCM = ["Jacobina", "Jacobina Airport", "Bahia, Brazil", "JCM"];
export const JAQ = [
  "Jacquinot Bay",
  " Jacquinot Bay Airport",
  "Papua New Guinea",
  "JAQ",
];
export const JAF = [
  "Jaffna",
  "Jaffna Airport (Kankesanturai)",
  "Sri Lanka",
  "JAF",
];
export const JGB = ["Jagdalpur", "Jagdalpur Airport", "India", "JGB"];
export const JAI = [
  "Jaipur",
  "Jaipur International Airport (Sanganer Airport)",
  "Rajasthan, India",
  "JAI",
];
export const JSA = [
  "Jaisalmer",
  "Jaisalmer Airport",
  "Rajasthan, India",
  "JSA",
];
export const JKT = ["Jakarta", "Metropolitan Area", "Indonesia", "JKT"];
export const HLP = [
  "Jakarta",
  "Halim Perdanakusuma International Airport",
  "Indonesia",
  "HLP",
];
export const CGK = [
  "Jakarta",
  "Soekarno-Hatta International Airport",
  "Indonesia",
  "CGK",
];
export const JAA = ["Jalalabad", "Jalalabad Airport", "Afghanistan", "JAA"];
export const JAL = ["Jalapa", "El Lencero Airport", "Veracruz, Mexico", "JAL"];
export const JLS = ["Jales", "Jales Airport", "Brazil", "JLS"];
export const UIT = [
  "Jaluit Island (Jaluit Atoll)",
  " Jaluit Airport",
  "Marshall Islands",
  "UIT",
];
export const JMB = ["Jamba", "Jamba Airport", "Angola", "JMB"];
export const DJB = [
  "Jambi",
  "Sultan Taha (Syarifudn) Airport",
  "Indonesia",
  "DJB",
];
export const JAM = ["Jambol", " Bezmer Air Base", "Bulgaria", "JAM"];
export const JMS = [
  "Jamestown (ND)",
  "Jamestown Regional Airport",
  "USA",
  "JMS",
];
export const JHW = [
  "Jamestown (NY)",
  " Chautauqua County-Jamestown Airport",
  "USA",
  "JHW",
];
export const IXJ = [
  "Jammu",
  "Jammu Airport (Satwari Airport)",
  "Jammu, India",
  "IXJ",
];
export const JGA = [
  "Jamnagar",
  "Govardhanpur Airport Jamnagar",
  "Gujurat, India",
  "JGA",
];
export const IXW = ["Jamshedpur", "Sonari Airport", "India", "IXW"];
export const JKR = ["Janakpur", "Janakpur Airport", "Nepal", "JKR"];
export const JAD = ["Jandakot", "Jandakot Airport", "WA, Australia", "JAD"];
export const JVL = [
  "Janesville (WI)",
  "Southern Wisconsin Regional Airport  (Rock County Airport)",
  "USA",
  "JVL",
];
export const JNA = [
  "Januaria",
  "Januária Airport",
  "Minas Gerais, Brazil",
  "JNA",
];
export const JQE = ["Jaque", "Jaqué Airport", "Panama", "JQE"];
export const JTI = ["Jatai", "Jatai Airport", "Goiás, Brazil", "JTI"];
export const JAU = ["Jauja", "Francisco Carle Airport", "Peru", "JAU"];
export const DJJ = ["Jayapura", "Sentani Airport", "Indonesia", "DJJ"];
export const JED = [
  "Jeddah",
  "King Abdulaziz International Airport",
  "Saudi Arabia",
  "JED",
];
export const JEF = [
  "Jefferson City (MO)",
  "Jefferson City Memorial Airport (GA)",
  "USA",
  "JEF",
];
export const JEE = ["Jeremie", "Jeremie Airport", "Haiti", "JEE"];
export const XRY = [
  "Jerez de la Frontera/Cadiz",
  "Jerez Airport  (La Parra Airport)",
  "Spain",
  "XRY",
];
export const JER = ["Jersey", "Jersey Airport", "Channel Islands, UK", "JER"];
export const JRS = ["Jerusalem", "Atarot Airport (closed)", "Israel", "JRS"];
export const JSR = ["Jessore", "Jessore Airport", "Bangladesh", "JSR"];
export const PYB = ["Jeypore", "Jeypore Airport", "Odisha, India", "PYB"];
export const JGS = ["Ji'an", "Jinggangshan Airport", "Jiangxi, China", "JGS"];
export const JMU = ["Jiamusi", "Jiamusi Airport", " China", "JMU"];
export const JGN = ["Jiayuguan", "Jiayuguan Airport", " China", "JGN"];
export const GJL = ["Jijel", "Jijel Ferhat Abbas Airport", "Algeria", "GJL"];
export const JIJ = ["Jijiga", " Jijiga Airport", "Ethiopia", "JIJ"];
export const JIL = [
  "Jilin City",
  "Jilin Ertaizi Airport",
  "Jilin, China",
  "JIL",
];
export const JIM = ["Jimma", "Aba Segud Airport", "Ethiopia", "JIM"];
export const TNA = [
  "Jinan",
  " Jinan Yaoqiang International Airport  (cn)",
  "Shandong, China",
  "TNA",
];
export const JDZ = ["Jingdezhen", "Jingdezhen Luojia Airport", "China", "JDZ"];
export const JHG = [
  "Jinghong",
  "Xishuangbanna Gasa Airport",
  "Jiangxi,  China",
  "JHG",
];
export const JNG = ["Jining", "Jining Qufu Airport", "Shandong, China", "JNG"];
export const JIN = ["Jinja", "Jinja Airport", "Uganda", "JIN"];
export const BCO = ["Jinka", "Baco/Jinka Airport", "Ethiopia", "BCO"];
export const JNZ = ["Jinzhou", "Jinzhou Airport", "Liaoning, China", "JNZ"];
export const JPR = [
  "Ji-Paraná",
  " Ji-Paraná Airport",
  "Rondônia, Brazil",
  "JPR",
];
export const JIP = ["Jipijapa", "Jipijapa Airport", "Ecuador", "JIP"];
export const JIR = ["Jiri", "Jiri Airport", "Nepal", "JIR"];
export const JIU = [
  "Jiujiang",
  "Jiujiang Lushan Airport",
  "Jiangxi, China",
  "JIU",
];
export const JIW = ["Jiwani", "Jiwani Airport", "Pakistan", "JIW"];
export const JCB = ["Joacaba", "Joaçaba Airport", "Brazil", "JCB"];
export const JXA = [
  " Jixi",
  " Jixi Xingkaihu Airport",
  "Heilongjiang, China",
  "JXA",
];
export const JPA = [
  "Joao Pessoa",
  "Presidente Castro Pinto International Airport",
  "Brazil",
  "JPA",
];
export const JDH = ["Jodhpur", "Jodhpur Airport", "India", "JDH"];
export const JKG = [
  "Jönköping (Joenkoeping)",
  " Jönköping Airport",
  "Sweden",
  "JKG",
];
export const JOE = ["Joensuu", "Joensuu Airport", "Finland", "JOE"];
export const JNB = [
  "Johannesburg",
  "O.R. Tambo International Airport",
  "Gauteng, South Africa",
  "JNB",
];
export const BGM = [
  "Johnson City/Binghamton (NY)",
  "Greater Binghamton Airport",
  "USA",
  "BGM",
];
export const JON = [
  "Johnston Island",
  "Johnston Atoll Airport",
  "U.S. Minor Outlying Islands",
  "JON",
];
export const JST = [
  "Johnstown (PA)",
  "Johnstown-Cambria County Airport",
  "USA",
  "JST",
];
export const JHB = [
  "Johor Bahru",
  "Senai International Airport",
  "Malaysia",
  "JHB",
];
export const JOI = ["Joinville", "Cubatao Airport", "Brazil", "JOI"];
export const JOT = ["Joliet (IL)", "Joliet Regional Airport", "USA", "JOT"];
export const JOL = ["Jolo", "Jolo Airport", "Sulu, Philippines", "JOL"];
export const JMO = ["Jomsom", "Jomsom Airport", "Nepal", "JMO"];
export const JBR = [
  "Jonesboro (AR)",
  "Jonesboro Municipal Airport",
  "USA",
  "JBR",
];
export const JLN = [
  "Joplin (MO)",
  "Joplin Regional Airport  (GA)",
  "USA",
  "JLN",
];
export const JRH = ["Jorhat", "Jorhat Rowriah Airport", "India", "JRH"];
export const JOS = ["Jos", "Jos Yakubu Gowon Airport", "Nigeria", "JOS"];
export const JSM = [
  "Jose De San Martin",
  "José de San Martín Airport",
  "Argentina",
  "JSM",
];
export const AJF = [
  "Jouf (Al-Jouf)",
  "Al-Jouf Domestic Airport",
  "Saudi Arabia",
  "AJF",
];
export const JJI = ["Juanjui", "Juanjuí Airport", "Peru", "JJI"];
export const JUB = ["Juba", "Juba International Airport", "South Sudan", "JUB"];
export const JUI = [
  "Juist (island)",
  " Juist Airport (Flugplatz Juist)",
  "Lower Saxony, Germany",
  "JUI",
];
export const JDF = [
  "Juiz De Fora",
  "Juiz de Fora Airport (Serrinha Airport)",
  "Minas Gerais, Brazil",
  "JDF",
];
export const JUJ = [
  "Jujuy",
  "Horacio Guzmán International Airport",
  "Argentina",
  "JUJ",
];
export const JCK = ["Julia Creek", "Julia Creek Airport", "Australia", "JCK"];
export const JUL = ["Juliaca", " Inca Manco Capac Airport", "Peru", "JUL"];
export const JUM = ["Jumla", "Jumla Airport", "Nepal", "JUM"];
export const JUN = ["Jundah", " Jundah Airport", "Australia", "JUN"];
export const JNU = [
  "Juneau (AK)",
  "Juneau International Airport",
  "USA",
  "JNU",
];
export const JNI = ["Junin", "Junín Airport", "Argentina", "JNI"];
export const JRN = [
  "Juruena",
  " Juruena Airport",
  "Mato Grosso, Brazil",
  "JRN",
];
export const JUT = ["Juticalpa", " Juticalpa Airport", "Honduras", "JUT"];
export const JWA = ["Jwaneng", "Jwaneng Airport", "Botswana", "JWA"];
export const JYV = [
  "Jyväskylä (Jyvaskyla)",
  "Jyväskylä Airport",
  "Finland",
  "JYV",
];
export const KBL = [
  "Kabul",
  "Hamid Karzai International Airport",
  "Afghanistan",
  "KBL",
];
export const KOJ = ["Kagoshima", "Kagoshima Airport", "Kyushu, Japan", "KOJ"];
export const KCM = ["Kahramanmaras", " Kahramanmaras Airport", "Turkey", "KCM"];
export const OGG = ["Kahului (HI)", "Kahului Airport", "USA", "OGG"];
export const KAJ = ["Kajaani", "Kajaani Airport", "Finland", "KAJ"];
export const KLX = ["Kalamata", "Kalamata Airport", "Greece", "KLX"];
export const AZO = [
  "Kalamazoo/Battle Creek (MI)",
  " Kalamazoo/Battle Creek International",
  "USA",
  "AZO",
];
export const KGI = [
  "Kalgoorlie",
  "Kalgoorlie-Boulder Airport",
  "WA, Australia",
  "KGI",
];
export const KLO = [
  "Kalibo",
  "Kalibo International Airport",
  " Boracay, Aklan, Philippines",
  "KLO",
];
export const KGD = ["Kaliningrad", "Khrabrovo Airport", "Russia", "KGD"];
export const FCA = [
  "Kalispell (MT)",
  "Glacier Park International Airport",
  "USA",
  "FCA",
];
export const KLR = ["Kalmar", "Kalmar Öland Airport", "Sweden", "KLR"];
export const YKA = ["Kamloops (BC)", "Kamloops Airport", "BC, Canada", "YKA"];
export const EBB = [
  "Kampala",
  "Entebbe International Airport",
  "Uganda",
  "EBB",
];
export const MUE = ["Kamuela (HI)", " Waimea-Kohala Airport", "USA", "MUE"];
export const KDH = [
  " Kandahar",
  " Kandahar International Airport",
  "Afghanistan",
  "KDH",
];
export const KAN = [
  "Kano",
  "Mallam Aminu Kano International Airport",
  "Nigeria",
  "KAN",
];
export const KNU = ["Kanpur", "Kanpur Airport", "Uttar Pradesh, India", "KNU"];
export const MCI = [
  "Kansas City (MO)",
  "Kansas City International Airport",
  "USA",
  "MCI",
];
export const KHH = [
  "Kaohsiung",
  " Kaohsiung International Airport",
  "Taiwan",
  "KHH",
];
export const JHM = ["Kapalua, Lahaina (HI)", "Kapalua Airport", "USA", "JHM"];
export const KHI = [
  "Karachi",
  "Jinnah International Airport",
  "Pakistan",
  "KHI",
];
export const FKB = ["Karlsruhe/Baden-Baden", "Baden Airpark", "Germany", "FKB"];
export const KSD = ["Karlstad", "Karlstad Airport", "Sweden", "KSD"];
export const AOK = ["Karpathos (island)", "Karpathos Airport", "Greece", "AOK"];
export const KTA = ["Karratha", "Karratha Airport", "WA, Australia", "KTA"];
export const KUN = [
  "Kaunas",
  "Kaunas International Airport",
  "Lithuania",
  "KUN",
];
export const KYS = ["Kayes", "Kayes Airport", "Mali", "KYS"];
export const KRB = ["Karumba", "Karumba Airport", "Australia", "KRB"];
export const KRP = ["Karup", "Karup Airport", "Denmark", "KRP"];
export const ZKE = [
  " Kashechewan",
  " Kashechewan Airport",
  "Ontario, Canada",
  "ZKE",
];
export const KS = ["Kassala", "Kassala Airport", "Sudan", " KSL"];
export const KTR = ["Katherine", "Tindal Airport", "NT, Australia", "KTR"];
export const KTM = [
  "Kathmandu",
  "Tribhuvan International Airport",
  "Nepal",
  "KTM",
];
export const MPA = [
  "Katima Mulilo/Mpacha",
  "Katima Mulilo Airport (Mpacha Airport)",
  "Namibia",
  "MPA",
];
export const KHJ = ["Kauhajoki", " Kauhajoki Airport", "Finland", "KHJ"];
export const MKK = ["Kaunakakai (HI)", "Molokai Airport", "USA", "MKK"];
export const KVA = [
  "Kavalla",
  "Alexander the Great International Airport",
  "Greece",
  "KVA",
];
export const ASR = [
  "Kayseri (province)",
  "Erkilet International Airport",
  "Turkey",
  "ASR",
];
export const KZN = [
  "Kazan",
  "Kazan International Airport",
  "Tatarstan, Russia",
  "KZN",
];
export const KMP = ["Keetmanshoop", "Keetmanshoop Airport", "Namibia", "KMP"];
export const YLW = [
  "Kelowna",
  "Kelowna International Airport",
  "BC, Canada",
  "YLW",
];
export const KEM = ["Kemi/Tornio", "Kemi-Tornio Airport", "Finland", "KEM"];
export const ENA = ["Kenai (AK)", " Kenai Municipal Airport", "USA", "ENA"];
export const MSE = [
  "Kent/Manston",
  "Kent International Airport (currently closed)",
  "United Kingdom",
  "MSE",
];
export const KIR = [
  "Kerry County",
  " Kerry Airport (Farranfore Airport)",
  "Ireland",
  "KIR",
];
export const KTN = [
  "Ketchikan (AK)",
  "Ketchikan International Airport",
  "USA",
  "KTN",
];
export const EYW = [
  "Key West (FL)",
  "Key West International Airport",
  "USA",
  "EYW",
];
export const KHV = ["Khabarovsk", "Khabarovsk Novy Airport", "Russia", "KHV"];
export const AHB = [
  "Khamis Mushayat",
  "Abha Regional Airport",
  "Saudi Arabia",
  "AHB",
];
export const HRK = [
  "Kharkov (Kharkiv)",
  "Kharkiv International Airport",
  "Ukraine",
  "HRK",
];
export const KRT = [
  "Khartoum",
  "Khartoum International Airport",
  "Sudan",
  "KRT",
];
export const KDD = ["Khuzdar", "Khuzdar Airport", "Pakistan", "KDD"];
export const KEL = ["Kiel", "Airport Kiel  (Kiel-Holtenau)", "Germany", "KEL"];
export const KBP = ["Kiev", "Boryspil International Airport", "Ukraine", "KBP"];
export const IEV = ["Kiev", "International Airport Kiev", "Ukraine", "IEV"];
export const KGL = ["Kigali", "Kigali International Airport", "Rwanda", "KGL"];
export const JRO = [
  " Kilimanjaro",
  "Kilimanjaro International Airport",
  "Tanzania",
  "JRO",
];
export const ILE = ["Killeem (TX)", " Skylark Field", "USA", "ILE"];
export const KIM = ["Kimberley", "Kimberley Airport", "South Africa", "KIM"];
export const KNS = ["King Island", "King Island Airport", "Australia", "KNS"];
export const AKN = ["King Salomon (AK)", "King Salmon Airport", "USA", "AKN"];
export const KGC = ["Kingscote", " Kingscote Airport", "Australia", "KGC"];
export const KIN = [
  "Kingston",
  "Norman Manley International Airport",
  "Jamaica",
  "KIN",
];
export const ISO = ["Kingston (NC)", "Kinston Regional Jetport", "USA", "ISO"];
export const SVD = [
  "Kingstown",
  "E. T. Joshua Airport",
  "Saint Vincent and the Grenadines",
  "SVD",
];
export const FIH = [
  "Kinshasa",
  " Kinshasa International (N'Djili Airport)",
  "Congo (DRC)",
  "FIH",
];
export const CXI = [
  "Kiritimati (island)",
  "Cassidy International Airport",
  "Kiribati",
  "CXI",
];
export const KKN = [
  "Kirkenes",
  " Kirkenes Airport, Høybuktmoen",
  "Norway",
  "KKN",
];
export const KIK = ["Kirkuk", " Kirkuk Air Base (closed)", "Iraq", "KIK"];
export const KRN = ["Kiruna", "Kiruna Airport", "Sweden", "KRN"];
export const FKI = [
  "Kisangani",
  " Bangoka International Airport",
  "Congo (DRC)",
  "FKI",
];
export const KIH = ["Kish Island", "Kish International Airport", "Iran", "KIH"];
export const KKJ = ["Kitakyushu", "Kitakyushu Airport", "Kyushu, Japan", "KKJ"];
export const KTT = ["Kittilä", "Kittilä Airport", "Finland", "KTT"];
export const KIW = ["Kitwe", "Southdowns Airport", "Zambia", "KIW"];
export const KLU = ["Klagenfurt", "Kärnten Airport", "Austria", "KLU"];
export const LMT = [
  "Klamath Fall (OR)",
  " Klamath Falls Airport",
  "USA",
  "LMT",
];
export const KLW = ["Klawock (AK)", "Klawock Airport", "USA", "KLW"];
export const KLZ = [
  "Kleinsee (Kleinzee)",
  "Kleinzee Airport",
  "South Africa",
  "KLZ",
];
export const NOC = ["Knock", "Ireland West Airport Knock", "Ireland", "NOC"];
export const TYS = ["Knoxville (TN)", "McGhee Tyson Airport", "USA", "TYS"];
export const UKB = ["Kobe", " Kobe Airport", "Japan", "UKB"];
export const KCZ = [
  "Kōchi (Kochi)",
  "Kōchi Ryōma Airport",
  "Kochi, Japan",
  "KCZ",
];
export const CGN = [
  "Köln (Cologne)",
  "Cologne Bonn Airport  (Köln/Bonn)",
  "Germany",
  "CGN",
];
export const ADQ = ["Kodiak (AK)", "Kodiak Airport", "USA", "ADQ"];
export const OHT = ["Kohat", "Kohat Airport", "Pakistan", "OHT"];
export const KOK = [
  "Kokkola/Pietarsaari",
  "Kokkola-Pietarsaari Airport",
  "Finland",
  "KOK",
];
export const CCU = [
  "Kolkata (Calcutta)",
  "Kolkata International Airport",
  "West Bengal, India",
  "CCU",
];
export const KMQ = [
  "Komatsu",
  " Komatsu Airport (Kanazawa Airport)",
  "Japan",
  "KMQ",
];
export const KOA = ["Kona (HI)", " Kona International Airport", "USA", "KOA"];
export const KYA = ["Konya", " Konya Airport", "Turkey", "KYA"];
export const HGO = ["Korhogo", "Korhogo Airport", "Cote d'Ivoire", "HGO"];
export const KGS = [
  "Kos (island)",
  " Kos Island International Airport",
  "Greece",
  "KGS",
];
export const KSA = [
  "Kosrae",
  "Kosrae International Airport",
  "Micronesia",
  "KSA",
];
export const BKI = [
  "Kota Kinabalu",
  " Kota Kinabalu International Airport",
  "Malaysia",
  "BKI",
];
export const OTZ = [
  "Kotzbue (AK)",
  "Ralph Wien Memorial Airport",
  "USA",
  "OTZ",
];
export const KWM = ["Kowanyama", "Kowanyama Airport", "Australia", "KWM"];
export const CCJ = [
  "Kozhikode (Calicut)",
  "Calicut International Airport",
  "Kerala, India",
  "CCJ",
];
export const KRK = [
  "Krakow (Cracow)",
  "John Paul II International Airport",
  "Poland",
  "KRK",
];
export const KJA = [
  "Krasnoyarsk",
  "Yemelyanovo Krasnoyarsk Airport",
  "Russia",
  "KJA",
];
export const KRS = [
  "Kristiansand",
  "Kristiansand Airport, Kjevik",
  "Norway",
  "KRS",
];
export const KID = ["Kristianstad", "Kristianstad Airport", "Sweden", "KID"];
export const KSU = [
  "Kristiansund",
  "Kristiansund Airport, Kvernberget",
  "Norway",
  "KSU",
];
export const KUL = [
  "Kuala Lumpur",
  "Kuala Lumpur International Airport",
  "Malaysia",
  "KUL",
];
export const SZB = [
  "Kuala Lumpur",
  "Sultan Abdul Aziz Shah",
  "Malaysia",
  "SZB",
];
export const KUA = [
  "Kuantan",
  "Sultan Haji Ahmad Shah Airport",
  "Pahang, Malaysia",
  "KUA",
];
export const KCH = [
  "Kuching",
  " Kuching International Airport",
  "Sarawak, Malaysia",
  "KCH",
];
export const KMJ = ["Kumamoto", "Kumamoto Airport", "Japan", "KMJ"];
export const KMG = [
  "Kunming",
  "Kunming Changshui International Airport",
  " Yunnan, China",
  "KMG",
];
export const KNX = ["Kununurra", " Kununurra Airport", "Australia", "KNX"];
export const KUO = ["Kuopio", " Kuopio Airport", "Finland", "KUO"];
export const KUH = ["Kushiro", "Kushiro Airport", "Japan", "KUH"];
export const YVP = [
  "Kuujjuaq (Fort Chimo)",
  "Kuujjuaq Airport",
  "Quebec, Canada",
  "YVP",
];
export const YGW = [
  "Kuujjuarapik",
  "Kuujjuarapik Airport",
  "Quebec, Canada",
  "YGW",
];
export const KAO = ["Kuusamo", "Kuusamo Airport", "Finland", "KAO"];
export const KWI = [
  "Kuwait City",
  "Kuwait International Airport",
  "Kuwait",
  "KWI",
];
export const LEK = ["Labe", " Tata Airport (Labé Airport)", "Guinea", "LEK"];
export const LBU = ["Labuan", "Labuan Airport", "Sabah, Malaysia", "LBU"];
export const XLB = [
  "Lac Brochet (MB)",
  " Lac Brochet Airport",
  "Manitoba, Canada",
  "XLB",
];
export const LSE = [
  "La Crosse (WI)",
  "La Crosse Regional Airport",
  "USA",
  "LSE",
];
export const LAE = ["Lae", "Lae Nadzab Airport", "Papua New Guinea", "LAE"];
export const LRH = [
  "La Rochelle",
  "La Rochelle – Île de Ré Airport",
  "France",
  "LRH",
];
export const LAF = [
  "Lafayette (IN)",
  " Purdue University Airport (no commercial service)",
  "USA",
  "LAF",
];
export const LFT = [
  "Lafayette (LA)",
  "Lafayette Regional Airport",
  "USA",
  "LFT",
];
export const LOS = [
  "Lagos",
  "Murtala Muhammed International Airport",
  "Nigeria",
  "LOS",
];
export const LHE = [
  "Lahore",
  "Allama Iqbal International Airport",
  "Pakistan",
  "LHE",
];
export const SOB = ["Lake Balaton", "Hévíz–Balaton Airport", "Hungary", "SOB"];
export const LCH = [
  "Lake Charles (LA)",
  "Lake Charles Regional Airport",
  "USA",
  "LCH",
];
export const HII = [
  "Lake Havasu City, (AZ)",
  "Lake Havasu City Airport",
  "USA",
  "HII",
];
export const TVL = ["Lake Tahoe (CA)", "Lake Tahoe Airport", "USA", "TVL"];
export const LKL = ["Lakselv", "Lakselv Airport, Banak", "Norway", "LKL"];
export const LBQ = ["Lambarene", "Lambaréné Airport", "Gabon", "LBQ"];
export const SUF = [
  "Lamezia Terme",
  "Lamezia Terme International Airport",
  "Italy",
  "SUF",
];
export const LMP = ["Lampedusa (island)", "Lampedusa Airport", "Italy", "LMP"];
export const LNY = ["Lanai City (HI)", " Lanai Airport", "USA", "LNY"];
export const LNS = ["Lancaster (PA)", "Lancaster Airport", "USA", "LNS"];
export const LEQ = [
  "Land's End",
  "Land's End Airport",
  "United Kingdom",
  "LEQ",
];
export const LGK = [
  "Langkawi (islands)",
  " Langkawi International Airport",
  "Malaysia",
  "LGK",
];
export const LAI = [
  "Lannion",
  " Lannion - Côte de Granit Airport",
  "France",
  "LAI",
];
export const HLA = ["Lanseria", " Lanseria Airport", "South Africa", "HLA"];
export const LAN = [
  "Lansing (MI)",
  "Capital Region International Airport",
  "USA",
  "LAN",
];
export const LPB = [
  "La Paz",
  "El Alto International Airport",
  "Bolivia",
  "LPB",
];
export const LAP = ["La Paz", "La Paz International", "Mexico", "LAP"];
export const LPP = ["Lappeenranta", " Lappeenranta Airport", "Finland", "LPP"];
export const LAR = ["Laramie (WY)", " Laramie Regional Airport", "USA", "LAR"];
export const LRD = [
  "Laredo (TX)",
  " Laredo International Airport",
  "USA",
  "LRD",
];
export const LCA = [
  "Larnaca",
  " Larnaca International Airport",
  "Cyprus",
  "LCA",
];
export const LPA = [
  "Las Palmas",
  "Gran Canaria International Airport",
  "Canary Islands, Spain",
  "LPA",
];
export const LAS = [
  "Las Vegas (NV)",
  "McCarran International Airport",
  "USA",
  "LAS",
];
export const LBE = [
  "Latrobe (near Pittsburgh) (PA)",
  "Arnold Palmer Regional Airport",
  "USA",
  "LBE",
];
export const LST = ["Launceston", "Launceston Airport", "Australia", "LST"];
export const LVO = ["Laverton", "Laverton Airport", "WA, Australia", "LVO"];
export const LAW = [
  "Lawton (OK)",
  " Lawton-Fort Sill Regional Airport",
  "USA",
  "LAW",
];
export const LZC = [
  "Lazaro Cardenas",
  " Lázaro Cárdenas Airport",
  "Mexico",
  "LZC",
];
export const YLR = [
  "Leaf Rapids",
  "Leaf Rapids Airport",
  "Manitoba, Canada",
  "YLR",
];
export const LEA = [
  "Learmouth (Exmouth)",
  "Learmonth Airport",
  "WA, Australia",
  "LEA",
];
export const LEB = ["Lebanon (NH)", " Lebanon Municipal Airport", "USA", "LEB"];
export const LBA = [
  "Leeds/Bradford",
  " Leeds Bradford International Airport",
  "United Kingdom",
  "LBA",
];
export const LER = ["Leinster", "Leinster Airport", "WA, Australia", "LER"];
export const LEJ = ["Leipzig", "Leipzig/Halle Airport", "Germany", "LEJ"];
export const LEY = ["Lelystad", "Lelystad Airport", "Netherlands", "LEY"];
export const BJX = [
  "Leon",
  "Guanajuato International Airport",
  "Mexico",
  "BJX",
];
export const LNO = ["Leonora", " Leonora Airport", "WA, Australia", "LNO"];
export const LWK = [
  "Lerwick/Tingwall (Shetland Islands)",
  "Tingwall Airport",
  "United Kingdom",
  "LWK",
];
export const LWB = [
  " Lewisburg (WV)",
  "Greenbrier Valley Airport",
  "USA",
  "LWB",
];
export const LWS = [
  "Lewiston (ID)",
  " Lewiston-Nez Perce County Airport",
  "USA",
  "LWS",
];
export const LWT = [
  "Lewistown (MT)",
  "Lewistown Municipal Airport",
  "USA",
  "LWT",
];
export const LEX = ["Lexington (KY)", " Blue Grass Airport", "USA", "LEX"];
export const LXA = ["Lhasa", "Lhasa Gonggar Airport", "Tibet (China)", "LXA"];
export const LBV = [
  "Libreville",
  "Libreville Leon M’ba International Airport",
  "Gabon",
  "LBV",
];
export const LDK = ["Lidkoeping", "Lidköping-Hovby Airport", "Sweden", "LDK"];
export const LGG = [
  "Liege",
  "Liège Airport  (Bierset Airport)",
  "Belgium",
  "LGG",
];
export const LIF = [
  "Lifou",
  "Ouanaham Airport",
  "Loyalty Islands, New Caledonia",
  "LIF",
];
export const LIH = ["Lihue (HI)", "Lihue Airport", "USA", "LIH"];
export const LIL = [
  "Lille",
  "Lille Lesquin International Airport",
  "France",
  "LIL",
];
export const LLW = [
  "Lilongwe",
  "Lilongwe International (Kumuzu Airport)",
  "Malawi",
  "LLW",
];
export const LIM = [
  "Lima",
  "Jorge Chávez International Airport",
  "Peru",
  "LIM",
];
export const LIG = ["Limoges", " Bellegarde Airport", "France", "LIG"];
export const LNK = ["Lincoln (NE)", "Lincoln Airport", "USA", "LNK"];
export const LDC = [
  "Lindeman Island",
  "Lindeman Island Airport",
  "Queensland, Australia",
  "LDC",
];
export const LNZ = [
  "Linz",
  "Linz Airport  (Blue Danube Airport)",
  "Austria",
  "LNZ",
];
export const LIQ = ["Lisala", " Lisala Airport", "Congo (DRC)", "LIQ"];
export const LIS = [
  "Lisbon",
  "Lisbon Airport  (Aeroportos Lisboa)",
  "Portugal",
  "LIS",
];
export const LSY = ["Lismore", "Lismore Airport", "NSW, Australia", "LSY"];
export const LIT = [
  "Little Rock (AR)",
  "Little Rock Clinton National Airport",
  "USA",
  "LIT",
];
export const LPL = [
  "Liverpool",
  "Liverpool John Lennon Airport",
  "United Kingdom",
  "LPL",
];
export const LZR = [
  "Lizard Island",
  "Lizard Island Airport",
  "Queensland, Australia",
  "LZR",
];
export const LJU = [
  "Ljubljana",
  "Ljubljana Jože Pučnik Airport",
  "Slovenia",
  "LJU",
];
export const IRG = [
  "Lockhart River",
  "Lockhart River Airport",
  "Queensland, Australia",
  "IRG",
];
export const LFW = [
  "Lomé",
  "Lomé-Tokoin International Airport  (Gnassingbé Eyadéma)",
  "Togo",
  "LFW",
];
export const LON = ["London", "Metropolitan Area", "United Kingdom", "LON"];
export const LCY = ["London", "London City Airport", "United Kingdom", "LCY"];
export const LGW = [
  "London",
  " London Gatwick Airport",
  "United Kingdom",
  "LGW",
];
export const LHR = [
  "London",
  "London Heathrow Airport",
  "United Kingdom",
  "LHR",
];
export const LTN = ["London", "London Luton Airport", "United Kingdom", "LTN"];
export const STN = [
  "London",
  "London Stansted Airport",
  "United Kingdom",
  "STN",
];
export const SEN = [
  "London",
  " London Southend Airport",
  "United Kingdom",
  "SEN",
];
export const YXU = [
  "London",
  " London International Airport",
  "Ontario, Canada",
  "YXU",
];
export const LDY = [
  "Londonderry, Derry, Eglinton",
  "City of Derry Airport",
  "United Kingdom",
  "LDY",
];
export const LGB = [
  "Long Beach (CA)",
  "Long Beach Airport  (Daugherty Field)",
  "USA",
  "LGB",
];
export const ISP = [
  "Long Island, Islip (NY)",
  "Long Island MacArthur Airport",
  "USA",
  "ISP",
];
export const LRE = ["Longreach", "Longreach Airport", "Australia", "LRE"];
export const GGG = [
  "Longview/Kilgore (TX)",
  "East Texas Regional Airport",
  "USA",
  "GGG",
];
export const LYR = [
  "Longyearbyen",
  "Svalbard Airport, Longyear",
  "Svalbard, Norway",
  "LYR",
];
export const LTO = ["Loreto", "Loreto International Airport", "Mexico", "LTO"];
export const LRT = [
  "Lorient",
  "Lorient South Brittany Airport",
  "France",
  "LRT",
];
export const LAX = [
  "Los Angeles (CA)",
  "Los Angeles International Airport",
  "USA",
  "LAX",
];
export const LMM = [
  "Los Mochis",
  " Federal del Valle del Fuerte International",
  "Mexico",
  "LMM",
];
export const LSZ = [
  "Losinj (island)",
  "Mali Losinj Airport",
  "Croatia (Hrvatska)",
  "LSZ",
];
export const LDE = [
  "Lourdes/Tarbes",
  " Tarbes-Lourdes-Pyrenees Airport",
  "France",
  "LDE",
];
export const SDF = [
  "Louisville (KY)",
  "Louisville International Airport",
  "USA",
  "SDF",
];
export const LAD = ["Luanda", "Quatro de Fevereiro Airport", "Angola", "LAD"];
export const LBB = [
  "Lubbock (TX)",
  "Lubbock Preston Smith International",
  "USA",
  "LBB",
];
export const LKO = [
  "Lucknow",
  " Chaudhary Charan Singh International",
  "Uttar Pradesh, India",
  "LKO",
];
export const LUD = ["Luederitz", "Lüderitz Airport", "Namibia", "LUD"];
export const MLA = ["Luqa", "Malta International Airport", "Malta", "MLA"];
export const LUG = ["Lugano", "Lugano Airport", "Switzerland", "LUG"];
export const LLA = ["Lulea (Luleå)", "Lulea Airport", "Sweden", "LLA"];
export const FBM = [
  "Lumbumbashi",
  "Lubumbashi International Airport",
  "Congo (DRC)",
  "FBM",
];
export const LUN = [
  "Lusaka",
  "Kenneth Kaunda International Airport",
  "Zambia",
  "LUN",
];
export const LUX = [
  "Luxembourg City",
  "Luxembourg – Findel Airport",
  "Luxembourg",
  "LUX",
];
export const LXR = ["Luxor", "Luxor International Airport", "Egypt", "LXR"];
export const LWO = [
  "Lviv (Lvov/Lemberg)",
  "Lviv Danylo Halytskyi International Airport",
  "Ukraine",
  "LWO",
];
export const LYX = [
  "Lydd",
  "London Ashford International Airport",
  "United Kingdom",
  "LYX",
];
export const LYH = [
  "Lynchburg (VA)",
  " Lynchburg Regional Airport",
  "USA",
  "LYH",
];
export const LYN = [
  "Lyon",
  " Lyon Bron Airport (general aviation)",
  "France",
  "LYN",
];
export const LYS = [
  "Lyon",
  "Lyon-Saint Exupéry Airport  (Satolas)",
  "France",
  "LYS",
];
export const MST = [
  "Maastricht/Aachen",
  "Maastricht Aachen Airport",
  "Netherlands",
  "MST",
];
export const MEA = ["Macaé", "Macaé Airport", "RJ, Brazil", "MEA"];
export const MCP = [
  "Macapa",
  "Alberto Alcolumbre International Airport",
  "Amapá, Brazil",
  "MCP",
];
export const MFM = [
  "Macau",
  "Macau International Airport",
  "Macau, China SAR",
  "MFM",
];
export const MCZ = [
  "Maceio",
  " Zumbi dos Palmares International Airport",
  "Alagoas, Brazil",
  "MCZ",
];
export const MKY = ["Mackay", " Mackay Airport", "Australia", "MKY"];
export const MCN = [
  "Macon (GA)",
  "Middle Georgia Regional Airport",
  "USA",
  "MCN",
];
export const CEB = [
  "Mactan Island (Cebu City)",
  "Mactan-Cebu International Airport",
  "Philippines",
  "CEB",
];
export const MSN = [
  "Madison (WI)",
  "Dane County Regional Airport",
  "USA",
  "MSN",
];
export const MAA = [
  "Madras (Chennai)",
  "Chennai International Airport",
  "India",
  "MAA",
];
export const MAD = ["Madrid", "Barajas International Airport", "Spain", "MAD"];
export const GDX = ["Magadan", "Sokol Airport", "Russia", "GDX"];
export const MAH = ["Mahon", "Mahon Airport", "Spain", "MAH"];
export const MTL = ["Maitland", "Maitland Airport", "Australia", "MTL"];
export const MJN = [
  " Mahajanga (Majunga)",
  "Amborovy Airport",
  "Madagascar",
  "MJN",
];
export const MAJ = [
  "Majuro",
  "Amata Kabua International Airport",
  "Marshall Islands",
  "MAJ",
];
export const MZG = ["Makung (Magong)", "Magong Airport", "Taiwan", "MZG"];
export const SSG = [
  "Malabo",
  "Malabo International Airport",
  "Equatorial Guinea",
  "SSG",
];
export const AGP = ["Malaga", "Pablo Ruiz Picasso Airport", "Spain", "AGP"];
export const MLX = ["Malatya", " Erhac Airport", "Turkey", "MLX"];
export const MLE = [
  "Male (Malé)",
  "Malé International Airport",
  "Maldives",
  "MLE",
];
export const MYD = ["Malindi", "Malindi Airport", "Kenya", "MYD"];
export const MMA = ["Malmö (Malmoe)", "Metropolitan Area", "Sweden", "MMA"];
export const MMX = ["Malmö (Malmoe)", "Malmö Airport", "Sweden", "MMX"];
export const MJC = ["Man", "Man Airport", "Cote d'Ivoire", "MJC"];
export const MGA = [
  "Managua",
  "Augusto C. Sandino International Airport",
  "Nicaragua",
  "MGA",
];
export const BAH = [
  "Manama",
  "Bahrain International Airport",
  "Bahrain",
  "BAH",
];
export const MAO = [
  "Manaus",
  "Eduardo Gomes International Airport",
  "Amazonas, Brazil",
  "MAO",
];
export const MAN = [
  "Manchester",
  "Manchester Airport",
  "United Kingdom",
  "MAN",
];
export const MHT = [
  "Manchester (NH)",
  "Manchester-Boston Regional Airport",
  "USA",
  "MHT",
];
export const MDL = [
  "Mandalay",
  "Mandalay International Airport (Annisaton)",
  "Myanmar",
  "MDL",
];
export const LUM = [
  "Mangshi (Luxi)",
  "Dehong Mangshi Airport",
  "Yunnan, China",
  "LUM",
];
export const MFO = ["Manguna", " Manguna Airport", "Papua New Guinea", "MFO"];
export const XMH = [
  "Manihi (island)",
  "Manihi Airport",
  "French Polynesia",
  "XMH",
];
export const MNL = [
  "Manila",
  "Ninoy Aquino International Airport",
  "Philippines",
  "MNL",
];
export const ZLO = [
  "Manzanillo",
  " Playa de Oro International Airport",
  "Mexico",
  "ZLO",
];
export const NZH = [
  "Manzhouli",
  "Manzhouli Xijiao Airport",
  "Inner Mongolia, China",
  "NZH",
];
export const MPM = [
  "Maputo",
  "Maputo International Airport",
  "Mozambique",
  "MPM",
];
export const MAB = ["Marabá", "Marabá Airport", "Pará, Brazil", "MAB"];
export const MAR = [
  "Maracaibo",
  " La Chinita International Airport",
  "Venezuela",
  "MAR",
];
export const MFQ = ["Maradi", " Maradi Airport", "Niger", "MFQ"];
export const MTH = [
  "Marathon (FL)",
  "Florida Keys Marathon Airport",
  "USA",
  "MTH",
];
export const MDQ = [
  "Mar del Plata",
  "Astor Piazzolla International Airport",
  "Argentina",
  "MDQ",
];
export const MQM = ["Mardin", " Mardin Airport", "Turkey", "MQM"];
export const MEE = ["Mare", "Maré Airport", "New Caledonia", "MEE"];
export const MGH = ["Margate", "Margate Airport", "South Africa", "MGH"];
export const MBX = ["Maribor", "Maribor Airport", "Slovenia", "MBX"];
export const MHQ = [
  "Mariehamn (Maarianhamina)",
  "Mariehamn Airport",
  "Finland",
  "MHQ",
];
export const MVR = ["Maroua", "Salak Airport", "Cameroon", "MVR"];
export const MQT = [
  "Marquette (MI)",
  "Sawyer International Airport",
  "USA",
  "MQT",
];
export const RAK = [
  "Marrakesh",
  "Menara International Airport",
  "Morocco",
  "RAK",
];
export const RMF = [
  "Marsa Alam",
  "Marsa Alam International Airport",
  "Egypt",
  "RMF",
];
export const MUH = [
  "Marsa Matrah (Marsa Matruh)",
  " Mersa Matruh Airport",
  "Egypt",
  "MUH",
];
export const MRS = ["Marseille", "Marseille Provence Airport", "France", "MRS"];
export const MHH = ["Marsh Harbour", "Marsh Harbour Airport", "Bahamas", "MHH"];
export const MVY = [
  "Martha's Vineyard (island) (MA)",
  "Martha's Vineyard Airport",
  "USA",
  "MVY",
];
export const MRB = [
  "Martinsburg (WV)",
  " Eastern WV Regional Airport",
  "USA",
  "MRB",
];
export const MBH = ["Maryborough", "Maryborough Airport", "Australia", "MBH"];
export const MSU = [
  "Maseru",
  " Moshoeshoe International Airport",
  "Lesotho",
  "MSU",
];
export const MCW = [
  "Mason City (IA)",
  "Mason City Municipal Airport",
  "USA",
  "MCW",
];
export const MVZ = [
  "Masvingo",
  "Masvingo International Airport",
  "Zimbabwe",
  "MVZ",
];
export const MMJ = ["Matsumoto/ Nagano", " Matsumoto Airport", "Japan", "MMJ"];
export const MYJ = ["Matsuyama", "Matsuyama Airport", "Japan", "MYJ"];
export const MTO = [
  "Mattoon (IL)",
  "Coles County Memorial Airport",
  "USA",
  "MTO",
];
export const MUB = ["Maun", " Maun Airport", "Botswana", "MUB"];
export const MAU = [
  "Maupiti (island)",
  " Maupiti Airport",
  "French Polynesia",
  "MAU",
];
export const MRU = [
  "Mauritius (island)",
  "Sir Seewoosagur Ramgoolam Int'l",
  "Mauritius",
  "MRU",
];
export const MAZ = [
  "Mayaguez",
  "Eugenio Maria de Hostos Airport",
  "Puerto Rico",
  "MAZ",
];
export const MZT = [
  "Mazatlan",
  "Rafael Buelna International Airport",
  "Mexico",
  "MZT",
];
export const MTS = ["Mbabane", "Matsapha Airport", "Swaziland", "MTS"];
export const MFE = [
  "McAllen (TX)",
  " McAllen-Miller International Airport",
  "USA",
  "MFE",
];
export const QCA = [
  "Mecca",
  "Mecca East Airport (no airline service)",
  "Saudi Arabia",
  "QCA",
];
export const KNO = [
  "Medan",
  "Kuala Namu International Airport",
  "Indonesia",
  "KNO",
];
export const MES = ["Medan", "Polonia Airport (now AFB)", "Indonesia", "MES"];
export const MDE = [
  "Medellin",
  " José María Córdova International Airport",
  "Colombia",
  "MDE",
];
export const MFR = [
  "Medford (OR)",
  "Rogue Valley International-Medford Airport",
  "USA",
  "MFR",
];
export const MED = [
  "Medina (Al-Madinah)",
  "Prince Mohammad Bin Abdulaziz Airport",
  "Saudi Arabia",
  "MED",
];
export const MKR = [
  "Meekatharra",
  " Meekatharra Airport",
  "WA, Australia",
  "MKR",
];
export const MEL = ["Melbourne", "Melbourne Airport", "Australia", "MEL"];
export const MLB = [
  "Melbourne (FL)",
  "Melbourne International Airport",
  "USA",
  "MLB",
];
export const FMM = ["Memmingen", "Allgäu Airport", "Germany", "FMM"];
export const MEM = [
  "Memphis (TN)",
  "Memphis International Airport",
  "USA",
  "MEM",
];
export const MDZ = [
  "Mendoza",
  "Francisco Gabrielli International Airport",
  "Argentina",
  "MDZ",
];
export const MDC = [
  "Manado",
  "Sam Ratulangi International Airport",
  "Sulawesi, Indonesia",
  "MDC",
];
export const MKQ = [
  "Merauke",
  "Merauke Mopah Airport",
  "Papua, Indonesia",
  "MKQ",
];
export const MCE = ["Merced (CA)", " Merced Municipal Airport", "USA", "MCE"];
export const MID = [
  "Merida",
  " Manuel Crescencio Rejón International",
  "Mexico",
  "MID",
];
export const MEI = ["Meridian (MS)", " Key Field", "USA", "MEI"];
export const MIM = ["Merimbula", " Merimbula Airport", "NSW, Australia", "MIM"];
export const MEZ = ["Messina", "Messina Airport", "South Africa", "MEZ"];
export const MTM = [
  "Metlakatla (AK)",
  "Metlakatla Seaplane Base",
  "USA",
  "MTM",
];
export const MZM = ["Metz", "Frescaty Airport", "France", "MZM"];
export const ETZ = ["Metz", "Metz-Nancy-Lorraine Airport", "France", "ETZ"];
export const MXL = [
  "Mexicali",
  "Rodolfo Sánchez Taboada International",
  "Mexico",
  "MXL",
];
export const MEX = [
  "Mexico City",
  "Mexico City International Airport",
  "Mexico",
  "MEX",
];
export const AZP = ["Mexico City", " Atizapan Airport", "Mexico", "AZP"];
export const NLU = ["Mexico City", "Santa Lucia AFB", "Mexico", "NLU"];
export const MFU = ["Mfuwe", "Mfuwe Airport", "Zambia", "MFU"];
export const MIA = ["Miami (FL)", "Miami International Airport", "USA", "MIA"];
export const MWD = ["Mianwali", "Mianwali AFB", "Pakistan", "MWD"];
export const MMM = [
  "Middlemount",
  "Middlemount Airport",
  "Queensland, Australia",
  "MMM",
];
export const MAF = [
  "Midland/Odessa (TX)",
  "Midland International Air and Space Port",
  "USA",
  "MAF",
];
export const MDY = [
  "Midway Island",
  "Henderson Field",
  "US Minor Outlying Islands",
  "MDY",
];
export const MIK = ["Mikkeli", "Mikkeli Airport", "Finland", "MIK"];
export const MIL = ["Milan", "Metropolitan Area", "Italy", "MIL"];
export const LIN = ["Milan", "Linate Airport", "Italy", "LIN"];
export const MXP = ["Milan", "Malpensa Airport", "Italy", "MXP"];
export const BGY = [
  "Milan",
  "Il Caravaggio International Airport",
  "Italy",
  "BGY",
];
export const MQL = [
  "Mildura",
  " Mildura Airport",
  "Victoria, Australia",
  "MQL",
];
export const MLS = [
  "Miles City (MT)",
  "Miles City Municipal Airport",
  "USA",
  "MLS",
];
export const MFN = [
  "Milford Sound",
  "Milford Sound Airport",
  "New Zealand",
  "MFN",
];
export const MKE = [
  "Milwaukee (WI)",
  "General Mitchell International Airport",
  "USA",
  "MKE",
];
export const MTT = [
  "Minatitlan",
  " Minatitlán/Coatzacoalcos National Airport",
  "Mexico",
  "MTT",
];
export const MRV = [
  "Mineralnye Vody",
  " Mineralnye Vody Airport",
  "Russia",
  "MRV",
];
export const MOT = ["Minot (ND)", " Minot International Airport", "USA", "MOT"];
export const MSQ = ["Minsk", "Minsk International Airport", "Belarus", "MSQ"];
export const MYY = [
  "Miri",
  "Miri International Airport",
  "Sarawak, Malaysia",
  "MYY",
];
//  export const --- =[
//     "Mirpur",
//     "Mirpur International Airport (under construction)",
//     "Pakistan",
//     "---"
//   ];
export const MSO = [
  "Missula (MT)",
  "Missoula International Airport",
  "USA",
  "MSO",
];
export const MHE = [
  "Mitchell (SD)",
  "Mitchell Municipal Airport",
  "USA",
  "MHE",
];
export const MMY = [
  "Miyako Jima (Ryuku Islands)",
  "Miyako Airport",
  "Okinawa, Japan",
  "MMY",
];
export const KMI = ["Miyazaki", "Miyazaki Airport", "Miyazaki, Japan", "KMI"];
export const MBM = [
  "Mkambati",
  "Mkambati Airport",
  "Eastern Cape, South Africa",
  "MBM",
];
export const MFF = ["Moanda", "Moanda Airport", "Gabon", "MFF"];
export const MOB = ["Mobile (AL)", "Mobile Regional Airport", "USA", "MOB"];
export const MOD = [
  "Modesto (CA)",
  "Modesto City-County Airport",
  "USA",
  "MOD",
];
export const MJD = ["Moenjodaro", "Moenjodaro Airport", "Pakistan", "MJD"];
export const MGQ = [
  "Mogadishu",
  "Aden Adde International Airport",
  "Somalia",
  "MGQ",
];
export const OKU = ["Mokuti Lodge", "Mokuti Lodge Airport", "Namibia", "OKU"];
export const MLI = [
  "Moline/Quad Cities (IL)",
  "Quad City International Airport",
  "USA",
  "MLI",
];
export const MBA = ["Mombasa", "Moi International Airport", "Kenya", "MBA"];
export const MCM = ["Monaco", "Monaco Heliport", "Monaco", "MCM"];
export const MIR = [
  "Monastir",
  "Monastir Habib Bourguiba International",
  "Tunisia",
  "MIR",
];
export const YQM = [
  "Moncton",
  "Greater Moncton International Airport",
  "NB, Canada",
  "YQM",
];
export const MLU = ["Monroe (LA)", "Monroe Regional Airport", "USA", "MLU"];
export const ROB = [
  "Monrovia",
  "Roberts International Airport",
  "Liberia",
  "ROB",
];
export const MLW = ["Monrovia", "Spriggs Payne Airport", "Liberia", "MLW"];
export const MBJ = [
  "Montego Bay",
  " Sangster International Airport",
  "Jamaica",
  "MBJ",
];
export const MRY = ["Monterey (CA)", "Monterey Regional Airport", "USA", "MRY"];
export const MTY = [
  "Monterrey",
  "Monterrey International Airport",
  "Nuevo Leon, Mexico",
  "MTY",
];
export const NTR = [
  "Monterrey",
  "Monterrey Del Norte International",
  "Nuevo Leon, Mexico",
  "NTR",
];
export const MOC = [
  "Montes Claros",
  "Montes Claros Airport",
  "Minas Gerais, Brazil",
  "MOC",
];
export const MVD = [
  "Montevideo",
  "Carrasco International Airport",
  "Uruguay",
  "MVD",
];
export const MGM = [
  "Montgomery (AL)",
  "Montgomery Regional Airport",
  "USA",
  "MGM",
];
export const MPL = [
  "Montpellier",
  "Mediterranée Airport  (Frejorgues)",
  "France",
  "MPL",
];
export const YMQ = ["Montreal", "Metropolitan Area", "Canada", "YMQ"];
export const YUL = [
  "Montreal",
  "Pierre Elliott Trudeau International Airport",
  "Canada",
  "YUL",
];
export const YMX = [
  "Montreal",
  " Montréal-Mirabel International Airport (cargo)",
  "Canada",
  "YMX",
];
export const MTJ = [
  "Montrose/Tellruide (CO)",
  "Montrose Regional Airport",
  "USA",
  "MTJ",
];
export const MOZ = [
  "Moorea (Mo'orea island)",
  " Moorea Airport",
  "French Polynesia",
  "MOZ",
];
export const MOV = [
  "Moranbah",
  "Moranbah Airport",
  "Queensland, Australia",
  "MOV",
];
export const MRZ = ["Moree", " Moree Airport", "NSW, Australia", "MRZ"];
export const MLM = [
  "Morelia",
  "Francisco J. Mujica International Airport",
  "Michoacán, Mexico",
  "MLM",
];
export const MGW = [
  "Morgantown (WV)",
  "Morgantown Municipal Airport",
  "USA",
  "MGW",
];
export const HNA = ["Morioka", "Hanamaki Airport", "Honshū, Japan", "HNA"];
export const HAH = [
  "Moroni",
  "Prince Said Ibrahim International Airport",
  "Comoros",
  "HAH",
];
export const YVA = [
  "Moroni",
  "Moroni Iconi Airport (closed)",
  "Comoros",
  "YVA",
];
export const MYA = ["Moruya", "Moruya Airport", "NSW, Australia", "MYA"];
export const MOW = ["Moscow", "Metropolitan Area", "Russia", "MOW"];
export const DME = [
  "Moscow",
  "Domodedovo International Airport",
  "Russia",
  "DME",
];
export const SVO = [
  "Moscow",
  "Sheremetyevo International Airport",
  "Russia",
  "SVO",
];
export const VKO = [
  "Moscow",
  " Vnukovo International Airport",
  "Russia",
  "VKO",
];
export const MWH = [
  "Moses Lake (WA)",
  "Grant County International Airport",
  "USA",
  "MWH",
];
export const RYG = ["Moss", "Moss Airport Rygge", "Norway", "RYG"];
export const MZY = ["Mossel Bay", "Mossel Bay Airport", "South Africa", "MZY"];
export const OMO = [
  "Mostar",
  "Mostar International Airport",
  "Bosnia and Herzegovina",
  "OMO",
];
export const OSM = ["Mosul", "Mosul International Airport", "Iraq", "OSM"];
export const MJL = ["Mouila", "Mouila Airport", "Gabon", "MJL"];
export const MQQ = ["Moundou", "Moundou Airport", "Chad", "MQQ"];
export const GTN = ["Mount Cook", "Glentanner Aerodrome", "New Zealand", "GTN"];
export const MGB = [
  "Mount Gambier",
  "Mount Gambier Airport",
  "Australia",
  "MGB",
];
export const MMG = ["Mount Magnet", "Mount Magnet Airport", "Australia", "MMG"];
export const UTT = [
  "Mthatha (Umtata)",
  "Mthatha Airport",
  "South Africa",
  "UTT",
];
export const ISA = [
  "Mt. Isa",
  "Mount Isa Airport",
  "Queensland, Australia",
  "ISA",
];
export const MCL = [
  "Mt. McKinley (AK)",
  "McKinley National Park Airport",
  "USA",
  "MCL",
];
export const MDG = [
  "Mudanjiang",
  "Mudanjiang Hailang Airport",
  "Heilongjiang, China",
  "MDG",
];
export const MUC = [
  "München (Munich)",
  "Munich Airport",
  "Bavaria, Germany",
  "MUC",
];
export const FMO = [
  "Muenster/Osnabrueck",
  "Münster Osnabrück International Airport",
  "NRW, Germany",
  "FMO",
];
export const MLH = [
  "Mulhouse",
  "EuroAirport Basel-Mulhouse-Freiburg",
  "France",
  "MLH",
];
export const MUX = [
  "Multan",
  "Multan International Airport",
  "Pakistan",
  "MUX",
];
export const BOM = [
  "Mumbai (Bombay)",
  "Chhatrapati Shivaji International Airport",
  "India",
  "BOM",
];
export const MJV = ["Murcia", "Murcia-San Javier Airport", "Spain", "MJV"];
export const MMK = ["Murmansk", "Murmansk Airport", "Russia", "MMK"];
export const MSR = ["Mus (Muş)", "Muş Airport", "Turkey", "MSR"];
export const MCT = [
  "Muscat",
  "Muscat International Airport  (Seeb)",
  "Oman",
  "MCT",
];
export const MSL = [
  "Muscle Shoals (AL)",
  "Northwest Alabama Regional Airport",
  "USA",
  "MSL",
];
export const MKG = ["Muskegon (MI)", " Muskegon County Airport", "USA", "MKG"];
export const MFG = ["Muzaffarabad", "Muzaffarabad Airport", "Pakistan", "MFG"];
export const MVB = ["Mvengue", "Franceville/Mvengue Airport", "Gabon", "MVB"];
export const JMK = [
  "Mykonos (island)",
  "Mykonos Island National Airport",
  "Greece",
  "JMK",
];
export const MYR = [
  "Myrtle Beach (SC)",
  " Myrtle Beach International Airport",
  "USA",
  "MYR",
];
export const CRE = ["Myrtle Beach (SC)", "Grand Strand Airport", "USA", "CRE"];
export const MYQ = [
  "Mysore (Mysuru)",
  "Mysore Airport",
  "Karnataka, India",
  "MYQ",
];
export const MJT = [
  "Mytilene (Lesbos island)",
  " Mytilene International Airport",
  "Greece",
  "MJT",
];
export const MZF = ["Mzamba", "Wild Coast Sun Airport", "South Africa", "MZF"];
export const NAN = [
  "Nadi",
  "Nadi International Airport",
  "Viti Levu, Fiji",
  "NAN",
];
export const NGS = ["Nagasaki", " Nagasaki Airport", "Japan", "NGS"];
export const NGO = [
  "Nagoya",
  " Chubu Centrair International Airport",
  "Japan",
  "NGO",
];
export const NAG = [
  "Nagpur",
  "Dr. Babasaheb Ambedkar International",
  "Maharashtra, India",
  "NAG",
];
export const OKA = [" Naha", "Naha Airport", "Okinawa, Japan", "OKA"];
export const NBO = ["Nairobi", "Jomo Kenyatta International", "Kenya", "NBO"];
export const NAJ = ["Nakhchivan", "Nakhchivan Airport", "Azerbaijan", "NAJ"];
export const NAK = [
  "Nakhon Ratchasima",
  "Nakhon Ratchasima Airport",
  "Thailand",
  "NAK",
];
export const NST = [
  "Nakhon Si Thammarat",
  "Nakhon Si Thammarat Airport",
  "Thailand",
  "NST",
];
export const KHN = [
  "Nanchang",
  "Nanchang Changbei International Airport",
  "Jiangxi, China",
  "KHN",
];
export const ENC = ["Nancy", " Essey Airport", "France", "ENC"];
export const YSR = ["Nanisivik", "Nanisivik Airport", "Nunavut, Canada", "YSR"];
export const NNG = [
  "Nanning",
  "Nanning Wuxu International Airport",
  "Guangxi,  China",
  "NNG",
];
export const NTE = ["Nantes", "Nantes Atlantique Airport", "France", "NTE"];
export const ACK = [
  "Nantucket (MA)",
  " Nantucket Memorial Airport",
  "USA",
  "ACK",
];
export const NAP = [
  "Naples (Napoli)",
  "Naples International Airport",
  "Italy",
  "NAP",
];
export const APF = ["Naples (FL)", "Naples Municipal Airport", "USA", "APF"];
export const NAA = ["Narrabri", "Narrabri Airport", "NSW, Australia", "NAA"];
export const NRA = [
  "Narrandera",
  "Narrandera Airport",
  "NSW, Australia",
  "NRA",
];
export const UAK = ["Narsarsuaq", " Narsarsuaq Airport", "Greenland", "UAK"];
export const BNA = [
  "Nashville (TN)",
  "Nashville International Airport",
  "USA",
  "BNA",
];
export const NAS = [
  "Nassau",
  "Lynden Pindling International Airport",
  "Bahamas",
  "NAS",
];
export const NAT = ["Natal", "Natal International Airport", "Brazil", "NAT"];
export const SUV = [
  "Nausori/Suva",
  " Nausori International Airport",
  "Viti Levu, Fiji",
  "SUV",
];
export const WNS = ["Nawabshah", "Nawabshah Airport", "Sindh, Pakistan", "WNS"];
export const JNX = [
  "Naxos (island)",
  "Naxos Island National Airport",
  "Greece",
  "JNX",
];
export const NYT = [
  "Naypyidaw",
  "Naypyidaw International Airport",
  "Myanmar (Burma)",
  "NYT",
];
export const NDJ = [
  "N'Djamena",
  "N’Djamena International Airport",
  "Chad",
  "NDJ",
];
export const NLA = ["N'Dola (Ndola)", " Ndola Airport", "Zambia", "NLA"];
export const NSN = ["Nelson", " Nelson Airport", "New Zealand", "NSN"];
export const NLP = [
  "Nelspruit",
  "Nelspruit Airport (closed)",
  "South Africa",
  "NLP",
];
export const MQP = [
  "Nelspruit",
  "Kruger Mpumalanga International Airport",
  "South Africa",
  "MQP",
];
export const NEV = [
  "Nevis",
  "Vance Amory International Airport",
  "Nevis",
  "NEV",
];
export const EWN = [
  "New Bern (NC)",
  "Craven County Regional Airport",
  "USA",
  "EWN",
];
export const HVN = ["New Haven (CT)", " Tweed-New Haven Airport", "USA", "HVN"];
export const MSY = [
  "New Orleans (LA)",
  "Louis Armstrong International Airport",
  "USA",
  "MSY",
];
export const NQ = [
  "Newquay",
  "Newquay Cornwall Airport",
  "United Kingdom",
  " NQY",
];
export const UVL = ["Kharga", "Kharga New Valley Airport", "Egypt", "UVL"];
export const NYC = ["New York (NY)", "Metropolitan Area", "USA", "NYC"];
export const JFK = [
  "New York (NY)",
  "John F Kennedy International Airport",
  "USA",
  "JFK",
];
export const LGA = ["New York (NY)", "LaGuardia Airport", "USA", "LGA"];
export const EWR = [
  "New York (NJ)",
  "Newark Liberty International Airport",
  "USA",
  "EWR",
];
export const SWF = [
  "Newburgh (NY)",
  "Stewart International Airport",
  "USA",
  "SWF",
];
export const BEO = [
  "Newcastle",
  "Newcastle - Belmont Airport",
  "NSW, Australia",
  "BEO",
];
export const NTL = ["Newcastle", " Newcastle Airport", "NSW, Australia", "NTL"];
export const NCL = [
  "Newcastle",
  "Newcastle International",
  "United Kingdom",
  "NCL",
];
export const NCS = [
  "Newcastle",
  "Newcastle Industrial Airport",
  "KwaZulu-Natal, South Africa",
  "NCS",
];
export const ZNE = ["Newman", "Newman Airport", "WA, Australia", "ZNE"];
export const PHF = [
  "Newport News/Williamsburg (VA)",
  " Newport News/Williamsburg International",
  "USA",
  "PHF",
];
export const NGE = ["N'Gaoundere", "Ngaoundéré Airport", "Cameroon", "NGE"];
export const ROR = [
  "Ngerulmud",
  "Roman Tmetuchl International Airport",
  "Palau",
  "ROR",
];
export const IAG = [
  "Niagara Falls International",
  "Niagara Falls International Airport",
  "USA",
  "IAG",
];
export const NIM = [
  "Niamey",
  "Diori Hamani International Airport",
  "Niger",
  "NIM",
];
export const NCE = ["Nice", "Nice Côte d'Azur Airport", "France", "NCE"];
export const NIC = [
  "Nicosia",
  "Nicosia International Airport (abandoned)",
  "Cyprus",
  "NIC",
];
export const NLV = [
  "Nikolaev (Mykolaiv)",
  " Mykolaiv Airport",
  "Ukraine",
  "NLV",
];
export const KIJ = ["Niigata", "Niigata Airport", "Japan", "KIJ"];
export const FNI = ["Nimes", "Nîmes Airport", "France", "FNI"];
export const NGB = [
  "Ningbo",
  "Ningbo Lishe International Airport  (cn)",
  "Zhejiang, China",
  "NGB",
];
export const INI = [
  "Nis (Niš)",
  "Niš Constantine the Great Airport",
  "Serbia",
  "INI",
];
export const GOJ = [
  "Nizhniy Novgorod",
  "Strigino International Airport",
  "Russia",
  "GOJ",
];
export const OLS = [
  "Nogales (AZ)",
  " Nogales International Airport",
  "USA",
  "OLS",
];
export const OME = ["Nome (AK)", "Nome Airport", "USA", "OME"];
export const NLK = [
  "Norfolk Island",
  "Norfolk Island Airport",
  "Australia",
  "NLK",
];
export const ORF = [
  "Norfolk/Virginia Beach (VA)",
  "Norfolk International Airport",
  "USA",
  "ORF",
];
export const YVQ = [
  "Norman Wells",
  "Norman Wells Airport",
  "NWT, Canada",
  "YVQ",
];
export const NRK = [
  "Norrkoeping",
  "Norrköping Airport",
  "Östergötland, Sweden",
  "NRK",
];
export const OTH = [
  "North Bend (OR)",
  "North Bend Municipal Airport",
  "USA",
  "OTH",
];
export const ELH = [
  "North Eleuthera",
  "North Eleuthera Airport",
  "Bahamas",
  "ELH",
];
export const NWI = [
  "Norwich",
  "Norwich International Airport",
  "United Kingdom",
  "NWI",
];
export const EMA = [
  "Nottingham",
  "East Midlands Airport",
  "England, UK",
  "EMA",
];
export const NDB = [
  "Nouadhibou",
  "Nouadhibou International Airport",
  "Mauritania",
  "NDB",
];
export const NKC = [
  "Nouakchott",
  "Nouakchott International Airport",
  "Mauritania",
  "NKC",
];
export const NOU = [
  "Noumea (administrative capital)",
  "La Tontouta International Airport",
  "New Caledonia",
  "NOU",
];
export const GEA = ["Noumea", "Nouméa Magenta Airport", "New Caledonia", "GEA"];
export const QND = ["Novi Sad", "Novi Sad Airport", "Serbia", "QND"];
export const OVB = [
  "Novosibirsk",
  "Novosibirsk Tolmachevo Airport",
  "Russia",
  "OVB",
];
export const NUE = [
  "Nürnberg (Nuremberg)",
  "Albrecht Dürer Airport Nürnberg",
  "Germany",
  "NUE",
];
export const NLD = [
  "Nuevo Laredo",
  "Quetzalcóatl International Airport",
  "Mexico",
  "NLD",
];
export const TBU = [
  "Nuku'alofa",
  "Fua’amotu International Airport",
  "Tonga",
  "TBU",
];
export const GOH = [
  "Nuuk",
  "Nuuk Airport  (Mittarfik Nuuk)",
  "Greenland",
  "GOH",
];
export const OAK = [
  "Oakland (CA)",
  "Oakland International Airport",
  "USA",
  "OAK",
];
export const OAX = [
  "Oaxaca de Juárez",
  "Xoxocotlán International Airport",
  "Mexico",
  "OAX",
];
export const ODE = [
  "Odense",
  "Hans Christian Andersen Airport",
  "Denmark",
  "ODE",
];
export const ODS = ["Odessa", "Odessa International Airport", "Ukraine", "ODS"];
export const ORB = ["Örebro (Oerebro)", "Örebro Airport", "Sweden", "ORB"];
export const OHD = ["Ohrid", " Ohrid Airport", "Macedonia", "OHD"];
export const OIT = ["Oita", "Oita Airport", "Kyushu, Japan", "OIT"];
export const OKJ = ["Okayama", "Okayama Airport", "Chūgoku, Japan", "OKJ"];
export const OKC = [
  "Oklahoma City (OK)",
  " Will Rogers World Airport",
  "USA",
  "OKC",
];
export const OLB = [
  "Olbia",
  "Olbia Costa Smeralda Airport",
  "Sardinia, Italy",
  "OLB",
];
export const OLP = [
  "Olympic Dam",
  "Olympic Dam Airport",
  "SA, Australia",
  "OLP",
];
export const OMA = ["Omaha (NE)", "Eppley Airfield", "USA", "OMA"];
export const OND = ["Ondangwa", "Ondangwa Airport", "Namibia", "OND"];
export const ONT = [
  "Ontario (CA)",
  "Ontario International Airport",
  "USA",
  "ONT",
];
export const ORN = [
  "Oran (Ouahran)",
  "Oran Es Sénia Airport",
  "Algeria",
  "ORN",
];
export const OAG = ["Orange", "Orange Airport", "NSW, Australia", "OAG"];
export const OMD = [
  "Oranjemund",
  "Oranjemund Airport",
  "Karas, Namibia",
  "OMD",
];
export const AUA = [
  "Oranjestad",
  "Reina Beatrix International",
  "Aruba",
  "AUA",
];
export const KOI = [
  "Orkney (islands)",
  "Kirkwall Airport",
  "United Kingdom",
  "KOI",
];
export const ORL = ["Orlando (FL)", "Metropolitan Area", "USA", "ORL"];
export const MCO = [
  "Orlando (FL)",
  "Orlando International Airport",
  "USA",
  "MCO",
];
export const ORS = [
  "Orpheus Island",
  "Waterport Airport",
  "Queensland, Australia",
  "ORS",
];
export const OSA = ["Osaka", "Metropolitan Area", "Japan", "OSA"];
export const ITM = [
  "Osaka",
  "Osaka International Airport  (Itami)",
  "Japan",
  "ITM",
];
export const KIX = ["Osaka", "Kansai International Airport", "Japan", "KIX"];
export const OSH = ["Oshkosh (WI)", "Wittman Regional Airport", "USA", "OSH"];
export const OSI = ["Osijek", "Osijek Airport", "Croatia (Hrvatska)", "OSI"];
export const OSL = ["Oslo", "Oslo Airport, Gardermoen", "Norway", "OSL"];
export const FBU = [
  "Oslo",
  "Fornebu Airport (closed) replaced by Oslo Airport Gardermoen",
  "Norway",
  "FBU",
];
export const TRF = ["Oslo", "Sandefjord Airport, Torp", "Norway", "TRF"];
export const YOW = [
  "Ottawa",
  "Macdonald-Cartier International Airport",
  "Ontario, Canada",
  "YOW",
];
export const ODA = [
  "Ouadda",
  "Ouadda Airport",
  "Central African Republic",
  "ODA",
];
export const OZZ = ["Ouarzazate", "Ouarzazate Airport", "Morocco", "OZZ"];
export const OUH = [
  "Oudtshoorn",
  "Oudtshoorn Airport",
  "Western Cape, South Africa",
  "OUH",
];
export const OUA = [
  "Ouagadougou",
  "Ouagadougou International Airport",
  "Burkina Faso",
  "OUA",
];
export const OUD = ["Oujda", "Angads Airport", "Morocco", "OUD"];
export const OUL = ["Oulu", " Oulu Airport", "Finland", "OUL"];
export const OUK = [
  "Out Skerries (Shetland)",
  "Out Skerries",
  "United Kingdom",
  "OUK",
];
export const UVE = ["Ouvea", "Ouloup Airport", "New Caledonia", "UVE"];
export const OVD = [
  "Oviedo",
  "Asturias Airport (Oviedo Airport)",
  "Spain",
  "OVD",
];
export const OWB = [
  "Owensboro (KY)",
  "Owensboro-Daviess County Airport",
  "USA",
  "OWB",
];
export const OXR = ["Oxnard (CA)", "Oxnard Airport", "USA", "OXR"];
export const OYE = ["Oyem", "Oyem Airport", "Gabon", "OYE"];
export const PAD = [
  "Paderborn/Lippstadt",
  "Paderborn/Lippstadt Airport",
  "Germany",
  "PAD",
];
export const PDG = [
  "Padang",
  "Minangkabau International Airport",
  "West Sumatra, Indonesia",
  "PDG",
];
export const PAH = ["Paducah (KY)", "Barkley Regional Airport", "USA", "PAH"];
export const PGA = [
  "Page/Lake Powell (AZ)",
  "Page Municipal Airport",
  "USA",
  "PGA",
];
export const PPG = [
  "Pago Pago",
  "Pago Pago International Airport",
  "American Samoa",
  "PPG",
];
export const PYY = ["Pai", "Pai Airport", "Thailand", "PYY"];
export const PKB = [
  "Pakersburg (WV) /Marietta (OH)",
  "Mid-Ohio Valley Regional Airport",
  "USA",
  "PKB",
];
export const PLQ = [
  "Palanga",
  "Palanga International Airport",
  "Lithuania",
  "PLQ",
];
export const PMO = ["Palermo", "Palermo Airport", "Italy", "PMO"];
export const PMI = [
  "Palma de Mallorca",
  "Palma de Mallorca Airport",
  "Spain",
  "PMI",
];
export const PMW = ["Palmas", "Palmas Airport", "Tocantins, Brazil", "PMW"];
export const PMD = [
  "Palmdale/Lancaster (CA)",
  " LA/Palmdale Regional Airport",
  "USA",
  "PMD",
];
export const PMR = [
  "Palmerston North",
  "Palmerston North International Airport",
  "New Zealand",
  "PMR",
];
export const PSP = [
  "Palm Springs (CA)",
  "Palm Springs International Airport",
  "USA",
  "PSP",
];
export const PAO = ["Palo Alto (CA)", "Palo Alto Airport", "USA", "PAO"];
export const PTY = [
  "Panama City",
  "Tocumen International Airport",
  "Panama",
  "PTY",
];
export const PFN = [
  "Panama City (FL)",
  "Panama City-Bay County Airport",
  "USA",
  "PFN",
];
export const PJG = ["Panjgur", " Panjgur Airport", "Pakistan", "PJG"];
export const PNL = ["Pantelleria", "Pantelleria Airport", "Italy", "PNL"];
export const PGH = [
  "Pantnagar",
  "Pantnagar Airport",
  "Uttarakhand, India",
  "PGH",
];
export const PPT = [
  "Papeete",
  "Tahiti International Airport",
  "French Polynesia (Tahiti)",
  "PPT",
];
export const PFO = ["Paphos", "Paphos International Airport", "Cyprus", "PFO"];
export const PBO = ["Paraburdoo", "Paraburdoo Airport", "Australia", "PBO"];
export const PBM = [
  "Paramaribo",
  "Paramaribo-Zanderij International Airport",
  "Suriname",
  "PBM",
];
export const PAR = ["Paris", "Metropolitan Area", "France", "PAR"];
export const CDG = ["Paris", "Charles de Gaulle Airport", "France", "CDG"];
export const LBG = ["Paris", "Paris–Le Bourget Airport", "France", "LBG"];
export const ORY = ["Paris", "Paris Orly Airport", "France", "ORY"];
export const PHB = [
  " Parnaiba",
  "Parnaíba International Airport",
  "Piaui, Brazil",
  "PHB",
];
export const PGL = [
  " Pascagoula (MS)",
  "Trent Lott International Airport",
  "USA",
  "PGL",
];
export const PSC = ["Pasco (WA)", " Tri-Cities Airport", "USA", "PSC"];
export const PSI = ["Pasni", "Pasni Airport", "Balochistan, Pakistan", "PSI"];
export const PAT = [
  "Patna",
  "Lok Nayak Jayaprakash Airport",
  "Bihar, India",
  "PAT",
];
export const PYX = ["Pattaya", "Pattaya Airpark", "Thailand", "PYX"];
export const PUF = ["Pau", " Pau Pyrénées Airport", "France", "PUF"];
export const PWQ = [" Pavlodar", "Pavlodar Airport", "Kazakhstan", "PWQ"];
export const PDU = [
  "Paysandu",
  "Tydeo Larre Borges International Airport",
  "Uruguay",
  "PDU",
];
export const PLN = ["Pellston (MI)", "Pellston Regional Airport", "USA", "PLN"];
export const PET = [
  "Pelotas",
  "Pelotas International Airport",
  "Brazil",
  "PET",
];
export const PEN = [
  "Penang",
  "Penang International Airport",
  "Malaysia",
  "PEN",
];
export const PDT = [
  "Pendelton (OR)",
  "Eastern Oregon Regional Airport",
  "USA",
  "PDT",
];
export const PNS = [
  "Pensacola (FL)",
  " Pensacola International Airport",
  "USA",
  "PNS",
];
export const PIA = [
  "Peoria/Bloomington (IL)",
  " Greater Peoria Regional Airport",
  "USA",
  "PIA",
];
export const PEI = [
  "Pereira",
  "Matecaña International Airport",
  "Colombia",
  "PEI",
];
export const PGF = [
  "Perpignan",
  "Perpignan – Rivesaltes Airport",
  "France",
  "PGF",
];
export const PER = ["Perth", "Perth Airport", "WA, Australia", "PER"];
export const PEG = ["Perugia", " San Egidio Airport", "Italy", "PEG"];
export const PSR = ["Pescara", "Pescara Airport", "Italy", "PSR"];
export const PEW = ["Peshawar", "Peshawar Airport", "Pakistan", "PEW"];
export const PSG = [
  "Petersburg (AK)",
  " Petersburg James A. Johnson Airport",
  "USA",
  "PSG",
];
export const PHW = [
  "Phalaborwa",
  "Hendrik Van Eck Airport",
  "Limpopo, South Africa",
  "PHW",
];
export const PHY = ["Phetchabun", "Phetchabun Airport", "Thailand", "PHY"];
export const PHL = [
  "Philadelphia (PA)",
  "Philadelphia International Airport",
  "USA",
  "PHL",
];
export const PNH = [
  "Phnom Penh",
  "Phnom Penh International Airport",
  "Cambodia",
  "PNH",
];
export const DVT = [
  "Phoenix (AZ)",
  "Phoenix Deer Valley Airport",
  "USA",
  "DVT",
];
export const AZA = [
  "Phoenix (AZ)",
  "Phoenix-Mesa Gateway Airport",
  "USA",
  "AZA",
];
export const PHX = ["Phoenix (AZ)", "Sky Harbor International", "USA", "PHX"];
export const HKT = [
  "Phuket (island)",
  "Phuket International Airport",
  "Thailand",
  "HKT",
];
export const PDS = [
  "Piedras Negras",
  "Piedras Negras International Airport",
  "Coahuila, Mexico",
  "PDS",
];
export const PIR = ["Pierre (SD)", "Pierre Regional Airport", "USA", "PIR"];
export const PZB = [
  "Pietermaritzburg",
  " Pietermaritzburg Airport",
  " South Africa",
  "PZB",
];
export const NTY = [
  "Pilanesberg/Sun City",
  "Pilanesberg International Airport",
  "South Africa",
  "NTY",
];
export const PSA = ["Pisa", "Galileo Galilei Airport", "Italy", "PSA"];
export const PIT = [
  "Pittsburgh (PA)",
  "Pittsburgh International Airport",
  "USA",
  "PIT",
];
export const PLB = [
  "Plattsburgh (NY)",
  "Clinton County Airport (closed)",
  "USA",
  "PLB",
];
export const PBZ = [
  "Plettenberg Bay",
  "Plettenberg Bay Airport",
  "South Africa",
  "PBZ",
];
export const PDV = [
  "Plovdiv",
  "Plovdiv International Airport",
  "Bulgaria",
  "PDV",
];
export const PIH = [
  "Pocatello (ID)",
  " Pocatello Regional Airport",
  "USA",
  "PIH",
];
export const TGD = ["Podgorica", "Podgorica Airport", "Montenegro", "TGD"];
export const PNI = [
  "Pohnpei, Kolonia",
  "Pohnpei International Airport",
  "Micronesia",
  "PNI",
];
export const PTP = [
  "Pointe a Pitre",
  "Pointe-à-Pitre International Airport",
  "Guadeloupe",
  "PTP",
];
export const PNR = [
  "Pointe Noire",
  "Pointe Noire Airport",
  "Congo (ROC)",
  "PNR",
];
export const PIS = ["Poitiers", "Poitiers - Biard Airport", "France", "PIS"];
export const PTG = [
  "Polokwane (Pietersburg)",
  "Polokwane International Airport",
  "Limpopo, South Africa",
  "PTG",
];
export const PSE = ["Ponce", "Mercedita Airport", "Puerto Rico", "PSE"];
export const PDL = [
  "Ponta Delgada",
  "João Paulo II Airport",
  "Azores, Portugal",
  "PDL",
];
export const PMG = ["Ponta Pora", "Ponta Porã Airport", "Brazil", "PMG"];
export const POR = ["Pori", "Pori Airport", "Finland", "POR"];
export const PMV = [
  "Porlamar, Isla Margarita",
  'Del Caribe"Santiago Mariño" International',
  "Venezuela",
  "PMV",
];
export const CLM = [
  "Port Angeles (WA)",
  "William R. Fairchild International Airport",
  "USA",
  "CLM",
];
export const PAP = [
  "Port au Prince",
  "Toussaint Louverture International Airport",
  "Haiti",
  "PAP",
];
export const PUG = [
  "Port Augusta",
  "Port Augusta Airport",
  "SA, Australia",
  "PUG",
];
export const PLZ = [
  "Port Elizabeth",
  "Port Elizabeth Airport",
  "Eastern Cape, South Africa",
  "PLZ",
];
export const POG = ["Port Gentil", "Port Gentil Airport", "Gabon", "POG"];
export const PHC = [
  "Port Harcourt",
  "Port Harcourt International Airport",
  "Nigeria",
  "PHC",
];
export const PHE = [
  "Port Hedland",
  "Port Hedland International Airport",
  "WA, Australia",
  "PHE",
];
export const PHN = [
  "Port Huron (MI)",
  "St. Clair County International Airport",
  "USA",
  "PHN",
];
export const PTJ = [
  "Portland",
  "Portland Airport",
  "Victoria, Australia",
  "PTJ",
];
export const PWM = [
  "Portland (ME)",
  "Portland International Jetport",
  "USA",
  "PWM",
];
export const PDX = [
  "Portland (OR)",
  "Portland International Airport",
  "USA",
  "PDX",
];
export const PLO = [
  "Port Lincoln",
  "Port Lincoln Airport",
  "SA, Australia",
  "PLO",
];
export const PQQ = [
  "Port Macquarie",
  "Port Macquarie Airport",
  "NSW, Australia",
  "PQQ",
];
export const YPN = [
  "Port Menier (PQ)",
  "Port-Menier Airport",
  "Quebec, Canada",
  "YPN",
];
export const POM = [
  "Port Moresby",
  "Jacksons International Airport",
  "Papua New Guinea",
  "POM",
];
export const OPO = [
  "Porto",
  "Francisco de Sá Carneiro Airport",
  "Portugal",
  "OPO",
];
export const POA = [
  "Porto Alegre",
  "Salgado Filho International Airport",
  "Brazil",
  "POA",
];
export const COO = ["Porto-Novo", "Cotonou Cadjehoun Airport", "Benin", "COO"];
export const PXO = [
  "Porto Santo Island",
  " Porto Santo Airport",
  "Porto Santo, Portugal",
  "PXO",
];
export const PZU = [
  "Port Sudan",
  "Port Sudan New International Airport",
  "Sudan",
  "PZU",
];
export const PVH = [
  "Porto Velho",
  "Porto Velho International Airport",
  "Rodondia, Brazil",
  "PVH",
];
export const PSD = ["Port Said", "Port Said Airport", "Egypt", "PSD"];
export const PSM = [
  "Portsmouth (NH)",
  "Portsmouth International Airport at Pease",
  "USA",
  "PSM",
];
export const VLI = [
  "Port Vila",
  "Bauerfield International Airport",
  "Vanuatu",
  "VLI",
];
export const POU = [
  "Poughkeepsie (NY)",
  "Dutchess County Airport",
  "USA",
  "POU",
];
export const POZ = ["Poznan", "Poznan-Lawica Airport", "Poland", "POZ"];
export const PRG = [
  "Prague",
  "Václav Havel Airport Prague",
  "Czech Republic",
  "PRG",
];
export const PQI = [
  "Presque Island (ME)",
  " Northern Maine Regional Airport",
  "USA",
  "PQI",
];
export const PIK = [
  "Prestwick (near Glasgow)",
  " Glasgow Prestwick International Airport",
  "Scotland, UK",
  "PIK",
];
export const PRY = [
  "Pretoria",
  "Wonderboom National Airport",
  "South Africa",
  "PRY",
];
export const PVK = ["Preveza/Lefkas", "Aktion Airport", "Greece", "PVK"];
export const YXS = [
  "Prince George",
  "Prince George Airport",
  "BC, Canada",
  "YXS",
];
export const YPR = [
  "Prince Rupert/Digby Island",
  "Prince Rupert Airport",
  "BC,Canada",
  "YPR",
];
export const PRN = [
  "Pristina",
  "Pristina Intl Airport Adem Jashari",
  "Serbia",
  "PRN",
];
export const PVD = ["Providence (RI)", "T. F. Green Airport", "USA", "PVD"];
export const PLS = [
  "Providenciales",
  "Providenciales International Airport",
  "Turks and Caicos Islands",
  "PLS",
];
export const SCC = ["Prudhoe Bay (AK)", "Deadhorse Airport", "USA", "SCC"];
export const PCL = [
  "Pucallpa",
  "Captain Rolden International Airport",
  "Peru",
  "PCL",
];
export const PBC = [
  "Puebla",
  "Hermanos Serdán International Airport",
  "Mexico",
  "PBC",
];
export const PUB = ["Pueblo (CO)", " Pueblo Memorial Airport", "USA", "PUB"];
export const PXM = [
  "Puerto Escondido",
  "Puerto Escondido International Airport",
  "Oaxaca, Mexico",
  "PXM",
];
export const PEM = [
  "Puerto Maldonado",
  "Puerto Maldonado International Airport",
  "Peru",
  "PEM",
];
export const PZO = [
  "Puerto Ordaz",
  "Manuel Carlos Piar Guayana Airport",
  "Venezuela",
  "PZO",
];
export const POP = [
  "Puerto Plata",
  "Gregorio Luperón International Airport",
  "Dominican Republic",
  "POP",
];
export const PPS = [
  " Puerto Princesa",
  " Puerto Princesa Airport",
  "Philippines",
  "PPS",
];
export const PSZ = ["Puerto Suarez", "Puerto Suárez Airport", "Bolivia", "PSZ"];
export const PVR = [
  "Puerto Vallarta",
  "Lic. Gustavo Díaz Ordaz International",
  "Mexico",
  "PVR",
];
export const XPK = ["Pukatawagan", "Pukatawagan Airport", "Canada", "XPK"];
export const PUY = ["Pula", "Pula Airport", "Croatia (Hrvatska)", "PUY"];
export const PUW = [
  "Pullman (WA)",
  "Pullman-Moscow Regional Airport",
  "USA",
  "PUW",
];
export const PNQ = [
  "Pune (Poona)",
  "Pune International Airport",
  "Maharashtra, India",
  "PNQ",
];
export const PUQ = [
  "Punta Arenas",
  "Carlos Ibanez Del Campo International",
  "Chile",
  "PUQ",
];
export const PUJ = [
  "Punta Cana",
  " Punta Cana International Airport",
  "Dominican Republic",
  "PUJ",
];
export const PDP = [
  "Punta del Este",
  "Punta del Este International Airport",
  "Uruguay",
  "PDP",
];
export const PUS = [
  "Pusan (Busan)",
  "Gimhae International Airport",
  "South Korea",
  "PUS",
];
export const FNJ = [
  "Pyongyang",
  "Sunan International Airport",
  "North Korea",
  "FNJ",
];
export const QUO = [
  "Uyo",
  "Victor Attah international Airport",
  "Nigeria",
  "QUO",
];

export const JQA = ["Qaarsut", "Qaarsut Airport", "Greenland", "JQA"];
export const LQN = [
  "Qala i Naw (city)",
  "Qala i Naw Airport",
  "Afghanistan",
  "LQN",
];
export const GSR = ["Qardho (Gardo)", "Qardho Airport", "Somalia", "GSR"];
export const GZW = ["Qazvin", "Qazvin Airport", "Iran", "GZW"];
export const JIQ = [
  " Qianjiang",
  " Qianjiang Airport",
  "Chongqing, China",
  "JIQ",
];
export const IQM = ["Qiemo", "Qiemo Airport", "Xinjiang, China", "IQM"];
export const YVM = [
  "Qikiqtarjuaq",
  "Qikiqtarjuaq Airport",
  "Nunavut, Canada",
  "YVM",
];
export const TAO = [
  "Qingdao",
  "Qingdao Liuting International Airport",
  "Shandong, China",
  "TAO",
];
export const IQN = ["Qingyang", "Qingyang Airport", "Gansu, China", "IQN"];
export const BPE = [
  "Qinhuangdao",
  "Qinhuangdao Beidaihe Airport",
  "Hebei, China",
  "BPE",
];
export const SHP = [
  " Qinhuangdao",
  " Qinhuangdao Shanhaiguan Airport",
  "Hebei, China",
  "SHP",
];
export const IH = ["Qishn", "Qishn Airport", " Yemen", " IHN"];
export const UKT = ["Quakertown (PA)", "Quakertown Airport", "USA", "UKT"];
export const XQU = [
  "Qualicum Beach",
  "Qualicum Beach Airport",
  "BC, Canada",
  "XQU",
];
export const JJN = [
  "Quanzhou",
  "Quanzhou Jinjiang Airport  (cn)",
  "Fujian, China",
  "JJN",
];
export const YQC = ["Quaqtaq", "Quaqtaq Airport", "Nunavik, Canada", "YQC"];
export const YQB = [
  "Quebec City",
  "Jean Lesage International Airport",
  "Canada",
  "YQB",
];
export const UEE = [
  "Queenstown",
  "Queenstown Airport",
  "Tasmania, Australia",
  "UEE",
];
export const ZQN = ["Queenstown", "Queenstown Airport", "New Zealand", "ZQN"];
export const UEL = ["Quelimane", "Quelimane Airport", "Mozambique", "UEL"];
export const XQP = ["Quepos", "Quepos La Managua Airport", "Costa Rica", "XQP"];
export const QRO = [
  " Queretaro",
  " Querétaro International Airport  (es)",
  "Mexico",
  "QRO",
];
export const YQZ = ["Quesnel", "Quesnel Airport", "BC, Canada", "YQZ"];
export const UET = [
  "Quetta",
  "Quetta International Airport",
  "Balochistan, Pakistan",
  "UET",
];
export const AAZ = [
  " Quetzaltenango (Xelajú)",
  " Quetzaltenango Airport",
  "Guatemala",
  "AAZ",
];
export const UIB = ["Quibdo", "El Caraño Airport", "Colombia", "UIB"];
export const AQB = [" Quiche", " Quiché Airport", "Guatemala", "AQB"];
export const UIP = [
  "Quimper",
  "Quimper - Cornouaille Airport",
  "France",
  "UIP",
];
export const UIN = ["Quincy (IL)", "Quincy Regional Airport", "USA", "UIN"];
export const KWN = ["Quinhagak (AK)", "Quinhagak Airport", "USA", "KWN"];
export const UIH = ["Qui Nhon", "Phu Cat Airport", "Vietnam", "UIH"];
export const UIO = [
  "Quito",
  "Quito International Airport  (es)",
  "Ecuador",
  "UIO",
];
export const UIQ = [
  "Quoin Hill",
  "Quoin Hill Airfield (abandoned)",
  "Vanuatu",
  "UIQ",
];
export const JUZ = ["Quzhou", "Quzhou Airport", "Zhejiang, China", "JUZ"];
export const RBA = ["Rabat", "Rabat-Salé Airport", "Morocco", "RBA"];
export const RYK = [
  "Rahim Yar Khan",
  "Shaikh Zayed International Airport",
  "Punjab, Pakistan",
  "RYK",
];
export const RFP = [
  "Raiatea (island)",
  "Raiatea Airport",
  "French Polynesia",
  "RFP",
];
export const YOP = [
  "Rainbow Lake",
  "Rainbow Lake Airport",
  "Alberta, Canada",
  "YOP",
];
export const RAJ = ["Rajkot", "Rajkot Airport", "India", "RAJ"];
export const RDU = [
  "Raleigh/Durham (NC)",
  " Raleigh-Durham International Airport",
  "USA",
  "RDU",
];
export const IXR = ["Ranchi", "Birsa Munda Airport", "Jharkhand, India", "IXR"];
export const RGI = [
  "Rangiroa (island)",
  "Rangiroa Airport",
  "French Polynesia",
  "RGI",
];
export const RAP = [
  "Rapid City (SD)",
  "Rapid City Regional Airport",
  "USA",
  "RAP",
];
export const RAR = [
  "Rarotonga (island)",
  "Rarotonga International Airport",
  "Cook Island",
  "RAR",
];
export const RKT = [
  "Ras al Khaymah (Ras al Khaimah)",
  "Ras Al Khaimah International Airport",
  "United Arab Emirates",
  "RKT",
];
export const RAZ = ["Rawala Kot", "Rawalakot Airport", "Pakistan", "RAZ"];
export const ISB = [
  "Rawalpindi",
  "Benazir Bhutto International Airport",
  "Pakistan",
  "ISB",
];
export const RDG = ["Reading (PA)", "Reading Regional Airport", "USA", "RDG"];
export const REC = [
  "Recife",
  "Recife/Guararapes–Gilberto Freyre International Airport",
  "Brazil",
  "REC",
];
export const RDD = ["Redding (CA)", "Redding Municipal Airport", "USA", "RDD"];
export const RDM = ["Redmond (OR)", "Roberts Field", "USA", "RDM"];
export const REG = [
  "Reggio Calabria",
  "Reggio Calabria Airport",
  "Italy",
  "REG",
];
export const YQR = [
  "Regina",
  "Regina International Airport",
  "Saskatchewan, Canada",
  "YQR",
];
export const RNS = [
  "Rennes",
  "Rennes–Saint-Jacques Airport",
  "Brittany, France",
  "RNS",
];
export const RNO = [
  "Reno (NV)",
  "Reno-Tahoe International Airport",
  "USA",
  "RNO",
];
export const RES = [
  "Resistencia",
  "Resistencia International Airport",
  "Chaco, Argentina",
  "RES",
];
export const YRB = [
  "Resolute Bay",
  " Resolute Bay Airport",
  "Nunavut, Canada",
  "YRB",
];
export const REU = ["Reus", "Reus Airport", "Spain", "REU"];
export const REK = ["Reykjavik", "Metropolitan Area", "Iceland", "REK"];
export const RKV = ["Reykjavik", "Reykjavik Airport", "Iceland", "RKV"];
export const KEF = [
  "Reykjavik",
  "Keflavík International Airport",
  "Iceland",
  "KEF",
];
export const REX = [
  " Reynosa",
  "Reynosa International Airport",
  "Mexico",
  "REX",
];
export const RHI = [
  "Rhinelander (WI)",
  "Rhinelander-Oneida County Airport",
  "USA",
  "RHI",
];
export const RHO = [
  "Rhodes (island)",
  "Rhodes International Airport",
  "Greece",
  "RHO",
];
export const RCB = [
  "Richards Bay",
  "Richards Bay Airport",
  "KZN, South Africa",
  "RCB",
];
export const RIC = [
  "Richmond (VA)",
  "Richmond International Airport",
  "USA",
  "RIC",
];
export const RIX = ["Riga", "Riga International Airport", "Latvia", "RIX"];
export const RJK = ["Rijeka", "Rijeka Airport", "Croatia (Hrvatska)", "RJK"];
export const RMI = [
  "Rimini",
  " Federico Fellini International Airport",
  "Italy",
  "RMI",
];
export const RBR = [
  "Rio Branco",
  "Plácido de Castro International Airport",
  "Brazil",
  "RBR",
];
export const RIO = ["Rio de Janeiro", "Metropolitan Area", "Brazil", "RIO"];
export const GIG = [
  "Rio de Janeiro",
  "Rio de Janeiro–Galeão International",
  "Brazil",
  "GIG",
];
export const SDU = ["Rio de Janeiro", "Santos Dumont Airport", "Brazil", "SDU"];
export const RGL = [
  "Río Gallegos",
  "Piloto N. Fernandez Airport",
  "Argentina",
  "RGL",
];
export const RGA = [
  "Río Grande",
  "Río Grande International Airport",
  "Argentina",
  "RGA",
];
export const RIG = [
  "Rio Grande",
  "Rio Grande Airport",
  "Rio Grande do Sul, Brazil",
  "RIG –",
];
export const RVY = ["Rivera", "Rivera International Airport", "Uruguay", "RVY"];
export const RUH = [
  "Riyadh",
  "King Khalid International Airport",
  "Saudi Arabia",
  "RUH",
];
export const RNE = ["Roanne", "Roanne - Renaison Airport", "France", "RNE"];
export const ROA = ["Roanoke (VA)", " Roanoke Regional Airport", "USA", "ROA"];
export const RZZ = [
  " Roanoke Rapids (NC)",
  "Halifax County Airport",
  "USA",
  "RZZ",
];
export const RTB = [
  "Roatan",
  "Juan Manuel Gálvez International Airport",
  "Honduras",
  "RTB",
];
export const RST = [
  "Rochester (MN)",
  "Rochester International Airport",
  "USA",
  "RST",
];
export const ROC = [
  "Rochester (NY)",
  "Greater Rochester International Airport",
  "USA",
  "ROC",
];
export const RSD = [
  "Rock Sound",
  "Rock Sound International Airport",
  "Eleuthera, Bahamas",
  "RSD",
];
export const RKS = [
  "Rock Springs (WY)",
  "Rock Springs – Sweetwater County Airport",
  "USA",
  "RKS",
];
export const RFD = [
  "Rockford (IL)",
  "Chicago Rockford International Airport",
  "USA",
  "RFD",
];
export const ROK = ["Rockhampton", "Rockhampton Airport", "Australia", "ROK"];
export const RKD = [
  "Rockland (ME)",
  " Knox County Regional Airport",
  "USA",
  "RKD",
];
export const RWI = [
  "Rocky Mount - Wilson (NC)",
  "Rocky Mount-Wilson Regional Airport",
  "USA",
  "RWI",
];
export const RDZ = ["Rodez", " Rodez-Marcillac Airport", "France", "RDZ"];
export const RRG = [
  "Rodrigues Island",
  "Sir Gaëtan Duval Airport",
  "Mauritius",
  "RRG",
];
export const RNN = [
  "Roenne (Rønne) Bornholm is.",
  "Bornholm Airport",
  "Denmark",
  "RNN",
];
export const ROM = ["Rome", "Metropolitan Area", "Italy", "ROM"];
export const CIA = ["Rome", "Ciampino Airport", "Italy", "CIA"];
export const FCO = [
  "Rome",
  "Leonardo da Vinci-Fiumicino Airport",
  "Italy",
  "FCO",
];
export const RME = [
  "Rome (NY) Oneida County",
  "Griffiss International Airport",
  "USA",
  "RME",
];
export const RNB = ["Ronneby", "Ronneby Airport", "Sweden", "RNB"];
export const ROS = [
  "Rosario",
  "Rosario - Islas Malvinas International",
  "Argentina",
  "ROS",
];
export const DCF = [
  "Roseau",
  "Roseau Airport (Canefield Airport)",
  "Dominica",
  "DCF",
];
export const DOM = ["Roseau", "Melville Hall Airport", "Dominica", "DOM"];
export const RPN = ["Rosh Pina", "Rosh Pina Airport", "Israel", "RPN"];
export const ROV = [
  "Rostov-on-Don",
  "Platov International Airport",
  "Russia",
  "ROV",
];
export const RVI = ["Rostov-on-Don", "Rostov-on-Don Airport", "Russia", "RVI"];
export const ROW = [
  "Roswell (NM)",
  "Roswell International Air Center",
  "USA",
  "ROW",
];
export const ROT = ["Rotorua", "Rotorua Airport", "New Zealand", "ROT"];
export const RTM = [
  "Rotterdam",
  "Rotterdam The Hague Airport",
  "Netherlands",
  "RTM",
];
export const RVN = ["Rovaniemi", "Rovaniemi Airport", "Finland", "RVN"];
export const NDU = ["Rundu", "Rundu Airport", "Namibia", "NDU"];
export const ROU = ["Ruse", "Ruse Airport (no service)", "Bulgaria", "ROU"];
export const RZN = [" Ryazan", "Turlatovo Airport (airstrip)", "Russia", "RZN"];
export const SCN = ["Saarbruecken", "Airport Saarbrücken", "Germany", "SCN"];
export const SMF = [
  "Sacramento (CA)",
  "Sacramento International Airport",
  "USA",
  "SMF",
];
export const SDS = [
  "Sado Shima (island)",
  "Sado Airport",
  "Niigata, Japan",
  "SDS",
];
export const MBS = [
  "Saginaw/Bay City/Midland (MI)",
  "MBS International Airport",
  "USA",
  "MBS",
];
export const SDT = [
  "Saidu Sharif",
  "Saidu Sharif Airport (no service)",
  "Pakistan",
  "SDT",
];
export const SGN = [
  "Saigon (Ho Chi Minh City)",
  "Tan Son Nhat International Airport",
  "Vietnam",
  "SGN",
];
export const SBK = [
  "Saint Brieuc",
  "Saint-Brieuc - Armor Airport",
  "France",
  "SBK",
];
export const SKV = [
  "Saint Catherine",
  "St Catherine Airport",
  "Sinai, Egypt",
  "SKV",
];
export const RUN = [
  "Saint Denis (administrative capital)",
  " Roland Garros Airport",
  "Reunion",
  "RUN",
];
export const YSJ = ["Saint John", "Saint John Airport", "NB, Canada", "YSJ"];
export const STL = [
  "Saint Louis (MO)",
  "Lambert-St. Louis International Airport",
  "USA",
  "STL",
];
export const SUS = [
  "Saint Louis (MO)",
  "Spirit of Saint Louis Airport",
  "USA",
  "SUS",
];
export const MSP = [
  "Saint Paul (MN)",
  "Minneapolis–Saint Paul International",
  "USA",
  "MSP",
];
export const SPN = [
  "Saipan",
  "Saipan International Airport",
  "Northern Mariana Islands",
  "SPN",
];
export const SID = [
  "Sal",
  " Amilcar Cabral International Airport",
  "Cape Verde",
  "SID",
];
export const SLL = ["Salalah", "Salalah Airport", "Oman", "SLL"];
export const SLE = [
  "Salem (OR)",
  "Salem Municipal Airport (McNary Field)",
  "USA",
  "SLE",
];
export const SNS = ["Salinas (CA)", "Salinas Municipal Airport", "USA", "SNS"];
export const SN = ["Salinas", "Ulpiano Paez Airport", "Ecuador", " SNC"];
export const SBY = [
  "Salisbury (MD)",
  "Salisbury-Ocean City Wicomico Airport",
  "USA",
  "SBY",
];
export const SLC = [
  "Salt Lake City (UT)",
  "Salt Lake City International Airport",
  "USA",
  "SLC",
];
export const STY = ["Salto", "Nueva Hespérides Airport", "Uruguay", "STY"];
export const SSA = [
  "Salvador",
  "Salvador International Airport",
  "Brazil",
  "SSA",
];
export const SZG = [
  "Salzburg",
  "Salzburg Airport W. A. Mozart",
  "Austria",
  "SZG",
];
export const AZS = [
  "Samana",
  "Samaná El Catey International Airport",
  "Dominican Republic",
  "AZS",
];
export const KUF = [
  "Samara",
  "Kurumoch International Airport",
  "Russia",
  "KUF",
];
export const SKD = [
  "Samarkand",
  "Samarkand International Airport",
  "Uzbekistan",
  "SKD",
];
export const SMI = [
  "Samos (island)",
  "Samos International Airport Aristarchos",
  "Greece",
  "SMI",
];
export const SZF = ["Samsun", "Carsamba Airport", "Turkey", "SZF"];
export const ADZ = [
  "San Andres (island)",
  "Gustavo Rojas Pinilla International Airport",
  "Colombia",
  "ADZ",
];
export const SJT = [
  "San Angelo (TX)",
  " San Angelo Regional Airport",
  "USA",
  "SJT",
];
export const SAT = [
  "San Antonio (TX)",
  "San Antonio International Airport",
  "USA",
  "SAT",
];
export const BRC = [
  "San Carlos de Bariloche",
  "San Carlos de Bariloche Airport",
  "Río Negro, Argentina",
  "BRC",
];
export const SCY = [
  "San Cristóbal Island",
  "San Cristóbal Airport",
  "Galápagos, Ecuador",
  "SCY",
];
export const SBT = [
  " San Bernardino (CA)",
  " San Bernardino International Airport",
  "USA",
  "SBT",
];
export const SDK = [" Sandakan", " Sandakan Airport", "Malaysia", "SDK"];
export const SDN = ["Sandane", "Sandane Airport", "Norway", "SDN"];
export const SAN = [
  "San Diego (CA)",
  "San Diego International Airport (Lindberg Field)",
  "USA",
  "SAN",
];
export const SFH = [
  " San Felipe",
  "San Felipe International Airport, Baja California",
  "Mexico",
  "SFH",
];
export const SFB = [
  " Sanford (FL)",
  "Orlando Sanford International Airport",
  "USA",
  "SFB",
];
export const SFO = [
  "San Francisco (CA)",
  "San Francisco International Airport",
  "USA",
  "SFO",
];
export const SJD = [
  "San Jose Cabo",
  " Los Cabos International Airport",
  "Mexico",
  "SJD",
];
export const SJO = [
  "San José",
  "Juan Santamaría International",
  "Costa Rica",
  "SJO",
];
export const SYQ = ["San José", "Tobías Bolaños Airport", "Costa Rica", "SYQ"];
export const SJC = [
  "San Jose (CA)",
  "Mineta San José International Airport",
  "USA",
  "SJC",
];
export const SJI = [
  "San Jose",
  "San Jose Airport",
  "Mindoro, Philippines",
  "SJI",
];
export const SJE = [
  "San José del Guaviare",
  "Jorge Enrique González Torres Airport",
  "Guaviare, Colombia",
  "SJE",
];
export const SJU = [
  "San Juan",
  "Luis Munoz Marin International Airport",
  "Puerto Rico",
  "SJU",
];
export const SIG = [
  "San Juan, Miramar",
  "Fernando Luis Ribas Dominicci Airport",
  "Puerto Rico",
  "SIG",
];
export const SBP = [
  "San Luis Obisco (CA)",
  "San Luis Obispo County Regional Airport",
  "USA",
  "SBP",
];
export const SLP = [
  "San Luis Potosi",
  "Ponciano Arriaga International Airport",
  "Mexico",
  "SLP",
];
export const SPR = ["San Pedro", "San Pedro Airport", "Belize", "SPR"];
export const SPY = ["San Pedro", "San Pédro Airport", "Cote d'Ivoire", "SPY"];
export const SAP = [
  "San Pedro Sula",
  "La Mesa International Airport",
  "Honduras",
  "SAP",
];
export const ZSA = ["San Salvador", "San Salvador Airport", "Bahamas", "ZSA"];
export const SAL = [
  "San Salvador",
  "El Salvador International Airport",
  "El Salvador",
  "SAL",
];
export const EAS = ["San Sebastian", "San Sebastián Airport", "Spain", "EAS"];
export const SAH = ["Sanaa", "Sana'a International Airport", "Yemen", "SAH"];
export const YZP = ["Sandspit", "Sandspit Airport", "BC, Canada", "YZP"];
export const SNA = ["Santa Ana (CA)", "John Wayne Airport", " USA", "SNA"];
export const SBA = [
  "Santa Barbara (CA)",
  "Santa Barbara Municipal Airport",
  "USA",
  "SBA",
];
export const SZN = [
  "Santa Barbara (CA)",
  "Santa Cruz Island Airport",
  "USA",
  "SZN",
];
export const SNU = [" Santa Clara", "Abel Santamaría Airport", "Cuba", "SNU"];
export const SPC = [
  "Santa Cruz de la Palma",
  "La Palma Airport",
  "Canary Islands, Spain",
  "SPC",
];
export const SRZ = [
  "Santa Cruz de la Sierra",
  " El Trompillo Airport",
  "Bolivia",
  "SRZ",
];
export const SAF = [
  "Santa Fe (NM)",
  "Santa Fe Municipal Airport",
  "USA",
  "SAF",
];
export const SMA = [
  "Santa Maria (island)",
  "Santa Maria Airport",
  "Azores, Portugal",
  "SMA",
];
export const SMX = [
  "Santa Maria (CA)",
  "Santa Maria Public Airport",
  "USA",
  "SMX",
];
export const SMR = ["Santa Marta", "Simón Bolívar Airport", "Colombia", "SMR"];
export const SDR = ["Santander", "Santander Airport", "Spain", "SDR"];
export const STM = [
  "Santarem",
  "Santarém-Maestro Wilson Fonseca Airport",
  "Para, Brazil",
  "STM",
];
export const STS = [
  "Santa Rosa (CA)",
  "Charles M. Schulz–Sonoma County Airport",
  "USA",
  "STS",
];
export const RSA = ["Santa Rosa", "Santa Rosa Airport", "Argentina", "RSA"];
export const SRB = ["Santa Rosa", "Santa Rosa Airport", "Bolivia", "SRB"];
export const SRA = ["Santa Rosa", "Luís Alberto Lehr Airport", "Brazil", "SRA"];
export const SDH = [
  "Santa Rosa de Copan",
  "Santa Rosa de Copan (no airport)",
  "Honduras",
  "SDH",
];
export const SSL = [
  "Santa Rosalia",
  "Santa Rosalia (no airport)",
  "Colombia",
  "SSL",
];
export const SRL = [
  "Santa Rosalia",
  "Santa Rosalia (no airport)",
  "Mexico",
  "SRL",
];
export const SCU = [
  "Santiago",
  "Antonio Maceo International Airport",
  "Cuba",
  "SCU",
];
export const SCL = [
  "Santiago de Chile",
  "Santiago International Airport",
  "Chile",
  "SCL",
];
export const SCQ = [
  "Santiago de Compostela",
  "Santiago de Compostela Airport",
  "Spain",
  "SCQ",
];
export const SDE = [
  "Santiago del Estero",
  "Ángel de la Paz Aragonés Airport",
  "Argentina",
  "SDE",
];
export const STI = [
  "Santiago de los Caballeros",
  "Cibao International Airport  (es)",
  "Dominican Republic",
  "STI",
];
export const RAI = [
  "Santiago Island",
  "Nelson Mandela International Airport",
  "Cape Verde",
  "RAI",
];
export const SON = [
  "Santo",
  "Santo-Pekoa International Airport",
  "Vanuatu",
  "SON",
];
export const SDQ = [
  "Santo Domingo",
  "Las Américas International Airport",
  "Dominican Republic",
  "SDQ",
];
export const SYX = [
  "Sanya",
  "Sanya Phoenix International Airport",
  "Hainan, China",
  "SYX",
];
export const SLZ = [
  "Sao Luis",
  "Cunha Machado International Airport",
  "Brazil",
  "SLZ",
];
export const SAO = ["Sao Paulo", "Metropolitan Area", "Brazil", "SAO"];
export const CGH = [
  "Sao Paulo",
  "São Paulo–Congonhas Airport",
  "Brazil",
  "CGH",
];
export const GRU = [
  "Sao Paulo",
  " Guarulhos International Airport",
  "Brazil",
  "GRU",
];
export const VCP = [
  "Sao Paulo",
  "Sao Paulo Viracopos International Airport",
  "Brazil",
  "VCP",
];
export const TMS = [
  "Sao Tome",
  "São Tomé International Airport",
  "Sao Tome & Principe",
  "TMS",
];
export const SPK = ["Sapporo", "Metropolitan Area", "Hokkaido, Japan", "SPK"];
export const OKD = ["Sapporo", "Okadama Airport", "Hokkaido, Japan", "OKD"];
export const SJJ = [
  "Sarajevo",
  "Sarajevo International Airport",
  "Bosnia and Herzegovina",
  "SJJ",
];
export const SKX = ["Saransk", "Saransk Airport", "Russia", "SKX"];
export const SRQ = [
  "Sarasota/Bradenton (FL)",
  "Sarasota-Bradenton International Airport",
  "USA",
  "SRQ",
];
export const YXE = [
  "Saskatoon",
  "Saskatoon International Airport",
  "Saskatchewan, Canada",
  "YXE",
];
export const ZSS = ["Sassandra", "Sassandra Airport", "Cote d'Ivoire", "ZSS"];
export const SUJ = [
  "Satu Mare",
  "Satu Mare International Airport",
  "Romania",
  "SUJ",
];
export const SAV = [
  "Savannah (GA)",
  "Savannah/Hilton Head International",
  "USA",
  "SAV",
];
export const SVL = ["Savonlinna", "Savonlinna Airport", "Finland", "SVL"];
export const SAU = [" Savu Island", "Tardamu Airport", "Indonesia", "SAU"];
export const NSO = ["Scone", "Scone Airport", "NSW, Australia", "NSO"];
export const SCF = ["Scottsdale (AZ)", " Scottsdale Airport", "USA", "SCF"];
export const SEA = [
  "Seattle (WA)",
  "Tacoma International Airport",
  "USA",
  "SEA",
];
export const LKE = [
  "Seattle (WA)",
  "Kenmore Air Harbor Seaplane Base",
  "USA",
  "LKE",
];
export const BFI = [
  "Seattle (WA)",
  "King County International (Boeing Field)",
  "USA",
  "BFI",
];
export const SDX = ["Sedona (AZ)", "Sedona Airport", "USA", "SDX"];
export const SEB = ["Sehba", "Sabha Airport", "Libya", "SEB"];
export const SJY = ["Seinaejoki", " Seinäjoki Airport", "Finland", "SJY"];
export const PKW = [
  "Selebi-Phikwe",
  "Selebi-Phikwe Airport",
  "Botswana",
  "PKW",
];
export const SRG = [
  "Semarang",
  "Achmad Yani International Airport",
  "Java, Indonesia",
  "SRG",
];
export const SDJ = ["Sendai", "Sendai Airport  (jp)", "Miyagi, Japan", "SDJ"];
export const ICN = [
  "Seoul",
  "Incheon International Airport",
  "South Korea",
  "ICN",
];
export const SEL = [
  "Seoul",
  "Gimpo International Airport",
  "South Korea",
  "SEL",
];
export const SVQ = [
  "Seville (Sevilla)",
  "San Pablo Airport",
  "Andalusia, Spain",
  "SVQ",
];
export const SFA = [
  "Sfax",
  "Sfax–Thyna International Airport",
  "Tunisia",
  "SFA",
];
export const ZTM = [
  "Shamattawa",
  "Shamattawa Airport",
  "Manitoba, Canada",
  "ZTM",
];
export const SHA = [
  "Shanghai",
  "Hongqiao International Airport",
  "China",
  "SHA",
];
export const PVG = ["Shanghai", "Pudong International Airport", "China", "PVG"];
export const SNN = ["Shannon (Limerick)", "Shannon Airport", "Ireland", "SNN"];
export const SHJ = ["Sharjah", "Sharjah International Airport", "UAE", "SHJ"];
export const SSH = [
  "Sharm El Sheikh",
  "Sharm el-Sheikh International Airport",
  "Egypt",
  "SSH",
];
export const SZD = [
  "Sheffield",
  "City Airport (closed in April 2008)",
  "United Kingdom",
  "SZD",
];
export const DSA = [
  "Sheffield/Doncaster",
  "Robin Hood International Airport",
  "United Kingdom",
  "DSA",
];
export const SHD = [
  "Shenandoah Valley/ Staunton (VA)",
  "Shenandoah Valley Regional Airport",
  "USA",
  "SHD",
];
export const SHE = [
  "Shenyang",
  "Shenyang Taoxian International Airport",
  "Liaoning, China",
  "SHE",
];
export const SZX = [
  "Shenzhen",
  "Shenzhen Bao'an International Airport",
  "Guangdong, China",
  "SZX",
];
export const SHR = ["Sheridan (WY)", "Sheridan County Airport", "USA", "SHR"];
export const SJW = [
  " Shijiazhuang",
  "Shijiazhuang Zhengding International  (cn)",
  "Hebei, China",
  "SJW",
];
export const SYZ = [
  "Shiraz",
  "Shiraz International Airport  (fas)",
  "Iran",
  "SYZ",
];
export const SHV = [
  "Shreveport (La)",
  " Shreveport Regional Airport",
  "USA",
  "SHV",
];
export const SKT = [
  "Sialkot",
  "Sialkot International Airport",
  "Punjab, Pakistan",
  "SKT",
];
export const SBZ = ["Sibiu", "Sibiu International Airport", "Romania", "SBZ"];
export const SBW = ["Sibu", "Sibu Airport", "Sarawak, Malaysia", "SBW"];
export const SDY = [
  "Sidney (MT)",
  " Sidney-Richland Municipal Airport",
  "USA",
  "SDY",
];
export const REP = [
  "Siem Reap (Angkor Wat)",
  "Siem Reap-Angkor International Airport",
  "Cambodia",
  "REP",
];
export const SAY = ["Siena", "Siena-Ampugnano Airport", "Italy", "SAY"];
export const FHU = [
  "Sierra Vista/Fort Huachuca",
  "Sierra Vista Municipal Airport",
  "AZ, USA",
  "FHU",
];
export const KOS = [
  "Sihanoukville",
  "Sihanoukville International Airport",
  "Cambodia",
  "KOS",
];
export const SIP = [
  "Simferopol",
  "Simferopol International Airport",
  "Crimea, Ukraine",
  "SIP",
];
export const MPD = ["Sindhri", "Sindhri Airport", "Pakistan", "MPD"];
export const SIN = [
  "Singapore",
  "Changi Airport Singapore",
  "Singapore",
  "SIN",
];
export const QPG = ["Singapore", "Paya Lebar AIr Base", "Singapore", "QPG"];
export const XSP = ["Singapore", "Seletar Airport", "Singapore", "XSP"];
export const SIX = ["Singleton", "Singleton Airport", "Australia", "SIX"];
export const SUX = ["Sioux City (IA)", "Sioux Gateway Airport", "USA", "SUX"];
export const FSD = [
  "Sioux Falls (SD)",
  "Sioux Falls Regional Airport",
  "USA",
  "FSD",
];
export const SIS = ["Sishen", "Sishen Airport", "South Africa", "SIS"];
export const SIT = [
  "Sitka (AK)",
  " Sitka Rocky Gutierrez Airport",
  "USA",
  "SIT",
];
export const VAS = ["Sivas", "Sivas Nuri Demirağ Airport", "Turkey", "VAS"];
export const SEW = ["Siwa Oasis", "Siwa Oasis North Airport", "Egypt", "SEW"];
export const SGY = ["Skagway (AK)", "Skagway Airport", "USA", "SGY"];
export const KDU = [
  "Skardu",
  "Skardu Airport",
  "Gilgit–Baltistan, Pakistan",
  "KDU",
];
export const JSI = [
  "Skiathos (island)",
  "Skiathos Island National Airport",
  "Greece",
  "JSI",
];
export const SKP = [
  "Skopje",
  " Alexander the Great Airport",
  "Macedonia",
  "SKP",
];
export const SKS = ["Skrydstrup", "Skrydstrup Airport", "Denmark", "SKS"];
export const SZK = [
  "Skukuza (Kruger Park)",
  "Skukuza Aerodrome",
  "Mpumalanga, South Africa",
  "SZK",
];
export const SXL = ["Sligo", "Sligo Airport", "Ireland", "SXL"];
export const YYD = ["Smithers", "Smithers Airport", "BC, Canada", "YYD"];
export const SOT = ["Sodankylae", "Sodankylä Airfield", "Finland", "SOT"];
export const SGD = ["Soenderborg", " Sønderborg Airport", "Denmark", "SGD"];
export const SFJ = [
  "Soendre Stroemfjord",
  "Kangerlussuaq Airport",
  "Greenland",
  "SFJ",
];
export const SOF = ["Sofia", "Sofia Airport", "Bulgaria", "SOF"];
export const SOG = ["Sogndal", "Sogndal Airport, Haukåsen", "Norway", "SOG"];
export const SOU = [
  "Southampton",
  "Southampton Airport  (Eastleigh)",
  "United Kingdoma",
  "SOU",
];
export const SBN = [
  "South Bend (IN)",
  " South Bend Regional Airport",
  "USA",
  "SBN",
];
export const XSI = [
  "South Indian Lake",
  "South Indian Lake Airport",
  "Manitoba, Canada",
  "XSI",
];
export const SPU = ["Split", "Split Airport", "Croatia (Hrvatska)", "SPU"];
export const GEG = [
  "Spokane (WA)",
  "Spokane International Airport",
  "USA",
  "GEG",
];
export const SBU = ["Springbok", "Springbok Airport", "South Africa", "SBU"];
export const SPI = [
  "Springfield (IL)",
  "Abraham Lincoln Capital Airport",
  "USA",
  "SPI",
];
export const SGF = [
  "Springfield (MO)",
  " Springfield-Branson National Airport",
  "USA",
  "SGF",
];
export const SXR = [
  "Srinagar",
  "Srinagar International Airport",
  "JK, India",
  "SXR",
];
export const YIF = [
  "St. Augustin",
  "Saint-Augustin Airport",
  "Quebec, Canada",
  "YIF",
];
export const STX = [
  "St. Croix",
  "Henry E. Rohlsen Airport",
  "U.S. Virgin Islands",
  "STX",
];
export const EBU = [
  "St. Etienne",
  "Saint-Étienne–Bouthéon Airport",
  "France",
  "EBU",
];
export const SGU = [
  "St. George (UT)",
  "St. George Municipal Airport",
  "USA",
  "SGU",
];
export const YYT = [
  "St. John's",
  "St. John's International Airport",
  "NL, Canada",
  "YYT",
];
export const ANU = [
  "St. John's",
  "V.C. Bird International Airport",
  "Antigua and Barbuda",
  "ANU",
];
export const UVF = [
  "St. Lucia",
  "Hewanorra International Airport",
  "St. Lucia",
  "UVF",
];
export const SXM = [
  "St. Marteen (island)",
  "Princess Juliana International Airport",
  "Netherlands Antilles",
  "SXM",
];
export const SFG = [
  "St. Martin (island)",
  "Saint Martin Airport Grand-Case",
  "Saint Martin, France",
  "SFG",
];
export const LED = [
  "St. Petersburg",
  "Pulkovo International Airport",
  "Russia",
  "LED",
];
export const RVH = [
  "St. Petersburg",
  "Rzhevka Airport (closed)",
  "Russia",
  "RVH",
];
export const FSP = [
  "St. Pierre (St Pierre and Miquelon)",
  "Saint Pierre Airport",
  "France/Canada",
  "FSP",
];
export const STT = [
  "St. Thomas, Charlotte Amalie",
  "Cyril E. King Airport",
  "Virgin Islands",
  "STT",
];
export const SCE = [
  "State College/Belefonte (PA)",
  "University Park Airport",
  "USA",
  "SCE",
];
export const SVG = ["Stavanger", "Stavanger Airport", "Norway", "SVG"];
export const HDN = [
  "Steamboat Springs (CO)",
  "Yampa Valley Airport",
  "USA",
  "HDN",
];
export const SZZ = [
  "Szczecin (German: Stettin)",
  "Szczecin–Goleniów Airport",
  "Poland",
  "SZZ",
];
export const STO = ["Stockholm", "Metropolitan Area", "Sweden", "STO"];
export const ARN = ["Stockholm", " Stockholm Arlanda Airport", "Sweden", "ARN"];
export const BMA = ["Stockholm", "Stockholm Bromma Airport", "Sweden", "BMA"];
export const SCK = [
  "Stockton (CA)",
  " Stockton Metropolitan Airport",
  "USA",
  "SCK",
];
export const SYY = ["Stornoway", "Stornoway Airport", "United Kingdoma", "SYY"];
export const SXB = [
  "Strasbourg",
  "Strasbourg Entzheim International Airport",
  "France",
  "SXB",
];
export const KBY = ["Streaky Bay", "Streaky Bay Aerodrome", "Australia", "KBY"];
export const STR = ["Stuttgart", "Stuttgart Airport", "Germany", "STR"];
export const SRE = [
  "Sucre",
  "Juana Azurduy de Padilla International",
  "Bolivia",
  "SRE",
];
export const SUL = ["Sui", "Sui Airport", "Balochistan, Pakistan", "SUL"];
export const SKZ = ["Sukkur", " Sukkur Airport", "Pakistan", "SKZ"];
export const LSI = [
  "Sumburgh (Shetland)",
  "Sumburgh Airport",
  "United Kingdom",
  "LSI",
];
export const SUN = [
  "Sun Valley (ID)",
  "Friedman Memorial Airport",
  "USA",
  "SUN",
];
export const SDL = [
  "Sundsvall",
  "Sundsvall-Härnösand Airport",
  "Sweden",
  "SDL",
];
export const MCY = [
  "Sunshine Coast",
  "Sunshine Coast Airport",
  "Australia",
  "MCY",
];
export const SUB = [
  "Surabaya",
  "Juanda International Airport",
  "Indonesia",
  "SUB",
];
export const STV = ["Surat", "Surat Airport", "Gujarat, India", "STV"];
export const SWP = ["Swakopmund", "Swakopmund Airport", "Namibia", "SWP"];
export const SYD = ["Sydney", "Sydney Airport", "Australia", "SYD"];
export const ZYL = [
  "Sylhet",
  "Osmani International Airport",
  "Bangladesh",
  "ZYL",
];
export const SYR = [
  "Syracuse (NY)",
  "Syracuse Hancock International Airport",
  "USA",
  "SYR",
];
export const TCP = ["Taba", "Taba Airport", "Egypt", "TCP"];
export const TBJ = [
  "Tabarka",
  "Tabarka–Ain Draham International Airport",
  "Tunisia",
  "TBJ",
];
export const TBT = [
  "Tabatinga",
  "Tabatinga International Airport",
  "Brazil",
  "TBT",
];
export const TBZ = [
  "Tabriz",
  " Tabriz International Airport  (persian)",
  "Iran",
  "TBZ",
];
export const TUU = ["Tabuk", "Tabuk Regional Airport", "Saudi Arabia", "TUU"];
export const THL = ["Tachilek", " Tachilek Airport", "Myanmar", "THL"];
export const TAC = [
  "Tacloban City",
  "Tacloban Airport",
  "Eastern Visayas, Philippines",
  "TAC",
];
export const TIW = ["Tacoma (WA)", "Tacoma Narrows Airport", "USA", "TIW"];
export const TAG = [
  "Tagbilaran",
  "Tagbilaran Airport",
  "Bohol, Philippines",
  "TAG",
];
export const TIF = ["Taif (Ta'if)", "Ta'if Airport", "Saudi Arabia", "TIF"];
export const TNN = ["Tainan City", "Tainan Airport", "Taiwan", "TNN"];
export const TPE = [
  "Taipei",
  "Taiwan Taoyuan International Airport",
  "Taiwan",
  "TPE",
];
export const TS = ["Taipei", " Taipei Songshan Airport", "Taiwan", " TSA"];
export const TYN = [
  "Taiyuan",
  "Taiyuan Wusu International Airport",
  "Shanxi, PR China",
  "TYN",
];
export const TAK = ["Takamatsu", "Takamatsu Airport", "Japan", "TAK"];
export const TYL = ["Talara", "Víctor Montes Arias Airport", "Peru", "TYL"];
export const TKA = ["Talkeetna (AK)", "Talkeetna Airport", "USA", "TKA"];
export const TLH = [
  "Tallahassee (FL)",
  "Tallahassee Regional Airport",
  "USA",
  "TLH",
];
export const TLL = [
  "Tallinn",
  "Lennart Meri Tallinn Airport",
  "Estonia",
  "TLL",
];
export const TML = ["Tamale", "Tamale Airport", "Ghana", "TML"];
export const TMR = [
  "Tamanrasset",
  "Tamanrasset Aguenar Airport",
  "Algeria",
  "TMR",
];
export const TNO = ["Tamarindo", "Tamarindo Airport", "Costa Rica", "TNO"];
export const TPA = ["Tampa (FL)", "Tampa International Airport", "USA", "TPA"];
export const TMP = ["Tampere", "Tampere-Pirkkala Airport", "Finland", "TMP"];
export const TAM = [
  "Tampico",
  "Gen. F. Javier Mina International Airport",
  "Mexico",
  "TAM",
];
export const TMW = [
  "Tamworth",
  "Tamworth Regional Airport",
  "Australia",
  "TMW",
];
export const TNG = [
  "Tangier",
  "Tangier Ibn Battouta Airport",
  "Morocco",
  "TNG",
];
export const TNJ = [
  "Tanjung Pinang",
  "Raja Haji Fisabilillah International Airport",
  " Riau Islands, Indonesia.",
  "TNJ",
];
export const TRW = [
  "Tarawa",
  "Bonriki International Airport",
  "Kiribati",
  "TRW",
];
export const TRO = ["Taree", "Taree Airport", "NSW, Australia", "TRO"];
export const TGV = [
  "Targovishte",
  "Targovishte Airport (inoperational)",
  "Bulgaria",
  "TGV",
];
export const TGM = [
  "Targu-Mures",
  "Târgu Mureș Transilvania Airport",
  "Romania",
  "TGM",
];
export const TAY = ["Tartu", "Tartu-Ülenurme Airport", "Estonia", "TAY"];
export const TAS = [
  "Tashkent",
  "Tashkent International Airport",
  "Uzbekistan",
  "TAS",
];
export const TRG = ["Tauranga", "Tauranga City Airport", "New Zealand", "TRG"];
export const TWU = ["Tawau", "Tawau Airport", "Sabah, Malaysia", "TWU"];
export const TBS = [
  "Tbilisi",
  "Tbilisi International Airport",
  "Georgia",
  "TBS",
];
export const TEU = ["Te Anau", "Te Anau Airport", "New Zealand", "TEU"];
export const MME = [
  "Teesside",
  "Durham Tees Valley Airport",
  "United Kingdom",
  "MME",
];
export const TGU = [
  "Tegucigalpa",
  "Toncontín International Airport",
  "Honduras",
  "TGU",
];
export const IKA = [
  "Tehran",
  "Imam Khomeini International Airport",
  "Iran",
  "IKA",
];
export const THR = ["Tehran", "Mehrabad International Airport", "Iran", "THR"];
export const TCN = ["Tehuacán", "Tehuacán National Airport", "Mexico", "TCN"];
export const TEQ = ["Tekirdag", "Tekirdag Corlu Airport", "Turkey", "TEQ"];
export const TLV = ["Tel Aviv", "Ben Gurion International", "Israel", "TLV"];
export const SDV = [
  "Tel Aviv",
  "Sde Dov Airport  (Dov Hoz Airport)",
  "Israel",
  "SDV",
];
export const TEX = ["Telluride (CO)", "Telluride Airport  (GA)", "USA", "TEX"];
export const TEM = ["Temora", "Temora Airport (GA)", "NSW, Australia", "TEM"];
export const ZCO = ["Temuco", "Temuco La Araucania Airport", "Chile", "ZCO"];
export const TCI = [
  "Tenerife (island)",
  "Metropolitan Area",
  "Canary Islands, Spain",
  "TCI",
];
export const TFN = [
  "Tenerife",
  "Tenerife North Airport  (Los Rodeos)",
  "Canary Islands, Spain",
  "TFN",
];
export const TFS = [
  "Tenerife",
  "Tenerife South Airport  (Reina Sofia)",
  "Canary Islands, Spain",
  "TFS",
];
export const TCZ = [
  "Tengchong",
  "Tengchong Tuofeng Airport",
  "Yunnan, China",
  "TCZ",
];
export const TCA = [
  "Tennant Creek",
  "Tennant Creek Airport",
  "NT, Australia",
  "TCA",
];
export const TPQ = [
  "Tepic",
  "Tepic International Airport",
  "Nayarit, Mexico",
  "TPQ",
];
export const TER = [
  "Terceira (island)",
  "Lajes Field",
  "Azores, Portugal",
  "TER",
];
export const THE = ["Teresina", "Teresina Airport", "Piauí, Brazil", "THE"];
export const TMZ = ["Termez (Termes)", "Termez Airport", "Uzbekistan", "TMZ"];
export const TNL = ["Ternopil", "Ternopil Airport", "Ukraine", "TNL"];
export const YXT = [
  "Terrace",
  "Northwest Regional Airport",
  "BC, Canada",
  "YXT",
];
export const HUF = [
  "Terre Haute (IN)",
  "Terre Haute International Airport  (GA)",
  "USA",
  "HUF",
];
export const TEB = ["Teterboro (NJ)", "Teterboro Airport  (GA)", "USA", "TEB"];
export const TTU = [
  "Tetouan (Tétouan)",
  "Sania Ramel Airport",
  "Morocco",
  "TTU",
];
export const TXK = [
  "Texarkana (AR)",
  "Texarkana Regional Airport",
  "USA",
  "TXK",
];
export const TEZ = ["Tezpur", "Tezpur Airport", "Assam, India", "TEZ"];
export const TEI = [
  "Tezu",
  "Tezu Airport (no scheduled service)",
  "Arunachal Pradesh, India",
  "TEI",
];
export const TCU = [
  "Thaba'Nchu",
  " Thaba Nchu Airport (not in use)",
  "Free State, South Africa",
  "TCU",
];
export const YQD = ["The Pas", "The Pas Airport", "Manitoba, Canada", "YQD"];
export const SKG = ["Thessaloniki", "Makedonia Airport", "Greece", "SKG"];
export const TVF = [
  "Thief River Falls (MN)",
  "Thief River Falls Regional Airport",
  "USA",
  "TVF",
];
export const PBH = [
  "Thimphu",
  "Paro Airport  (the only international airport in Bhutan)",
  "Bhutan",
  "PBH",
];
export const JTR = [
  "Thira (island)",
  "Santorini National Airport",
  "Greece",
  "JTR",
];
export const TED = ["Thisted", "Thisted Airport (GA)", "Denmark", "TED"];
export const YTH = ["Thompson", "Thompson Airport", "Manitoba, Canada", "YTH"];
export const KTB = [
  "Thorne Bay (AK)",
  "Thorne Bay Seaplane Base",
  "USA",
  "KTB",
];
export const YQT = ["Thunder Bay", "Thunder Bay Airport", "Canada", "YQT"];
export const TSN = [
  "Tianjin",
  " Tianjin Binhai International Airport  (cn)",
  "China",
  "TSN",
];
export const TIJ = [
  "Tijuana",
  "Tijuana International Airport",
  "Mexico",
  "TIJ",
];
export const TIU = ["Timaru", "Richard Pearse Airport", "New Zealand", "TIU"];
export const TOM = [
  "Timbuktu",
  "Timbuktu Airport (services suspended)",
  "Mali",
  "TOM",
];
export const TIM = ["Timika", "Timika Airport", "Papua, Indonesia", "TIM"];
export const TSR = [
  "Timisoara",
  'Timișoara Airport"Traian Vuia"',
  "Romania",
  "TSR",
];
export const TGI = ["Tingo Marí", "Tingo María Airport", "Peru", "TGI"];
export const TIQ = [
  "Tinian (island)",
  "Tinian International Airport",
  "Northern Mariana Islands",
  "TIQ",
];
export const TOD = ["Tioman", "Tioman Airport", "Indonesia", "TOD"];
export const TIA = [
  "Tirana",
  "Tirana International Airport Nënë Tereza",
  "Albania",
  "TIA",
];
export const TRE = ["Tiree (island)", "Tiree Airport", "Scotland, UK", "TRE"];
export const TRZ = [
  "Tiruchirapally",
  " Tiruchirapalli international Airport",
  "Tamil Nadu, India",
  "TRZ",
];
export const TIR = [
  "Tirupati",
  "Tirupati Airport (Renigunta Airport)",
  "Andhra Pradesh, India",
  "TIR",
];
export const TIX = [
  "Titusville (FL)",
  "Space Coast Regional Airport (GA)",
  "USA",
  "TIX",
];
export const TIV = ["Tivat", "Tivat Airport", "Montenegro", "TIV"];
export const TZM = [
  "Tizimín",
  "Cupul National Airport (no airport)",
  "Yucatan, Mexico",
  "TZM",
];
export const TMM = ["Toamasina", "Toamasina Airport", "Madagascar", "TMM"];
export const TOB = ["Tobruk", "Tobruk International Airport", "Libya", "TOB"];
export const TAB = [
  "Tobago",
  "Crown Point International Airport",
  "Trinidad and Tobago",
  "TAB",
];
export const TKS = ["Tokushima", "Tokushima Airport", "Japan", "TKS"];
export const TYO = ["Tokyo", "Metropolitan Area", "Japan", "TYO"];
export const HND = ["Tokyo", "Haneda Airport International", "Japan", "HND"];
export const NRT = ["Tokyo", "Narita International Airport", "Japan", "NRT"];
export const TOL = ["Toledo (OH)", "Toledo Express Airport", "USA", "TOL"];
export const TLC = [
  "Toluca (near Mexico City)",
  "Toluca International Airport",
  "Mexico State, Mexico",
  "TLC",
];
export const TPR = [
  "Tom Price",
  "Tom Price Airport (GA)",
  "WA, Australia",
  "TPR",
];
export const TOF = ["Tomsk", "Airport Tomsk  (ru)", "Russia", "TOF"];
export const TNH = [
  "Tonghua",
  "Tonghua Sanyuanpu Airport",
  "Jilin, China",
  "TNH",
];
export const TEN = [
  "Tongren",
  "Tongren Fenghuang Airport",
  "Guizhou, China",
  "TEN",
];
export const TWB = [
  "Toowoomba",
  "Toowoomba Airport",
  "Queensland, Australia",
  "TWB",
];
export const FOE = [
  "Topeka (KS)",
  " Topeka Regional Airport  (Forbes Field)",
  "USA",
  "FOE",
];
export const YTO = ["Toronto", "Metropolitan Area", "Ontario, Canada", "YTO"];
export const YYZ = [
  "Toronto",
  "Toronto Pearson International Airport",
  "Canada",
  "YYZ",
];
export const YKZ = [
  "Toronto",
  "Toronto/Buttonville Municipal Airport",
  "Canada",
  "YKZ",
];
export const YTZ = ["Toronto", "Toronto Island Airport", "Canada", "YTZ"];
export const TRC = [
  "Torreón",
  "Torreón International Airport",
  "Coahuila, Mexico",
  "TRC",
];
export const TTQ = ["Tortuguero", "Tortuguero Airport", "Costa Rica", "TTQ"];
export const TOU = ["Touho", "Touho Airport", "New Caledonia", "TOU"];
export const TLN = ["Toulon", "Toulon-Hyères Airport", "France", "TLN"];
export const TLS = [
  "Toulouse",
  "Toulouse Blagnac International Airport",
  "France",
  "TLS",
];
export const TUF = [
  "Tours",
  "Tours Val de Loire Airport  (fr)",
  "France",
  "TUF",
];
export const TSV = [
  "Townsville",
  "Townsville Airport",
  "QueenslandAustralia",
  "TSV",
];
export const TOY = ["Toyama", "Toyama Kitokito Airport", "Japan", "TOY"];
export const TZX = ["Trabzon", "Trabzon Airport", "Turkey", "TZX"];
export const TST = ["Trang", " Trang Airport", "Thailand", "TST"];
export const TPS = [
  "Trapani",
  "Trapani Airport 'Vincenzo Florio'",
  "Sicily, Italy",
  "TPS",
];
export const TDX = ["Trat", "Trat Airport", "Thailand", "TDX"];
export const TVC = [
  "Traverse City (MI)",
  "Cherry Capital Airport",
  "USA",
  "TVC",
];
export const TCB = ["Treasure Cay", "Treasure Cay Airport", "Bahamas", "TCB"];
export const TTN = ["Trenton (NJ)", "Trenton Mercer Airport", "USA", "TTN"];
export const TSF = ["Treviso", "Treviso Airport", "Italy", "TSF"];
export const TRS = [
  "Trieste",
  "Trieste – Friuli Venezia Giulia Airport",
  "Italy",
  "TRS",
];
export const TRI = [
  "Tri-Cities (TN/VA)",
  "Tri-Cities Regional Airport",
  "USA",
  "TRI",
];
export const TRR = ["Trincomalee", "China Bay Airport", "Sri Lanka", "TRR"];
export const POS = [
  "Trinidad (island)",
  "Piarco International Airport",
  "Trinidad and Tobago",
  "POS",
];
export const TDD = [
  "Trinidad",
  "Jorge Henrich Arauz Airport",
  "Bolivia",
  "TDD",
];
export const TIP = [
  "Tripoli",
  "Tripoli International Airport (not in use)",
  "Libya",
  "TIP",
];
export const TRV = [
  "Trivandrum (Thiruvananthapuram)",
  "Trivandrum International Airport",
  "Kerala, India",
  "TRV",
];
export const TOS = ["Tromsoe (Tromsø)", "Tromsø Airport", "Norway", "TOS"];
export const TRD = ["Trondheim", "Trondheim Airport, Værnes", "Norway", "TRD"];
export const QYR = ["Troyes", "Troyes – Barberey Airport", "France", "QYR"];
export const TKF = [
  "Truckee (CA)",
  "Truckee Tahoe Airport  (GA)",
  "USA",
  "TKF",
];
export const TRU = [
  "Trujillo",
  "Carlos Martínez de Pinillos International",
  "Peru",
  "TRU",
];
export const TSB = ["Tsumeb", "Tsumeb Airport", "Namibia", "TSB"];
export const TUS = [
  "Tucson (AZ)",
  "Tucson International Airport",
  "USA",
  "TUS",
];
export const TUC = [
  "Tucuman",
  "Benj Matienzo International Airport",
  "Argentina",
  "TUC",
];
export const TUG = [
  "Tuguegarao",
  "Tuguegarao Airport",
  "Cagayan, Philippines",
  "TUG",
];
export const TLR = ["Tulare (CA)", "Mefford Field Airport (GA)", "USA", "TLR"];
export const TUA = [
  " Tulcán",
  "Luis a Mantilla International Airport",
  "Ecuador",
  "TUA",
];
export const THA = [
  "Tullahoma (TN)",
  "Tullahoma Regional Airport",
  "USA",
  "THA",
];
export const TUP = ["Tupelo (MS)", "Tupelo Regional Airport", "USA", "TUP"];
export const TUL = ["Tulsa (OK)", "Tulsa International Airport", "USA", "TUL"];
export const TBP = ["Tumbes", "Pedro Canga Rodríguez Airport", "Peru", "TBP"];
export const TUN = [
  "Tunis",
  "Tunis–Carthage International Airport",
  "Tunisia",
  "TUN",
];
export const TUK = [
  "Turbat",
  "Turbat International Airport",
  "Pakistan",
  "TUK",
];
export const TRN = ["Turin", "Turin International Airport", "Italy", "TRN"];
export const TKU = ["Turku", "Turku Airport", "Finland", "TKU"];
export const TCL = [
  "Tuscaloosa (AL)",
  "Tuscaloosa Regional Airport",
  "USA",
  "TCL",
];
export const TGZ = [
  "Tuxtla Gutierrez",
  " Tuxtla Gutierrez International Airport",
  "Mexico",
  "TGZ",
];
export const TWF = [
  "Twin Falls (ID)",
  "Magic Valley Regional Airport",
  "USA",
  "TWF",
];
export const TYR = [
  "Tyler (TX)",
  "Tyler Pounds Regional Airport",
  "USA",
  "TYR",
];
export const TJM = [
  "Tyumen",
  "Roshchino International Airport  (ru)",
  "Russia",
  "TJM",
];
export const UAH = [
  "Ua Huka (island)",
  "Ua Huka Airport",
  "French Polynesia",
  "UAH",
];
export const UAP = [
  "Ua Pou (island)",
  "Ua Pou Airport",
  "French Polynesia",
  "UAP",
];
export const UBJ = ["Ube", "Yamaguchi Ube Airport", "Yamaguchi, Japan", "UBJ"];
export const UBA = [
  "Uberaba",
  "Uberaba–Mário de Almeida Franco Airport",
  "Minas Gerais, Brazil",
  "UBA",
];
export const UDI = [
  "Uberlandia",
  "Uberlandia Airport César Bombonato",
  "Minas Gerais, Brazil",
  "UDI",
];
export const UBP = [
  "Ubon Ratchathani",
  "Ubon Ratchathani Airport",
  "Thailand",
  "UBP",
];
export const UDR = [
  "Udaipur",
  "Udaipur Dabok Airport",
  "Rajasthan, India",
  "UDR",
];
export const UTH = ["Udon Thani", "Udon Thani Airport", "Thailand", "UTH"];
export const UFA = [
  "Ufa",
  "Ufa International Airport",
  "Bashkortostan, Russia",
  "UFA",
];
export const UHE = [
  "Uherske Hradiste",
  "Kunovice Airport (GA)",
  "Czech Republic",
  "UHE",
];
export const UGO = ["Uige", "Uíge Airport", "Angola", "UGO"];
export const UPG = [
  "Ujung Pandang (Makassar)",
  "Makassar Hasanuddin International",
  "Sulawesi, Indonesia",
  "UPG",
];
export const UCT = ["Ukhta", "Ukhta Airport", "Komi, Russia", "UCT"];
export const UKI = ["Ukiah (CA)", "Ukiah Municipal Airport (GA)", "USA", "UKI"];
export const ULN = [
  "Ulaanbaatar",
  "Chinggis Khaan International Airport",
  "Mongolia",
  "ULN",
];
export const ULO = ["Ulaangom", "Ulaangom Airport", "Mongolia", "ULO"];
export const HLH = [
  "Ulanhot",
  "Ulanhot Airport",
  "Inner Mongolia, China",
  "HLH",
];
export const UUD = [
  "Ulan-Ude",
  "Baikal International Airport",
  "Russia",
  "UUD",
];
export const ULB = ["Ulei", "Ulei Airport", "Ambrym, Vanuatu", "ULB"];
export const ULG = ["Ulgii (Ölgii)", "Ulgii Airport", "Mongolia", "ULG"];
export const USN = ["Ulsan", "Ulsan Airport", "South Korea", "USN"];
export const ULD = ["Ulundi", "Ulundi Airport", "South Africa", "ULD"];
export const ULV = [
  "Ulyanovsk",
  "Ulyanovsk Baratayevka Airport",
  "Russia",
  "ULV",
];
export const ULY = [
  "Ulyanovsk",
  "Ulyanovsk Vostochny Airport (cargo)",
  "Russia",
  "ULY",
];
export const UME = ["Umea (Umeå)", "Umeå Airport", "Sweden", "UME"];
export const UMT = ["Umiat (AK)", "Umiat Airport", "USA", "UMT"];
export const YUD = ["Umiujaq", "Umiujaq Airport", "Canada", "YUD"];
export const UNK = ["Unalakleet (AK)", "Unalakleet Airport", "USA", "UNK"];
export const UCY = [
  "Union City (TN)",
  "Everett–Stewart Regional Airport  (GA)",
  "USA",
  "UCY",
];
export const UNI = [
  "Union Island",
  "Union Island Airport",
  "Saint Vincent and the Grenadines",
  "UNI",
];
export const UNT = [
  "Unst (Shetland Island)",
  "Baltasound Airport",
  "United Kingdom",
  "UNT",
];
export const UPL = ["Upala", "Upala Airport", "Costa Rica", "UPL"];
export const JUV = ["Upernavik", "Upernavik Heliport", "Greenland", "JUV"];
export const UTN = ["Upington", "Upington Airport", "South Africa", "UTN"];
export const UPP = ["Upolu Point (HI)", "Upolu Airport", "USA", "UPP"];
export const YBE = [
  "Uranium City",
  "Uranium City Airport",
  "Saskatchewan, Canada",
  "YBE",
];
export const URJ = ["Uray", "Uray Airport", "Russia", "URJ"];
export const UGC = [
  "Urgench",
  "Urgench International Airport",
  "Uzbekistan",
  "UGC",
];
export const URM = ["Uriman", "(no airport)", "Venezuela", "URM"];
export const OMH = ["Urmia (Urmieh)", "Urmia Airport", "Iran", "OMH"];
export const UPN = [
  "Uruapan",
  "Uruapan International Airport",
  "Mexico",
  "UPN",
];
export const URB = ["Urubupunga", "Ernesto Pochler Airport", "Brazil", "URB"];
export const URG = [
  "Uruguaiana",
  "Ruben Berta International Airport",
  "Brazil",
  "URG",
];
export const URC = [
  "Urumqi",
  "Urumqi International Airport  (cn)",
  "Xinjiang, China",
  "URC",
];
export const URZ = ["Uruzgan", "(no airport)", "Afghanistan", "URZ"];
export const USQ = ["Usak", "Usak Airport", "Turkey", "USQ"];
export const USH = [
  "Ushuaia",
  ' Ushuaia"Malvinas Argentinas" Airport',
  "Tierra del Fuego, Argentina",
  "USH",
];
export const UIK = [
  "Ust-Ilimsk",
  "(in a state of disrepair)",
  "Irkutsk Oblast, Russia",
  "UIK",
];
export const UKX = [
  "Ust-Kut",
  "Ust-Kut Airport",
  "Irkutsk Oblast, Russia",
  "UKX",
];
export const UKG = ["Ust-Kuyga", "Ust-Kuyga Airport", "Yakutia, Russia", "UKG"];
export const UTP = [
  "Utapao (Pattaya)",
  "U-Tapao Airport (Rayong)",
  "Thailand",
  "UTP",
];
export const UCA = ["Utica (NY)", "Oneida County Airport (GA)", "USA", "UCA"];
export const UII = ["Utila (island)", "Útila Airport", "Honduras", "UII"];
export const UVA = ["Uvalde (TX)", "Garner Field (GA)", "USA", "UVA"];
export const UMD = ["Uummannaq", "Uummannaq Heliport", "Greenland", "UMD"];
export const UYU = ["Uyuni", "Uyuni Airport", "Potosí, Bolivia", "UYU"];
export const UDJ = [
  "Uzhgorod",
  "Uzhhorod International Airport",
  "Ukraine",
  "UDJ",
];
export const UZC = ["Uzice", "Užice-Ponikve Airport", "Serbia", "UZC"];
export const VAA = ["Vaasa", " Vaasa Airport", "Finland", "VAA"];
export const VDS = ["Vadsø", "Vadsø Airport", "Norway", "VDS"];
export const VXO = [
  "Vaexjoe (Växjö)",
  "Växjö Småland Airport",
  "Sweden",
  "VXO",
];
export const EGE = ["Vail (CO)", "Eagle County Regional Airport", "USA", "EGE"];
export const YVO = ["Val d'Or", "Val-d'Or Airport", "Quebec, Canada", "YVO"];
export const VDZ = [
  "Valdez (AK)",
  " Valdez Airport (Pioneer Field)",
  "USA",
  "VDZ",
];
export const VLD = [
  "Valdosta (GA)",
  " Valdosta Regional Airport",
  "USA",
  "VLD",
];
export const VAF = [
  "Valence",
  "Valence-Chabeuil Airport (GA)",
  "France",
  "VAF",
];
export const VLC = ["Valencia", "Valencia Airport", "Spain", "VLC"];
export const VLN = [
  "Valencia",
  "Arturo Michelena International Airport",
  "Venezuela",
  "VLN",
];
export const VLV = [
  "Valera",
  "Antonio Nicolás Briceño Airport",
  "Venezuela",
  "VLV",
];
export const VLL = ["Valladolid", "Valladolid Airport", "Spain", "VLL"];
export const VDP = [
  "Valle de la Pascua",
  "Valle de la Pascua Airport",
  "Venezuela",
  "VDP",
];
export const VUP = [
  "Valledupar",
  "Alfonso López Pumarejo Airport",
  "Colombia",
  "VUP",
];
export const VPZ = [
  "Valparaiso, (IN)",
  "Porter County Regional Airport (GA)",
  "USA",
  "VPZ",
];
export const VAP = [
  "Valparaiso (Viña del Mar)",
  "Rodelillo Airfield",
  "Chile",
  "VAP",
];
export const VDE = [
  "Valverde (Canary Islands)",
  "El Hierro Airport",
  "Spain",
  "VDE",
];
export const VAN = ["Van", "Van Ferit Melen Airport", "Turkey", "VAN"];
export const YVR = [
  "Vancouver",
  "Vancouver International Airport",
  "Canada",
  "YVR",
];
export const VNY = [
  "Van Nuys (Los Angeles)",
  "Van Nuys Airport  (GA)",
  "USA",
  "VNY",
];
export const VRA = ["Varadero", "Juan Gualberto Gómez Airport", "Cuba", "VRA"];
export const VNS = [
  "Varanasi",
  "Lal Bahadur Shastri Airport",
  "Uttar Pradesh, India",
  "VNS",
];
export const VAW = [
  "Vardo (Vardø)",
  "Vardø Airport, Svartnes",
  "Norway",
  "VAW",
];
export const VAG = ["Varginha", "Varginha Airport", "Brazil", "VAG"];
export const VRK = [
  "Varkaus",
  "Varkaus Airport (terminal is closed)",
  "Finland",
  "VRK",
];
export const VAR = ["Varna", "Varna Airport", "Bulgaria", "VAR"];
export const VST = [
  "Vasteras (Västerås)",
  "Stockholm-Västerås Airport",
  "Sweden",
  "VST",
];
export const XCR = [
  "Vatry",
  "Châlons Vatry Airport  (Paris Vatry)",
  "France",
  "XCR",
];
export const VLU = [
  "Velikiye Luki (Welikije Luki)",
  "Velikiye Luki Airport",
  "Russia",
  "VLU",
];
export const VCE = ["Venice", "Venice Marco Polo Airport", "Italy", "VCE"];
export const VNT = ["Ventspils", "Ventspils Airport", "Latvia", "VNT"];
export const VER = [
  "Veracruz",
  "Heriberto Jara International Airport",
  "Mexico",
  "VER",
];
export const VEL = [
  "Vernal (UT)",
  "Vernal Regional Airport (GA)",
  "USA",
  "VEL",
];
export const VRB = [
  "Vero Beach/Ft. Pierce (FL)",
  "Vero Beach Municipal Airport (GA)",
  "USA",
  "VRB",
];
export const VRN = ["Verona", "Verona Villafranca Airport", "Italy", "VRN"];
export const VEY = [
  "Vestmannaeyjar",
  "Vestmannaeyjar Airport",
  "Iceland",
  "VEY",
];
export const VIC = ["Vicenza", "Vicenza Airport (closed 2008)", "Italy", "VIC"];
export const VCH = ["Vichadero", "Vichadero Airport", "Uruguay", "VCH"];
export const YYJ = [
  "Victoria",
  "Victoria International Airport",
  "BC, Canada",
  "YYJ",
];
export const SEZ = [
  "Victoria (Port Victoria)",
  "Seychelles International Airport (Aeroport de La pointe Larue)",
  "Mahé, Seychelles",
  "SEZ",
];
export const VCT = ["Victoria (TX)", "Victoria Regional Airport", "USA", "VCT"];
export const VFA = [
  "Victoria Falls",
  "Victoria Falls Airport",
  "Zimbabwe",
  "VFA",
];
export const VCV = [
  "Victorville (CA)",
  "Southern California Logistics Airport",
  "USA",
  "VCV",
];
export const VID = ["Vidin", "Vidin Airfield (inactive)", "Bulgaria", "VID"];
export const VDM = [
  "Viedma",
  "Edgardo Castello Airport",
  "Río Negro, Argentina",
  "VDM",
];
export const VTE = [
  "Vientiane",
  "Wattay International Airport",
  "Lao PDR",
  "VTE",
];
export const VQS = [
  " Vieques (island)",
  "Antonio Rivera Rodriguez Airport",
  "Puerto Rico",
  "VQS",
];
export const VGO = ["Vigo", "Vigo-Peinador Airport", "Galicia, Spain", "VGO"];
export const VGA = [
  "Vijayawada",
  "Vijayawada Airport",
  "Andhra Pradesh, India",
  "VGA",
];
export const VNX = ["Vilankulo", "Vilankulo Airport", "Mozambique", "VNX"];
export const VRL = ["Vila Real", "Vila Real Airport", "Portugal", "VRL"];
export const VLG = ["Villa Gesell", "Villa Gesell Airport", "Argentina", "VLG"];
export const VSA = [
  "Villahermosa",
  "Carlos Rovirosa Pérez Intl Airport",
  "Mexico",
  "VSA",
];
export const VII = ["Vinh", "Vinh Airport", "Vietnam", "VII"];
export const VRC = [
  "Virac",
  "Virac Airport",
  "Catanduanes, Philippines",
  "VRC",
];
export const VIJ = [
  "Virgin Gorda",
  "Virgin Gorda Airport",
  "Virgin Islands (UK)",
  "VIJ",
];
export const VTZ = [
  "Visakhapatnam",
  "Visakhapatnam Airport",
  "Andhra Pradesh, India",
  "VTZ",
];
export const VIS = [
  "Visalia (CA)",
  "Visalia Municipal Airport (GA)",
  "USA",
  "VIS",
];
export const VBY = ["Visby", "Visby Airport", "Sweden", "VBY"];
export const VIX = [
  "Vitoria",
  "Eurico de Aguiar Salles Airport",
  "Espírito Santo, Brazil",
  "VIX",
];
export const VDC = [
  "Vitória da Conquista",
  "Vitória da Conquista Airport",
  "Bahia, Brazil",
  "VDC",
];
export const VIT = [
  "Vitoria-Gasteiz",
  "Vitoria Airport (no scheduled service)",
  "Spain",
  "VIT",
];
export const VVO = [
  "Vladivostok",
  "Vladivostok International Airport",
  "Russia",
  "VVO",
];
export const VOG = ["Volgograd", "Volgograd Airport", "Russia", "VOG"];
export const VOL = [
  " Volos",
  "Nea Anchialos National Airport",
  "Greece",
  "VOL",
];
export const VOZ = ["Voronezh", "Airport Voronezh (ru)", "Russia", "VOZ"];
export const VYD = [
  "Vryheid",
  "Vryheid Airstrip (GA)",
  "KwaZulu-Natal, South Africa",
  "VYD",
];
export const YWK = ["Wabush", "Wabush Airport", "NL, Canada", "YWK"];
export const ACT = ["Waco (TX)", "Waco Regional Airport", "USA", "ACT"];
export const WHF = ["Wadi Halfa", "Wadi Halfa Airport", "Sudan", "WHF"];
export const WGA = [
  "Wagga Wagga",
  "Wagga Wagga Airport",
  "NSW, Australia",
  "WGA",
];
export const WGP = ["Waingapu", "Waingapu Airport", "Sumba, Indonesia", "WGP"];
export const WKJ = ["Wakkanai", " Wakkanai Airport", "Hokkaido, Japan", "WKJ"];
export const ALW = [
  "Walla Walla (WA)",
  "Walla Walla Regional Airport",
  "USA",
  "ALW",
];
export const WLS = [
  "Wallis (island)",
  "Hihifo Airport",
  "Wallis and Futuna Islands",
  "WLS",
];
export const WVB = [
  "Walvis Bay",
  "Walvis Bay Airport",
  "Erongo, Namibia",
  "WVB",
];
export const WKA = [
  "Wanaka",
  "Wanaka Airport (no scheduled service)",
  "New Zealand",
  "WKA",
];
export const WOT = [
  "Wang-an",
  "Wang-an Airport",
  "Penghu Islands, Taiwan",
  "WOT",
];
export const WXN = [
  " Wanzhou (district)",
  "Wanzhou Wuqiao Airport",
  "Chongqing, China",
  "WXN",
];
export const WMB = [
  "Warrnambool",
  "Warrnambool Airport (no scheduled service)",
  "Victoria, Australia",
  "WMB",
];
export const WAW = ["Warsaw", "Warsaw Chopin Airport", "Poland", "WAW"];
export const WMI = ["Warsaw", "Warsaw–Modlin Mazovia Airport", "Poland", "WMI"];
export const WAS = ["Washington D.C.", "Metropolitan Area", "USA", "WAS"];
export const BWI = [
  "Washington D.C.",
  "Baltimore Washington International",
  "USA",
  "BWI",
];
export const IAD = [
  "Washington D.C.",
  "Washington Dulles International Airport",
  "USA",
  "IAD",
];
export const DCA = [
  "Washington D.C.",
  "Ronald Reagan National Airport",
  "USA",
  "DCA",
];
export const WAT = ["Waterford", "Waterford Airport", "Ireland", "WAT"];
export const ALO = ["Waterloo (IA)", "Waterloo Regional Airport", "USA", "ALO"];
export const ART = [
  "Watertown (NY)",
  "Watertown International Airport",
  "USA",
  "ART",
];
export const ATY = [
  "Watertown (SD)",
  "Watertown Regional Airport",
  "USA",
  "ATY",
];
export const CWA = [
  "Wausau/Stevens Point (WI)",
  "Central Wisconsin Airport",
  "USA",
  "CWA",
];
export const WAY = [
  "Waynesburg (PA)",
  "Greene County Airport (GA)",
  "USA",
  "WAY",
];
export const WEF = ["Weifang", "Weifang Airport", "Shandong, China", "WEF"];
export const WEH = [
  "Weihai",
  "Weihai International Airport  (cn)",
  "Shandong, China",
  "WEH",
];
export const WEI = ["Weipa", "Weipa Airport", "Queensland, Australia", "WEI"];
export const WEL = [
  "Welkom",
  "Welkom Airport (GA)",
  "Free State, South Africa",
  "WEL",
];
export const WLG = [
  "Wellington",
  "Wellington International Airport",
  "New Zealand",
  "WLG",
];
export const EAT = [
  "Wenatchee (WA)",
  "Pangborn Memorial Airport  (GA)",
  "USA",
  "EAT",
];
export const TKK = [
  "Weno (island)",
  "Chuuk International Airport",
  "Micronesia",
  "TKK",
];
export const WNZ = [
  "Wenzhou",
  "Wenzhou Longwan International   (cn)",
  "Zhejiang, China",
  "WNZ",
];
export const GWT = ["Westerland", "Sylt Airport  (de)", "Germany", "GWT"];
export const PBI = [
  "West Palm Beach (FL)",
  "Palm Beach International Airport",
  "USA",
  "PBI",
];
export const WSZ = ["Westport", "Westport Airport", "New Zealand", "WSZ"];
export const WYS = [
  "West Yellowstone (MT)",
  "Yellowstone Airport",
  "USA",
  "WYS",
];
export const WHK = ["Whakatane", "Whakatane Airport", "New Zealand", "WHK"];
export const YXN = [
  "Whale Cove",
  "Whale Cove Airport",
  "Nunavut, Canada",
  "YXN",
];
export const WAG = ["Whanganui", "Wanganui Airport", "New Zealand", "WAG"];
export const WRE = ["Whangarei", "Whangarei Airport", "New Zealand", "WRE"];
export const HPN = [
  "White Plains (NY)",
  "Westchester County Airport",
  "USA",
  "HPN",
];
export const YXY = [
  "Whitehorse",
  "Erik Nielsen Whitehorse Airport",
  "Yukon, Canada",
  "YXY",
];
export const WTR = ["Whiteriver (AZ)", "Whiteriver Airport (GA)", "USA", "WTR"];
export const PPP = [
  "Whitsunday Islands",
  "Whitsunday Coast Airport",
  "Queensland, Australia",
  "PPP",
];
export const WYA = ["Whyalla", "Whyalla Airport", "SA, Australia", "WYA"];
export const SPS = [
  "Wichita Falls (TX)",
  "Wichita Falls Municipal Airport",
  "USA",
  "SPS",
];
export const ICT = [
  "Wichita (KS)",
  "Dwight D. Eisenhower National Airport",
  "USA",
  "ICT",
];
export const WIC = ["Wick", "Wick Airport", "Scotland, UK", "WIC"];
export const VIE = [
  "Wien (Vienna)",
  "Vienna International Airport",
  "Austria",
  "VIE",
];
export const WIE = ["Wiesbaden", "Air Base", "Hessen, Germany", "WIE"];
export const AVP = [
  "Wilkes Barre-Scranton (PA)",
  "Wilkes-Barre/Scranton Airport",
  "USA",
  "AVP",
];
export const CUR = [
  "Willemstad",
  "Curaçao International Airport (Hato International Airport)",
  "Netherlands Antilles",
  "CUR",
];
export const YWL = [
  "Williams Lake",
  "Williams Lake Airport",
  "BC, Canada",
  "YWL",
];
export const IPT = [
  "Williamsport (PA)",
  "Williamsport Regional Airport",
  "USA",
  "IPT",
];
export const ISN = ["Williston (ND)", "Sloulin Field Airport", "USA", "ISN"];
export const ILM = [
  "Wilmington (NC)",
  "Wilmington International Airport",
  "USA",
  "ILM",
];
export const VNO = [
  "Wilna (Vilnius; capital city)",
  "Vilnius International Airport",
  "Lithuania",
  "VNO",
];
export const WUN = ["Wiluna", "Wiluna Airport", "WA, Australia", "WUN"];
export const WGO = [
  "Winchester (VA)",
  "Winchester Regional Airport",
  "USA",
  "WGO",
];
export const WDH = [
  "Windhoek",
  "Windhoek Hosea Kutako International",
  "Namibia",
  "WDH",
];
export const ERS = ["Windhoek", "Eros Airport", "Namibia", "ERS"];
export const YQG = ["Windsor", "Windsor Airport", "Ontario, Canada", "YQG"];
export const WLD = [" Winfield (KS)", "Strother Field", "USA", "WLD"];
export const WMC = [
  "Winnemucca (NV)",
  "Winnemucca Municipal Airport",
  "USA",
  "WMC",
];
export const YWG = [
  "Winnipeg",
  "James Armstrong Richardson International",
  "Manitoba, Canada",
  "YWG",
];
export const OLF = [
  "Wolf Point (MT)",
  "L. M. Clayton Airport (GA)",
  "USA",
  "OLF",
];
export const WOL = [
  "Wollongong",
  "Illawarra Regional Airport",
  "NSW, Australia",
  "WOL",
];
export const WJU = ["Wonju", "Wonju Airport", "Gangwon, South Korea", "WJU"];
export const WWR = [
  "Woodward (OK)",
  "West Woodward Airport (GA)",
  "USA",
  "WWR",
];
export const YKF = [
  "Woolwich",
  "Region of Waterloo International Airport",
  "Ontario, Canada",
  "YKF",
];
export const ORH = [
  "Worcester (MA)",
  "Worcester Regional Airport",
  "USA",
  "ORH",
];
export const WRL = [
  "Worland (WY)",
  "Worland Municipal Airport (GA)",
  "USA",
  "WRL",
];
export const WRG = ["Wrangell (AK)", "Wrangell Airport", "USA", "WRG"];
export const YWY = ["Wrigley", "Wrigley Airport (GA)", "NWT, Canada", "YWY"];
export const WRO = [
  "Wroclaw (Wrocław)",
  "Wrocław - Copernicus Airport",
  "Poland",
  "WRO",
];
export const WUA = ["Wuhai", "Wuhai Airport", "Inner Mongolia, China", "WUA"];
export const WUH = [
  "Wuhan",
  "Wuhan Tianhe International Airport  (cn)",
  "Hubei, China",
  "WUH",
];
export const WUX = [
  "Wuxi, Suzhou",
  "Sunan Shuofang International Airport",
  "Jiangsu, China",
  "WUX",
];
export const WUZ = [
  "Wuzhou",
  "Wuzhou Changzhoudao Airport",
  "Guangxi, China",
  "WUZ",
];
export const WYN = ["Wyndham", "Wyndham Airport", "WA, Australia", "WYN"];
export const XGN = [
  "Xangongo",
  "Xangongo Airport (airstrip)",
  "Cunene, Angola",
  "XGN",
];
export const XMN = [
  "Xiamen",
  "Xiamen Gaoqi International Airport  (cn)",
  "Fujian, China",
  "XMN",
];
export const XFN = ["Xiangyang", "Xiangyang Airport", "Hubei, China", "XFN"];
export const XIY = [
  "Xi'an - Xianyang",
  "Xi'an Xianyang International Airport",
  "Shaanxi, China",
  "XIY",
];
export const XIC = [
  "Xichang",
  "Xichang Qingshan Airport",
  "Sichuan, China",
  "XIC",
];
export const XKH = [
  "Xieng Khouang (Phonsavan)",
  "Xieng Khouang Airport",
  "Laos",
  "XKH",
];
export const XIL = [
  " Xilinhot",
  "Xilinhot Airport",
  "Inner Mongolia, China",
  "XIL",
];
export const XIG = ["Xinguara", "Xinguara Airstrip", "Pará, Brazil", "XIG"];
export const XNT = ["Xingtai", "Xingtai Dalian Airport", "Hebei, China", "XNT"];
export const XNN = [
  "Xining",
  " Xining Caojiabu Airport",
  "Qinghai, China",
  "XNN",
];
export const XUZ = [
  "Xuzhou",
  "Xuzhou Guanyin Airport",
  "Jiangsu, China",
  "XUZ",
];
export const YKM = ["Yakima (WA)", "Yakima Air Terminal", "USA", "YKM "];
export const YAK = ["Yakutat (AK)", "Yakutat Airport", "USA", "YAK "];
export const YKS = ["Yakutsk", "Yakutsk Airport", "Russia", "YKS "];
export const GAJ = ["Yamagata", "Yamagata Airport", "Japan", "GAJ "];
export const ASK = [
  "Yamoussoukro ",
  "Yamoussoukro Airport",
  "Côte d'Ivoire",
  "ASK ",
];
export const YNB = ["Yanbu (Yenbo)", "Yanbu Airport", "Saudi Arabia", "YNB "];
export const YNZ = [
  "Yancheng",
  "Yancheng Nanyang Airport  (cn)",
  "Jiangsu, China",
  "YNZ",
];
export const RGN = [
  "Yangon (Rangoon)",
  "Yangon Mingaladon Airport",
  "Myanmar",
  "RGN ",
];
export const YTY = [
  "Yangzhou",
  "Yangzhou Taizhou Airport",
  "Jiangsu, China",
  "YTY",
];
export const YNJ = [
  "Yanji",
  "Yanji Chaoyangchuan Airport",
  "Jilin, China",
  "YNJ",
];
export const YKN = [
  "Yankton (SD)",
  " Chan Gurney Municipal Airport (GA)",
  "USA",
  "YKN",
];
export const YNT = [
  "Yantai",
  "Yantai Laishan International Airport",
  " Shandong, China",
  "YNT",
];
export const YAO = ["Yaounde ", "Metropolitan Area", "Cameroon", "YAO"];
export const NSI = [
  "Yaounde",
  "Yaoundé Nsimalen International Airport",
  "Cameroon",
  "NSI ",
];
export const YAP = [
  "Yap (island)",
  "Yap International Airport",
  "Micronesia",
  "YAP",
];
export const YQI = [
  "Yarmouth",
  "Yarmouth International Airport",
  "Nova Scotia, Canada",
  "YQI",
];
export const YAS = ["Yasawa (island)", "Yasawa Island Airport", " Fiji", "YAS"];
export const SVX = ["Yekaterinburg", "Koltsovo Airport", "Russia", "SVX"];
export const YZF = [
  "Yellowknife",
  "Yellowknife Airport",
  "NWT, Canada",
  "YZF ",
];
export const YEI = [
  "Yenisehir (Bursa)",
  "Bursa Yenişehir Airport",
  "Turkey",
  "YEI",
];
export const EVN = [
  "Yerevan ",
  "Zvartnots International Airport",
  "Armenia",
  "EVN",
];
export const YBP = ["Yibin", "Yibin Caiba Airport", " Sichuan, China", "YBP"];
export const YIH = [
  "Yichang",
  "Yichang Sanxia Airport",
  "Hubei, China",
  "YIH ",
];
export const YIC = [
  "Yichun",
  "Yichun Mingyueshan Airport",
  "Jiangxi, China",
  "YIC",
];
export const INC = [
  "Yinchuan",
  "Yinchuan Hedong International Airport",
  "Ningxia, China",
  "INC",
];
export const YIN = ["Yining", "Yining Airport", "Xinjiang, China", "YIN"];
export const YIW = ["Yiwu", " Yiwu Airport", "Zhejiang, China", "YIW"];
export const JOG = [
  "Yogyakarta",
  "Adisucipto International Airport",
  "Java, Indonesia",
  "JOG",
];
export const YOK = ["Yokohama", "Tokyo Haneda Airport", "Japan", "YOK"];
export const YOL = ["Yola", "Yola Airport", "Nigeria", "YOL"];
export const YGJ = ["Yonago", "Yonago Kitaro Airport", "Tottori, Japan", "YGJ"];
export const YNG = [
  "Youngstown (OH)",
  "Youngstown-Warren Regional Airport",
  "USA",
  "YNG",
];
export const YUM = ["Yuma (AZ)", "Yuma International Airport", "USA", "YUM "];
export const YCU = [
  "Yuncheng",
  "Yuncheng Guangong Airport  (cn)",
  "Shanxi, China",
  "YCU",
];
export const YMS = ["Yurimaguas", "Yurimaguas Airport", " Loreto, Peru", "YMS"];
export const YUS = ["Yushu", "Yushu Batang Airport", "Qinghai, China", "YUS"];
export const UUS = [
  "Yuzno-Sakhalinsk",
  "Yuzno-Sakhalinsk Airport",
  "Sakhalin, Russia",
  "UUS",
];
export const ZBK = ["Zabljak", "Žabljak Airport (GA)", "Montenegro", "ZBK"];
export const ZCL = [
  "Zacatecas",
  "Leobardo C. Ruiz International Airport",
  "Mexico",
  "ZCL ",
];
export const ZAD = ["Zadar", "Zadar Airport", "Croatia (Hrvatska)", "ZAD "];
export const ZAG = [
  "Zagreb ",
  "Zagreb International Airport",
  "Croatia (Hrvatska)",
  "ZAG ",
];
export const ZAH = ["Zahedan", "Zahedan Airport", "Iran", "ZAH"];
export const ZTH = [
  "Zakynthos (island)",
  "Zakynthos International Airport",
  "Greece",
  "ZTH ",
];
export const ZAM = [
  "Zamboanga City",
  "Zamboanga International Airport",
  "Mindanao, Philippines",
  "ZAM",
];
export const ZNZ = [
  "Zanzibar City",
  "Zanzibar Karume International Airport",
  "Tanzania",
  "ZNZ",
];
export const ZTU = [
  "Zaqatala",
  "Zaqatala International Airport",
  "Azerbaijan",
  "ZTU",
];
export const ZAZ = ["Zaragoza", "Zaragoza Airport", "Spain", "ZAZ "];
export const ZAJ = ["Zaranj", "Zaranj Airport", "Afghanistan", "ZAJ"];
export const ZAR = ["Zaria", "Zaria Airport", "Kaduna, Nigeria", "ZAR"];
export const ZQZ = [
  "Zhangjiakou",
  "Zhangjiakou Ningyuan Airport",
  "Hebei, China",
  "ZQZ",
];
export const YZY = [
  " Zhangye",
  "Zhangye Ganzhou Airport",
  "Gansu, China",
  "YZY",
];
export const ZHA = [
  " Zhanjiang",
  "Zhanjiang Airport",
  "Guangdong, China",
  "ZHA",
];
export const ZAT = ["Zhaotong", "Zhaotong Airport", "Yunnan, China", "ZAT"];
export const PZH = ["Zhob", "Zhob Airport", "Balochistan, Pakistan", "PZH "];
export const ZHY = [
  "Zhongwei",
  "Zhongwei Xiangshan Airport",
  "Ningxia, China",
  "ZHY",
];
export const HSN = [
  "Zhoushan",
  "Zhoushan Putuoshan Airport",
  "Zhejiang, China",
  "HSN",
];
export const ZUH = ["Zhuhai", "Zhuhai Airport", "Guangdong, China", "ZUH"];
export const ZTR = [
  "Zhytomyr",
  "Zhytomyr Airport (no airline service)",
  "Ukraine",
  "ZTR",
];
export const ZIG = ["Ziguinchor", "Ziguinchor Airport", "Senegal", "ZIG"];
export const ZIH = [
  "Zihuatanejo",
  "Ixtapa-Zihuatanejo International Airport",
  "Guerrero, Mexico",
  "ZIH",
];
export const ZND = ["Zinder", "Zinder Regional Airport", "Niger", "ZND "];
export const ZER = [
  "Ziro (Zero)",
  "Ziro Airport",
  "Arunachal Pradesh, India",
  "ZER",
];
export const OUZ = ["Zouerate", "Tazadit Airport", "Mauritania", "OUZ "];
export const ZYI = ["Zunyi", "Zunyi Xinzhou Airport", "Guizhou, China", "ZYI"];
export const ZRH = [
  "Zurich (Zürich)",
  "Zürich Airport (Kloten)",
  "Switzerland",
  "ZRH",
];
