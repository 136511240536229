import { createSlice } from "@reduxjs/toolkit";
import { numberOfInsuranceBooking, insuranceBookings } from "./insuranceThunk";

const initialState = {
  insurancebookings: [],
  thenumberOfInsuranceBooking: null,
  insuranceLoader: false,
};

export const insuranceSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {},
  extraReducers: {
    [insuranceBookings.pending]: (state, action) => {
      state.insuranceLoader = true;
    },
    [insuranceBookings.fulfilled]: (state, action) => {
      state.insurancebookings = action.payload;
      state.insuranceLoader = false;
    },
    [insuranceBookings.rejected]: (state, action) => {
      state.insuranceLoader = false;
    },
    [numberOfInsuranceBooking.pending]: (state, action) => {
      state.insuranceLoader = true;
    },
    [numberOfInsuranceBooking.fulfilled]: (state, action) => {
      state.thenumberOfInsuranceBooking = action.payload;
      state.insuranceLoader = false;
    },
    [numberOfInsuranceBooking.rejected]: (state, action) => {
      state.insuranceLoader = false;
    },
  },
});

export default insuranceSlice.reducer;
