import React, { useEffect, useState } from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
// import Table from "../../components/table/Table";
import { Table, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import * as formats from "../../utils/FormatingFunctions";
import {
  getAllTransactions,
  getTotals,
  getTodaysIncome,
  getPastSixMonths,
} from "../../features/finance/financeThunk";
import { allUsers } from "../../features/user/userThunk";
import { formatMoney, formatDOLS } from "../../utils/FormatingFunctions";
const Home = () => {
  const dispatch = useDispatch();
  const { todaystotal, alltransactions, financeLoader, total, pastsixmonths } =
    useSelector((state) => state.finance);
  const { users, usersLoader } = useSelector((state) => state.user);

  const totalUsers = users?.localUsers?.length + users?.socialUsers?.length;
  // Get today's date
  const cash = todaystotal?.NGN?.income;
  var today = new Date();
  const { admin } = useSelector((state) => state.auth);

  // Extract year, month, and day as strings
  var year = today.getFullYear().toString();
  var month = (today.getMonth() + 1).toString().padStart(2, "0");
  var day = today.getDate().toString().padStart(2, "0");
  const flightroles = [1, 2, 5];
  const insuranceroles = [1, 2, 7];
  const financeroles = [1, 2, 3];
  const revenueroles = [1, 2, 3];
  const analyticsroles = [1, 2, 3];
  const staffroles = [1, 2];
  const userroles = [1, 2, 5, 3];
  const vaultroles = [1, 2, 3, 4, 5];
  useEffect(() => {
    dispatch(getAllTransactions());
    dispatch(getTotals());
  }, []);

  // Extract single-digit month and day

  useEffect(() => {
    if (financeroles.includes(admin?.admin_section_id)) {
      // dispatch(getAllTransactions());
      dispatch(allUsers());
      // dispatch(getAllVendors());
      dispatch(getPastSixMonths());
      dispatch(
        getTodaysIncome({
          year: year,
          month: month,
          day: day,
        })
      );
    }
  }, [dispatch, year, month, day]);

  const reversed = alltransactions?.payments
    ?.slice()
    ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    ?.slice(0, 4); // Get the latest 4 transactions

  const [idata, setIData] = useState(reversed);
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Booking ID",
      dataIndex: "tramangoId",
      key: "tramangoId",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Billing Status",
      dataIndex: "billing_status",
      key: "billing_status",
      render: (text, record) =>
        record.billing_status === true ? <a>PAID</a> : <a>pending</a>,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Transaction Reference",
      dataIndex: "paymentReference",
      key: "paymentReference",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <>
          {" "}
          <a>{record.currency === "NGN" && formatMoney(record.amount)}</a>
          <a>{record.currency === "USD" && formatDOLS(record.amount)}</a>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <a>{new Date(text).toDateString()}</a>,
    },
  ];
  // useEffect(() => {
  //   setIData(reversed);
  // }, [alltransactions]);
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget
            type="order"
            amount={alltransactions?.metadata?.totalPayments}
            isLoading={financeLoader}
          />
          {analyticsroles.includes(admin.admin_section_id) && (
            <>
              {" "}
              <Widget
                type="earning"
                amount={formats.formatMoney(total?.NGN?.income)}
                isLoading={financeLoader}
              />
              <Widget
                type="users"
                amount={totalUsers}
                isLoading={usersLoader}
              />
            </>
          )}
        </div>
        {analyticsroles.includes(admin.admin_section_id) && (
          <>
            <div className="charts">
              <Featured cash={cash} isLoading={financeLoader} />
              <Chart
                data={pastsixmonths}
                title="Last 6 Months (Revenue)"
                aspect={2 / 1}
              />
            </div>
            <div className="listContainer">
              <div className="listTitle">Latest Transactions</div>
              {financeLoader && !idata ? (
                <Skeleton active />
              ) : (
                <Table columns={columns} dataSource={reversed?.slice(0, 4)} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
