import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { message } from "antd";

import {
  getFinancialReport,
  getAllTransactions,
  getVendorWithdrawalRequest,
  changePaymentStatus,
  getTotals,
  getTodaysIncome,
  getAllFlightBookings,
  getAllInsuranceBookings,
  getPastSixMonths,
  getCurrencyRates,
  updateCurrencyRates,
  getCommission,
  updateCommission,
  confirmBankTransfer,
  updateServiceCharge,
  createVoucher,
  getVoucher,
} from "./financeThunk";

const initialState = {
  currencyrates: null,
  commissions: null,
  alltransactions: null,
  total: null,
  todaystotal: null,
  thecurrencyhistory: null,
  financialreport: null,
  financeLoader: false,
  requests: null,
  flightbookings: null,
  insurancebookings: null,
  pastsixmonths: null,
  voucher: null,
};

export const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {},
  extraReducers: {
    [updateCurrencyRates.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [updateCurrencyRates.fulfilled]: (state, action) => {
      state.financeLoader = false;
      message.success(action.payload.message);
    },
    [updateCurrencyRates.rejected]: (state, action) => {
      state.financeLoader = false;
      message.error(action.error.message);
    },
    [updateCommission.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [updateCommission.fulfilled]: (state, action) => {
      state.financeLoader = false;
      message.success(action.payload.message);
    },
    [updateCommission.rejected]: (state, action) => {
      state.financeLoader = false;
      message.error(action.error.message);
    },

    [updateServiceCharge.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [updateServiceCharge.fulfilled]: (state, action) => {
      state.financeLoader = false;
      message.success(action.payload.message);
    },
    [updateServiceCharge.rejected]: (state, action) => {
      state.financeLoader = false;
      message.error(action.error.message);
    },
    [confirmBankTransfer.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [confirmBankTransfer.fulfilled]: (state, action) => {
      state.financeLoader = false;
      message.success(action.payload.message);
    },
    [confirmBankTransfer.rejected]: (state, action) => {
      state.financeLoader = false;
      message.error(action.error.message);
    },
    [getCommission.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getCommission.fulfilled]: (state, action) => {
      state.commissions = action.payload;
      state.financeLoader = false;
    },
    [getCommission.rejected]: (state, action) => {
      state.financeLoader = false;
      message.error(action.error.message);
    },
    [getCurrencyRates.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getCurrencyRates.fulfilled]: (state, action) => {
      state.currencyrates = action.payload;
      state.financeLoader = false;
    },
    [getCurrencyRates.rejected]: (state, action) => {
      state.financeLoader = false;
      message.error(action.error.message);
    },
    [getPastSixMonths.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getPastSixMonths.fulfilled]: (state, action) => {
      state.pastsixmonths = action.payload;
      state.financeLoader = false;
    },
    [getPastSixMonths.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [getAllInsuranceBookings.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getAllInsuranceBookings.fulfilled]: (state, action) => {
      state.insurancebookings = action.payload;
      state.financeLoader = false;
    },
    [getAllInsuranceBookings.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [getAllFlightBookings.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getAllFlightBookings.fulfilled]: (state, action) => {
      state.flightbookings = action.payload;
      state.financeLoader = false;
    },
    [getAllFlightBookings.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [getTodaysIncome.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getTodaysIncome.fulfilled]: (state, action) => {
      state.todaystotal = action.payload;
      state.financeLoader = false;
    },
    [getTodaysIncome.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [getTotals.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getTotals.fulfilled]: (state, action) => {
      state.total = action.payload;
      state.financeLoader = false;
    },
    [getTotals.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [getAllTransactions.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getAllTransactions.fulfilled]: (state, action) => {
      state.alltransactions = action.payload;
      state.financeLoader = false;
    },
    [getAllTransactions.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [getFinancialReport.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getFinancialReport.fulfilled]: (state, action) => {
      state.financialreport = action.payload;
      state.financeLoader = false;
    },
    [getFinancialReport.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [getVendorWithdrawalRequest.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getVendorWithdrawalRequest.fulfilled]: (state, action) => {
      state.requests = action.payload;
      state.financeLoader = false;
    },
    [getVendorWithdrawalRequest.rejected]: (state, action) => {
      state.financeLoader = false;
    },
    [changePaymentStatus.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [changePaymentStatus.fulfilled]: (state, action) => {
      state.requests = action.payload;
      state.financeLoader = false;
      toast.success("Vendor Paid", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [changePaymentStatus.rejected]: (state, action) => {
      state.financeLoader = false;
      toast.error("Server error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [createVoucher.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [createVoucher.fulfilled]: (state, action) => {
      state.voucher = action.payload;
      state.financeLoader = false;
      message.success("Voucher created successfully");
    },
    [createVoucher.rejected]: (state, action) => {
      state.financeLoader = false;
      message.error(action.error.message);
    },
    [getVoucher.pending]: (state, action) => {
      state.financeLoader = true;
    },
    [getVoucher.fulfilled]: (state, action) => {
      state.voucher = action.payload;
      state.financeLoader = false;
    },
    [getVoucher.rejected]: (state, action) => {
      state.financeLoader = false;
    },
  },
});

export default financeSlice.reducer;
