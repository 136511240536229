import React, { useState, useEffect } from "react";
import "./users.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import StatisticWidget from "../../components/shared-components/StatisticWidget";
import { useSelector, useDispatch } from "react-redux";

import { Table, Button, Space, Tag, Skeleton } from "antd";
import { allUsers } from "../../features/user/userThunk";

const Users = () => {
  const dispatch = useDispatch();

  const { users, usersLoader } = useSelector((state) => state.user);

  const localUsers = users?.localUsers || [];
  const socialUsers = users?.socialUsers || [];
  const mergedUsers = [...localUsers, ...socialUsers];
  const [idata, setIData] = useState(mergedUsers);

  const searchTerm = (term) => {
    // const result = data.filter((item) => item.order_id === orderId);
    const result = mergedUsers.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(term.toLowerCase());
    });
    if (result) {
      setIData(result);
    } else {
      setIData(users);
    }

    console.log(result);
  };
  useEffect(() => {
    setIData(mergedUsers);
  }, [users]);
  useEffect(() => {
    dispatch(allUsers());
  }, [dispatch]);
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email Adress",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Role",
    //   dataIndex: "admin_section_id",
    //   key: "admin_section_id",
    //   render: (_, { admin_section_id }) => (
    //     <>
    //       {admin_section_id === 1 && <Tag color="blue">SUPER ADMIN</Tag>}
    //       {admin_section_id === 2 && <Tag color="blue">UTILITY ADMIN</Tag>}
    //       {admin_section_id === 3 && <Tag color="blue">FINANCE ADMIN</Tag>}
    //       {admin_section_id === 4 && <Tag color="blue">VISA ADMIN</Tag>}
    //       {admin_section_id === 5 && <Tag color="blue">FLIGHT ADMIN</Tag>}
    //       {admin_section_id === 6 && <Tag color="blue">STAYS ADMIN</Tag>}
    //       {admin_section_id === 7 && <Tag color="blue">INSURANCE ADMIN</Tag>}
    //     </>
    //   ),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "suspension_status",
    //   key: "suspension_status",
    //   render: (_, { suspension_status }) => (
    //     <>
    //       <Tag color="green">ACTIVE</Tag>
    //     </>
    //   ),
    // },

    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       {/* <a>Suspend {record.first_name}</a> */}
    //       <Button onClick={(e) => handleSuspend(e, record.id)}>
    //         {/* {record.suspension_status === 1 ? ( */}
    //         <>Suspend {record.first_name}</>
    //         {/* ) : ( */}
    //         {/* <>Un-suspend {record.first_name}</> */}
    //         {/* )} */}
    //       </Button>
    //       <Button onClick={(e) => handleDelete(e, record.id)}>Delete</Button>
    //     </Space>
    //   ),
    // },
  ];
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />

        <div className="widgets"></div>
        <div style={{ width: 250 }} className=" p-2">
          <StatisticWidget
            title="Total Number of Users"
            value={mergedUsers?.length}
            status={8.8}
            subtitle="Past 30 days"
            isLoading={usersLoader}
          />
        </div>
        <div className="charts"></div>
        <div className="listContainer">
          <div className="listTitle">Users</div>

          <input
            style={{ width: "70%", padding: "9px", marginBottom: "10px" }}
            type="text"
            placeholder="Search Users"
            onChange={(e) => searchTerm(e.target.value)}
          />
          {usersLoader ? (
            <Skeleton active />
          ) : (
            <Table columns={columns} dataSource={idata} />
          )}
        </div>
      </div>
    </div>
  );
};
export default Users;
