import * as api from "../../services/advert-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createAdvert = createAsyncThunk(
  "CREATE ADVERT",
  async (payload) => {
    const response = await api.createAd(payload);
    return response;
  }
);

export const todayAdvert = createAsyncThunk(
  "GET TODAY ADVERT",
  async (payload) => {
    const response = await api.getTodaysAds(payload);
    return response;
  }
);

export const allAdverts = createAsyncThunk(
  "GET ALL ADVERT",
  async (payload) => {
    const response = await api.getAllAds(payload);
    return response;
  }
);

export const updateAdvert = createAsyncThunk(
  "UPDATE ADVERT",
  async (payload) => {
    const response = await api.updateAds(payload);
    return response;
  }
);

export const deleteAdvert = createAsyncThunk(
  "DELETE ADVERT",
  async (payload) => {
    const response = await api.deleteAds(payload);
    return response;
  }
);
