import * as api from "../../services/experience-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllExperience = createAsyncThunk(
  "GET_ALL_EXPERIENCE",
  async (payload) => {
    const response = await api.getAllExperience();
    return response;
  }
);
export const getExperienceWithID = createAsyncThunk(
  "GET_EXPERIENCE_WITH_ID",
  async (payload) => {
    const response = await api.getExperienceWithID(payload);
    return response;
  }
);
export const verifyExperience = createAsyncThunk(
  "VERIFY_EXPERIENCE",
  async (payload) => {
    const response = await api.verifyExperience(payload);
    return response;
  }
);

export const createInformational = createAsyncThunk(
  "CREATE_INFORMATIONAL",
  async (payload) => {
    const response = await api.createInformational(payload);
    return response;
  }
);

export const getInformationalwithID = createAsyncThunk(
  "GET_INFORMATIONAL_WITH_ID",
  async (payload) => {
    const response = await api.getInformationalwithID(payload);
    return response;
  }
);

export const getAllInformational = createAsyncThunk(
  "GET_ALL_INFORMATIONAL",
  async (payload) => {
    const response = await api.getAllInformational(payload);
    return response;
  }
);

export const updateInformational = createAsyncThunk(
  "UPDATE_INFORMATIONAL",
  async (payload) => {
    const response = await api.updateInformational(payload);
    return response;
  }
);

export const deleteInformational = createAsyncThunk(
  "DELETE_INFORMATIONAL",
  async (payload) => {
    const response = await api.deleteInformational(payload);
    return response;
  }
);
