import React from "react";

import "./navbar.scss";
import { useSelector, useDispatch } from "react-redux";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const { admin } = useSelector((state) => state.auth);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          {/* <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon /> */}
        </div>
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}
          {/* <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div> */}
          {/* <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div> */}
          {/* <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div> */}
          {/* <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
            <p style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}>
              {admin?.lastName}
              {"  "}
              {admin?.firstName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

// import React from "react";
// import { Menu, Avatar } from "antd";
// import {
//   SearchOutlined,
//   LanguageOutlined,
//   DarkModeOutlined,
//   FullscreenExitOutlined,
//   NotificationsNoneOutlined,
//   ChatBubbleOutlineOutlined,
//   ListOutlined,
// } from "@mui/icons-material";
// import { useSelector, useDispatch } from "react-redux";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";

// const { SubMenu } = Menu;

// const Navbar = () => {
//   const { dispatch } = useContext(DarkModeContext);
//   const { admin } = useSelector((state) => state.auth);

//   return (
//     <Menu mode="horizontal">
//       <Menu.Item key="search">
//         <SearchOutlined />
//         Search
//       </Menu.Item>
//       <Menu.Item key="language">
//         <LanguageOutlined />
//         English
//       </Menu.Item>
//       <Menu.Item key="darkMode" onClick={() => dispatch({ type: "TOGGLE" })}>
//         <DarkModeOutlined />
//       </Menu.Item>
//       <Menu.Item key="fullscreen">
//         <FullscreenExitOutlined />
//       </Menu.Item>
//       <SubMenu
//         key="notifications"
//         title={
//           <>
//             <NotificationsNoneOutlined />
//             Notifications
//             <div className="counter">1</div>
//           </>
//         }
//       >
//         <Menu.Item key="notification1">Notification 1</Menu.Item>
//         <Menu.Item key="notification2">Notification 2</Menu.Item>
//       </SubMenu>
//       <SubMenu
//         key="messages"
//         title={
//           <>
//             <ChatBubbleOutlineOutlined />
//             Messages
//             <div className="counter">2</div>
//           </>
//         }
//       >
//         <Menu.Item key="message1">Message 1</Menu.Item>
//         <Menu.Item key="message2">Message 2</Menu.Item>
//       </SubMenu>
//       <Menu.Item key="list">
//         <ListOutlined />
//       </Menu.Item>
//       <Menu.Item key="profile" style={{ float: "right" }}>
//         <Avatar>{admin?.firstName[0]}</Avatar>
//         {admin?.lastName} {admin?.firstName}
//       </Menu.Item>
//     </Menu>
//   );
// };

// export default Navbar;
