import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LOGIN, ChangePassword } from "../../features/auth/authThunk";
import "./login.scss";
import Logo from "../../asset/tramango-logo.svg";
const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const { challenge } = useSelector((state) => state.auth);

  const data = {
    email,
    password,
  };
  const passwords = {
    newPasswordx: oldPassword,
    repeatedPassword: newPassword,
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(LOGIN(data));
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    dispatch(ChangePassword(passwords));
  };
  return (
    <div className="login">
      {challenge === null && (
        <form onSubmit={(e) => handleLogin(e)}>
          <img src={Logo} alt="logo" width="100px" />
          <div className="input">
            {/* <label>Email </label> */}
            <input
              type="text"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input">
            {/* <label>password </label> */}
            <input
              type="password"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button type="submit">Login</button>
        </form>
      )}
      {challenge?.newAdmin === true && (
        <form onSubmit={(e) => handlePasswordChange(e)}>
          <img src={Logo} alt="logo" width="100px" />
          <div className="input">
            {/* <label>Email </label> */}
            <input
              type="text"
              placeholder="Enter New Password"
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
          <div className="input">
            {/* <label>password </label> */}
            <input
              type="password"
              placeholder="Confirm New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>

          <button type="submit">Change Password</button>
        </form>
      )}
    </div>
  );
};

export default Login;
