import * as airports from "./airportNames";

export const formatFlightTime = (time) => {
  // formats PT28H25M to 28h25m
  let newFlightTime = time.split("T")[1];
  return newFlightTime.toLowerCase();
};

export const formatSelectedDate = (date) => {
  // formats 2022-08-26T05:25:00 to 2022-08-26
  let newSelectedDate = date.split("T")[0];
  return newSelectedDate;
};

export const formatDate = (date) => {
  // formats 2022-08-26T05:25:00 to 26/08/2022
  // formats 2022-08-26T05:25:00.000000Z to 2022/08/26
  let newDate = date.split("T")[0];
  newDate = newDate.split("-").reverse().join("/");
  return newDate;
};

// export const formatTime = (time) => {
//   // formats 2022-08-26T05:25:00 to 05:25
//   let newTime = time.split("T")[1].slice(0, -3);
//   return newTime;
// };

export const formatTime = (time) => {
  if (!time) {
    return ""; // or handle the case when time is undefined/null
  }
  // formats 2022-08-26T05:25:00 to 05:25
  let newTime = time.split("T")[1]?.slice(0, -3); // using optional chaining (?.)
  return newTime || ""; // return an empty string if newTime is undefined/null
};

export const formatMoney = (money) => {
  // formats price to naira, no kobo is added
  // eg. 1234.50 becomes NGN1234
  // eg. 5678.00 becomes NGN5678
  let newMoney = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  })
    .format(money)
    .slice(0, -3);
  return newMoney;
};

export const formatInsuranceMoney = (money) => {
  // formats price to naira and kobo
  // eg. 1234.50 becomes NGN1234.50
  // eg. 5678 becomes NGN5678.00
  let newMoney = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  }).format(money);
  return newMoney;
};

export const capitalizeFirstLetter = (string) => {
  // changes 'FIRST CLASS' to 'First class'  or 'economy' to 'Economy'
  let newString = string.toLowerCase();
  newString = newString[0].toUpperCase() + newString.slice(1);
  return newString;
};

export const formatFlightTicket = (ticket) => {
  // changes 'FIRST_CLASS' to 'first class'
  let newTicket = ticket.split("_").join(" ");
  return newTicket;
};

export const getNextDate = (d, index) => {
  let d8 = d;
  d8.setDate(d + index);
  return d8;
};

export const formatDOLS = (money) => {
  // formats price to naira, no kobo is added
  // eg. 1234.50 becomes NGN1234
  // eg. 5678.00 becomes NGN5678
  const newMoney = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(money)
    .slice(0, -3);
  return newMoney;
};

export const formatDateString = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const ordinalSuffixes = ["st", "nd", "rd", "th"];

  const day = inputDate.getUTCDate();
  const month = monthNames[inputDate.getUTCMonth()];
  const year = inputDate.getUTCFullYear();
  const hours = inputDate.getUTCHours();
  const minutes = inputDate.getUTCMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedTime = `${hours % 12}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  let daySuffix = "";
  if (day >= 1 && day <= 31) {
    if (day === 11 || day === 12 || day === 13) {
      daySuffix = "th";
    } else {
      daySuffix = ordinalSuffixes[(day - 1) % 10] || "th";
    }
  }

  const formattedDateString = `${day}${daySuffix} ${month}, ${year}`;

  return [formattedDateString, formattedTime];
};

export const compareDates = (departureDate, arrivalDate) => {
  const departure = new Date(departureDate);
  const arrival = new Date(arrivalDate);

  if (
    departure.getDate() === arrival.getDate() &&
    departure.getMonth() === arrival.getMonth() &&
    departure.getFullYear() === arrival.getFullYear()
  ) {
    return "Same day arrival";
  }
  return "Next day arrival";
};

export const formatDateInfo = (dateString) => {
  const options = { weekday: "short", month: "short", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

export const calculateTimeDifference = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const timeDifference = end - start;
  const hours = Math.floor(timeDifference / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((timeDifference % 3600000) / 60000); // 1 minute = 60000 milliseconds

  return { hours, minutes };
};

export const getAirportCity = (code) => {
  if (airports[code] === undefined) {
    return "";
  }
  return airports[code][0];
};

export const getAirportName = (code) => {
  if (airports[code] === undefined) {
    return "";
  }
  return airports[code][1];
};

export const formatFlightTimeNew = (time) => {
  if (time) {
    const match = time.match(/PT(\d+H)?(\d+M)?/); // Match the hours and minutes
    if (match) {
      const hours = match[1] ? parseInt(match[1]) : 0; // Extract hours, default to 0 if not present
      const minutes = match[2] ? parseInt(match[2]) : 0; // Extract minutes, default to 0 if not present
      return `${hours}h ${minutes}m`; // Format the time
    }
  }
  return null; // Return null if time format is invalid or not provided
};

// Define the array of genders
export const genders = [
  {
    GenderId: 1,
    Name: "Male",
  },
  {
    GenderId: 2,
    Name: "Female",
  },
];

// Function to get gender name by ID
export function getGenderNameById(genderId) {
  // Find the gender object with the matching ID
  const gender = genders.find((g) => g.GenderId === genderId);
  // Return the name if found, otherwise return null or a default value
  return gender ? gender.Name : null;
}

// Define the array of titles
export const titles = [
  {
    TitleId: 1,
    Name: "Mr.",
  },
  {
    TitleId: 2,
    Name: "Miss.",
  },
  {
    TitleId: 3,
    Name: "Mrs.",
  },
];

// Function to get title name by ID
export function getTitleNameById(titleId) {
  // Find the title object with the matching ID
  const title = titles.find((t) => t.TitleId === titleId);
  // Return the name if found, otherwise return null or a default value
  return title ? title.Name : null;
}

// Define the array of Nigerian states
export const nigerianStates = [
  {
    StateId: 1,
    Name: "Abia State",
  },
  {
    StateId: 2,
    Name: "Adamawa State",
  },
  {
    StateId: 3,
    Name: "Akwa Ibom State",
  },
  {
    StateId: 4,
    Name: "Anambra State",
  },
  {
    StateId: 5,
    Name: "Bauchi State",
  },
  {
    StateId: 6,
    Name: "Bayelsa State",
  },
  {
    StateId: 7,
    Name: "Benue State",
  },
  {
    StateId: 8,
    Name: "Borno State",
  },
  {
    StateId: 9,
    Name: "Cross River State",
  },
  {
    StateId: 10,
    Name: "Delta State",
  },
  {
    StateId: 11,
    Name: "Ebonyi State",
  },
  {
    StateId: 12,
    Name: "Edo State",
  },
  {
    StateId: 13,
    Name: "Ekiti State",
  },
  {
    StateId: 14,
    Name: "Enugu State",
  },
  {
    StateId: 15,
    Name: "FCT",
  },
  {
    StateId: 16,
    Name: "Gombe State",
  },
  {
    StateId: 17,
    Name: "Imo State",
  },
  {
    StateId: 18,
    Name: "Jigawa State",
  },
  {
    StateId: 19,
    Name: "Kaduna State",
  },
  {
    StateId: 20,
    Name: "Kano State",
  },
  {
    StateId: 21,
    Name: "Katsina State",
  },
  {
    StateId: 22,
    Name: "Kebbi State",
  },
  {
    StateId: 23,
    Name: "Kogi State",
  },
  {
    StateId: 24,
    Name: "Kwara State",
  },
  {
    StateId: 25,
    Name: "Lagos State",
  },
  {
    StateId: 26,
    Name: "Nasarawa State",
  },
  {
    StateId: 27,
    Name: "Niger State",
  },
  {
    StateId: 28,
    Name: "Ogun State",
  },
  {
    StateId: 29,
    Name: "Ondo State",
  },
  {
    StateId: 30,
    Name: "Osun State",
  },
  {
    StateId: 31,
    Name: "Oyo State",
  },
  {
    StateId: 32,
    Name: "Plateau State",
  },
  {
    StateId: 33,
    Name: "Rivers State",
  },
  {
    StateId: 34,
    Name: "Sokoto State",
  },
  {
    StateId: 35,
    Name: "Taraba State",
  },
  {
    StateId: 36,
    Name: "Yobe State",
  },
  {
    StateId: 37,
    Name: "Zamfara State",
  },
];

// Function to get state name by ID
export function getStateNameById(stateId) {
  // Find the state object with the matching ID
  const state = nigerianStates.find((s) => s.StateId === stateId);
  // Return the name if found, otherwise return null or a default value
  return state ? state.Name : null;
}

// Define the array of marital statuses
export const maritalStatus = [
  {
    MaritalStatusId: 1,
    Name: "Single",
  },
  {
    MaritalStatusId: 2,
    Name: "Married",
  },
  {
    MaritalStatusId: 3,
    Name: "Divorced",
  },
  {
    MaritalStatusId: 4,
    Name: "Widowed",
  },
  {
    MaritalStatusId: 5,
    Name: "Separated",
  },
];

// Function to get marital status name by ID
export function getMaritalStatusNameById(maritalStatusId) {
  // Find the marital status object with the matching ID
  const status = maritalStatus.find(
    (s) => s.MaritalStatusId === maritalStatusId
  );
  // Return the name if found, otherwise return null or a default value
  return status ? status.Name : null;
}
