import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const adminurl = "https://tramango-core.com/api/";
const API_URL = process.env.REACT_APP_BASEURL;

export const getAllBookings = (payload) => {
  const URL = `${API_URL}/admin/bookings`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const getAllFlightBookings = (payload) => {
  const URL = `${API_URL}/admin/bookings/flight`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getAllInsuranceBookings = (payload) => {
  const URL = `${API_URL}/admin/bookings/insurance`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getCurrencyRates = (payload) => {
  const URL = `${API_URL}/flight/rates`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateCurrencyRates = (payload) => {
  const URL = `${API_URL}/flight/rates`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const confirmBank = (payload) => {
  const URL = `${API_URL}/admin/confirmbank`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getCommission = (payload) => {
  const URL = `${API_URL}/flight/commission`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateServiceCharge = (payload) => {
  const URL = `${API_URL}/flight/service-charges`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateCommission = (payload) => {
  const URL = `${API_URL}/flight/commission`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getTodaysIncome = (payload) => {
  const { year, month, day } = payload;
  const URL = `${API_URL}/payments/total`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: { byDate: { year: year, month: month, day: day } },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getPastSixMonths = (payload) => {
  const URL = `${API_URL}/payments/sixmonth`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: { currency: "NGN" },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const confirmABooking = (payload) => {
  const { bookingId } = payload;
  const URL = `${API_URL}/bookings/${bookingId}/confirm`;
  return axios(URL, {
    method: "PATCH",
    data: payload,
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const cancelABooking = (payload) => {
  const { bookingId } = payload;
  const URL = `${API_URL}/bookings/${bookingId}/cancel`;
  return axios(URL, {
    method: "PATCH",
    data: payload,
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteABooking = (payload) => {
  const { bookingId } = payload;
  const URL = `${API_URL}/bookings/${bookingId}`;
  return axios(URL, {
    method: "DELETE",
    data: payload,
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const getABooking = (payload) => {
  const { bookingId } = payload;
  const URL = `${API_URL}/bookings/${bookingId}`;
  return axios(URL, {
    method: "GET",
    data: payload,
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const getAllPayments = (payload) => {
  const URL = `${API_URL}/payments/confirmed`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getTotalPayments = (payload) => {
  const URL = `${API_URL}/payments/total`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getAPayment = (payload) => {
  const { paymentId } = payload;
  const URL = `${API_URL}/payments/${paymentId}`;
  return axios(URL, {
    method: "GET",
    data: payload,
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data.data.payment)
    .catch((error) => {
      throw error;
    });
};

export const currencyHistory = (payload) => {
  const URL = `${adminurl}exchangeRateHistory`;

  return axios(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const updateCurrency = (payload) => {
  const URL = `${adminurl}currencyChangr`;

  return axios(URL, {
    method: "POST",
    payload: payload,
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const getMergedBookings = (payload) => {
  // const { id } = payload;
  const URL = `${adminurl}mergedBooking`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const getFinancialReport = (payload) => {
  const URL = `${adminurl}currentFinancialReport`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const getVendorWithdrawalRequest = (payload) => {
  const URL = `https://api.tramango.com/api/getPayReq?payStatus=all`;

  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data.reqPay)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const changePaymentStatus = (payload) => {
  const { status, id } = payload;
  const URL = `https://api.tramango.com/api/changePayStatus?payStatus=${status}&id=${id}`;

  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

/**
 * Fetch all vouchers (paginated)
 * @param {number} page - The page number to fetch
 * @returns {Promise} - Promise object represents the response from the API
 */
export const fetchVouchers = (page = 1) => {
  const URL = `${API_URL}/payment/vouchers`;
  return axios.get(URL, {
    params: { page },
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

/**
 * Create a new voucher
 * @param {Object} voucherData - The data for the new voucher
 * @returns {Promise} - Promise object represents the response from the API
 */
export const createVoucher = (voucherData) => {
  const URL = `${API_URL}/payment/vouchers`;
  return axios
    .post(URL, voucherData, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

/**
 * Get a specific voucher by code
 * @param {string} code - The code of the voucher to retrieve
 * @returns {Promise} - Promise object represents the response from the API
 */
export const fetchVoucherByCode = (code) => {
  const URL = `${API_URL}/payment/vouchers/${code}`;
  return axios.get(URL, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

/**
 * Update a voucher
 * @param {string} code - The code of the voucher to update
 * @param {Object} voucherData - The updated data for the voucher
 * @returns {Promise} - Promise object represents the response from the API
 */
export const updateVoucher = (code, voucherData) => {
  const URL = `${API_URL}/payment/vouchers/${code}`;
  return axios.patch(URL, voucherData, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

/**
 * Delete a voucher
 * @param {string} code - The code of the voucher to delete
 * @returns {Promise} - Promise object represents the response from the API
 */
