import React, { useState, useEffect } from "react";
import "./Analytics.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import StatisticWidget from "../../components/shared-components/StatisticWidget";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Button, Select } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;
const Analytics = () => {
  const [dates, setDates] = useState([]);
  const [singleDate, setSingleDate] = useState(null);
  const [category, setCategory] = useState(null);
  const handleDateChange = (dates) => {
    setDates(dates);
    setSingleDate(null); // Reset single date when a range is selected
  };

  const handleSingleDateChange = (date) => {
    setSingleDate(date);
    setDates([]); // Reset date range when a single date is selected
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleDownload = () => {
    if (dates.length === 0 && !singleDate) {
      alert("Please select a date or date range first.");
      return;
    }

    if (!category) {
      alert("Please select a data category first.");
      return;
    }

    let startDate, endDate;
    if (singleDate) {
      startDate = endDate = singleDate.format("YYYY-MM-DD");
    } else {
      startDate = dates[0].format("YYYY-MM-DD");
      endDate = dates[1].format("YYYY-MM-DD");
    }

    // Replace this with your download logic
    alert(`Downloading ${category} data from ${startDate} to ${endDate}`);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />

        <div className="widgets"></div>
        {/* <div style={{ width: 250 }} className=" p-2">
          <StatisticWidget
            title="Total Number of blah"
            value={20}
            status={8.8}
            subtitle="Past 30 days"
            isLoading={false}
          />
        </div> */}
        <div className="charts"></div>
        <div className="listContainer">
          <div className="listTitle">Tramango Booking Data</div>
          <Select
            onChange={handleCategoryChange}
            placeholder="Select a category"
          >
            <Option value="flight">Flight</Option>
            <Option value="insurance">Insurance</Option>
          </Select>

          <DatePicker onChange={handleSingleDateChange} />
          <RangePicker onChange={handleDateChange} />

          <Button onClick={handleDownload}>Download Data</Button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
