import * as api from "../../services/vendor-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

// export const addVendor = createAsyncThunk("CREATE_VENDOR", async (payload) => {
//   const response = await api.addVendor(payload);
//   return response;
// });

export const createVendor = createAsyncThunk("ADD_VENDOR", async (payload) => {
  const response = await api.createVendor(payload);
  return response;
});

export const getAllVendors = createAsyncThunk(
  "FETCH_ALL_VENDOR",
  async (payload) => {
    const response = await api.getAllVendors();
    return response;
  }
);

export const activateVendor = createAsyncThunk(
  "ACTIVATE_VENDOR",
  async (payload) => {
    const response = await api.activateVendor(payload);
    return response;
  }
);

export const suspendVendor = createAsyncThunk(
  "SUSPEND_VENDOR",
  async (payload) => {
    const response = await api.suspendVendor(payload);
    return response;
  }
);

export const deleteVendor = createAsyncThunk(
  "DELETE_VENDOR",
  async (payload) => {
    const response = await api.deleteVendor(payload);
    return response;
  }
);
