import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";

const adminurl = "https://tramango-core.com/api/";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const usersBasedOnRole = (payload) => {
  const URL = `${adminurl}userList?user_role=1`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const numberOfUsers = (payload) => {
  const URL = `${adminurl}userNumber`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const createVendor = (payload) => {
  const URL = `${adminurl}createVendor`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getAllVendors = (payload) => {
  const URL = `${adminurl}userList?user_role=3`;

  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const activateVendor = (payload) => {
  const URL = `${adminurl}activationAccount?id=${payload}`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const suspendVendor = (payload) => {
  const URL = `${adminurl}suspendAccount?id=${payload}`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteVendor = (payload) => {
  const URL = `${adminurl}deleteAccount?id=${payload}`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
