import * as api from "../../services/staff-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addAdmin = createAsyncThunk("CREATE_ADMIN", async (payload) => {
  const response = await api.addAdmin(payload);
  return response;
});

export const enableAdmin = createAsyncThunk("ENABLE_ADMIN", async (payload) => {
  const response = await api.enableAdmin(payload);
  return response;
});
export const disableAdmin = createAsyncThunk(
  "DISABLE_ADMIN",
  async (payload) => {
    const response = await api.disableAdmin(payload);
    return response;
  }
);
export const deleteAccount = createAsyncThunk(
  "DELETE_ACCOUNT",
  async (payload) => {
    const response = await api.deleteAccount(payload);
    return response;
  }
);

export const allStaff = createAsyncThunk("FETCH_STAFF", async (payload) => {
  const response = await api.getAllStaff();
  return response;
});
