import { createSlice } from "@reduxjs/toolkit";

import {
  getAllStays,
  getAStay,
  verifyStay,
  getStayBookings,
  getHotelsFinance,
  getStaysFinance,
} from "./staysThunk";
const initialState = {
  stays: [],
  stayLoader: false,
  staybookings: [],
  hotelbookings: [],
  staysfinance: null,
  hotelsfinance: null,
};
export const staysSlice = createSlice({
  name: "stays",
  initialState,
  reducers: {},
  extraReducers: {
    [getHotelsFinance.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getHotelsFinance.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getHotelsFinance.fulfilled]: (state, action) => {
      state.hotelsfinance = action.payload;
      state.stayLoader = false;
    },
    [getStaysFinance.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getStaysFinance.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getStaysFinance.fulfilled]: (state, action) => {
      state.staysfinance = action.payload;
      state.stayLoader = false;
    },
    [getStayBookings.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getStayBookings.fulfilled]: (state, action) => {
      state.staybookings = action.payload.data.stays;
      state.hotelbookings = action.payload.data.hotels;
      state.stayLoader = false;
    },
    [getStayBookings.rejected]: (state, action) => {
      state.stayLoader = false;
    },
    [getAStay.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getAllStays.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getAllStays.fulfilled]: (state, action) => {
      state.stays = action.payload;
      state.stayLoader = false;
    },
    [getAllStays.rejected]: (state, action) => {
      state.stayLoader = false;
    },
    [getAStay.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [getAStay.fulfilled]: (state, action) => {
      state.stayLoader = false;
      window.location.reload();
    },
    [getAStay.rejected]: (state, action) => {
      state.stayLoader = false;
    },
    [verifyStay.pending]: (state, action) => {
      state.stayLoader = true;
    },
    [verifyStay.fulfilled]: (state, action) => {
      state.stayLoader = false;
      window.location.reload();
    },
    [verifyStay.rejected]: (state, action) => {
      state.stayLoader = false;
    },
  },
});

export default staysSlice.reducer;
