import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../features/auth/authThunk";
import "./ChangePassword.scss";
import Logo from "../../asset/tramango-logo.svg";
const ChangePassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const data = {
    email,
    password,
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(LOGIN(data));
  };
  return (
    <div className="login">
      <form onSubmit={(e) => handleLogin(e)}>
        <img src={Logo} alt="logo" width="100px" />
        <div className="input">
          {/* <label>Email </label> */}
          <input
            type="text"
            placeholder="Enter New Password"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input">
          {/* <label>password </label> */}
          <input
            type="password"
            placeholder="Confirm New Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;
