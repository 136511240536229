import * as api from "../../services/stays-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllStays = createAsyncThunk(
  "GET_ALL_STAYS",
  async (payload) => {
    const response = await api.getAllStays();
    return response;
  }
);
export const getAStay = createAsyncThunk("GET_A_STAY", async (payload) => {
  const response = await api.getAStay();
  return response;
});
export const verifyStay = createAsyncThunk("VERIFY_A_STAY", async (payload) => {
  const response = await api.verifyStay(payload);
  return response;
});

export const getStayBookings = createAsyncThunk(
  "GET_STAY_BOOKINGS",
  async (payload) => {
    const response = await api.getMergedBookings();
    return response;
  }
);

export const getHotelsFinance = createAsyncThunk(
  "GET_HOTELS_FINANCE",
  async (payload) => {
    const response = await api.getHotelsFinance();
    return response;
  }
);
export const getStaysFinance = createAsyncThunk(
  "GET_STAYS_FINANCE",
  async (payload) => {
    const response = await api.getStaysFinance();
    return response;
  }
);
