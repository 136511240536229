import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

import {
  createAdvert,
  todayAdvert,
  allAdverts,
  updateAdvert,
  deleteAdvert,
} from "./advertThunk";

const initialState = {
  advertLoader: false,
  todayadvert: null,
  all: null,
};

export const advertSlice = createSlice({
  name: "advert",
  initialState,
  reducers: {},
  extraReducers: {
    [allAdverts.pending]: (state, action) => {
      state.advertLoader = true;
    },
    [allAdverts.fulfilled]: (state, action) => {
      state.advertLoader = false;
      state.all = action.payload;
      message.success(action.payload.message);
    },
    [allAdverts.rejected]: (state, action) => {
      state.advertLoader = false;
      message.error(action.error.message);
    },
    [deleteAdvert.pending]: (state, action) => {
      state.advertLoader = true;
    },
    [deleteAdvert.fulfilled]: (state, action) => {
      state.advertLoader = false;
      // state.all = action.payload;
      message.success(action.payload.message);
    },
    [deleteAdvert.rejected]: (state, action) => {
      state.advertLoader = false;
      message.error(action.error.message);
    },
    [updateAdvert.pending]: (state, action) => {
      state.advertLoader = true;
    },
    [updateAdvert.fulfilled]: (state, action) => {
      state.advertLoader = false;
      // state.all = action.payload;
      message.success(action.payload.message);
    },
    [updateAdvert.rejected]: (state, action) => {
      state.advertLoader = false;
      message.error(action.error.message);
    },
    [createAdvert.pending]: (state, action) => {
      state.advertLoader = true;
    },
    [createAdvert.fulfilled]: (state, action) => {
      state.advertLoader = false;
      message.success(action.payload.message);
    },
    [createAdvert.rejected]: (state, action) => {
      state.advertLoader = false;
      message.error(action.error.message);
    },

    [todayAdvert.pending]: (state, action) => {
      state.advertLoader = true;
    },
    [todayAdvert.fulfilled]: (state, action) => {
      state.advertLoader = false;
      state.todayadvert = action.payload;
    },
    [todayAdvert.rejected]: (state, action) => {
      state.advertLoader = false;
      message.error(action.error.message);
    },
  },
});

export default advertSlice.reducer;
