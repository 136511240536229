import React from "react";

const AdItem = ({ ad, today, onEdit, onDelete }) => {
  const adItemStyle = {
    border: "1px solid #ccc",
    padding: "10px",
    textAlign: "center",
  };

  const imageStyle = {
    maxWidth: "200px",
    height: "100px",
  };

  const linkStyle = {
    display: "block",
    marginTop: "10px",
    color: "#007bff",
    cursor: "pointer",
  };

  return (
    <div style={adItemStyle}>
      <img src={ad.image} alt={ad.name} style={imageStyle} />
      <h3>{ad.name}</h3>
      <p>{ad.organization}</p>
      {!today && <p>Visible in: {ad?.locations?.join(", ")}</p>}
      {!today && (
        <>
          <p>Live Date: {new Date(ad.liveDate).toLocaleDateString()}</p>
          <p>End Date: {new Date(ad.endDate).toLocaleDateString()}</p>
        </>
      )}
      {!today && (
        <>
          <a
            href={ad.link}
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            View Ad
          </a>
          <div style={linkStyle} onClick={() => onEdit()}>
            Edit
          </div>
          <div style={linkStyle} onClick={() => onDelete()}>
            Delete
          </div>
        </>
      )}
    </div>
  );
};

export default AdItem;
