import * as api from "../../services/insurance-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const insuranceBookings = createAsyncThunk(
  "FETCH_INSURANCE_BOOKINGS",
  async (payload) => {
    const response = await api.insuranceBookings();
    return response;
  }
);
export const numberOfInsuranceBooking = createAsyncThunk(
  "NUMBER_OF_INSURANCE_BOOKING",
  async (payload) => {
    const response = await api.numberOfInsuranceBooking();
    return response;
  }
);
