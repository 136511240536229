import React, { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const LocationPicker = () => {
  const [selectedLocations, setSelectedLocations] = useState([]); // State to hold the selected locations

  const locationOptions = [
    { code: "USA", name: "United States" },
    { code: "CAN", name: "Canada" },
    { code: "GBR", name: "United Kingdom" },
    // Add more locations as needed
  ];

  const handleLocationChange = (value) => {
    setSelectedLocations(value); // Update the selected locations
  };

  return (
    <Select
      mode="multiple" // Enable multiple selection
      value={selectedLocations}
      placeholder="Select locations"
      onChange={handleLocationChange}
    >
      {locationOptions.map((location) => (
        <Option key={location.code} value={location.code}>
          {location.name}
        </Option>
      ))}
    </Select>
  );
};

export default LocationPicker;
