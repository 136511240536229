import * as api from "../../services/finance-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTodaysIncome = createAsyncThunk(
  "GET_TODAYS_INCOME",
  async (payload) => {
    const response = await api.getTodaysIncome(payload);
    return response;
  }
);

export const getCurrencyRates = createAsyncThunk(
  "GET_CURRENCY_RATES",
  async (payload) => {
    const response = await api.getCurrencyRates(payload);
    return response;
  }
);
export const updateCurrencyRates = createAsyncThunk(
  "UPDATE_CURRENCY_RATES",
  async (payload) => {
    const response = await api.updateCurrencyRates(payload);
    return response;
  }
);

export const getCommission = createAsyncThunk(
  "GET_COMMISSION",
  async (payload) => {
    const response = await api.getCommission(payload);
    return response;
  }
);
export const updateCommission = createAsyncThunk(
  "UPDATE_COMMISSION",
  async (payload) => {
    const response = await api.updateCommission(payload);
    return response;
  }
);

export const updateServiceCharge = createAsyncThunk(
  "UPDATE_SERVICE_CHARGE",
  async (payload) => {
    const response = await api.updateServiceCharge(payload);
    return response;
  }
);

export const confirmBankTransfer = createAsyncThunk(
  "CONFIRM_BANK_TRANSFER",
  async (payload) => {
    const response = await api.confirmBank(payload);
    return response;
  }
);

export const getPastSixMonths = createAsyncThunk(
  "GET_PAST_SIX_MONTHS",
  async (payload) => {
    const response = await api.getPastSixMonths(payload);
    return response;
  }
);

export const getFinancialReport = createAsyncThunk(
  "GET_FINANCIAL_REPORT",
  async (payload) => {
    const response = await api.getFinancialReport();
    return response;
  }
);
export const getAllTransactions = createAsyncThunk(
  "GET_ALL_TRANSACTIONS",
  async (payload) => {
    const response = await api.getAllPayments();
    return response;
  }
);
export const getTotals = createAsyncThunk("GET_TOTALS", async (payload) => {
  const response = await api.getTotalPayments();
  return response;
});

export const getVendorWithdrawalRequest = createAsyncThunk(
  "GET_WITHDRAWAL_REQUEST",
  async (payload) => {
    const response = await api.getVendorWithdrawalRequest();
    return response;
  }
);

export const changePaymentStatus = createAsyncThunk(
  "CHANGE_PAYMENT_STATUS",
  async (payload) => {
    const response = await api.changePaymentStatus(payload);
    return response;
  }
);

export const getAllFlightBookings = createAsyncThunk(
  "GET_ALL_FLIGHT_BOOKINGS",
  async (payload) => {
    const response = await api.getAllFlightBookings(payload);
    return response;
  }
);

export const getAllInsuranceBookings = createAsyncThunk(
  "GET_ALL_INSURANCE_BOOKINGS",
  async (payload) => {
    const response = await api.getAllInsuranceBookings(payload);
    return response;
  }
);

export const getVoucher = createAsyncThunk("GET_VOUCHER", async (payload) => {
  const response = await api.fetchVoucherByCode(payload);
  return response;
});

export const createVoucher = createAsyncThunk(
  "CREATE_VOUCHER",
  async (payload) => {
    const response = await api.createVoucher(payload);
    return response;
  }
);
