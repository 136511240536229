import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { getToken } from "./auth-helpers";

const PrivateRoute = ({ children, roles }) => {
  const { admin, authLoader } = useSelector((state) => state.auth);
  const adminType = admin?.adminType;

  // useEffect(() => {
  //   setIsAuthenticated(adminType);
  // }, [adminType]);

  if (authLoader) {
    return "xx0xxå";
  }

  return adminType ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
