import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";

const adminurl = "https://tramango-core.com/api/";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
export const numberOfInsuranceBooking = (payload) => {
  const URL = `${adminurl}insuranceNumber`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const insuranceBookings = (payload) => {
  const URL = `${adminurl}insuranceBookings`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
