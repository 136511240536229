import "./Insurance.scss";
import React, { useEffect, useState } from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import * as formats from "../../utils/FormatingFunctions";
import { countries } from "../../utils/countries";

import StatisticWidget from "../../components/shared-components/StatisticWidget";
import { Table, Skeleton, Button, Space, Modal } from "antd";
import { getAllInsuranceBookings } from "../../features/finance/financeThunk";

import qs from "qs";
const API_URL = process.env.REACT_APP_BASEURL;
const Insurance = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [insuranceData, setInsuranceData] = useState();
  const { insurancebookings, financeLoader } = useSelector(
    (state) => state.finance
  );
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
    },
  });
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim()); // Trim leading/trailing spaces
    if (!value) {
      // Check if search term is empty
      fetchData(); // Call fetchData to fetch initial data
    } else {
      searchData(value); // Call searchData for filtering
    }
  };
  const getParams = (params) => ({
    page: params.pagination.page,
  });

  const searchData = (value) => {
    setLoading(true);
    fetch(`${API_URL}/admin/bookings/search?type=INSURANCE`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      credentials: "include",
      body: JSON.stringify({ searchText: value }), // Include the search term in the body
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalBookings,
            pageSize: 20,
          },
        });
      })
      .catch((error) => {
        console.error("Error while searching:", error);
        setLoading(false); // Make sure to stop loading state in case of an error
      });
  };

  const fetchData = () => {
    setLoading(true);
    fetch(
      `${API_URL}/admin/bookings/insurance?${qs.stringify(
        getParams(tableParams)
      )}`,
      {
        headers: {
          // Add your headers here
          // Example of adding an authorization header
          "Content-Type": "application/json", // Example of setting Content-Type header
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalBookings,
            pageSize: 20,
          },
        });
      });
  };
  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams.pagination?.page,
  ]);
  const handleTableChange = (pagination, filters, sorter) => {
    // Update pagination parameters correctly
    console.log(pagination);
    const newTableParams = {
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: data.metadata.totalBookings,
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
    };
    setTableParams(newTableParams);
    console.log(tableParams);
  };
  useEffect(() => {
    setIData(insurancebookings?.bookings);
  }, [insurancebookings]);
  useEffect(() => {
    dispatch(getAllInsuranceBookings());
  }, [dispatch]);

  const [idata, setIData] = useState(insurancebookings?.bookings);

  function getCountryName(countryId) {
    const country = countries.find(
      (country) => country.CountryId === countryId
    );
    return country ? country.Name : "Country Not Found";
  }
  // const searchTerm = (term) => {
  //   // const result = data.filter((item) => item.order_id === orderId);
  //   const result = insurancebookings.filter((item) => {
  //     return Object.values(item)
  //       .join("")
  //       .toLowerCase()
  //       .includes(term.toLowerCase());
  //   });
  //   if (result) {
  //     setIData(result);
  //   } else {
  //     setIData(insurancebookings);
  //   }
  //   console.log(result);
  // };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Boooking ID",
      dataIndex: "contract",
      key: "contract",
    },
    {
      title: "Country",
      dataIndex: ["booking_reference", "quote", "CountryId"],
      key: "CountryId",
      render: (text) => <a>{getCountryName(text)}</a>,
    },
    {
      title: "No of Travellers",
      dataIndex: ["booking_reference", "quote", "NoOfPeople"],
      key: "NoOfPeople",
      width: 2,
    },
    {
      title: "Cover begins",
      dataIndex: ["booking_reference", "quote", "CoverBegins"],
      key: "CoverBegins",
      render: (text) => <a>{new Date(text).toDateString()}</a>,
    },
    {
      title: "Cover Ends",
      dataIndex: ["booking_reference", "quote", "CoverEnds"],
      key: "CoverEnds",
      render: (text) => <a>{new Date(text).toDateString()}</a>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <a>{formats.formatMoney(text)}</a>,
    },
    {
      title: "Tramango ID",
      dataIndex: "tramangoId",
      key: "tramangoid",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              showModal();
              setInsuranceData(record);
            }}
          >
            View details
          </Button>

          {/* <Button
            onClick={() => {
              handleCancelTicket(record.booking_reference.data.id);
            }}
          >
            Cancel Order
          </Button> */}
        </Space>
      ),
    },
  ];

  console.log(insuranceData);
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />

        <Modal
          title="INSURANCE BOOKING DETAILS"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          <h3>User Data</h3>
          <div>
            <strong> Name: </strong>
            {insuranceData?.booking_data?.Surname}{" "}
            {insuranceData?.booking_data?.MiddleName}{" "}
            {insuranceData?.booking_data?.FirstName}
          </div>
          <div>
            <strong> Gender: </strong>
            {formats.getGenderNameById(
              insuranceData?.booking_data?.GenderId
            )}{" "}
            (GenderId: {insuranceData?.booking_data?.GenderId})
          </div>

          <div>
            <strong> Title: </strong>
            {formats.getTitleNameById(
              insuranceData?.booking_data?.TitleId
            )}{" "}
            (TitleId: {insuranceData?.booking_data?.TitleId})
          </div>
          <div>
            <strong> Date of Birth: </strong>

            {insuranceData?.booking_data?.DateOfBirth}
          </div>
          <div>
            <strong> Email: </strong>

            {insuranceData?.booking_data?.Email}
          </div>
          <div>
            <strong> Telephone: </strong>

            {insuranceData?.booking_data?.Telephone}
          </div>
          <div>
            <strong> Address: </strong>

            {insuranceData?.booking_data?.Address}
          </div>
          <div>
            <strong> Zip Code: </strong>

            {insuranceData?.booking_data?.ZipCode}
          </div>

          <div>
            <strong> State: </strong>
            {formats.getStateNameById(
              insuranceData?.booking_data?.StateId
            )}{" "}
            (StateId: {insuranceData?.booking_data?.StateId})
          </div>

          <div>
            <strong> Nationality: </strong>

            {insuranceData?.booking_data?.Nationality}
          </div>
          <div>
            <strong> Passport Number: </strong>

            {insuranceData?.booking_data?.PassportNo}
          </div>
          <div>
            <strong> Occupation: </strong>

            {insuranceData?.booking_data?.Occupation}
          </div>
          <div>
            <strong> Marital Status: </strong>{" "}
            {formats.getMaritalStatusNameById(
              insuranceData?.booking_data?.MaritalStatusId
            )}{" "}
            (MaritalStatusId: {insuranceData?.booking_data?.MaritalStatusId})
          </div>
          <div>
            <strong> Pre-existing Medical Condition: </strong>

            {insuranceData?.booking_data?.PreExistingMedicalCondition}
          </div>
          <div>
            <strong> Medical Condition Details: </strong>

            {insuranceData?.booking_data?.MedicalCondition
              ? insuranceData?.booking_data?.MedicalCondition
              : "Null"}
          </div>
          <div>
            <strong> Type: </strong>

            {insuranceData?.booking_data?.type}
          </div>
          <div>
            <strong> Next of Kin Full Name: </strong>

            {insuranceData?.booking_data?.NextOfKin?.FullName}
          </div>
          <div>
            <strong> Next of Kin Address: </strong>

            {insuranceData?.booking_data?.NextOfKin?.Address}
          </div>
          <div>
            <strong> Next of Kin Relationship: </strong>

            {insuranceData?.booking_data?.NextOfKin?.Relationship}
          </div>
          <div>
            <strong> Next of Kin Telephone: </strong>

            {insuranceData?.booking_data?.NextOfKin?.Telephone}
          </div>
        </Modal>

        <div className="widgets">
          <div style={{ width: 390 }} className=" p-2">
            <StatisticWidget
              title="Total Insurance Bookings"
              value={`${data?.metadata?.totalBookings}`}
              status={8.8}
              subtitle="Past 30 days"
              isLoading={financeLoader}
            />
          </div>
        </div>
        <div className="charts">
          {/* <Featured />
            <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} /> */}
        </div>
        <div className="listContainer">
          <div className="listTitle">Insurance bookings</div>
          <input
            style={{ width: "70%", padding: "9px", marginBottom: "10px" }}
            type="text"
            value={searchTerm}
            placeholder="Search for Insurance Bookings"
            onChange={(e) => {
              handleSearch(e);
            }}
          />

          <Table
            columns={columns}
            dataSource={data?.bookings}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Insurance;
