import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const adminurl = "https://tramango-core.com/api/";
const API_URL = process.env.REACT_APP_BASEURL;

export const addAdmin = (payload) => {
  const { email, firstName, lastName, adminType } = payload;
  const URL = `${API_URL}/admin/create`;

  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const enableAdmin = (payload) => {
  // const { email, disable } = payload;
  const URL = `${API_URL}/admin/update`;

  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const disableAdmin = (payload) => {
  // const { email, disable } = payload;
  const URL = `${API_URL}/admin/update`;

  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteAccount = (payload) => {
  const URL = `${API_URL}/admin/${payload}`;
  return axios(URL, {
    method: "DELETE",
    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const getAllStaff = (payload) => {
  const URL = `${adminurl}userList?user_role=2`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
