import axios from "axios";
import { setAuthHeaders } from "../utils/setAuthHeaders";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const API_URL = "https://tramango-core.com/api/v1/";
const API_AUTH_URL = "https://tramango-core.com/api/";

export const verifyStay = (payload) => {
  const { id } = payload;
  const URL = `${API_AUTH_URL}staysVerify?id=${id}&verify=verified`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const getAllStays = (payload) => {
  const URL = `${API_AUTH_URL}staysList?status=all`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const getAStay = (payload) => {
  const URL = `${API_AUTH_URL}staysView?id=${payload}`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const respondStay = (payload) => {
  const { id, respond, accomodation } = payload;
  const URL = `${API_URL}respondStays?id=${id}&respond=${respond}&availability=available&accommodation_id=${accomodation}`;
  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const getMergedBookings = (payload) => {
  const URL = `${API_AUTH_URL}staysBookings`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const getHotelsFinance = (payload) => {
  const URL = `${API_AUTH_URL}hotelCurrentFinance?type=amadeus&baseCode=NGN`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const getStaysFinance = (payload) => {
  const URL = `${API_AUTH_URL}vendorCurrentFinance?type=stays&baseCode=NGN`;

  return axios(URL, {
    method: "GET",

    headers: setAuthHeaders(defaultHeader),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
