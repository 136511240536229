import React, { useEffect, useState } from "react";
import StatisticWidget from "../../components/shared-components/StatisticWidget";
import {
  Select,
  Table,
  Skeleton,
  Button,
  Modal,
  Form,
  InputNumber,
  message,
} from "antd";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import ExchangeRates from "./ExchangeRates";
import * as formats from "../../utils/FormatingFunctions";
import {
  getCommission,
  updateCommission,
  getCurrencyRates,
  updateCurrencyRates,
  updateServiceCharge,
} from "../../features/finance/financeThunk";
import "./Revenue.scss";
import { CurrencyDetails } from "./CurrencyDetails";
const Finance = () => {
  const { financeLoader, commissions, currencyrates } = useSelector(
    (state) => state.finance
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);

  const rates = currencyrates;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommission());
    dispatch(getCurrencyRates());
  }, [dispatch]);

  // const [idata, setIData] = useState(alltransactions?.payments);
  const [view, setView] = useState("NGN");

  const handleChange = (value) => {
    setView(value);
  };
  const onFinish = async (values) => {
    try {
      const data = {
        currency: view,
        officialRate: values.officialRate,
        blackmarketRate: values.blackmarketRate,
        to: values.to || "NGN",
        iataRate: values.iataRate,
      };
      console.log(data);
      dispatch(updateCurrencyRates(data));
    } catch (error) {
      message.error("Failed to update currency rates");
    }
  };

  const onDiscountFinish = async (values) => {
    try {
      const data = {
        commissionDiscounts: {
          [values.airline]: {
            originalPercentage: values.originalPercentage,
            discountedPercentage: values.discountedPercentage,
          },
        },
      };
      dispatch(updateCommission(data));
    } catch (error) {
      message.error("Failed to Add Discount to Airline");
    }
  };

  const onServiceFinish = async (values) => {
    try {
      const data = {
        VSC: values.VSC,
        TSC: values.TSC,
      };
      dispatch(updateServiceCharge(data));
    } catch (error) {
      message.error("Failed to Add Discount to Airline");
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDiscountOk = () => {
    setIsDiscountModalOpen(false);
  };

  const handleDiscountCancel = () => {
    setIsDiscountModalOpen(false);
  };

  const handleServiceOk = () => {
    setIsServiceModalOpen(false);
  };

  const handleServiceCancel = () => {
    setIsServiceModalOpen(false);
  };

  return (
    <div className="home">
      <Modal
        title={`Update ${view} rate`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Official Rate"
            name="officialRate"
            rules={[
              { required: true, message: "Please enter the official rate" },
              { type: "number", message: "Official rate should be a number" },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="IATA Rate"
            name="iataRate"
            rules={[
              { required: true, message: "Please enter the iata rate" },
              { type: "number", message: "iata rate should be a number" },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Black Market Rate"
            name="blackmarketRate"
            rules={[
              { required: true, message: "Please enter the black market rate" },
              {
                type: "number",
                message: "Black market rate should be a number",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Target Currency"
            name="to"
            // rules={[
            //   { required: true, message: "Please enter the black market rate" },
            //   {
            //     type: "number",
            //     message: "Black market rate should be a number",
            //   },
            // ]}
          >
            <Select
              defaultValue="NGN"
              style={{
                width: 230,
              }}
              // onChange={handleChange}
              options={[
                {
                  value: "NGN",
                  label: "Nigerian Naira",
                },
                {
                  value: "GHS",
                  label: "Ghanaian Cedi",
                },
                {
                  value: "USD",
                  label: "United States Dollar",
                },
                {
                  value: "XOF",
                  label: "The West African CFA franc",
                },
                {
                  value: "XAF",
                  label: "Central African CFA Franc",
                },
              ]}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Rates
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`Add Discount to Airline`}
        open={isDiscountModalOpen}
        onOk={handleDiscountOk}
        onCancel={handleDiscountCancel}
      >
        <Form layout="vertical" onFinish={onDiscountFinish}>
          <Form.Item
            label="Airline"
            name="airline"
            // rules={[
            //   { required: true, message: "Please enter the black market rate" },
            //   {
            //     type: "number",
            //     message: "Black market rate should be a number",
            //   },
            // ]}
          >
            <Select
              defaultValue="ET"
              style={{
                width: 230,
              }}
              // onChange={handleChange}
              options={[
                { value: "ET", label: "Ethiopian Airlines" },
                { value: "KL", label: "KLM Royal Dutch Airlines" },
                { value: "SA", label: "South African Airways" },
                { value: "AT", label: "Royal Air MAROC" },
                { value: "QR", label: "Qatar Airways" },
                { value: "LH", label: "Lufthansa" },
                { value: "WB", label: "RwandAir" },
                { value: "TK", label: "Turkish Airlines" },
                { value: "HF", label: "AIR COTE D’IVOIRE" },
                { value: "AW", label: "Africa World Airlines" },
                { value: "EY", label: "Etihad Airways" },
                { value: "KQ", label: "Kenya Airways" },
                { value: "EK", label: "Emirates" },
                { value: "VS", label: "Virgin Atlantic" },
                { value: "KP", label: "ASKY Airlines" },
                { value: "BA", label: "British Airways" },
                { value: "SW", label: "Air Namibia" },
                { value: "DL", label: "Delta Air Lines" },
                { value: "MS", label: "EgyptAir" },
                { value: "P4", label: "Air Peace" },
                { value: "W3", label: "Arik Air" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Original Percentage"
            name="originalPercentage"
            rules={[
              {
                required: true,
                message: "Please enter the Original Percentage",
              },
              {
                type: "number",
                message: "Original Percentage should be a number",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Discounted Percentage"
            name="discountedPercentage"
            rules={[
              {
                required: true,
                message: "Please enter the Discounted Percentage",
              },
              {
                type: "number",
                message: "Discounted Percentage should be a number",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Discount
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`Update Service Charge`}
        open={isServiceModalOpen}
        onOk={handleServiceOk}
        onCancel={handleServiceCancel}
      >
        <Form layout="vertical" onFinish={onServiceFinish}>
          <p>Current VSC: {rates?.rates?.["USD"].flex.VSC}</p>
          <p>Current TSC: {rates?.rates?.["USD"].flex.TSC}</p>
          <Form.Item
            label="VSC"
            name="VSC"
            rules={[
              {
                required: true,
                message: "Please enter the VSC",
              },
              {
                type: "number",
                message: "VSC should be a number",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="TSC"
            name="TSC"
            rules={[
              {
                required: true,
                message: "Please enter the TSC",
              },
              {
                type: "number",
                message: "TSC should be a number",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Service Charge
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="listContainer">
          <section style={{ marginBottom: "40px" }}>
            <Select
              defaultValue="NGN"
              style={{
                width: 230,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "NGN",
                  label: "Nigerian Naira",
                },
                {
                  value: "GBP",
                  label: "British Pound",
                },
                {
                  value: "USD",
                  label: "United States Dollar",
                },
              ]}
            />

            <Button onClick={() => setIsModalOpen(!isModalOpen)}>
              Update Rate
            </Button>
          </section>
          {view === "USD" && (
            <section style={{ marginBottom: "40px" }}>
              <Button
                onClick={() => setIsServiceModalOpen(!isServiceModalOpen)}
              >
                Update Service Charge
              </Button>
            </section>
          )}
          <section style={{ marginBottom: "40px" }}>
            <Button
              onClick={() => setIsDiscountModalOpen(!isDiscountModalOpen)}
            >
              Discount An Airline
            </Button>
          </section>
        </div>

        <div className="charts"></div>
        <div className="listContainer">
          {financeLoader ? (
            <Skeleton active />
          ) : (
            <>
              {rates ? (
                <div>
                  {view === "NGN" && (
                    <CurrencyDetails currency={rates.rates["NGN"]} />
                  )}
                  {view === "USD" && (
                    <CurrencyDetails currency={rates.rates["USD"]} />
                  )}
                  {view === "GBP" && (
                    <CurrencyDetails currency={rates.rates["GBP"]} />
                  )}
                </div>
              ) : (
                <div>No Data to display</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Finance;
