import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import Flights from "./pages/flight/Flights";
import Insurance from "./pages/insurance/Insurance";
import Finance from "./pages/finance/Finance";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { userInputs } from "./formSource";
import Staff from "./pages/staff/Staff";
import PrivateRoute from "./utils/PrivateRoutes";
import PostAuthRoute from "./utils/PostAuthRoutes";
import Users from "./pages/users/Users";
import Vault from "./pages/vault/Vault";
import Analytics from "./pages/analytics/Analytics";
import TwoFactor from "./pages/twofactor/TwoFactor";
import Revenue from "./pages/revenue/Revenue";
import ChangePassword from "./pages/change-password/ChangePassword";
import Discounts from "./pages/discount/Discount";

import Advert from "./pages/advert/Advert";
function Routers() {
  const { challenge } = useSelector((state) => state.auth);
  return (
    <Router>
      <Routes>
        {/* <Route path="/"> */}

        <Route path="change-password" element={<PostAuthRoute />}>
          <Route index element={<ChangePassword />} />
        </Route>

        <Route path="login" element={<PostAuthRoute />}>
          <Route index element={<Login />} />
        </Route>

        <Route
          path="/"
          element={<PrivateRoute roles={[1, 2, 3, 4, 5, 6, 7]} />}
        >
          <Route index element={<Home />} />
        </Route>

        <Route path="users" element={<PrivateRoute roles={[1, 2]} />}>
          <Route index element={<List />} />
          <Route path=":userId" element={<Single />} />
          <Route
            path="new"
            element={<New inputs={userInputs} title="Add New User" />}
          />
        </Route>
        <Route
          path="tramango-users"
          element={<PrivateRoute roles={[1, 2, 6]} />}
        >
          <Route index element={<Users />} />
          {/* <Route path=":staysId" element={<Stay />} /> */}
        </Route>
        <Route path="staff" element={<PrivateRoute roles={[1, 2]} />}>
          <Route index element={<Staff />} />
        </Route>

        <Route path="flights" element={<PrivateRoute roles={[1, 2, 5]} />}>
          <Route index element={<Flights />} />
          {/* <Route path=":staysId" element={<Stay />} /> */}
        </Route>
        <Route path="insurance" element={<PrivateRoute roles={[1, 2, 7]} />}>
          <Route index element={<Insurance />} />
          {/* <Route path=":staysId" element={<Stay />} /> */}
        </Route>
        <Route path="advert" element={<PrivateRoute roles={[1, 2, 6]} />}>
          <Route index element={<Advert />} />
          {/* <Route path=":staysId" element={<Stay />} /> */}
        </Route>
        <Route path="finance" element={<PrivateRoute roles={[1, 2, 3]} />}>
          <Route index element={<Finance />} />
          {/* <Route path=":staysId" element={<Stay />} /> */}
        </Route>
        <Route path="revenue" element={<PrivateRoute roles={[1, 2, 3]} />}>
          <Route index element={<Revenue />} />
          {/* <Route path=":staysId" element={<Stay />} /> */}
        </Route>
        <Route path="discount" element={<PrivateRoute roles={[1, 2, 3]} />}>
          <Route index element={<Discounts />} />
        </Route>
        <Route path="vault" element={<PrivateRoute roles={[1, 2, 3]} />}>
          <Route index element={<Vault />} />
        </Route>
        <Route path="twofactor" element={<PrivateRoute roles={[1, 2, 3]} />}>
          <Route index element={<TwoFactor />} />
        </Route>
        <Route path="analytics" element={<PrivateRoute roles={[1, 2, 6]} />}>
          <Route index element={<Analytics />} />
        </Route>

        {/* </Route> */}
      </Routes>
    </Router>
  );
}
export default Routers;
