import React, { useEffect, useState } from "react";
import StatisticWidget from "../../components/shared-components/StatisticWidget";
import {
  Select,
  Table,
  Skeleton,
  Button,
  Modal,
  Form,
  InputNumber,
  Input,
  message,
} from "antd";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import * as formats from "../../utils/FormatingFunctions";
import {
  getAllTransactions,
  getTotals,
  getCurrencyRates,
  confirmBankTransfer,
} from "../../features/finance/financeThunk";
import { formatMoney, formatDOLS } from "../../utils/FormatingFunctions";

import "./Finance.scss";
import qs from "qs";
const API_URL = process.env.REACT_APP_BASEURL;
const Finance = () => {
  const { total, financeLoader, alltransactions, currencyrates } = useSelector(
    (state) => state.finance
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
    },
  });
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim()); // Trim leading/trailing spaces
    if (!value) {
      // Check if search term is empty
      fetchData(); // Call fetchData to fetch initial data
    } else {
      searchData(value); // Call searchData for filtering
    }
  };
  const getParams = (params) => ({
    page: params.pagination.page,
  });

  const searchData = (value) => {
    setLoading(true);
    fetch(`${API_URL}/payments/confirmed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      credentials: "include",
      body: JSON.stringify({ searchText: value }), // Include the search term in the body
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalPayments,
            pageSize: 20,
          },
        });
      })
      .catch((error) => {
        console.error("Error while searching:", error);
        setLoading(false); // Make sure to stop loading state in case of an error
      });
  };

  const fetchData = () => {
    setLoading(true);
    fetch(
      `${API_URL}/payments/confirmed?${qs.stringify(getParams(tableParams))}`,
      {
        headers: {
          // Add your headers here
          // Example of adding an authorization header
          "Content-Type": "application/json", // Example of setting Content-Type header
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalPayments,
            pageSize: 20,
          },
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams.pagination?.page,
  ]);
  const handleTableChange = (pagination, filters, sorter) => {
    // Update pagination parameters correctly
    console.log(pagination);
    const newTableParams = {
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: data.metadata.totalBookings,
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
    };
    setTableParams(newTableParams);
    console.log(tableParams);
  };
  const rates = currencyrates?.rates;
  const dispatch = useDispatch();
  useEffect(() => {
    setIData(alltransactions?.payments);
  }, [alltransactions]);

  useEffect(() => {
    dispatch(getTotals());
    dispatch(getAllTransactions());
    dispatch(getCurrencyRates());
  }, [dispatch]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [idata, setIData] = useState(alltransactions?.payments);
  const [view, setView] = useState("NGN");

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Booking ID",
      dataIndex: "tramangoId",
      key: "tramangoId",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Billing Status",
      dataIndex: "billing_status",
      key: "billing_status",
      render: (text, record) =>
        record.billing_status === true ? <a>PAID</a> : <a>pending</a>,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Transaction Reference",
      dataIndex: "paymentReference",
      key: "paymentReference",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <>
          {" "}
          <a>{record.currency === "NGN" && formatMoney(record.amount)}</a>
          <a>{record.currency === "USD" && formatDOLS(record.amount)}</a>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <a>{new Date(text).toDateString()}</a>,
    },
  ];
  const currencies = ["NGN", "XOF", "USD", "XAF", "GHS"];
  const handleChange = (value) => {
    setView(value);
  };
  const onFinish = async (values) => {
    try {
      const data = {
        paymentId: values.paymentId,
        tramangoId: values.tramangoId,
        amount: values.amount,
        currency: values.currency,
      };
      dispatch(confirmBankTransfer(data));
    } catch (error) {
      message.error("Failed to confirm bank transfer, try again later");
    }
  };
  return (
    <div className="home">
      <Modal
        title={`Confirm a bank transfer`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Payment ID"
            name="paymentId"
            rules={[{ required: true, message: "Please enter the Payment ID" }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Tramango ID"
            name="tramangoId"
            rules={[
              { required: true, message: "Please enter the Tramango ID" },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please enter the Amount" }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Currency"
            name="currency"
            rules={[{ required: true, message: "Please enter the Currency" }]}
          >
            <Select
              defaultValue="NGN"
              style={{
                width: 230,
              }}
              options={[
                {
                  value: "NGN",
                  label: "Nigerian Naira",
                },
                {
                  value: "GHS",
                  label: "Ghanaian Cedi",
                },
                {
                  value: "USD",
                  label: "United States Dollar",
                },
                {
                  value: "XOF",
                  label: "The West African CFA franc",
                },
                {
                  value: "XAF",
                  label: "Central African CFA Franc",
                },
              ]}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm a Bank Transfer (be careful, this cannot be undone)
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="listContainer">
          <section style={{ marginBottom: "40px" }}>
            <Select
              defaultValue="NGN"
              style={{
                width: 230,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "NGN",
                  label: "Nigerian Naira",
                },
                // {
                //   value: "GHS",
                //   label: "Ghanaian Cedi",
                // },
                {
                  value: "USD",
                  label: "United States Dollar",
                },
                // {
                //   value: "XOF",
                //   label: "The West African CFA franc",
                // },
                // {
                //   value: "XAF",
                //   label: "Central African CFA Franc",
                // },
              ]}
            />
            <section style={{ marginTop: "40px" }}>
              <>
                <Button onClick={showModal}>Confirm a Bank Transfer</Button>
              </>
            </section>
          </section>
        </div>
        <div className="grid">
          {currencies.map((currency) => (
            <>
              {view === currency && (
                <div style={{ width: 300 }} className="p-2" key={currency}>
                  <StatisticWidget
                    title={`Total Income (${currency})`}
                    value={
                      view === "NGN"
                        ? formatMoney(total?.[currency]?.income)
                        : view === "USD"
                        ? formatDOLS(total?.[currency]?.income)
                        : ""
                    }
                    status={8.8}
                    subtitle="Past 30 days"
                    isLoading={financeLoader}
                  />
                </div>
              )}
            </>
          ))}
          {currencies.map((currency) => (
            <>
              {view === currency && (
                <div style={{ width: 300 }} className="p-2" key={currency}>
                  <StatisticWidget
                    title={`Total PPC (${currency})`}
                    value={
                      view === "NGN"
                        ? formatMoney(total?.[currency]?.ppc)
                        : view === "USD"
                        ? formatDOLS(total?.[currency]?.ppc)
                        : ""
                    }
                    status={8.8}
                    subtitle="Past 30 days"
                    isLoading={financeLoader}
                  />
                </div>
              )}
            </>
          ))}
          {currencies.map((currency) => (
            <>
              {view === currency && (
                <div style={{ width: 300 }} className="p-2" key={currency}>
                  <StatisticWidget
                    title={`Total Service Charge (${currency})`}
                    value={
                      view === "NGN"
                        ? formatMoney(total?.[currency]?.sc)
                        : view === "USD"
                        ? formatDOLS(total?.[currency]?.sc)
                        : ""
                    }
                    status={8.8}
                    subtitle="Past 30 days"
                    isLoading={financeLoader}
                  />
                </div>
              )}
            </>
          ))}

          {currencies.map((currency) => (
            <>
              {view === currency && (
                <div style={{ width: 300 }} className="p-2" key={currency}>
                  <StatisticWidget
                    title={`Total Tax (${currency})`}
                    value={
                      view === "NGN"
                        ? formatMoney(total?.[currency]?.vat)
                        : view === "USD"
                        ? formatDOLS(total?.[currency]?.vat)
                        : ""
                    }
                    status={8.8}
                    subtitle="Past 30 days"
                    isLoading={financeLoader}
                  />
                </div>
              )}
            </>
          ))}
        </div>
        <div className="charts"></div>
        <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <input
            style={{ width: "70%", padding: "9px", marginBottom: "10px" }}
            type="text"
            placeholder="Search Transactions"
            onChange={(e) => searchTerm(e.target.value)}
          />
          {financeLoader ? (
            <Skeleton active />
          ) : (
            <Table
              columns={columns}
              dataSource={data?.payments}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Finance;
