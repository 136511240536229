import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { getToken } from "./auth-helpers";

const PostAuthRoute = ({ children, ...rest }) => {
  const { admin } = useSelector((state) => state.auth);
  const adminType = admin?.adminType;

  return !adminType ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/",
      }}
    />
  );
};

export default PostAuthRoute;
