import { createSlice } from "@reduxjs/toolkit";
import { allUsers } from "./userThunk";

const initialState = {
  users: [],
  usersLoader: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [allUsers.pending]: (state, action) => {
      state.usersLoader = true;
    },
    [allUsers.fulfilled]: (state, action) => {
      state.users = action.payload;
      state.usersLoader = false;
    },
    [allUsers.rejected]: (state, action) => {
      state.usersLoader = false;
    },
  },
});

export default userSlice.reducer;
