import React from "react";
import { useContext, useEffect } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { Provider } from "react-redux";
import store from "./store/store";
import { useSelector } from "react-redux";
import { getToken } from "./utils/auth-helpers";
import GeneralLoading from "./components/general-loading/GeneralLoading";
import Routers from "./Routes";
import { getUserData } from "./features/auth/authThunk";
import { ToastContainer, toast } from "react-toastify";
import notificationSound from "./notification.wav";
import useSound from "use-sound";
import { message } from "antd";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { authLoader } = useSelector((state) => state.auth);
  const [playNotificationSound] = useSound(notificationSound);

  console.log(process.env.REACT_APP_VAPID_PUBLIC_KEY);
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const handleServiceWorker = async () => {
        try {
          const register = await navigator.serviceWorker.register("/sw.js");
          console.log("Service Worker registered successfully:", register);

          const subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
          });

          const res = await fetch(
            `${process.env.REACT_APP_BASEURL}/notifications/subscribe`,
            {
              method: "POST",
              body: JSON.stringify({ subscription }),
              headers: {
                "content-type": "application/json",
              },
              credentials: "include",
            }
          );

          const data = await res.json();
          console.log("Subscription successful:", data);
          console.log("Subscription data:", data);

          // Send a test message to the service worker
        } catch (error) {
          console.error("Service Worker registration failed:", error);
        }
      };

      handleServiceWorker();
    }

    store.dispatch(getUserData());
  }, []);

  // Listen for messages from the service worker
  navigator.serviceWorker.addEventListener("message", (event) => {
    const { title, body } = event.data;

    // Trigger your custom notification component here
    // For example, you could set state in a React component
    // this.setState({ notification: { title, body } });
    notifyNewMessage(title + ": " + body);
  });
  const notifyNewMessage = (messageContent) => {
    message.info({
      content: messageContent,
      duration: 5,
      key: 3,
      onClick: () => message.destroy(3),
    });
    playNotificationSound(); // Play sound when new message arrives
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Provider store={store}>
        <ToastContainer />
        {authLoader ? <GeneralLoading /> : null}
        <Routers />
      </Provider>
    </div>
  );
}

export default App;
