import React, { useEffect, useState } from "react";
import "./twofactor.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { get2FA, verify2FA } from "../../features/auth/authThunk";

const TwoFactor = () => {
  const [token, setToken] = useState("");
  const { twofactor, admin } = useSelector((state) => state.auth);
  console.log(twofactor);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!admin?.mfaEnabled) {
      dispatch(get2FA());
    }
  }, [dispatch]);

  const handleEnable = (e) => {
    e.preventDefault();

    dispatch(verify2FA({ token: token }));
  };
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />

        <div className="widgets"></div>
        <div style={{ width: 250 }} className=" p-2"></div>
        <div className="charts"></div>
        {admin?.mfaEnabled && (
          <div className="listContainer">
            {" "}
            <h1>Two Factor Auth is Already Enabled</h1>{" "}
          </div>
        )}

        {!admin?.mfaEnabled && (
          <div className="listContainer">
            <div className="listTitle">Enable Two Factor Authentication</div>
            <p>
              1. Download and install an authenticator app on your mobile
              device. Some popular options include:
              <ul>
                <li>Google Authenticator</li>
                <li>Microsoft Authenticator</li>
                <li>Duo mobile</li>
              </ul>
            </p>
            <div>
              <div>
                <p>
                  2. Scan the QR code below with your authenticator app to get
                  your authentication code.
                </p>
                <img src={twofactor?.url} alt="QR Code" />
              </div>
              <div>
                <p>
                  If you can't scan the code, you can enter this secret key into
                  your authentication app
                </p>
                <input type="text" value={twofactor?.secret} readOnly />
              </div>
            </div>
            <p>
              3. Enter the 6-digit code from the authenticator app to verify
              that it's working, and then click Enable.
            </p>
            <form onSubmit={() => handleEnable()}>
              <input
                onChange={(e) => setToken(e.target.value)}
                type="text"
                placeholder="Enter 6-digit code"
                required
              />
              <button type="submit" onClick={(e) => handleEnable(e)}>
                Enable
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoFactor;
