import { createSlice } from "@reduxjs/toolkit";
import {
  createVendor,
  getAllVendors,
  activateVendor,
  suspendVendor,
  deleteVendor,
} from "./vendorThunk";

const initialState = {
  allVendors: [],
  vendorLoader: false,
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllVendors.pending]: (state, action) => {
      state.vendorLoader = true;
    },
    [getAllVendors.fulfilled]: (state, action) => {
      state.allVendors = action.payload;
      state.vendorLoader = false;
    },
    [getAllVendors.rejected]: (state, action) => {
      state.vendorLoader = false;
    },
    [createVendor.pending]: (state, action) => {
      state.vendorLoader = true;
    },
    [createVendor.fulfilled]: (state, action) => {
      state.vendorLoader = false;
      window.location.reload();
    },
    [createVendor.rejected]: (state, action) => {
      state.vendorLoader = false;
    },
    [activateVendor.pending]: (state, action) => {
      state.vendorLoader = true;
    },
    [activateVendor.fulfilled]: (state, action) => {
      state.vendorLoader = false;
      window.location.reload();
    },
    [activateVendor.rejected]: (state, action) => {
      state.vendorLoader = false;
    },
    [suspendVendor.pending]: (state, action) => {
      state.vendorLoader = true;
    },
    [suspendVendor.fulfilled]: (state, action) => {
      state.vendorLoader = false;
      window.location.reload();
    },
    [suspendVendor.rejected]: (state, action) => {
      state.vendorLoader = false;
    },
    [deleteVendor.pending]: (state, action) => {
      state.vendorLoader = true;
    },
    [deleteVendor.fulfilled]: (state, action) => {
      state.vendorLoader = false;
      window.location.reload();
    },
    [deleteVendor.rejected]: (state, action) => {
      state.vendorLoader = false;
    },
  },
});

export default vendorSlice.reducer;
