import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AdItem from "./Aditem";

import {
  todayAdvert,
  createAdvert,
  allAdverts,
  updateAdvert,
} from "../../features/advert/advertThunk";
import { deleteAdvert } from "../../features/advert/advertThunk";
import LocationPicker from "./LocationPicker";
import * as moment from "moment";
import {
  Select,
  Spin,
  Empty,
  Form,
  Input,
  Button,
  Upload,
  DatePicker,
  Modal,
  Drawer,
  Space,
} from "antd";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Dragger } = Upload;

const Advert = () => {
  const dispatch = useDispatch();
  const { todayadvert, advertLoader, all } = useSelector(
    (state) => state.advert
  );
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [editingAd, setEditingAd] = useState(null);
  const [edited, setEdited] = useState(null);
  console.log(editingAd);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const handleEdit = (ad) => {
    setEditingAd(ad);
  };
  const handleDelete = (ad) => {
    dispatch(deleteAdvert(ad?.id));
  };
  const handleOk = () => {
    setEditingAd(null);
  };

  const handleCancel = () => {
    setEditingAd(null);
  };
  const [currentAdvert, setCurrentAdvert] = useState("FRONT_PAGE");
  const [currentGroup, setCurrentGroup] = useState("LIVE");
  const sectionStyle = {
    marginBottom: "40px",
  };

  const adListStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(150px, 2fr))",
    gridGap: "20px",
  };
  useEffect(() => {
    dispatch(todayAdvert({ position: currentAdvert }));
    dispatch(allAdverts());
  }, [currentAdvert]);
  const handleChange = (value) => {
    setCurrentAdvert(value);
  };
  const handleAdsChange = (value) => {
    setCurrentGroup(value);
  };
  const onFinish = async (values) => {
    if (startDate) {
      values.durationFrom = startDate.startOf("day").format("YYYY-MM-DD");
    }
    if (values.image !== null) {
      setUploadedImage(values.image.fileList[0].originFileObj);
    }
    console.log(values.image.fileList[0].originFileObj[0]);
    // Manually set the end date to the start of the day (00:00:00)
    if (endDate) {
      values.durationTo = endDate.startOf("day").format("YYYY-MM-DD");
    }

    if (values.paid && values.paid !== "false") {
      values.paid = "true";
    } else {
      values.paid = "false";
    }
    const selectedLocations = values.locations || []; // Default to an empty array
    // Create a new File object from the image data
    // const imageFile = new File([values.image.fileList[0]], values.name);

    // Create a new FormData object
    const formData = new FormData();
    // formData.append("locations", JSON.stringify(selectedLocations));

    for (const key in values) {
      // Handle the locations field separately
      if (key === "locations") {
        formData.append("locations", JSON.stringify(selectedLocations));
      } else if (key === "image") {
        // Append the file to the FormData with the key "image"
        formData.append("image", values.image.fileList[0].originFileObj);
      } else {
        formData.append(key, values[key]);
      }
    }

    dispatch(createAdvert(formData));
  };
  const onEditFinish = async (values) => {
    // Perform update logic here using the updated values
    // Dispatch an action to update the ad in your Redux store or API
    const selectedLocations = values.locations || []; // Default to an empty array

    const formData = new FormData();
    for (const key in values) {
      // Handle the locations field separately
      if (key === "locations") {
        formData.append("locations", JSON.stringify(selectedLocations));
      } else if (key === "image" && values.image.fileList) {
        // Append the file to the FormData with the key "image"
        formData.append("image", values.image.fileList[0].originFileObj);
      } else {
        formData.append(key, values[key]);
      }
    }
    dispatch(updateAdvert({ id: editingAd.id, data: formData }));
    console.log(values);
    setEdited(values);
    // Clear the editing state
    setEditingAd(null);
  };
  const handleEndDateChange = (date, dateString) => {
    setEndDate(moment(dateString, "YYYY-MM-DD")); // Store the selected end date without time
  };

  const handleStartDateChange = (date, dateString) => {
    setStartDate(moment(dateString, "YYYY-MM-DD")); // Store the selected start date without time
  };
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets"></div>
        <div className="listContainer">
          <section style={{ marginBottom: "40px" }}>
            <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
              Create New Advert
            </Button>
          </section>
          <section style={{ marginBottom: "40px" }}>
            <Select
              defaultValue="FRONT_PAGE"
              style={{
                width: 230,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "FRONT_PAGE",
                  label: "Front page",
                },
                {
                  value: "DASHBOARD",
                  label: "Dashboard",
                },
                {
                  value: "BOOKING_CONFIRMATION",
                  label: "Booking Confirmation page",
                },
                {
                  value: "FLIGHT_RESULTS",
                  label: "Flight results page",
                },
              ]}
            />
          </section>

          <section>
            <h1>Todays Adverts for {currentAdvert} </h1>
            <div className="imageContainer">
              {advertLoader ? (
                // Show loading spinner while data is being fetched
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Spin size="large" />
                </div>
              ) : todayadvert?.length === 0 ? (
                // Show message when there are no images
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Empty description="No images available" />
                </div>
              ) : (
                // Render the images when available
                <div style={sectionStyle}>
                  <div style={adListStyle}>
                    {todayadvert?.map((advert, index) => (
                      // <div>{advert.name}</div>
                      <AdItem
                        onEdit={() => handleEdit(advert)}
                        onDelete={() => handleDelete(advert)}
                        today={true}
                        key={advert.id}
                        ad={advert}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="listContainer">
          {editingAd && (
            <Modal
              title="EDIT ADVERT"
              visible={editingAd}
              onOk={handleOk}
              onCancel={handleCancel}
              width={700}
              bodyStyle={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 200px)",
              }}
            >
              <section>
                <Form
                  onFinish={onEditFinish}
                  initialValues={editingAd}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Form.Item
                    label="Advert's image"
                    name="image"
                    rules={[{ required: true, message: "Please add an image" }]}
                  >
                    <Dragger
                      name="image"
                      accept=".jpg,.png,.jpeg,.gif"
                      maxFileSize={2 * 1024 * 1024}
                      showUploadList={false}
                      beforeUpload={(file) => {
                        // Set the uploaded image file
                        setUploadedImage(file);
                        return false; // Prevent automatic upload
                      }}
                      onChange={(info) => {
                        if (info.file.status === "removed") {
                          // Clear the uploaded image when the user removes the selected image
                          setUploadedImage(null);
                        }
                      }}
                    >
                      {uploadedImage ? (
                        <img
                          src={URL.createObjectURL(uploadedImage)}
                          alt="Uploaded"
                          style={{ width: "100%", height: "auto" }}
                        />
                      ) : (
                        <>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag image to this area to upload
                          </p>
                        </>
                      )}
                    </Dragger>
                  </Form.Item>

                  <Form.Item
                    label="Advert's name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please specify the ad's name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Organization"
                    name="organization"
                    rules={[
                      {
                        required: true,
                        message: "Please specify the ad's organization",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Full Link"
                    name="fullLink"
                    rules={[
                      {
                        pattern: /^(https?|ftp):\/\/.+$/,
                        message:
                          "Please enter a valid URL. It should start with either 'https://', 'http://' or 'ftp://'",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Ad's Locations" name="locations">
                    <Select
                      mode="multiple"
                      placeholder="Select an ad locations"
                    >
                      {/* Map over options and create Option elements */}
                      {[
                        { value: "BEN", label: "Benin" },
                        { value: "BFA", label: "Burkina Faso" },
                        { value: "CIV", label: "Côte d'Ivoire" },
                        { value: "CMR", label: "Cameroon" },
                        { value: "GHA", label: "Ghana" },
                        { value: "GIN", label: "Guinea" },
                        { value: "MLI", label: "Mali" },
                        { value: "NER", label: "Niger" },
                        { value: "SEN", label: "Senegal" },
                        { value: "TGO", label: "Togo" },
                        { value: "USA", label: "United States" },
                        { value: "CAN", label: "Canada" },
                        { value: "GBR", label: "United Kingdom" },
                        { value: "NGA", label: "Nigeria" },
                        // Add more locations as needed
                      ].map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Ad's position"
                    name="position"
                    rules={[
                      {
                        required: true,
                        message: "Please specify the ad's position",
                      },
                    ]}
                  >
                    <Select placeholder="Select an ad position">
                      {/* Map over options and create Option elements */}
                      {[
                        {
                          value: "FRONT_PAGE",
                          label: "Front page",
                        },
                        {
                          value: "DASHBOARD",
                          label: "Dashboard",
                        },
                        {
                          value: "BOOKING_CONFIRMATION",
                          label: "Booking Confirmation page",
                        },
                        {
                          value: "FLIGHT_RESULTS",
                          label: "Flight results page",
                        },
                      ].map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* <Form.Item
                    label="Ad's start date"
                    name="durationFrom"
                    rules={[
                      {
                        required: true,
                        message: "Please specify the ad's start date",
                      },
                      {
                        type: "date",
                        message:
                          "Please specify the ad's start date in format YYYY-MM-DD",
                        transform: (value) => value?.format("YYYY-MM-DD"),
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      onChange={handleStartDateChange}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Ad's end date"
                    name="durationTo"
                    rules={[
                      {
                        required: true,
                        message: "Please specify the ad's end date",
                      },
                      {
                        type: "date",
                        message:
                          "Please specify the ad's end date in format YYYY-MM-DD",
                        transform: (value) => value?.format("YYYY-MM-DD"),
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      onChange={handleEndDateChange}
                    />
                  </Form.Item> */}

                  <Form.Item label="Paid" name="paid">
                    <Input />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </section>
            </Modal>
          )}

          <Drawer
            title="Create new advert"
            width={720}
            onClose={onClose}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
          >
            <Form
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 10 }}
            >
              <Form.Item
                label="Advert's image"
                name="image"
                rules={[{ required: true, message: "Please add an image" }]}
              >
                <Dragger
                  name="image"
                  accept=".jpg,.png,.jpeg,.gif"
                  maxFileSize={2 * 1024 * 1024}
                  showUploadList={false}
                  beforeUpload={(file) => {
                    // Set the uploaded image file
                    setUploadedImage(file);
                    return false; // Prevent automatic upload
                  }}
                  onChange={(info) => {
                    if (info.file.status === "removed") {
                      // Clear the uploaded image when the user removes the selected image
                      setUploadedImage(null);
                    }
                  }}
                >
                  {uploadedImage ? (
                    <img
                      src={URL.createObjectURL(uploadedImage)}
                      alt="Uploaded"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag image to this area to upload
                      </p>
                    </>
                  )}
                </Dragger>
              </Form.Item>

              <Form.Item
                label="Advert's name"
                name="name"
                rules={[
                  { required: true, message: "Please specify the ad's name" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Organization"
                name="organization"
                rules={[
                  {
                    required: true,
                    message: "Please specify the ad's organization",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Full Link"
                name="fullLink"
                rules={[
                  {
                    pattern: /^(https?|ftp):\/\/.+$/,
                    message:
                      "Please enter a valid URL. It should start with either 'https://', 'http://' or 'ftp://'",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Ad's Locations" name="locations">
                <Select mode="multiple" placeholder="Select an ad locations">
                  {/* Map over options and create Option elements */}
                  {[
                    { value: "BEN", label: "Benin" },
                    { value: "BFA", label: "Burkina Faso" },
                    { value: "CIV", label: "Côte d'Ivoire" },
                    { value: "CMR", label: "Cameroon" },
                    { value: "GHA", label: "Ghana" },
                    { value: "GIN", label: "Guinea" },
                    { value: "MLI", label: "Mali" },
                    { value: "NER", label: "Niger" },
                    { value: "SEN", label: "Senegal" },
                    { value: "TGO", label: "Togo" },
                    { value: "USA", label: "United States" },
                    { value: "CAN", label: "Canada" },
                    { value: "GBR", label: "United Kingdom" },
                    { value: "NGA", label: "Nigeria" },
                    // Add more locations as needed
                  ].map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Ad's position"
                name="position"
                rules={[
                  {
                    required: true,
                    message: "Please specify the ad's position",
                  },
                ]}
              >
                <Select placeholder="Select an ad position">
                  {/* Map over options and create Option elements */}
                  {[
                    {
                      value: "FRONT_PAGE",
                      label: "Front page",
                    },
                    {
                      value: "DASHBOARD",
                      label: "Dashboard",
                    },
                    {
                      value: "BOOKING_CONFIRMATION",
                      label: "Booking Confirmation page",
                    },
                    {
                      value: "FLIGHT_RESULTS",
                      label: "Flight results page",
                    },
                  ].map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Ad's start date"
                name="durationFrom"
                rules={[
                  {
                    required: true,
                    message: "Please specify the ad's start date",
                  },
                  {
                    type: "date",
                    message:
                      "Please specify the ad's start date in format YYYY-MM-DD",
                    transform: (value) => value?.format("YYYY-MM-DD"),
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={handleStartDateChange}
                />
              </Form.Item>

              <Form.Item
                label="Ad's end date"
                name="durationTo"
                rules={[
                  {
                    required: true,
                    message: "Please specify the ad's end date",
                  },
                  {
                    type: "date",
                    message:
                      "Please specify the ad's end date in format YYYY-MM-DD",
                    transform: (value) => value?.format("YYYY-MM-DD"),
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={handleEndDateChange}
                />
              </Form.Item>

              <Form.Item label="Paid" name="paid">
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Drawer>

          <section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                border: "1px solid #ccc",
              }}
            >
              <section style={{ marginBottom: "40px" }}>
                <Select
                  defaultValue={currentGroup}
                  style={{
                    width: 230,
                  }}
                  onChange={handleAdsChange}
                  options={[
                    {
                      value: "LIVE",
                      label: "Live Ads",
                    },
                    {
                      value: "UPCOMING",
                      label: "Upcomind Ads",
                    },
                    {
                      value: "PAST",
                      label: "Past Ads",
                    },
                    {
                      value: "UNPAID",
                      label: "Unpaid Ads",
                    },
                  ]}
                />
              </section>
              {currentGroup === "PAST" && (
                <div style={sectionStyle}>
                  <h2>Past Ads</h2>
                  <div style={adListStyle}>
                    {all?.past?.map((ad) => (
                      <AdItem
                        onDelete={(e) => handleDelete(ad)}
                        onEdit={(e) => handleEdit(ad)}
                        today={false}
                        key={ad.id}
                        ad={ad}
                      />
                    ))}
                  </div>
                </div>
              )}
              {currentGroup === "UPCOMING" && (
                <div style={sectionStyle}>
                  <h2>Upcoming Ads</h2>
                  <div style={adListStyle}>
                    {all?.upcoming?.map((ad) => (
                      <AdItem
                        onDelete={() => handleDelete(ad)}
                        onEdit={() => handleEdit(ad)}
                        today={false}
                        key={ad.id}
                        ad={ad}
                      />
                    ))}
                  </div>
                </div>
              )}

              {currentGroup === "LIVE" && (
                <div style={sectionStyle}>
                  <h2>Live Ads</h2>
                  <div style={adListStyle}>
                    {all?.live?.map((ad) => (
                      <AdItem
                        onDelete={() => handleDelete(ad)}
                        onEdit={() => handleEdit(ad)}
                        today={false}
                        key={ad.id}
                        ad={ad}
                      />
                    ))}
                  </div>
                </div>
              )}

              {currentGroup === "UNPAID" && (
                <div style={sectionStyle}>
                  <h2>Unpaid Ads</h2>
                  <div style={adListStyle}>
                    {all?.unpaid?.map((ad) => (
                      <AdItem
                        onDelete={() => handleDelete(ad)}
                        onEdit={() => handleEdit(ad)}
                        today={false}
                        key={ad.id}
                        ad={ad}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Advert;
