import axios from "axios";
import { setAuthHeaders } from "../utils/setAuthHeaders";

const API_URL = process.env.REACT_APP_BASEURL;

const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const createAd = (payload) => {
  const URL = `${API_URL}/ad/create`;

  return axios(URL, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.error;
    });
};

export const getTodaysAds = (payload) => {
  const URL = `${API_URL}/ad/today`;

  return axios(URL, {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.error;
    });
};

export const getAllAds = (payload) => {
  const URL = `${API_URL}/ads`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.error;
    });
};

export const updateAds = (payload) => {
  const { id, data } = payload;
  const URL = `${API_URL}/ad/${id}`;
  return axios(URL, {
    method: "PATCH",

    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    withCredentials: true,
    data: data,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.error;
    });
};
export const deleteAds = (payload) => {
  const URL = `${API_URL}/ad/${payload}`;
  return axios(URL, {
    method: "DELETE",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.error;
    });
};
