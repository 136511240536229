import React from "react";
import LoadingGif from "./loading.gif";
import styles from "./Loader.module.scss";

const GeneralLoading = () => {
  return (
    <div className={styles.loader}>
      <img src={LoadingGif} alt="loading" width="90" height="120" />
    </div>
  );
};

export default GeneralLoading;
