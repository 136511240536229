import React from "react";
import { Card, List, Table, Typography } from "antd";

export const CurrencyDetails = ({ currency }) => {
  const {
    to,
    flex,
    currency: currencyName,
    createdAt,
    updatedAt,
    comments,
  } = currency;
  const columns = [
    {
      title: "Target Currency",
      dataIndex: "targetCurrency",
      key: "targetCurrency",
    },
    {
      title: "Black Market Rate",
      dataIndex: "blackmarketRate",
      key: "blackmarketRate",
    },
    {
      title: "Official Rate",
      dataIndex: "officialRate",
      key: "officialRate",
    },
    {
      title: "IATA Rate",
      dataIndex: "iataRate",
      key: "iataRate",
    },
  ];
  const data = Object.entries(to).map(([targetCurrency, rates], index) => ({
    key: index,
    targetCurrency,
    ...rates,
  }));
  return (
    <div>
      <Typography.Title level={3}>
        Conversion Rates:{currencyName}
      </Typography.Title>
      <Table columns={columns} dataSource={data} />
      {flex && currencyName !== "USD" && (
        <Card title="Flex Commission">
          <List>
            {Object.entries(flex?.commission).map(
              ([commissionType, commission]) => (
                <List.Item key={commissionType}>
                  {commissionType}:
                  <List>
                    <List.Item>
                      Original Percentage: {commission.originalPercentage}
                    </List.Item>
                    <List.Item>
                      Discounted Percentage: {commission.discountedPercentage}
                    </List.Item>
                  </List>
                </List.Item>
              )
            )}
          </List>
        </Card>
      )}

      <Typography.Title level={3}>Update History:</Typography.Title>
      <List>
        {comments.map((comment, index) => (
          <List.Item key={index}>{comment}</List.Item>
        ))}
      </List>
    </div>
  );
};
