import { createSlice } from "@reduxjs/toolkit";

import {
  addAdmin,
  allStaff,
  deleteAccount,
  enableAdmin,
  disableAdmin,
} from "./staffThunk";
import { message } from "antd";

const initialState = {
  staff: [],
  staffLoader: false,
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {},
  extraReducers: {
    [deleteAccount.pending]: (state, action) => {
      state.staffLoader = true;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.staffLoader = false;
      window.location.reload();
    },
    [deleteAccount.rejected]: (state, action) => {
      state.staffLoader = false;
      message.error(action.error.message);
    },
    [enableAdmin.pending]: (state, action) => {
      state.staffLoader = true;
    },
    [enableAdmin.fulfilled]: (state, action) => {
      state.staffLoader = false;
      window.location.reload();
    },
    [enableAdmin.rejected]: (state, action) => {
      state.staffLoader = false;
      message.error(action.payload.message);
    },
    [disableAdmin.pending]: (state, action) => {
      state.staffLoader = true;
    },
    [disableAdmin.fulfilled]: (state, action) => {
      state.staffLoader = false;
      window.location.reload();
    },
    [disableAdmin.rejected]: (state, action) => {
      state.staffLoader = false;
      message.error(action.payload.message);
    },

    [allStaff.pending]: (state, action) => {
      state.staffLoader = true;
    },
    [allStaff.fulfilled]: (state, action) => {
      state.staff = action.payload;
      state.staffLoader = false;
    },
    [allStaff.rejected]: (state, action) => {
      state.staffLoader = false;
    },
    [addAdmin.pending]: (state, action) => {
      state.staffLoader = true;
    },
    [addAdmin.fulfilled]: (state, action) => {
      state.staffLoader = false;
      window.location.reload();
      message.success(action.payload.message);
    },
    [addAdmin.rejected]: (state, action) => {
      state.staffLoader = false;
      message.error(action.error.message);
    },
  },
});

export default staffSlice.reducer;
