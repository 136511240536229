import * as api from "../../services/flight-service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const flightBookings = createAsyncThunk(
  "GET_FLIGHT_BOOKINGS",
  async (payload) => {
    const response = await api.flightBookings();
    return response;
  }
);
export const issueTicket = createAsyncThunk("ISSUE TICKET", async (payload) => {
  const response = await api.issueTicket(payload);
  return response;
});

export const cancelOrder = createAsyncThunk("CANCEL ORDER", async (payload) => {
  const response = await api.cancelOrder(payload);
  return response;
});
export const numberofFlightsInMonth = createAsyncThunk(
  "NUMBER_OF_FLIGHTS_IN_MONTH",
  async (payload) => {
    const response = await api.numberOfFlightsForMonth();
    return response;
  }
);
export const numberofSuccessfulFlightsInMonth = createAsyncThunk(
  "NUMBER_OF_SUCCESSFUL_FLIGHTS_IN_MONTH",
  async (payload) => {
    const response = await api.numberOfSuccessfulFlightsForMonth();
    return response;
  }
);
export const numberofFailedFlightsInMonth = createAsyncThunk(
  "NUMBER_OF_FAILED_FLIGHTS_IN_MONTH",
  async (payload) => {
    const response = await api.numberOfFailedFlightsForMonth();
    return response;
  }
);
export const numberofFlightsInYear = createAsyncThunk(
  "NUMBER_OF_FLIGHTS_IN_YEAR",
  async (payload) => {
    const response = await api.numberOfFlightsforAYear();
    return response;
  }
);
export const numberofSuccessfulFlightsInYear = createAsyncThunk(
  "NUMBER_OF_SUCCESSFUL_FLIGHTS_IN_YEAR",
  async (payload) => {
    const response = await api.numberOfSuccessfulFlightsforAYear();
    return response;
  }
);
export const numberofFailedFlightsInYear = createAsyncThunk(
  "NUMBER_OF_FAILED_FLIGHTS_IN_YEAR",
  async (payload) => {
    const response = await api.numberOfFailedFlightsforAYear();
    return response;
  }
);
