import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as formats from "../../utils/FormatingFunctions";
import axios from "axios";

import StatisticWidget from "../../components/shared-components/StatisticWidget";
import {
  Select,
  Table,
  Skeleton,
  Button,
  Modal,
  Form,
  InputNumber,
  Input,
  message,
  Space,
} from "antd";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./Flights.scss";
import { getAllFlightBookings } from "../../features/finance/financeThunk";
import { issueTicket, cancelOrder } from "../../features/flight/flightThunk";
import { IoAirplane } from "react-icons/io5";
import { formatMoney, formatDOLS } from "../../utils/FormatingFunctions";

import qs from "qs";
import { getAirline } from "../../utils/getAirlineNames";
const API_URL = process.env.REACT_APP_BASEURL;

const Flights = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);

  const [tripdata, setTripData] = useState();
  const { financeLoader } = useSelector((state) => state.finance);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
    },
  });

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim()); // Trim leading/trailing spaces
    if (!value) {
      // Check if search term is empty
      fetchData(); // Call fetchData to fetch initial data
    } else {
      searchData(value); // Call searchData for filtering
    }
  };
  const onFinish = (values) => {
    const { PNR } = values;
    console.log(values);
    const tickets = tripdata?.booking_reference?.data?.travelers?.map(
      (t, index) => ({
        traveler: `${t.name.firstName} ${t.name.lastName}`,
        ticket_number: values[`ticket_number_${index}`],
      })
    );

    const requestBody = {
      tramangoId: tripdata?.tramangoId,
      PNR,
      tickets,
    };

    axios
      .post(`${API_URL}/manual-issuance`, requestBody, {
        withCredentials: true,
      })
      .then((response) => {
        message.success(response?.data?.message);
        handleTicketCancel(false);

        // Handle success (e.g., show a success message, redirect, etc.)
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        // Handle error (e.g., show an error message)
      });
  };

  const getParams = (params) => ({
    page: params.pagination.page,
  });
  const searchData = (value) => {
    setLoading(true);
    fetch(`${API_URL}/admin/bookings/search?type=FLIGHT`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      credentials: "include",
      body: JSON.stringify({ searchText: value }), // Include the search term in the body
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalBookings,
            pageSize: 20,
          },
        });
      })
      .catch((error) => {
        console.error("Error while searching:", error);
        setLoading(false); // Make sure to stop loading state in case of an error
      });
  };

  const fetchData = () => {
    setLoading(true);
    fetch(
      `${API_URL}/admin/bookings/flight?${qs.stringify(
        getParams(tableParams)
      )}`,
      {
        headers: {
          // Add your headers here
          // Example of adding an authorization header
          "Content-Type": "application/json", // Example of setting Content-Type header
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalBookings,
            pageSize: 20,
          },
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams.pagination?.page,
  ]);
  const handleTableChange = (pagination, filters, sorter) => {
    // Update pagination parameters correctly
    console.log(pagination);
    const newTableParams = {
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: data.metadata.totalBookings,
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
    };
    setTableParams(newTableParams);
    console.log(tableParams);
  };

  // const handleCancelTicket = (id) => {
  //   dispatch(cancelOrder(id));
  //   console.log(id);
  // };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showTicketModal = () => {
    setIsModalVisible(true);
  };

  const handleTicketOk = () => {
    setIsTicketModalOpen(false);
    form.resetFields();
  };

  const handleTicketCancel = () => {
    setIsTicketModalOpen(false);
    form.resetFields();
  };

  const columns = [
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      key: "email",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Departure",
      dataIndex: "destination",
      key: "destination",
      render: (text, record) =>
        record?.booking_reference?.data?.flightOffers?.map((r) => {
          return r.itineraries.map((i) => {
            return i.segments.map((s) => {
              return <p>{s.departure.iataCode}</p>;
            });
          });
        }),
    },
    {
      title: "Arrival",
      dataIndex: "destination",
      key: "destination",
      render: (text, record) =>
        record?.booking_reference?.data?.flightOffers?.map((r) => {
          return r.itineraries.map((i) => {
            return i.segments.map((s) => {
              return <p>{s.arrival.iataCode}</p>;
            });
          });
        }),
    },
    {
      title: "PNR",
      dataIndex: "reference",
      key: "reference",
      render: (text, record) =>
        record?.booking_reference?.data?.associatedRecords?.map((item) => (
          <a>{item.reference}</a>
        )),
    },
    {
      title: "Office",
      dataIndex: "tramangoOffice",
      key: "tramangoOffice",
      render: (text, record) =>
        record?.booking_reference?.data?.flightOffers?.map((item) => (
          <a>{item.tramangoOffice}</a>
        )),
    },

    {
      title: "Tramango ID",
      dataIndex: "tramangoId",
      key: "tramangoId",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <>
          {" "}
          <a>{record.currency === "NGN" && formatMoney(record.amount)}</a>
          <a>{record.currency === "USD" && formatDOLS(record.amount)}</a>
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              showModal();
              setTripData(record);
            }}
          >
            View details
          </Button>
          {!record?.booking_reference?.data?.associatedRecords?.[0]
            .reference && (
            <Button
              onClick={() => {
                setIsTicketModalOpen(true);
                console.log(record.booking_reference.data.travelers);
                setTripData(record);
              }}
            >
              Manually Issue Ticket
            </Button>
          )}
          {/* <Button
            onClick={() => {
              handleCancelTicket(record.booking_reference.data.id);
            }}
          >
            Cancel Order
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />

        <Modal
          title={`Issue Ticket`}
          open={isTicketModalOpen}
          onOk={handleTicketOk}
          onCancel={handleTicketCancel}
        >
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="PNR"
              name="PNR"
              rules={[
                {
                  required: true,
                  message: "Please enter the PNR",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <>
              {tripdata?.booking_reference?.data?.travelers?.map((t, index) => (
                <Form.Item
                  label={`Enter ticket number for ${t.name.firstName} ${t.name.lastName}`}
                  name={`ticket_number_${index}`}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Ticket Number",
                    },
                  ]}
                  key={index}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              ))}
            </>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Manually Issue Ticket
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="FLIGHT BOOKING DETAILS"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          <h4>User Data</h4>
          <p>email:{tripdata?.email}</p>

          <h4>associatedRecords</h4>
          {tripdata?.booking_reference?.data?.associatedRecords?.map(
            (r, index) => (
              <div key={index}>
                <p>
                  Reference number {"(PNR)"}: {r.reference}
                </p>
              </div>
            )
          )}
          <h4>Travelers</h4>
          {tripdata?.booking_reference?.data?.travelers?.map((t, index) => (
            <div key={index}>
              <p>
                Name: {t.name.firstName} {t.name.lastName}
              </p>
              <p>Phone number: {t?.contact?.phones[0]?.number}</p>
              {t?.documents?.map((d) => (
                <p>Passport number: {d.number}</p>
              ))}
            </div>
          ))}

          <h4>Booking creation date</h4>
          <div className=" flex">
            <div className="">
              <div>{formats.formatDateInfo(tripdata?.updatedAt)}.</div>
            </div>

            <div className="">{formats.formatTime(tripdata?.updatedAt)}</div>
          </div>

          <h4>Itineraries</h4>
          {/* {tripdata?.booking_reference?.data?.flightOffers?.map((f, index) => (
            <div>
              {f.itineraries.map((it) => (
                <div>
                  {it.segments.map((s, index) => (
                    <p>
                      Departure {index + 1}: {s.departure.iataCode}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          ))}

          {tripdata?.booking_reference?.data?.flightOffers?.map((f) => (
            <div>
              {f.itineraries.map((it) => (
                <div>
                  {it.segments.map((s, index) => (
                    <p>
                      Arrival {index + 1}: {s.arrival.iataCode}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          ))} */}

          {tripdata?.booking_reference?.data?.flightOffers?.map(
            (flightDetails, index) => (
              <div className="itinerary" key={index}>
                {flightDetails?.itineraries?.map((itinerary, index) => (
                  <div key={index}>
                    <div className="title">
                      <p>Flights from</p>
                      {formats.getAirportCity(
                        itinerary?.segments[0]?.departure?.iataCode
                      )}{" "}
                      to{" "}
                      {formats.getAirportCity(
                        itinerary?.segments[itinerary?.segments?.length - 1]
                          ?.arrival?.iataCode
                      )}
                    </div>
                    <div className="infos">
                      {itinerary?.segments?.map((segment, _index) => (
                        <section className="section" key={segment.id}>
                          <div>
                            <h5 className=" ">
                              {itinerary?.segments[_index]?.airlineName}
                            </h5>

                            <div className="">
                              {formats.getAirportCity(
                                segment?.departure?.iataCode
                              )}{" "}
                              ({segment?.departure?.iataCode})
                            </div>

                            <div className="">
                              {formats.getAirportName(
                                segment?.departure?.iataCode
                              )}
                            </div>

                            <div className=" flex">
                              <div className="">
                                <div>
                                  {formats.formatDateInfo(
                                    segment?.departure?.at
                                  )}
                                  .
                                </div>
                              </div>

                              <div className="">
                                {formats.formatTime(segment?.departure?.at)}
                              </div>
                            </div>

                            {segment?.carrierCode !==
                              segment?.operating?.carrierCode &&
                              segment?.operating?.carrierCode && (
                                <div>
                                  This flight is operated by{" "}
                                  {segment.operating.carrierCode}
                                </div>
                              )}

                            <span className="">
                              {formats.compareDates(
                                segment?.departure?.at,
                                segment?.arrival?.at
                              )}
                            </span>
                          </div>

                          <div className="flexcol">
                            <IoAirplane />
                            {_index !== 0 && (
                              <div className="">
                                {`${
                                  formats.calculateTimeDifference(
                                    itinerary?.segments[_index - 1].arrival.at,
                                    segment?.departure?.at
                                  )?.hours
                                }h ${
                                  formats.calculateTimeDifference(
                                    itinerary?.segments[_index - 1].arrival.at,
                                    segment?.departure?.at
                                  )?.minutes
                                }min layover`}
                              </div>
                            )}

                            <div className="">
                              {formats.formatFlightTimeNew(segment?.duration)}
                            </div>
                            <div className="flex">{segment?.airlineName}</div>
                          </div>

                          <div className="">
                            <div className=" ">
                              {formats.getAirportCity(
                                segment?.arrival?.iataCode
                              )}
                            </div>

                            <div className="">
                              {formats.getAirportName(
                                segment?.arrival?.iataCode
                              )}
                            </div>

                            <div className=" flex">
                              <div className="">
                                {formats.formatDateInfo(segment?.arrival?.at)}.
                              </div>
                              <div className="">
                                {formats.formatTime(segment?.arrival?.at)}
                              </div>
                            </div>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )
          )}

          {/* record.flightinfo.flightOffers.map((r) => {
          return <>{r.price.grandTotalTramangoNaira}</>; */}
          {/* <h4>Travel date</h4>
          {tripdata?.booking_reference?.data?.flightOffers?.map((f) => (
            <div>
              {f.itineraries.map((it) => (
                <div>
                  {it.segments.map((s, index) => (
                    <p>
                      Departure {index + 1}: {s.departure.at}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          ))} */}
          <h4>Tramango Number</h4>
          {tripdata?.tramangoId}

          <h4>Payment type</h4>
          {tripdata?.paymentMethod}
          <h4>Amount</h4>

          <>
            <>{tripdata?.currency === "NGN" && formatMoney(tripdata?.amount)}</>
            <>{tripdata?.currency === "USD" && formatDOLS(tripdata?.amount)}</>
          </>
        </Modal>
        <div className="widgets">
          <div style={{ width: 250 }} className=" p-2">
            <StatisticWidget
              title="Total Booking"
              value={`${data?.metadata?.totalBookings}`}
              status={8.8}
              subtitle="Total flight bookings"
              isLoading={financeLoader}
            />
          </div>
        </div>

        <div className="listContainer">
          <div className="listTitle">All Flights</div>
          <input
            style={{ width: "70%", padding: "9px", marginBottom: "10px" }}
            type="text"
            value={searchTerm}
            placeholder="Search for flights with Tramango ID or ref number"
            onChange={(e) => {
              handleSearch(e);
            }}
          />

          <Table
            columns={columns}
            dataSource={data?.bookings}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Flights;
