import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";

const API_URL = process.env.REACT_APP_BASEURL;

const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
export const Login = (payload) => {
  const URL = `${API_URL}/admin/login`;

  return axios(URL, {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-ota-useragent": navigator.userAgent,
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.error;
    });
};

export const getUserData = (payload) => {
  // const { id } = payload;
  const URL = `${API_URL}/user?filter=admin`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data.user)
    .catch((error) => {
      throw error;
    });
};

export const LogOut = (payload) => {
  const URL = `${API_URL}/admin/logout`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const get2FA = (payload) => {
  const URL = `${API_URL}/admin/2FA`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const verify2FA = (payload) => {
  const URL = `${API_URL}/admin/2FA`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const changePassword = (payload) => {
  const URL = `${API_URL}/admin/password`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const addKey = (payload) => {
  const URL = `${API_URL}/vault`;
  return axios(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-totp": payload.otp,
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const getAllKeys = (payload) => {
  const URL = `${API_URL}/vault`;
  return axios(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-totp": payload.otp,
    },
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const getKey = (payload) => {
  const URL = `${API_URL}/vault/${payload}`;
  return axios(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-totp": payload.otp,
    },
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const updateKey = (payload) => {
  const URL = `${API_URL}/vault/${payload}`;
  return axios(URL, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-totp": payload.otp,
    },
    withCredentials: true,
    data: payload.data,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};
