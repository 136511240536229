import axios from "axios";

import { setAuthHeaders } from "../utils/setAuthHeaders";
const API_URL = process.env.REACT_APP_BASEURL;

const adminurl = "https://tramango-core.com/api/";
const defaultHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const usersBasedOnRole = (payload) => {
  const URL = `${adminurl}userList?user_role=1`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};
export const numberOfUsers = (payload) => {
  const URL = `${adminurl}userNumber`;

  return axios(URL, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error.response.data.message);
      throw error.response.data.message;
    });
};

export const getAllUsers = (payload) => {
  const URL = `${API_URL}/users`;
  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeader),
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
