import React, { useState, useEffect } from "react";

import "./list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import {
  Table,
  Button,
  Space,
  Tabs,
  Modal,
  Input,
  Form,
  Checkbox,
  Tag,
  Skeleton,
} from "antd";
import { useAllVendors } from "../../hooks/vendor-hooks";
import { useSelector, useDispatch } from "react-redux";
import {
  createVendor,
  getAllVendors,
  activateVendor,
  suspendVendor,
  deleteVendor,
} from "../../features/vendor/vendorThunk";
import StatisticWidget from "../../components/shared-components/StatisticWidget";
const List = () => {
  const dispatch = useDispatch();
  const { allVendors, vendorLoader } = useSelector((state) => state.vendor);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TabPane } = Tabs;
  const [idata, setIData] = useState(allVendors);
  const searchTerm = (term) => {
    // const result = data.filter((item) => item.order_id === orderId);
    const result = allVendors.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(term.toLowerCase());
    });
    if (result) {
      setIData(result);
    } else {
      setIData(allVendors);
    }

    console.log(result);
  };
  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);
  useEffect(() => {
    setIData(allVendors);
  }, [allVendors]);

  const onChange = (key) => {
    console.log(key);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = (values) => {
    console.log("Vendor details:", values);
    dispatch(createVendor(values));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleActivate = (e, id) => {
    e.preventDefault();
    dispatch(activateVendor(id));
  };
  const handleSuspend = (e, id) => {
    e.preventDefault();
    dispatch(suspendVendor(id));
  };
  const handleDelete = (e, id) => {
    e.preventDefault();
    dispatch(deleteVendor(id));
  };

  // const { data, isError, isLoading } = useAllVendors();
  // console.log(data);

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 300,
      fixed: "left",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: 300,
      fixed: "left",
    },
    {
      title: "Email Adress",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Activation Status",
      dataIndex: "activation_status",
      key: "activation_status",
      render: (_, { activation_status }) => (
        <>
          {activation_status === 1 ? (
            <Tag color="volcano">DEACTIVATED</Tag>
          ) : (
            <Tag color="green">ACTIVATED</Tag>
          )}
        </>
      ),
    },

    // {
    //   title: "Tramango Points",
    //   dataIndex: "tramango_points",
    //   key: "noorder",
    // },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button size="small">VIEW</Button>
          <Button size="small">DEACTIVATE</Button>
          <Button size="small">SUSPEND</Button>
          <Button size="small">DELETE</Button>
        </Space>
      ),
    },
  ];
  // if (isLoading) {
  //   return <h1>Fetching</h1>;
  // }
  // if (isError) {
  //   return <h1>An Error has occured</h1>;
  // }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 17,
      },
    },
  };

  const [form] = Form.useForm();

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <Modal
          title="New Vendor"
          visible={isModalVisible}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              label="Vendor First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input vendor first name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Vendor Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input vendor last name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="Vendor Email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input vendor E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Vendor Password"
              name="password"
              rules={[
                { required: true, message: "Please input vendor password!" },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Vendor Password"
              name="password_confirmation"
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm vendor password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="vendor_type_id"
              label="Vendor Type"
              rules={[
                {
                  required: true,
                  message: "Please select at least one vendor type!",
                },
              ]}
            >
              <Checkbox.Group>
                {/* <Row> */}
                {/* <Col span={8}> */}
                <Checkbox
                  value="1"
                  style={{
                    lineHeight: "32px",
                  }}
                >
                  Stays
                </Checkbox>
                {/* </Col> */}
                {/* <Col span={8}> */}
                <Checkbox
                  value="2"
                  style={{
                    lineHeight: "32px",
                  }}
                >
                  Experience and Events
                </Checkbox>
                {/* </Col> */}
                {/* <Col span={8}> */}
                <Checkbox
                  value="3"
                  style={{
                    lineHeight: "32px",
                  }}
                >
                  Attractions
                </Checkbox>
                {/* </Col> */}
                {/* <Col span={8}> */}
                <Checkbox
                  value="4"
                  style={{
                    lineHeight: "32px",
                  }}
                >
                  Tours
                </Checkbox>
                {/* </Col> */}
                {/* <Col span={8}> */}
                <Checkbox
                  value="5"
                  style={{
                    lineHeight: "32px",
                  }}
                >
                  Relaxation and Beauty
                </Checkbox>
                {/* </Col> */}
                {/* <Col span={8}> */}
                <Checkbox
                  value="6"
                  style={{
                    lineHeight: "32px",
                  }}
                >
                  Outdoor and Sports
                </Checkbox>
                {/* </Col> */}
                {/* </Row> */}
              </Checkbox.Group>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Create Vendor
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <StatisticWidget
          title="Total Number of Vendors"
          value={allVendors?.length}
          status={8.8}
          subtitle="Past 30 days"
          isLoading={vendorLoader}
        />
        <div className="listContainer">
          <Button type="primary" onClick={showModal}>
            Create Vendor
          </Button>
          <div className="listTitle">Vendors</div>

          <input
            style={{ width: "70%", padding: "9px", marginBottom: "10px" }}
            type="text"
            placeholder="Search Vendors"
            onChange={(e) => searchTerm(e.target.value)}
          />

          {vendorLoader ? (
            <Skeleton active />
          ) : (
            <Tabs defaultActiveKey="1" onChange={onChange}>
              <TabPane tab="All" key="1">
                <Table
                  columns={columns}
                  dataSource={idata}
                  scroll={{
                    x: 1000,
                  }}
                />
              </TabPane>
              <TabPane tab="Stays" key="2">
                <Table columns={columns} dataSource={idata} />
              </TabPane>
              <TabPane tab="Experience" key="3">
                <Table columns={columns} dataSource={idata} />
              </TabPane>
              <TabPane tab="Attractions" key="4">
                <Table columns={columns} dataSource={idata} />
              </TabPane>
              <TabPane tab="Relaxation and beauty" key="5">
                <Table columns={columns} dataSource={idata} />
              </TabPane>
              <TabPane tab="Outdoor and Sports" key="6">
                <Table columns={columns} dataSource={idata} />
              </TabPane>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;
