import React from "react";
import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { formatMoney } from "../../utils/FormatingFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Featured = ({ cash, isLoading }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#FE5F55",
      }}
      spin
    />
  );
  function calculatePercentage(value, goal) {
    // Calculate the percentage
    const percentage = (value / goal) * 100;

    // Round the percentage to two decimal places
    const roundedPercentage = percentage.toFixed(2);

    // Return the rounded percentage as a string
    return roundedPercentage.toString();
  }

  // Example usage
  const dailyGoal = 3000000;
  const value = cash;
  const percentage = calculatePercentage(value, dailyGoal);
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Revenue</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      {isLoading ? (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          {" "}
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="bottom">
          <div className="featuredChart">
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              strokeWidth={5}
            />
          </div>
          <p className="title">Total sales made today</p>
          <p className="amount">{formatMoney(cash)}</p>
          <p className="desc">
            Previous transactions processing. Last payments may not be included.
          </p>
          <div className="summary">
            <div className="item">
              <div className="itemTitle">Target</div>
              <div className="itemResult positive">
                <div className="resultAmount">{formatMoney(dailyGoal)}</div>
              </div>
            </div>
            {/* <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">$12.4k</div>
            </div>
          </div> */}
            {/* <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">$12.4k</div>
            </div>
          </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Featured;
