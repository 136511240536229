import React, { useState, useEffect } from "react";
import "./Vault.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import StatisticWidget from "../../components/shared-components/StatisticWidget";
import { useSelector, useDispatch } from "react-redux";
import {
  getKey,
  getAllKeys,
  updateKey,
  addKey,
} from "../../features/auth/authThunk";
const Vault = () => {
  const dispatch = useDispatch();
  const [keys, setKeys] = useState([]);
  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);

  const { admin } = useSelector((state) => state.auth);

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    dispatch(getAllKeys({ otp: otp }));
  };
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />

        <div className="widgets"></div>
        <div style={{ width: 250 }} className=" p-2"></div>
        <div className="charts"></div>
        <div className="listContainer">
          <div className="listTitle">Vault</div>
          <p>
            Introducing Tramango Vault – your organization's safeguard for
            secrets and keys, where security meets simplicity! This isn't just a
            key storage system; it's your digital lockbox, ensuring your
            confidential information stays secure and accessible only to those
            with the right keys.
          </p>
          {admin?.mfaEnabled ? (
            <form onSubmit={handleOtpSubmit}>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
              />
              <button type="submit">Submit</button>
            </form>
          ) : (
            <p>You need to enable 2FA to view the keys.</p>
          )}
          {isOtpValid && keys.map((key) => <div key={key.id}>{key.name}</div>)}
        </div>
      </div>
    </div>
  );
};

export default Vault;
