// import React from "react";
// import { useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { MdAirplanemodeActive, MdAnalytics, MdSecurity } from "react-icons/md";
// import { FaBuilding, FaGamepad, FaAd, FaShieldAlt } from "react-icons/fa";
// import "./sidebar.scss";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// // import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// // import CreditCardIcon from "@mui/icons-material/CreditCard";
// import StoreIcon from "@mui/icons-material/Store";
// import InsertChartIcon from "@mui/icons-material/InsertChart";
// import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
// import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
// import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// import { Link } from "react-router-dom";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";
// import { removeToken } from "../../utils/auth-helpers";
// import { useSelector } from "react-redux";
// import { LogOut } from "../../features/auth/authThunk";
// const Sidebar = () => {
//   const dispatchr = useDispatch();
//   const location = useLocation();
//   const pathname = location.pathname;
//   const { dispatch } = useContext(DarkModeContext);
//   const handleLogout = () => {
//     dispatchr(LogOut());
//     removeToken();
//   };
//   const { admin } = useSelector((state) => state.auth);

//   const flightroles = [1, 2, 5];
//   const insuranceroles = [1, 2, 7];
//   const financeroles = [1, 2, 3];
//   const revenueroles = [1, 2, 3];
//   const analyticsroles = [1, 2, 3];
//   const staffroles = [1, 2];
//   const userroles = [1, 2, 5, 3];
//   const vaultroles = [1, 2, 3, 4, 5];
//   return (
//     <div className="sidebar">
//       <div className="top">
//         <Link to="/" style={{ textDecoration: "none" }}>
//           <span className="logo">tramango</span>
//         </Link>
//       </div>
//       <hr />
//       <div className="center">
//         <ul>
//           <p className="title">MAIN</p>
//           <Link to="/" style={{ textDecoration: "none" }}>
//             <li className={pathname === "/" ? "selected" : ""}>
//               <DashboardIcon className="icon" />
//               <span>Dashboard</span>
//             </li>
//           </Link>
//           {analyticsroles.includes(admin.admin_section_id) && (
//             <Link to="/analytics" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/analytics" ? "selected" : ""}>
//                 <MdAnalytics className="icon" />
//                 <span>Analytics and Marketing Data</span>
//               </li>
//             </Link>
//           )}

//           <p className="title">LISTS</p>
//           {userroles.includes(admin.admin_section_id) && (
//             <Link to="/tramango-users" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/tramango-users" ? "selected" : ""}>
//                 <PersonOutlineIcon className="icon" />
//                 <span>Tramango Users</span>
//               </li>
//             </Link>
//           )}

//           {staffroles.includes(admin.admin_section_id) && (
//             <Link to="/staff" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/staff" ? "selected" : ""}>
//                 <PersonOutlineIcon className="icon" />
//                 <span>Staffs</span>
//               </li>
//             </Link>
//           )}
//           {financeroles.includes(admin.admin_section_id) && (
//             <Link to="/finance" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/finance" ? "selected" : ""}>
//                 <CurrencyExchangeIcon className="icon" />
//                 <span>Finance</span>
//               </li>
//             </Link>
//           )}

//           {revenueroles.includes(admin.admin_section_id) && (
//             <Link to="/revenue" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/revenue" ? "selected" : ""}>
//                 <CurrencyExchangeIcon className="icon" />
//                 <span>Revenue</span>
//               </li>
//             </Link>
//           )}

//           {flightroles.includes(admin.admin_section_id) && (
//             <Link to="/flights" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/flights" ? "selected" : ""}>
//                 <MdAirplanemodeActive className="icon" />
//                 <span>Flights</span>
//               </li>
//             </Link>
//           )}
//           {insuranceroles.includes(admin.admin_section_id) && (
//             <Link to="/insurance" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/insurance" ? "selected" : ""}>
//                 <MdSecurity className="icon" />
//                 <span>Insurance</span>
//               </li>
//             </Link>
//           )}

//           {insuranceroles.includes(admin.admin_section_id) && (
//             <Link to="/advert" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/advert" ? "selected" : ""}>
//                 <FaAd className="icon" />
//                 <span>Advertisment</span>
//               </li>
//             </Link>
//           )}
//           {vaultroles.includes(admin.admin_section_id) && (
//             <Link to="/vault" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/vault" ? "selected" : ""}>
//                 <FaShieldAlt className="icon" />
//                 <span>Tramango Vault</span>
//               </li>
//             </Link>
//           )}

//           <p className="title">USEFUL</p>
//           <li>
//             <InsertChartIcon className="icon" />
//             <span>Stats</span>
//           </li>
//           <li>
//             <NotificationsNoneIcon className="icon" />
//             <span>Notifications</span>
//           </li>
//           <p className="title">SERVICE</p>
//           {userroles.includes(admin.admin_section_id) && (
//             <Link to="/twofactor" style={{ textDecoration: "none" }}>
//               <li className={pathname === "/tramango-users" ? "selected" : ""}>
//                 <PersonOutlineIcon className="icon" />
//                 <span>2 Factor Authentication</span>
//               </li>
//             </Link>
//           )}

//           <li>
//             <SettingsSystemDaydreamOutlinedIcon className="icon" />
//             <span>System Health</span>
//           </li>
//           <li>
//             <PsychologyOutlinedIcon className="icon" />
//             <span>Logs</span>
//           </li>
//           <li>
//             <SettingsApplicationsIcon className="icon" />
//             <span>Settings</span>
//           </li>
//           <p className="title">USER</p>
//           <li>
//             <AccountCircleOutlinedIcon className="icon" />
//             <span>Profile</span>
//           </li>
//           <li
//             onClick={() => {
//               handleLogout();
//             }}
//           >
//             <ExitToAppIcon className="icon" />
//             <span>Logout</span>
//           </li>
//         </ul>
//       </div>
//       <div className="bottom">
//         <div
//           className="colorOption"
//           onClick={() => dispatch({ type: "LIGHT" })}
//         ></div>
//         <div
//           className="colorOption"
//           onClick={() => dispatch({ type: "DARK" })}
//         ></div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Space, Divider } from "antd";
import logo from "./tramango-logo-black.png";
import {
  DashboardOutlined,
  UserOutlined,
  DollarOutlined,
  AuditOutlined,
  SecurityScanOutlined,
  AirplaneOutlined,
  InsuranceOutlined,
  AdvertisementOutlined,
  BankOutlined,
  BarChartOutlined,
  BellOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { LogOut } from "../../features/auth/authThunk";
import { removeToken } from "../../utils/auth-helpers";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const { admin } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(LogOut());
    removeToken();
  };

  const flightroles = [1, 2, 5];
  const insuranceroles = [1, 2, 5, 7];
  const financeroles = [1, 2, 3];
  const revenueroles = [1, 2, 3];
  const analyticsroles = [1, 2, 3, 6];
  const staffroles = [1, 2];
  const userroles = [1, 2, 5, 3, 6];
  const vaultroles = [1, 2, 3, 4, 5];
  const advertroles = [1, 2, 6];
  const discountroles = [1, 2, 3, 6];

  return (
    <Sider width={300} className="site-layout-background">
      <div className="logo" style={{ textAlign: "center", margin: "16px" }}>
        <img src={logo} alt="Logo" style={{ width: "125px", height: "auto" }} />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[pathname]}
        defaultOpenKeys={["main", "lists", "useful", "service", "user"]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <SubMenu key="main" icon={<DashboardOutlined />} title="MAIN">
          <Menu.Item key="/">
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          {analyticsroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/analytics">
              <Link to="/analytics">Analytics and Marketing Data</Link>
            </Menu.Item>
          )}
        </SubMenu>

        <SubMenu key="lists" icon={<MenuFoldOutlined />} title="LISTS">
          {userroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/tramango-users">
              <Link to="/tramango-users">Tramango Users</Link>
            </Menu.Item>
          )}
          {discountroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/discount">
              <Link to="/discount">Discounts</Link>
            </Menu.Item>
          )}
          {staffroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/staff">
              <Link to="/staff">Staffs</Link>
            </Menu.Item>
          )}
          {financeroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/finance">
              <DollarOutlined className="icon" />
              <Link to="/finance">Finance</Link>
            </Menu.Item>
          )}
          {revenueroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/revenue">
              <Link to="/revenue">Revenue</Link>
            </Menu.Item>
          )}
          {flightroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/flights">
              <Link to="/flights">Flights</Link>
            </Menu.Item>
          )}
          {insuranceroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/insurance">
              <Link to="/insurance">Insurance</Link>
            </Menu.Item>
          )}
          {advertroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/advert">
              <Link to="/advert">Advertisement</Link>
            </Menu.Item>
          )}
          {vaultroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/vault">
              <Link to="/vault">Tramango Vault</Link>
            </Menu.Item>
          )}
        </SubMenu>

        <SubMenu key="useful" icon={<MenuFoldOutlined />} title="USEFUL">
          <Menu.Item key="stats">
            <Link to="/stats">Stats</Link>
          </Menu.Item>
          <Menu.Item key="notifications">
            <Link to="/notifications">Notifications</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="service" icon={<MenuFoldOutlined />} title="SERVICE">
          {userroles.includes(admin.admin_section_id) && (
            <Menu.Item key="/twofactor">
              <Link to="/twofactor">2 Factor Authentication</Link>
            </Menu.Item>
          )}
          <Menu.Item key="system-health">System Health</Menu.Item>
          <Menu.Item key="logs">Logs</Menu.Item>
          <Menu.Item key="settings">Settings</Menu.Item>
        </SubMenu>

        <SubMenu key="user" icon={<MenuFoldOutlined />} title="USER">
          <Menu.Item key="profile">
            <Link to="/profile">Profile</Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout}>
            <LogoutOutlined className="icon" />
            Logout
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
