import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  LOGIN,
  getUserData,
  LogOut,
  get2FA,
  verify2FA,
  addKey,
  getAllKeys,
  getKey,
  updateKey,
  ChangePassword,
} from "./authThunk";
import { removeToken } from "../../utils/auth-helpers";
const initialState = {
  token: "",
  admin: null,
  authLoader: false,
  twofactor: null,
  keys: null,
  key: null,
  challenge: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [LOGIN.pending]: (state, action) => {
      state.authLoader = true;
    },
    [LOGIN.fulfilled]: (state, action) => {
      state.authLoader = false;
      state.challenge = action.payload;
      action.payload.adminType && window.location.reload();
      message.error(action.payload.message);
    },
    [LOGIN.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
    },
    [getUserData.pending]: (state, action) => {
      state.authLoader = true;
    },
    [getUserData.fulfilled]: (state, action) => {
      state.admin = action.payload;
      state.authLoader = false;
    },
    [getUserData.rejected]: (state, action) => {
      state.authLoader = false;
      removeToken();
    },
    [LogOut.pending]: (state) => {
      state.authLoader = true;
    },
    [LogOut.fulfilled]: (state) => {
      state.authLoader = false;
      state.token = "";
      state.admin = null;
    },
    [LogOut.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
    },
    [get2FA.pending]: (state) => {},
    [get2FA.fulfilled]: (state, action) => {
      state.twofactor = action.payload;
    },
    [get2FA.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
    },
    [verify2FA.pending]: (state) => {
      state.authLoader = true;
    },
    [verify2FA.fulfilled]: (state, action) => {
      state.authLoader = false;
      // state.twofactor = action.payload;
    },
    [verify2FA.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
      console.log(action);
    },

    [ChangePassword.pending]: (state) => {
      state.authLoader = true;
    },
    [ChangePassword.fulfilled]: (state, action) => {
      state.authLoader = false;
      window.location.reload();
      // state.twofactor = action.payload;
    },
    [ChangePassword.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
      console.log(action);
    },

    [addKey.pending]: (state) => {
      state.authLoader = true;
    },
    [addKey.fulfilled]: (state, action) => {
      state.authLoader = false;
      message.success(action.payload.message);
    },
    [addKey.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
    },
    [getAllKeys.pending]: (state) => {},
    [getAllKeys.fulfilled]: (state, action) => {
      state.authLoader = false;
      state.keys = action.payload;
    },
    [getAllKeys.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
    },
    [getKey.pending]: (state) => {
      state.authLoader = true;
    },
    [getKey.fulfilled]: (state, action) => {
      state.authLoader = false;
      state.key = action.payload;
    },
    [getKey.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
    },
    [updateKey.pending]: (state) => {
      state.authLoader = true;
    },
    [updateKey.fulfilled]: (state, action) => {
      state.authLoader = false;
      message.success(action.payload.message);
    },
    [updateKey.rejected]: (state, action) => {
      state.authLoader = false;
      message.error(action.error.message);
    },
  },
});

// export const {} = authSlice.actions;
export default authSlice.reducer;
